import { RouteRecordRaw } from 'vue-router'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

export function adapters (): RouteRecordRaw[] {
  const context = ContextType.BIDDERS
  return [
    {
      path: 'adapters',
      name: `${context}.adapters.index`,
      component: () => import('./views/Index.vue'),
      meta: {
        title: i18n.global.t('labels.adapter', 2),
        featuresPermissions: ['adapters'],
        requireService: true
      },
      children: [
        {
          path: ':adapterId/edit',
          name: `${context}.adapters.edit`,
          component: () => import('./views/Edit.vue'),
          meta: {
            title: i18n.global.t('adapters.edit', { name: ':adapterName' }),
            pattern: ':adapterName',
            featuresPermissions: ['adapters'],
            requireService: true
          },
          props: route => ({ adapterId: route.params.adapterId })
        }
      ]
    }

  ]
}
