<template>
  <Collapse header-class="py-4">
    <template #header>
      {{ t('labels.widget', 2) }}
    </template>

    <div class="grid grid-cols-2 grid-cols-vertical-form-reverse text-sm striped mb-2 overflow-y-auto max-h-40">
      <template
        v-for="w in widgets"
        :key="w.uid"
      >
        <div
          class="font-medium break-words cursor-pointer p-1"
          :title="t('dashboards.viewWidget')"
          @click="scrollToWidget(w)"
        >
          {{ w.title || w.uid }}
        </div>
        <div class="flex flex-row flex-wrap gap-1 p-1">
          <WidgetEditor
            :value="w"
            @update:value="$emit('update', $event)"
          />
          <button
            :title="t('actions.duplicate')"
            class="focus:outline-none align-middle"
            @click="$emit('duplicate', w)"
          >
            <Square2StackIcon class="h-4 w-4" />
          </button>
          <button
            :title="t('actions.remove')"
            class="focus:outline-none"
            @click="$emit('remove', w)"
          >
            <TrashIcon class="h-4 w-4" />
          </button>
          <WidgetInfo
            :definition="w"
            button-class="inline-block cursor-pointer align-middle focus:outline-none"
          />
        </div>
      </template>
    </div>
    <p class="font-medium">
      {{ t('actions.create-new', [t('app.widget')]) }}
    </p>
    <WidgetForm
      class="w-full"
      :stores="stores"
      @submit="$emit('submit', $event)"
    />
  </Collapse>
</template>

<script lang="ts">
import { Square2StackIcon, TrashIcon } from '@heroicons/vue/24/outline'
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

import { WidgetDefinition, Store } from '@/plugins/dashboard'

import Collapse from '@/components/Collapse/Collapse.vue'

import { highlightWidget } from '../widgets/Widget'
import WidgetEditor from '../widgets/WidgetEditor.vue'
import WidgetInfo from '../widgets/WidgetInfo.vue'

import WidgetForm from './WidgetForm.vue'

export default defineComponent({
  components: {
    Collapse,
    WidgetForm,
    WidgetInfo,
    Square2StackIcon,
    TrashIcon,
    WidgetEditor
  },
  props: {
    widgets: {
      type: Array as PropType<WidgetDefinition[]>,
      required: true
    },
    stores: {
      type: Array as PropType<Store[]>,
      required: true
    }
  },
  emits: ['submit', 'update', 'remove', 'duplicate'],
  setup () {
    const { t } = useI18n()
    const scrollToWidget = (w : WidgetDefinition) => {
      highlightWidget(w.uid)
    }

    return {
      scrollToWidget,
      t
    }
  }
})
</script>
<style scoped>
@tailwind base;
@tailwind utilities;

@layer utilities {
  .grid.striped > div:nth-child(4n+1),
  .grid.striped > div:nth-child(4n+2) {
    @apply bg-gray-100
  }
}
</style>
