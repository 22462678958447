import { format } from 'date-fns'
import { TooltipComponent, LegendComponent, GridComponent } from 'echarts/components'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { defineComponent } from 'vue'
import VChart from 'vue-echarts'

import { download } from '@/plugins/dashboard/export'

import { ECHARTS_THEME_NAME } from '../theme'

import { WIDGET_PROPS } from './Widget'

use([CanvasRenderer, TooltipComponent, LegendComponent, GridComponent])

// Mixin for charts
// For "save as image" to work, you need to set the "v-chart" tag's ref to "chart"
export default defineComponent({
  components: {
    VChart
  },
  props: {
    ...WIDGET_PROPS
  },
  data () {
    return {
      initOptions: {
        renderer: 'canvas' as ('canvas' | 'svg' | undefined)
      },
      ECHARTS_THEME_NAME
    }
  },
  methods: {
    onSaveAsImageClicked (title: string) {
      if (this.$refs.chart) {
        const chartDataURL = (this.$refs.chart as typeof VChart).chart.getConnectedDataURL({})
        const titleWithPeriods = title + '_' + format(this.filters.dateRange.from, 'yyyy-MM-dd') + ' - ' + format(this.filters.dateRange.to, 'yyyy-MM-dd')
        download(titleWithPeriods, chartDataURL)
      }
    }
  }
})
