<template>
  <div
    class="flex flex-nowrap space-x-1 w-full overflow-hidden"
    :title="title"
  >
    <ul>
      <li
        v-for="val in value"
        :key="getKey(val)"
      >
        {{ getValue(val) }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import get from 'lodash/get'
import { computed, defineComponent, PropType } from 'vue'

import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter'

export default defineComponent({
  props: {
    value: {
      type: Array as PropType<string[] | number[] | object[]>,
      required: true
    },
    key: {
      type: String,
      default: null
    }
  },
  setup (props) {
    const getKey = (val: any) => {
      const v = (props.key) ? props.key : val
      return capitalizeFirstLetter(v)
    }

    const getValue = (val: any) => {
      const v = (props.key) ? get(val, props.key) : val
      return capitalizeFirstLetter(v)
    }

    const title = computed(() => props.value.map(getValue).join(', '))

    return {
      getKey,
      getValue,
      title
    }
  }
})
</script>
