<template>
  <WidgetShell
    :loading="isLoading"
    :title="title"
    :definition="definition"
    :edit-mode="editMode"
    :enlarged="enlarged"
    :has-data="hasData"
    @json-editor-input="onJSONEditorInput"
    @remove-clicked="$emit('remove-clicked')"
    @export="onExport(title, $event)"
    @save-image="onSaveAsImageClicked(title)"
    @expand-clicked="onExpandClicked"
  >
    <template #header>
      <MultiSelect
        v-model="metric"
        name="metric"
        mode="single"
        :close-on-select="true"
        :options="definition.metrics.map(m => ({ id: m.name, name: translateDBName(m.name.toString()) }))"
        :classes="multiselectTailwindClasses"
        :can-clear="false"
        :can-deselect="false"
      />
    </template>

    <v-chart
      v-if="!isLoading"
      ref="chart"
      class="flex-grow min-h-px"
      :init-options="initOptions"
      :option="chartOptions"
      :theme="ECHARTS_THEME_NAME"
      autoresize
    />
  </WidgetShell>
</template>

<script lang="ts">
import { Grouping } from 'crossfilter2'
import { EChartsOption, MapSeriesOption } from 'echarts'
import { MapChart } from 'echarts/charts'
import { VisualMapComponent } from 'echarts/components'
import { use, registerMap, getMap } from 'echarts/core'
import { defineComponent, ref, computed, watch } from 'vue'

import { echartsTooltipFormatter, translateDBName, ValueFormatter, ValueFormatterOptions, WidgetDefinition } from '@/plugins/dashboard'

import MultiSelect from '@/components/Form/FormMultiselect.vue'

import WorldMap from '../maps/world.geo.json'

import ChartWidget from './ChartWidgetMixin'
import { setupWidget, WidgetSettings, multiselectTailwindClasses } from './Widget'
import WidgetShell from './WidgetShell.vue'

use([MapChart, VisualMapComponent])

export default defineComponent({
  components: {
    WidgetShell,
    MultiSelect
  },
  mixins: [ChartWidget],
  emits: ['remove-clicked', 'definition-changed'],
  setup (props, { emit }) {
    let cachedGroup: ReadonlyArray<Grouping<any, Record<string, any>>> | undefined
    const countryNames: Record<string, string> = {}
    WorldMap.features.forEach(f => {
      countryNames[f.properties.iso_a3] = f.properties.name
    })

    // Data
    const metric = ref(props.definition.metrics[0].name)
    const chartOptions = ref({})

    const updateChart = (v: ReadonlyArray<Grouping<any, Record<string, any>>> | undefined) => {
      cachedGroup = v
      if (!cachedGroup) {
        return
      }

      // Register map if not already available
      if (getMap('world') === null) {
        registerMap('world', WorldMap as any)
      }

      const currentMetric = props.definition.metrics.find(m => m.name === metric.value)!
      const records = cachedGroup
      const formatter = new ValueFormatter()
      const options: ValueFormatterOptions = {
        metric: metric.value,
        dimensionName: props.definition.dimensions[0].name
      }
      const data = formatter.format(records, options)
      const values = data.map(r => r.value)
      const min = Math.min(...values)
      const max = Math.max(...values)
      const newOptions: EChartsOption = {
        series: [
          {
            name: translateDBName(metric.value),
            type: 'map',
            roam: true,
            map: 'world',
            nameProperty: 'iso_a3',
            emphasis: {
              label: {
                show: false
              }
            },
            data
          } as MapSeriesOption
        ],
        visualMap: {
          left: 'right',
          calculable: true,
          min,
          max,
          range: [min, max],
          inRange: {
            color: [
              '#f7fcf0',
              '#e0f3db',
              '#ccebc5',
              '#a8ddb5',
              '#7bccc4',
              '#4eb3d3',
              '#2b8cbe',
              '#0868ac',
              '#084081'
            ]
          }
        },
        grid: {
          containLabel: true,
          left: 'left',
          right: 0,
          top: 0,
          bottom: 0
        },
        tooltip: {
          trigger: 'item',
          appendToBody: true,
          formatter: echartsTooltipFormatter(currentMetric.formatter, (v) => countryNames[v])
        }
      }
      chartOptions.value = newOptions
    }

    // Watch
    watch(
      () => metric.value,
      () => updateChart(cachedGroup)
    )

    watch(
      () => props.definition,
      () => {
        metric.value = props.definition.metrics[0].name
      }
    )

    // Methods
    const onJSONEditorInput = (newDefinition: WidgetDefinition) => {
      emit('definition-changed', newDefinition)
    }

    const settings: WidgetSettings = {
      props,
      dimension: computed(() => props.definition.dimensions[0]),
      updateFunc: updateChart
    }

    return {
      // Data
      chartOptions,
      metric,

      // Methods
      onJSONEditorInput,

      // Misc
      translateDBName,
      multiselectTailwindClasses,

      ...setupWidget(settings)
    }
  }
})
</script>
