import { endOfDay, format, parse, startOfDay } from 'date-fns'

import { ComparisonRangePreset, COMPARISON_DATE_RANGE_PRESETS, DateRange, DateRangePreset, DATE_RANGE_PRESETS } from '@/components/DateRangePicker/dateRange'

import { SerializedDataDefinition } from './definition'
import { deserializeDimensions, SerializedDimension, TimeGranularityName, TIME_GRANULARITIES } from './dimensions'
import { SerializedReducer } from './reducers'
import { ComparisonGroup, deserializeSources, Filters, SerializedSource, Source } from './source'

import { Metric, RequiredFilter, ResponsiveLayout, VisualizationType, WidgetDefinition, Store, DEFAULT_MAX_DAYS_RANGE } from '.'

export interface Serializable<TSerialized> {
  serialize(): TSerialized
}

export interface Deserializable<T, TSerialized> {
  deserialize(x: TSerialized): T
}

export type SerializedMetric = {
  name: string
  formatter: string // e.g. DOLLAR, PERCENT
  reducer: SerializedReducer
}

export type SerializedWidgetDefinition = {
  uid: string
  store: string // the UID of a store
  dimensions: SerializedDimension[]
  metrics: SerializedMetric[]
  vizType: VisualizationType,
  title?: string,
  disableComparison?: boolean
  defaultSort?: {
    column: string,
    order: 'ASC' | 'DESC'
  }
}

export type SerializedStore = {
  uid: string,
  name: string,
  source: string,
  definition: SerializedDataDefinition,
  timeDimensions: string[]
  requiredFilters: RequiredFilter[]
  refreshDimensions: string[],
  granularity?: TimeGranularityName,
  disableComparison?: boolean
  periodOverride?: string[] | DateRangePreset,
  comparisonPeriodOverride?: ComparisonRangePreset
}

export type SerializedDashboard = {
  maxDaysRange: number
  sources: SerializedSource[]
  stores: SerializedStore[]
  widgets: SerializedWidgetDefinition[]
  layout: ResponsiveLayout
  granularity?: TimeGranularityName
  defaultPeriod?: string[] | DateRangePreset
  defaultComparisonPeriod?: ComparisonRangePreset
}

export type Dashboard = {
  maxDaysRange: number
  sources: Source[]
  stores: Store[]
  widgets: WidgetDefinition[]
  layout: ResponsiveLayout
  granularity: TimeGranularityName
  defaultPeriod: DateRangePreset | DateRange
  defaultComparisonPeriod?: ComparisonRangePreset
}

export type DashboardSerializerOptions = {
  maxDaysRange: number
  granularity: TimeGranularityName
  defaultPeriod: DateRangePreset | DateRange
  defaultComparisonPeriod?: ComparisonRangePreset
  sources: Source[]
  stores: Store[]
  widgets: WidgetDefinition[]
  layout: ResponsiveLayout
}

export function serializeWidgetDefinition (definition: WidgetDefinition): SerializedWidgetDefinition {
  return {
    uid: definition.uid,
    store: definition.store.uid,
    dimensions: definition.dimensions.map(d => d.serialize()),
    metrics: serializeMetrics(definition.metrics),
    vizType: definition.vizType,
    title: definition.title,
    disableComparison: definition.disableComparison,
    defaultSort: definition.defaultSort
  }
}

export function deserializeWidgetDefinition (def: SerializedWidgetDefinition, stores: Store[]): WidgetDefinition {
  if (def.metrics === undefined || typeof def.metrics !== 'object') {
    throw new Error('Invalid SerializedWidgetDefinition: missing metrics')
  }
  if (def.dimensions === undefined || !Array.isArray(def.dimensions)) {
    throw new Error('Invalid SerializedWidgetDefinition: missing dimensions')
  }

  const store = stores.find(s => s.uid === def.store)
  if (store === undefined) {
    throw new Error(`Invalid SerializedWidgetDefinition: store "${def.store}" doesn't exist`)
  }

  // Backwards compatibility for sortByDefaultOn
  let sortDefault: SerializedWidgetDefinition['defaultSort']
  const sortByDefaultOn = (def as any).sortByDefaultOn
  if (sortByDefaultOn !== undefined && typeof sortByDefaultOn === 'string') {
    sortDefault = {
      column: sortByDefaultOn,
      order: 'DESC'
    }
  }

  sortDefault = def.defaultSort || sortDefault

  const dimensions = deserializeDimensions(def.dimensions)
  const metrics = deserializeMetrics(def.metrics)

  return {
    uid: def.uid,
    dimensions,
    metrics,
    store,
    vizType: def.vizType,
    title: def.title,
    disableComparison: def.disableComparison,
    defaultSort: sortDefault && (metrics.some(m => m.name === sortDefault?.column) || dimensions.some(m => m.name === sortDefault?.column)) ? sortDefault : undefined
  }
}

export function serializeMetrics (metrics: Metric[]): SerializedMetric[] {
  return metrics.map(m => m.serialize())
}

export function deserializeMetrics (metrics: SerializedMetric[]): Metric[] {
  return metrics.map(m => Metric.deserialize(m))
}

const DATE_FORMAT = 'yyyy-MM-dd'
export function serializeDateRange (period: DateRangePreset | DateRange): string[] | DateRangePreset {
  if (typeof period === 'object') {
    return [
      format(period.start, DATE_FORMAT),
      format(period.end, DATE_FORMAT)
    ]
  }

  return period
}

export function deserializeDateRange (period?: string[] | DateRangePreset): DateRange | DateRangePreset {
  if (period === undefined) {
    return 'last_7_days'
  }
  if (Array.isArray(period)) {
    const dates = period.map(d => parse(d, DATE_FORMAT, new Date()))
    if (dates.length < 2 || dates.some(d => isNaN(d.getTime()))) {
      return 'last_7_days'
    }
    return { start: startOfDay(dates[0]), end: endOfDay(dates[1]) }
  }

  if (DATE_RANGE_PRESETS[period] === undefined) {
    return 'last_7_days'
  }
  return period
}

export function deserializeComparisonPeriod (period?: ComparisonRangePreset): ComparisonRangePreset | undefined {
  if (period === undefined || COMPARISON_DATE_RANGE_PRESETS[period] === undefined) {
    return undefined
  }

  return period
}

export class DashboardSerializer {
  serialize (options: DashboardSerializerOptions): SerializedDashboard {
    const serialized: SerializedDashboard = {
      maxDaysRange: options.maxDaysRange,
      defaultPeriod: serializeDateRange(options.defaultPeriod),
      defaultComparisonPeriod: options.defaultComparisonPeriod,
      granularity: options.granularity,
      sources: options.sources.map(s => s.serialize()),
      stores: options.stores.map(s => s.serialize()),
      widgets: [] as SerializedWidgetDefinition[],
      layout: {
        md: options.layout.md.map(l => {
          return {
            x: l.x,
            y: l.y,
            w: l.w,
            h: l.h,
            i: l.i
          }
        }),
        sm: options.layout.sm.map(l => {
          return {
            x: l.x,
            y: l.y,
            w: l.w,
            h: l.h,
            i: l.i
          }
        })
      }
    }
    options.widgets.forEach(w => {
      serialized.widgets.push(serializeWidgetDefinition(w))
    })
    return serialized
  }

  deserialize (dashboard: SerializedDashboard, comparisonGroups: ComparisonGroup[], filters: Filters, onError?: (e: any) => void, onDataLimit?: () => void): Dashboard {
    const sources = deserializeSources(dashboard.sources)
    const stores = dashboard.stores.map(s => Store.deserialize(s, sources, comparisonGroups, filters, onError, onDataLimit))
    const granularity: TimeGranularityName = dashboard.granularity === undefined || TIME_GRANULARITIES[dashboard.granularity] === undefined ? 'day' : dashboard.granularity
    return {
      maxDaysRange: dashboard.maxDaysRange || DEFAULT_MAX_DAYS_RANGE,
      sources,
      stores,
      widgets: dashboard.widgets.map(w => deserializeWidgetDefinition(w, stores)),
      layout: {
        sm: dashboard.layout.sm.map(i => Object.assign({}, i)),
        md: dashboard.layout.md.map(i => Object.assign({}, i))
      },
      granularity,
      defaultPeriod: deserializeDateRange(dashboard.defaultPeriod),
      defaultComparisonPeriod: deserializeComparisonPeriod(dashboard.defaultComparisonPeriod)
    }
  }
}
