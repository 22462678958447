import { NumberReducer, Reducer, ReducerConstructor, SerializedReducer } from '.'

/**
 * Number sum reducer.
 *
 * Also accepts an array of strings "globalSumDimensions". If provided, calculates the sum based on the dimensions
 * selected in it instead of regular group-based sum. This can be used to "ignore" one or more dimensions in the
 * crossfilter grouping.
 * Tip: give an empty globalSumDimensions array to calculate an entirely ungrouped sum.
 *
 * Limitations when using the global sum mode:
 * - the dimensions in "globalSumDimensions" must be included in the widget's metric (or dimensions) list
 *   so the postprocessor can calculate the rate from it.
 */
export class SumReducer extends NumberReducer implements Reducer {
  metric?: string
  globalSumDimensions?: string[] // Empty = ungrouped sum, Not empty = same as globalSumDimensions in RateReducer, undefined = group-based sum
  globalSum: Record<string, number>

  constructor (metric?: string, globalSumDimensions?: string[]) {
    super()
    this.metric = metric
    this.globalSumDimensions = globalSumDimensions
    this.globalSum = {}
  }

  key (key: string): string {
    return this.metric || key
  }

  getSumKey (p: Record<string, any>): string {
    if (this.globalSumDimensions === undefined) {
      return ''
    }
    return Object.entries(p)
      .filter(e => this.globalSumDimensions!.includes(e[0]))
      .map(e => e[1])
      .join('|')
  }

  addToGlobalSum (key: string, value: number): void {
    if (this.globalSum[key] === undefined) {
      this.globalSum[key] = 0
    }
    this.globalSum[key] += value
  }

  add (p: Record<string, any>, key: string, v: Record<string, any>): void {
    const metricKey = this.key(key)
    if (this.globalSumDimensions !== undefined) {
      this.addToGlobalSum(this.getSumKey(p), v[metricKey])
    } else {
      p[key] += v[metricKey]
    }
  }

  remove (p: Record<string, any>, key: string, v: Record<string, any>): void {
    const metricKey = this.key(key)
    if (this.globalSumDimensions !== undefined) {
      this.addToGlobalSum(this.getSumKey(p), -v[metricKey])
    } else {
      p[key] -= v[metricKey]
    }
  }

  post (p: Record<string, any>, key: string): void {
    if (this.globalSumDimensions) {
      const sumKey = this.getSumKey(p)
      p[key] = this.globalSum[sumKey] || 0
    }
  }

  static deserialize (m: SerializedReducer): ReducerConstructor {
    if (m.params === undefined) {
      throw new Error('Invalid SerializedReducer (SumReducer): missing parameters')
    }
    if (m.params.globalSumDimensions !== undefined && !Array.isArray(m.params.globalSumDimensions)) {
      throw new Error('Invalid SerializedReducer (SumReducer): globalSumDimensions must be an array')
    }
    return SumReducerConstructor(m.params.metric, m.params.globalSumDimensions !== undefined ? [...m.params.globalSumDimensions] : undefined)
  }
}

/**
 * @param metric the name of the metric to calculate the sum off
 * @returns SumReducer constructor using this metric
 */
export function SumReducerConstructor (metric: string, globalSumDimensions?: string[]): ReducerConstructor {
  return class extends SumReducer {
    constructor () { super(metric, globalSumDimensions) }

    static serialize (): SerializedReducer {
      const params: Record<string, any> = {
        metric
      }
      if (globalSumDimensions !== undefined) {
        params.globalSumDimensions = globalSumDimensions
      }
      return {
        constructor: 'SumReducer',
        params
      }
    }
  }
}
