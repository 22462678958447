export function calculateScrollPos (scrollTop: number, top: number): number {
  return scrollTop ? (scrollTop > top ? scrollTop - top : scrollTop) : 0
}

export function calculateCurrentIndex (scrollPos: number, itemSize: number): number {
  return Math.floor(scrollPos / (itemSize || scrollPos))
}

export function calculateTriggerIndex (currentIndex: number, firstIndex: number, lastIndex: number, numItemsInViewport: number, numToleratedItems: number, isScrollBottom: boolean): number {
  return (currentIndex <= numToleratedItems ? numToleratedItems : (isScrollBottom ? (lastIndex - numItemsInViewport - numToleratedItems) : (firstIndex + numToleratedItems - 1)))
}

export function calculateFirstIndex (currentIndex: number, triggerIndex: number, firstIndex: number, numToleratedItems: number, isScrollBottom: boolean): number {
  if (currentIndex <= numToleratedItems) { return 0 } else {
    return Math.max(0, isScrollBottom
      ? (currentIndex < triggerIndex ? firstIndex : currentIndex - numToleratedItems)
      : (currentIndex > triggerIndex ? firstIndex : currentIndex - (2 * numToleratedItems)))
  }
}

export function calculateLastIndex (currentIndex: number, firstIndex: number, lastIndex: number, numItemsInViewport: number, numToleratedItems: number, getLast: (lastValue: number) => number): number {
  let lastValue = firstIndex + numItemsInViewport + (2 * numToleratedItems)

  if (currentIndex >= numToleratedItems) {
    lastValue += (numToleratedItems + 1)
  }

  return getLast(lastValue)
}

export function calculateTranslateVal (firstIndex: number, itemSize: number): number {
  return firstIndex * itemSize
}
