<template>
  <div class="relative flex flex-col justify-between flex-1 mt-3 mb-3 overflow-hidden">
    <VirtualScroller
      v-slot="slotProps"
      :items="items"
      :is-disabled="!virtualScroll"
      class="flex flex-col flex-1 max-h-full"
      :item-size="itemSize"
      :content-element="tbodyRef"
      :is-loading="isLoading"
    >
      <table
        ref="tableRef"
        class="min-w-full border-collapse table-auto"
        :style="[slotProps.spacerStyle]"
      >
        <!-- Main rows -->
        <tbody
          v-if="slotProps.rows.length > 0"
          ref="tbodyRef"
          :class="[ 'block min-w-full', !slotProps.isDisabled ? 'will-change-transform absolute left-0' : '' ]"
          style="contain: layout;"
          role="rowgroup"
          :style="[
            tbodyStyle,
            slotProps.contentStyle,
            !slotProps.isDisabled ? { top: `${itemSize}px !important` } : ''
          ]"
        >
          <tr
            v-for="row in slotProps.rows"
            :id="row.id"
            :key="`row-${row.id}`"
            :class="[theme.row]"
            :style="{ height: itemSize ? `${itemSize}px` : undefined }"
            style="transition: none;"
          >
            <td
              class="flex"
              style="width: 25px;"
            />
            <td
              v-for="column in visibleColumns"
              :key="`row-${row.id}-${column.field}`"
              :class="[
                'datatable-cell',
                theme.cell
              ]"
              :style="getColumnWidth(column)"
              tabindex="-1"
            >
              <div
                v-for="(cell, i) in [getCell(row, column)]"
                :key="i"
                reference-is="div"
                :class="cell.class"
                :reference-props="{
                  class: cell.class
                }"
                placement="right"
                :modifiers="tooltipModifiers"
              >
                <!-- Please do not add truncate class here -->
                <span
                  v-if="rowHasColumnSlot(column)"
                  class="flex flex-col justify-center flex-1 w-full"
                >
                  <slot
                    :key="`slot-${row.id}-${column.field}`"
                    :name="`${column.field.replaceAll('.', '__')}`"
                    :row="row"
                    :column="column"
                    :value="cell.value"
                  />
                </span>
                <span
                  v-else
                  class="flex flex-col justify-center flex-1"
                  :title="cell.value"
                >
                  {{ cell.value }}
                </span>
              </div>
            </td>
            <!-- Last column to fill the void -->
            <td
              :class="[
                theme.cell,
                'flex-1'
              ]"
            />
          </tr>
        </tbody>

        <!-- Empty rows -->
        <tbody
          v-else-if="slotProps.rows.length === 0 && !isLoading"
          ref="tbodyRef"
          :class="[ 'box-border min-w-full', !slotProps.isDisabled ? 'will-change-transform absolute top-0 left-0' : '' ]"
          style="contain: layout;"
          :style="[tbodyStyle, slotProps.contentStyle]"
        >
          <tr class="box-border flex w-full h-full flex-nowrap">
            <td
              class="flex items-center justify-center flex-1 w-full h-full px-4 py-8"
              :colspan="columns.length"
            >
              <slot name="no-records">
                <div class="text-sm font-semibold">
                  {{ t('messages.noDataFound') }}
                </div>
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
    </VirtualScroller>

    <SubDatatablePagination
      v-if="pagination"
      v-model:pagination="datatablePagination"
      :class="theme.pagination"
      :is-loading="isLoading"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { PaginateWithoutRecords } from '@/types/paginate'

import VirtualScroller from '@/plugins/VirtualScroller/VirtualScroller.vue'

import { Column, Row } from './datatable.d'
import SubDatatablePagination from './SubDatatablePagination.vue'
import theme from './theme'
import { getRowColumnValue } from './utils'

export default defineComponent({
  components: {
    VirtualScroller,
    SubDatatablePagination
  },
  props: {
    columns: {
      type: Array as PropType<Column[]>,
      required: true
    },
    items: {
      type: Array as PropType<Row[]>,
      required: false,
      default: () => []
    },
    // itemSize is the height of a cell.
    itemSize: {
      type: Number,
      required: false,
      default: 40
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    pagination: {
      type: Object as PropType<PaginateWithoutRecords>,
      required: false,
      default: null
    },
    virtualScroll: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: [
    'columnReorder',
    'rowReorder',
    'rowSelect',
    'rowSelect',
    'rowSelectRange',
    'rowSelectAll',
    'update:selectedRows',
    'update:items',
    'update:sortedColumns',
    'update:pagination',
    'rowClick',
    'addItem',
    'removeItem'
  ],
  setup (props, { emit, slots }) {
    const { t } = useI18n()

    const tableHeader = ref<HTMLElement | null>(null)
    const tableRef = ref<HTMLElement | null>(null)
    const tbodyRef = ref<HTMLElement | null>(null)
    const tbodyStyle = ref<{ [key: string]: string }>({})

    // Slots
    const rowHasColumnSlot = (column: Column): boolean => {
      if (column.field) {
        const f = column.field.replaceAll('.', '__')
        return !!slots[f]
      }

      return false
    }

    // Pagination
    const datatablePagination = computed({
      get: () => {
        return props.pagination
      },
      set: (newPagination: PaginateWithoutRecords) => {
        emit('update:pagination', newPagination)
      }
    })

    // Columns
    const visibleColumns = computed(() => props.columns?.filter((c) => c.isVisible))

    // Returns class for the cell wrapper: td > div|span|…
    const getRowCellClass = (row: Row, column: Column) => {
      return [
        'text-text-primary font-regular text-sm',
        'relative flex flex-col w-full h-full px-2.5',
        column.rowCellClass
      ]
    }

    const getColumnWidth = (column: Column) => {
      let result

      if (!column) {
        result = 'width: 200px;'
      } else if (column.width === 'auto') {
        result = ''
      } else {
        result = `width: ${column.width}px;`
      }

      return result
    }

    const getCellValue = (row: Row, column: Column) => {
      return getRowColumnValue(row.data, column)
    }

    const getCell = (row: Row, column: Column) => {
      return {
        value: getCellValue(row, column),
        class: getRowCellClass(row, column)
      }
    }

    const tooltipModifiers = [
      {
        name: 'preventOverflow',
        options: {
          mainAxis: true,
          tether: false
        }
      }
    ]

    return {
      // Refs
      tableHeader,
      tableRef,
      tbodyRef,

      tbodyStyle,

      tooltipModifiers,
      theme,
      getRowCellClass,

      // Data
      getCell,

      // Columns
      visibleColumns,
      getColumnWidth,

      // Pagination
      datatablePagination,

      // Slots
      rowHasColumnSlot,
      t
    }
  }
})
</script>
