import i18n from '@/plugins/i18n'

import { ApprovalStatusType, AggregatedApprovalStatusType } from '@/models/enum/approvalStatus'
import { publisherWhitelistStatus, whitelistStatus } from '@/models/whitelist'

import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter'

export const approvalStatusTypes = [
  {
    id: ApprovalStatusType.APPROVED,
    name: capitalizeFirstLetter(ApprovalStatusType.APPROVED)
  },
  {
    id: ApprovalStatusType.PENDING,
    name: capitalizeFirstLetter(ApprovalStatusType.PENDING)
  },
  {
    id: ApprovalStatusType.BLOCKED,
    name: capitalizeFirstLetter(ApprovalStatusType.BLOCKED)
  }
]

export const demandLibraryApprovalStatusTypes = [
  {
    id: ApprovalStatusType.APPROVED,
    name: i18n.global.t('labels.connected')
  },
  {
    id: ApprovalStatusType.BLOCKED,
    name: i18n.global.t('labels.rejected')
  },
  {
    id: ApprovalStatusType.PENDING,
    name: i18n.global.t('labels.actionRequired')
  }
]

// demandLibraryApprovalStatusTypesTextOverrides transforms the demandLibraryApprovalStatusTypes array to a
// Record<ApprovalStatusType, string>, the key being the type and the value the display name.
// This value can be used with the ApprovalStatusComponent's prop "text-override" to change
// how the badges are displayed.
export const demandLibraryApprovalStatusTypesTextOverrides: Record<ApprovalStatusType, string> = demandLibraryApprovalStatusTypes.reduce((acc, e) => {
  acc[e.id] = e.name
  return acc
}, {} as Record<ApprovalStatusType, string>)

// whitelistApprovalStatusTypesTextOverrides transforms the whitelistStatus array to a
// Record<ApprovalStatusType, string>, the key being the type and the value the display name.
// This value can be used with the ApprovalStatusComponent's prop "text-override" to change
// how the badges are displayed.
export const whitelistApprovalStatusTypesTextOverrides: Record<ApprovalStatusType, string> = whitelistStatus.reduce((acc, e) => {
  acc[e.id] = e.name
  return acc
}, {} as Record<ApprovalStatusType, string>)

// publisherWhitelistApprovalStatusTypesTextOverrides transforms the publisherWhitelistStatus array to a
// Record<ApprovalStatusType, string>, the key being the type and the value the display name.
// This value can be used with the ApprovalStatusComponent's prop "text-override" to change
// how the badges are displayed.
export const publisherWhitelistApprovalStatusTypesTextOverrides: Record<ApprovalStatusType, string> = publisherWhitelistStatus.reduce((acc, e) => {
  acc[e.id] = e.name
  return acc
}, {} as Record<ApprovalStatusType, string>)

export const aggregatedApprovalStatusTypes = [
  {
    id: AggregatedApprovalStatusType.CONNECTED,
    name: i18n.global.t('labels.connected')
  },
  {
    id: AggregatedApprovalStatusType.PENDING,
    name: i18n.global.t('labels.notConnected')
  },
  {
    id: AggregatedApprovalStatusType.WAITING,
    name: i18n.global.t('labels.waitingApproval')
  },
  {
    id: AggregatedApprovalStatusType.REQUESTED,
    name: i18n.global.t('labels.requestedApproval')
  },
  {
    id: AggregatedApprovalStatusType.BLOCKED,
    name: i18n.global.t('labels.disconnected')
  }
]

export const aggregatedApprovalStatusTypeTextOverrides: Record<AggregatedApprovalStatusType, string> = aggregatedApprovalStatusTypes.reduce((acc, e) => {
  acc[e.id] = e.name
  return acc
}, {} as Record<AggregatedApprovalStatusType, string>)

export const aggregatedPublisherApprovalStatusTypes = [
  {
    id: AggregatedApprovalStatusType.CONNECTED,
    name: i18n.global.t('labels.connected')
  },
  {
    id: AggregatedApprovalStatusType.PENDING,
    name: i18n.global.t('labels.actionRequired')
  },
  {
    id: AggregatedApprovalStatusType.BLOCKED,
    name: i18n.global.t('labels.rejected')
  }
]
