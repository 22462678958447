export interface Paginate<T> {
  records: T
  maxPage: number
  total: number
  pageSize: number
  currentPage: number
}

export interface PaginateWithoutRecords {
  maxPage: number
  total: number
  pageSize: number
  currentPage: number
}

export function defaultPagination (): PaginateWithoutRecords {
  return {
    currentPage: 0,
    maxPage: 1,
    total: 0,
    pageSize: 0
  }
}

export function defaultPaginateRecords<T> (): Paginate<T[]> {
  return {
    maxPage: 0,
    total: 0,
    pageSize: 0,
    currentPage: 0,
    records: []
  }
}
