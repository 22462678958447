import Joi from 'joi'
import { Component } from 'vue'

export enum FieldType {
  NUMBER = 'number',
  STRING = 'string',
  STRING_LIST = 'stringList',
  ARRAY = 'array',
  BOOLEAN = 'boolean',
  DATE = 'date',
  ROLES = 'roles',
  UNKNOWN = 'unknown'
}

export enum ValidationStatus {
  ERROR = 'error',
  WARNING = 'warning',
  VALID = 'valid',
  INFO = 'info'
}

export interface Row {
  id: string
  isSelected: Ref<boolean>
  index: Ref<number>
  data: any
  isLoading?: Ref<boolean>
  isOpen?: Ref<boolean>
}

export interface ExtraColumn {
  value: string|number|null
  size: number
  data: any
  frozen?: boolean
  frozenBackground?: string
  frozenAlign?: 'left' | 'right'
  align?: string
  classes?: string | string[]
}

export type ColumnRenderingType = 'approvalStatus' | 'array' | 'boolean' | 'currency' | 'date' | 'enum' | 'letter' | 'list' | 'priority' | 'targetable' | 'truncate' | 'warning' | 'websiteStatus' | 'bidderLinesStatus' | 'country' | 'largeNumber' | 'custom'

export interface Column {
  field: string
  name?: string
  info?: string // Will add a little question mark with a tooltip next to the column name
  hideInfoQuestionMark?: boolean // Hides the info question mark next to the column name if true, and show to tooltip message by hovering the header text instead
  frozen?: boolean
  frozenBackground?: string
  frozenAlign?: 'left' | 'right'
  locked?: boolean
  required?: boolean // Adds an asterisk next to the column name to indicate it is required (only visual)
  resizableColumns?: boolean
  showGridlines?: boolean
  style?: string
  class?: string
  rowCellClass?: string
  width?: number | 'auto' // Width is in px.
  sortable?: boolean
  sortFunc?: (a: any, b: any) => bool
  sortableCaseInsensitive?: boolean
  filterKey?: string // Override of "field" used in sorts and filters (useful for computed columns that duplicate data)
  filterable?: boolean
  filterableType?: FieldType
  filterableAsyncOptions?: (field: string, query: object) => Promise<AxiosResponse<FilterResponse[]>>
  filterableOptions?: () => Array<{ id: string, name: string }>
  filterableDateOptions?: {
    maxDate: Date | 'no'
  },
  validator?: (value: any, row: Row, column: Column) => Joi.ValidationError | undefined
  revalidateRow?: boolean
  isActive?: boolean
  isNumber?: boolean
  isVisible?: boolean
  isEditable?: boolean | ((row: Row) => boolean)
  isBulkEditable?: boolean
  editableType?: FieldType // 'input', 'multiselect…'
  editableOptions?: () => Array<{ id: string, name: string }>
  editableAttrs?: InputHTMLAttributes
  attrs?: (value: any, row: Row) => Record<string, any>
  renderingType?: ColumnRenderingType
  renderer?: (row: Row) => Component // Component rendered if the renderingType is 'custom'
  // `condition` is callback function used during Column instanciation.
  // Usefull to add a Column conditionnaly (based on user role as example).
  condition?: (...args: any) => boolean
  groupKey?: string
}
export interface ColumnGroup {
  key: string
  label: string
  includesCheckbox?: boolean
}
export interface RowType<T> extends Omit<Row, 'data'>{
  data: T
}

export interface DatatableOptions {
  name?: string,
  sorts?: 'client' | 'server',
  queryString?: {
    columns?: boolean,
    sorts?: boolean
  },
  selectedRows?: Ref<Row[]>
  selectedBy?: string | string[]
  onQueryUpdated?: (queryParams) => void
}

export interface DatatableValidation {
  isDatatableValid: () => boolean
  isRowValid: (row: Row) => boolean
  isCellValid: (row: Row, column: Column) => boolean
  getCellErrors: (row: Row, column: Column) => string[] | undefined
  addErrors: (errs: string[] | undefined, row: Row, column: Column) => void
  setErrors: (errs: string[] | undefined, row: Row, column?: Column) => void
  setDatatableErrors: (errs: Record<string, FieldErrors> | undefined, rows: Row[], columns: Column[]) => void

  // validCell clears errors on the cell and revalidates it.
  // If revalidateRow is true and the column also has revalidateRow to true,
  // the other cells in the same row will be revalidated as well.
  validCell: (row: Row, column: Column, value: any, revalidateRow?: boolean) => void

  // Warnings
  setWarnings: (warns: Record<string, Record<string, string[]>>) => void
  addWarning: (row: Row, column: Column, message: string) => void
  clearWarnings: (row: Row, column?: Column) => void
  getCellWarnings: (row: Row, column: Column) => string[] | undefined
  cellHasWarnings: (row: Row, column: Column) => boolean
  hasWarnings: () => boolean
  rowHasWarnings: (row: Row) => boolean

  // Infos
  setInfos: (infos: Record<string, Record<string, string[]>>) => void
  addInfo: (row: Row, column: Column, message: string) => void
  clearInfos: (row: Row, column?: Column) => void
  getCellInfos: (row: Row, column: Column) => string[] | undefined
  cellHasInfos: (row: Row, column: Column) => boolean
  hasInfos: () => boolean
  rowHasInfos: (row: Row) => boolean

  getRowStatus: (row: Row) => ValidationStatus
  getCellStatus: (row: Row, column: Column) => ValidationStatus
  purge: (rows: Row[]) => void
}

export interface DatatableColumns {
  columns: Ref<Column[]>
  initialColumns: Column[]
  visibleColumns: ColumnProperty[]
  filterableColumns: ColumnFilter[]

  addColumn(column: Column): void
  addColumnAt(column: Column, index: number): void
  addColumnAfter(column: Column, field: string): void
  updateColumnsVisibility(columns: Ref<Column[]>): (columnsProperty: ColumnProperty[]) => void
}

export interface DatatableItems {
  rows: ComputedRef<Row[]>,
  addItem: (item: any) => void,
  addFrozenItem: (item: any) => void,
  clearItems: (selectedRows?: Row[]) => void
}

export interface Datatable {
  rows: ComputedRef<Row[]>,
  options: DatatableOptions,
  validation: DatatableValidation,
  items: DatatableItems
}
export interface SubDatatable {
  api: (...opts: any) => Promise<AxiosResponse>
  apiArgs: (row: Row) => any[]
  queryParams: (row: Row) => Record<string, any>
  columns: Column[]
}
