<template>
  <button
    :class="[
      'p-2 relative cursor-pointer select-none text-text-primary hover:text-primary-500',
      'disabled:text-gray-600 disabled:cursor-not-allowed'
    ]"
    :title="t('labels.bulkEdit')"
  >
    <PencilIcon class="h-4 w-4" />
  </button>
</template>

<script lang="ts">
import { PencilIcon } from '@heroicons/vue/24/solid'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    PencilIcon
  },
  setup () {
    const { t } = useI18n()
    return {
      t
    }
  }
})
</script>
