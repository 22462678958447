<template>
  <EnumComponent
    :value="translatedValue"
    :bg-class="bgClass"
  />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

import countries from '@/static/countries.json'

import EnumComponent from './EnumComponent.vue'

const countriesMap: Record<string, string> = {}
countries.forEach(c => {
  countriesMap[c.id.toLocaleLowerCase()] = c.name
})

export default defineComponent({
  components: { EnumComponent },
  props: {
    value: {
      type: String,
      required: true
    },
    bgClass: {
      type: String,
      default: 'bg-primary-100'
    }
  },
  setup (props) {
    const translatedValue = computed(() => countriesMap[props.value.toLocaleLowerCase()] || props.value)
    return {
      translatedValue
    }
  }
})
</script>
