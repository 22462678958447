import { RouteRecordRaw } from 'vue-router'

import { rolesGroup } from '@/static/roles'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

export function pbsTests (context: ContextType): RouteRecordRaw[] {
  return [
    {
      path: 'pbsTests',
      name: `${context}.pbsTests.index`,
      component: () => import('./views/Index.vue'),
      meta: {
        requireContext: true,
        title: i18n.global.t('labels.pbsTests', 100),
        feature: 'pbs_tests',
        featuresPermissions: ['pbs_tests'],
        layout: 'base',
        roles: rolesGroup.management,
        requireService: context === ContextType.GROUPS
      }
    }
  ]
}
