<template>
  <button
    class="inline-block align-middle cursor-pointer focus:outline-none"
    :title="t('actions.edit')"
    @click="open = true"
  >
    <PencilSquareIcon class="w-4 h-4" />
  </button>
  <Modal
    v-model:open="open"
    css="w-full md:w-md lg:min-w-136"
    @close="cancelJSON()"
  >
    <template #title>
      {{ t('dashboards.editWidgetTitle') }}
    </template>

    <TabGroup>
      <TabList class="flex flex-wrap px-3 mb-1 whitespace-nowrap">
        <Tab
          v-slot="{ selected }"
          as="template"
        >
          <span
            class="p-2 rounded-md cursor-pointer select-none"
            :class="selected ? 'bg-primary-500 text-white' : '' "
          >
            {{ t('labels.form') }}
          </span>
        </Tab>
        <Tab
          v-slot="{ selected }"
          as="template"
        >
          <span
            class="p-2 rounded-md cursor-pointer select-none"
            :class="selected ? 'bg-primary-500 text-white' : '' "
          >
            JSON
          </span>
        </Tab>
      </TabList>
      <TabPanels class="flex-grow">
        <TabPanel class="h-full focus:outline-none">
          <WidgetForm
            :stores="stores"
            :definition="value"
            :vertical="true"
            class="w-full px-3"
            @submit="confirmForm"
          />
        </TabPanel>
        <TabPanel class="h-full">
          <div
            class="flex flex-col items-end w-full h-full pt-1 mt-1 overflow-hidden text-sm"
            @vue:mounted="updateJSON"
          >
            <textarea
              v-model="json"
              spellcheck="false"
              class="flex-grow w-full font-mono text-sm border-none resize-none focus:ring-0 min-h-75-screen"
            />
            <div class="p-3">
              <button
                type="button"
                :title="t('actions.cancel')"
                class="px-3 py-1 mr-1 text-white capitalize rounded-md bg-amber-500"
                @click="cancelJSON()"
              >
                🚫&nbsp;{{ t('actions.cancel') }}
              </button>
              <button
                type="button"
                :title="t('actions.confirm')"
                class="px-3 py-1 text-white capitalize bg-green-500 rounded-md focus:outline-none"
                @click="confirmJSON()"
              >
                ✔️&nbsp;{{ t('actions.confirm') }}
              </button>
            </div>
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </Modal>
</template>

<script lang="ts">
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
import { PencilSquareIcon } from '@heroicons/vue/24/outline'
import { PropType, defineComponent, inject, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { SerializedWidgetDefinition, StoresInjectKey, WidgetDefinition, deserializeWidgetDefinition, serializeWidgetDefinition } from '@/plugins/dashboard'

import { useNotificationsStore } from '@/store/notifications.store'

import Modal from '@/components/Modal/Modal.vue'

import WidgetForm from '../forms/WidgetForm.vue'

export default defineComponent({
  components: {
    Modal,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    WidgetForm,
    PencilSquareIcon
  },
  props: {
    value: {
      type: Object as PropType<WidgetDefinition>,
      required: true
    }
  },
  emits: ['update:value', 'cancel'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const notificationsStore = useNotificationsStore()

    const stores = inject(StoresInjectKey)
    if (stores === undefined) {
      throw new Error('WidgetEditor requires parent to provide "stores"')
    }

    // Data
    const json = ref('')
    const open = ref(false)

    // Methods
    const updateJSON = () => {
      json.value = JSON.stringify(serializeWidgetDefinition(props.value), null, 2)
    }

    const cancelJSON = () => {
      updateJSON()
      open.value = false
      emit('cancel')
    }

    const confirmJSON = () => {
      try {
        const def = JSON.parse(json.value) as SerializedWidgetDefinition
        def.uid = props.value.uid // Prevent modifying the UID
        emit('update:value', deserializeWidgetDefinition(def, stores.value!))
        open.value = false
      } catch (e: any) {
        console.error(e)
        notificationsStore.add({
          message: t('dashboards.jsonValidationError', [e.message]),
          type: 'error'
        })
      }
    }

    const confirmForm = (def: WidgetDefinition) => {
      emit('update:value', def)
      open.value = false
    }

    // Watch
    watch(
      () => props.value,
      () => updateJSON()
    )

    onMounted(() => updateJSON())

    return {
      // Data
      json,
      open,
      stores,

      // Methods
      updateJSON,
      cancelJSON,
      confirmJSON,
      confirmForm,

      // Misc
      t
    }
  }
})
</script>
