<template>
  <WidgetShell
    :loading="isLoading"
    :title="title"
    :definition="definition"
    :edit-mode="editMode"
    :enlarged="enlarged"
    :has-data="hasData"
    @json-editor-input="onJSONEditorInput"
    @remove-clicked="$emit('remove-clicked')"
    @export="onExport(title, $event)"
    @save-image="onSaveAsImageClicked(title)"
    @expand-clicked="onExpandClicked"
  >
    <template #header>
      <MultiSelect
        v-model="metric"
        name="metric"
        mode="single"
        :close-on-select="true"
        :options="definition.metrics.map(m => ({ id: m.name, name: translateDBName(m.name.toString()) }))"
        :classes="multiselectTailwindClasses"
        :can-clear="false"
        :can-deselect="false"
      />
    </template>
    <template #controls>
      <button
        type="button"
        class="invisible inline-block align-middle cursor-pointer group-hover:visible focus:outline-none"
        :title="t('labels.sort')"
        @click="onSortClick"
      >
        <BarsArrowUpIcon
          v-if="sortOrder === true"
          class="w-4 h-4"
        />
        <BarsArrowDownIcon
          v-else-if="sortOrder === false"
          class="w-4 h-4"
        />
        <Bars3Icon
          v-else
          class="w-4 h-4"
        />
      </button>

      <button
        v-if="title !== '' && hasComparisonData"
        type="button"
        class="inline-block mx-0.5 cursor-pointer align-middle invisible group-hover:visible"
        :title="hideComparison ? t('dashboards.showComparison') : t('dashboards.hideComparison') "
        @click="hideComparison = !hideComparison"
      >
        <EyeIcon
          v-if="hideComparison"
          class="w-4 h-4"
        />
        <EyeSlashIcon
          v-else
          class="w-4 h-4"
        />
      </button>
    </template>
    <div
      ref="chartContainer"
      class="flex-grow h-full"
    >
      <template v-if="!isLoading">
        <v-chart
          v-if="!empty"
          ref="chart"
          class="h-full min-h-px"
          :class="{'pointer-events-none': editMode}"
          :init-options="initOptions"
          :option="chartOptions"
          :theme="ECHARTS_THEME_NAME"
          :update-options="{ notMerge: true }"
          autoresize
        />
        <template v-else>
          <div class="flex h-full">
            <p class="m-auto leading-relaxed">
              {{ t('dashboards.nothingToShow') }}
            </p>
          </div>
        </template>
      </template>
    </div>
  </WidgetShell>
</template>

<script lang="ts">
import { EyeIcon, EyeSlashIcon, BarsArrowUpIcon, BarsArrowDownIcon, Bars3Icon } from '@heroicons/vue/24/outline'
import { Grouping } from 'crossfilter2'
import { EChartsOption, SeriesOption, YAXisComponentOption } from 'echarts'
import { BarSeriesOption, BarChart } from 'echarts/charts'
import { DatasetComponent, DataZoomComponent, DataZoomComponentOption } from 'echarts/components'
import { use } from 'echarts/core'
import orderBy from 'lodash/orderBy'
import { defineComponent, ref, computed, nextTick, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { WidgetDefinition, translateDBName, CategoryFormatter, CategoryFormatterOptions, echartsLabelFormatter, echartsTooltipFormatter } from '@/plugins/dashboard'

import { localToUTC } from '@/utils/dates'

import MultiSelect from '@/components/Form/FormMultiselect.vue'

import { ECHARTS_BAR_THEME } from '../theme'

import ChartWidget from './ChartWidgetMixin'
import { setupWidget, WidgetSettings, multiselectTailwindClasses, selectMetric } from './Widget'
import WidgetShell from './WidgetShell.vue'

use([BarChart, DatasetComponent, DataZoomComponent])

export default defineComponent({
  components: {
    WidgetShell,
    MultiSelect,
    BarsArrowUpIcon,
    BarsArrowDownIcon,
    Bars3Icon,
    EyeIcon,
    EyeSlashIcon
  },
  mixins: [ChartWidget],
  emits: ['remove-clicked', 'definition-changed'],
  setup (props, { emit }) {
    const { t } = useI18n()
    let cachedGroup: ReadonlyArray<Grouping<any, Record<string, any>>> | undefined
    let cachedComparisonGroup: ReadonlyArray<Grouping<any, Record<string, any>>> | undefined

    const sortOrder = ref(false as boolean | null) // false = DESC, true = ASC, null = don't sort

    // Refs
    const chartContainer = ref(null as HTMLElement | null)

    // Data
    const dimension = ref(props.definition.dimensions[0])

    const metric = ref(selectMetric(props.queryBuilder.widgetMetrics[props.definition.uid], props.definition))
    const chartOptions = ref<EChartsOption>({})
    const hideComparison = ref(false)

    const updateChart = () => {
      if (!cachedGroup || chartContainer.value === null || commonWidget.isWaitingForBothData.value) {
        return
      }

      const currentMetric = props.definition.metrics.find(m => m.name === metric.value)!
      const options: CategoryFormatterOptions = {
        dimension: dimension.value,
        metrics: [metric.value],
        granularity: props.definition.store.granularity
      }

      const dataMap = new Map<string, number[]>()
      let max = 0

      const formatter = new CategoryFormatter()
      formatter.format(cachedGroup, options).forEach((e) => {
        max = Math.max(max, e[1])
        dataMap.set(e[0], [e[1], 0])
      })

      const tooltipLabels = [
        localToUTC(props.definition.store.lastDateRange!.from).toISOString().split('T')[0]
      ]

      if (cachedComparisonGroup && !hideComparison.value) {
        formatter.format(cachedComparisonGroup, options).forEach((e) => {
          max = Math.max(max, e[1])
          const elem = dataMap.get(e[0])
          if (elem) {
            dataMap.set(e[0], [elem[0], e[1]])
          } else {
            dataMap.set(e[0], [0, e[1]])
          }
        })

        tooltipLabels.push(localToUTC(props.definition.store.comparisonStore!.lastDateRange!.from).toISOString().split('T')[0])
      }

      let data = [] as any[][]
      dataMap.forEach((v, k) => {
        data.push([k, ...v])
      })

      if (sortOrder.value !== null) {
        data = orderBy(data, r => {
          const v = r[1]
          if (Number.isNaN(v) || v === undefined) {
            return -1
          }
          return v
        }, sortOrder.value === true ? 'asc' : 'desc')
      }
      data.reverse()

      const recordCount = Math.ceil((chartContainer.value?.clientHeight || 0) / 25)
      const dataZoom: DataZoomComponentOption[] | undefined = data.length >= recordCount
        ? [
            {
              type: 'slider',
              yAxisIndex: [0, 1],
              right: 0,
              showDetail: false,
              brushSelect: false,
              showDataShadow: false,
              zoomLock: true,
              startValue: data.length > recordCount ? data.length - recordCount : 0,
              endValue: data.length > 0 ? data.length - 1 : 0,
              handleSize: '0%',
              width: 10,
              fillerColor: '#c1c1c1'
            },
            {
              type: 'inside',
              yAxisIndex: [0, 1],
              moveOnMouseWheel: true,
              zoomOnMouseWheel: false
            }
          ]
        : undefined

      const newOptions: EChartsOption = {
        xAxis: {
          type: 'value',
          show: false,
          max
        },
        yAxis: {
          type: 'category',
          data: data.map(e => e[0]),
          axisLabel: {
            inside: true
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          z: 3
        },
        dataset: {
          source: data
        },
        grid: {
          left: 50,
          right: 0,
          top: 20,
          bottom: 0
        },
        tooltip: {
          trigger: 'axis',
          appendToBody: true,
          axisPointer: {
            type: 'shadow'
          },
          formatter: echartsTooltipFormatter(currentMetric.formatter, (e, idx) => idx !== undefined ? tooltipLabels[idx] : e)
        },
        dataZoom
      }

      const series = [{
        data: data.map(e => e[1]),
        type: 'bar',
        barCategoryGap: 2,
        name: props.definition.title,
        itemStyle: {
          color: ECHARTS_BAR_THEME.currentSerieColor
        },
        label: {
          show: true,
          position: 'left',
          align: 'right',
          verticalAlign: 'middle',
          fontWeight: 'bold',
          formatter: echartsLabelFormatter(currentMetric.formatter)
        },
        areaStyle: {}
      } as BarSeriesOption]

      if (cachedComparisonGroup && !hideComparison.value) {
        newOptions.yAxis = [
          newOptions.yAxis as YAXisComponentOption,
          {
            type: 'category',
            data: data.map(e => e[0]),
            show: false
          }
        ]

        series.push({
          data: data.map(e => e[2]),
          type: 'bar',
          stack: 'x',
          barCategoryGap: 2,
          barGap: '-100%',
          name: 'Previous',
          yAxisIndex: 1,
          itemStyle: {
            color: 'transparent'
          }
        } as BarSeriesOption,
        {
          data: data.map(() => max / 250),
          type: 'bar',
          stack: 'x',
          barCategoryGap: 2,
          name: 'NONE',
          yAxisIndex: 1,
          itemStyle: {
            color: ECHARTS_BAR_THEME.previousSerieColor
          }
        } as BarSeriesOption)
      }

      newOptions.series = series as SeriesOption[]
      chartOptions.value = newOptions
    }

    const empty = computed(() => !chartOptions.value)

    const updateFunc = (v: ReadonlyArray<Grouping<any, Record<string, any>>> | undefined) => {
      cachedGroup = v
      updateChart()
    }
    const comparisonUpdateFunc = (v: ReadonlyArray<Grouping<any, Record<string, any>>> | undefined) => {
      cachedComparisonGroup = v
      updateChart()
    }

    // Methods
    const onJSONEditorInput = (newDefinition: WidgetDefinition) => {
      emit('definition-changed', newDefinition)
    }

    const onSortClick = () => {
      switch (sortOrder.value) {
        case true:
          sortOrder.value = null
          break
        case false:
          sortOrder.value = true
          break
        case null:
          sortOrder.value = false
          break
      }
    }

    const settings: WidgetSettings = {
      props,
      dimension,
      updateFunc,
      comparisonUpdateFunc
    }

    // Watch
    const commonWidget = setupWidget(settings)

    watch(
      () => [commonWidget.enlarged.value, chartContainer.value],
      () => {
        nextTick(() => setTimeout(() => {
          updateChart()
        }, 0))
      }
    )
    watch(
      () => [metric.value, hideComparison.value],
      () => updateChart()
    )

    watch(
      () => sortOrder.value,
      () => updateChart()
    )

    watch(
      () => metric.value,
      () => updateChart()
    )

    watch(
      () => metric.value,
      () => props.queryBuilder.setMetric(props.definition.uid, metric.value !== props.definition.metrics[0].name ? metric.value : undefined),
      { immediate: true }
    )

    watch(
      () => props.definition,
      () => {
        metric.value = props.definition.metrics[0].name
      }
    )

    return {
      // Refs
      chartContainer,

      // Computed
      empty,

      // Data
      metric,
      chartOptions,
      sortOrder,
      hideComparison,

      // Methods
      ...commonWidget,
      onJSONEditorInput,
      onSortClick,
      translateDBName,
      t,
      multiselectTailwindClasses
    }
  }
})
</script>
