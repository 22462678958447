import { Validation, ValidationArgs } from '@vuelidate/core'
import { Ref, unref } from 'vue'

export function touchInvalidFields <T extends object> (form: T, validation: Ref<Validation<ValidationArgs>> | Validation<ValidationArgs>): void {
  const val = unref(validation)
  if (!form) {
    return
  }
  Object.entries(form).forEach(e => {
    if (!val[e[0]]) {
      return
    }
    if (typeof e[1] === 'object') {
      touchInvalidFields(e[1], (val[e[0]] as any))
    }
    if (val[e[0]].$invalid) {
      val[e[0]].$touch()
    }
  })
}

export function extractDirtyFields <T extends object> (form: T, validation: Ref<Validation<ValidationArgs>> | Validation<ValidationArgs>): T {
  const dirtyFields: { [key: string]: any } = {}

  for (const [key, value] of Object.entries(form)) {
    const k = unref(validation)[key]

    if (k) {
      if (k.$dirty) {
        dirtyFields[key] = value
      } else if (k.$anyDirty) {
        dirtyFields[key] = extractDirtyFields(value, k as any)
      }
    }
  }

  return dirtyFields as T
}
