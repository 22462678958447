import { alphaNum, numeric, required } from '@vuelidate/validators'
import numbro from 'numbro'

export const findByKey = (arr: any[], key: string): any => {
  return arr && arr.find((a) => a.key === key)
}

export const oneOf = (arrayOne: any[], arrayTwo: any[]): boolean => {
  return arrayOne && arrayTwo && arrayOne.some(r => arrayTwo.includes(r))
}

export const randomString = (length: number): string => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let result = ''
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)]
  }
  return result
}

export const configurationBuilder = (data: any): { r: Record<string, string|number>, rules: Record<string, any> } => {
  const r: ({[key: string]: string | number }) = {} // the json to return

  const rules: {[key: string]: any } = {}

  Object.keys(data).forEach(prop => {
    rules[prop] = {}

    if (data[prop].mandatory) {
      Object.assign(rules[prop], required)
    }

    switch (data[prop].validation) {
      case 'number':
        r[prop] = 0
        Object.assign(rules[prop], numeric)
        break
      case 'string':
        r[prop] = ''
        Object.assign(rules[prop], alphaNum)
        break
    }
  })

  return {
    r, rules
  }
}

export const removeEmptyValues = (data: Record<string, any>): Record<string, any> => {
  const result: Record<string, any> = {}
  for (const k in data) {
    if (data[k] !== null && data[k] !== undefined && data[k] !== '') {
      result[k] = data[k]
    }
  }
  return result
}

function validNumber (n: any): boolean {
  return !Number.isNaN(n) && typeof n === 'number'
}

export const formatLargeNumber = (n: number | null | undefined): string => {
  if (n === null || n === undefined || !validNumber(n)) {
    return ''
  }

  return numbro(n).format({
    average: true,
    lowPrecision: false,
    thousandSeparated: true,
    optionalMantissa: true,
    trimMantissa: true,
    totalLength: 2,
    mantissa: 1
  }).toUpperCase()
}

export const ceilValue = (value: number): number => {
  return Math.round((value + Number.EPSILON) * 100) / 100
}
