<template>
  <Popover
    v-slot="{ close }"
    class="relative"
  >
    <PopoverButton
      class="w-full inline-flex items-center text-base font-medium rounded-md outline-none"
    >
      <span class="capitalize w-full text-left text-gray-700">
        ✏️
      </span>
    </PopoverButton>

    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel
        class="absolute z-20 max-w-sm select-none text-black sm:text-sm"
        :class="popoverPanelClass"
      >
        <div
          class="flex flex-col mt-1 shadow-lg bg-white border border-gray-100 ring-1 ring-black ring-opacity-5 text-sm rounded-md"
          :class="popoverClass"
        >
          <TabGroup v-if="definition">
            <TabList class="whitespace-nowrap mb-1 flex flex-wrap">
              <Tab
                v-slot="{ selected }"
                as="template"
              >
                <span
                  class="rounded-b-md p-2 cursor-pointer"
                  :class="selected ? 'bg-primary-500 text-white' : '' "
                >
                  {{ t('labels.new') }}
                </span>
              </Tab>
              <Tab
                v-for="(f, i) in modelValue"
                :key="i"
                v-slot="{ selected }"
                as="template"
              >
                <span
                  class="rounded-b-md p-2 cursor-pointer"
                  :class="selected ? 'bg-primary-500 text-white' : '' "
                >
                  {{ f.dimension }}
                </span>
              </Tab>
            </TabList>
            <TabPanels class="p-1">
              <TabPanel>
                <RequiredFilterForm
                  :definition="definition"
                  @submit="onSubmit"
                />
              </TabPanel>
              <TabPanel
                v-for="(_, i) in modelValue"
                :key="i"
              >
                <RequiredFilterForm
                  :model-value="modelValue[i]"
                  :definition="definition"
                  @update:model-value="onUpdate(i, $event); close()"
                  @remove="onRemove(i); close()"
                />
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script lang="ts">
import { Popover, PopoverButton, PopoverPanel, TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

import { RequiredFilter } from '@/plugins/dashboard'
import { DataDefinition } from '@/plugins/dashboard/definition'

import RequiredFilterForm from './RequiredFilterForm.vue'

export default defineComponent({
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    RequiredFilterForm
  },
  props: {
    definition: {
      type: Object as PropType<DataDefinition>,
      required: true
    },
    modelValue: {
      type: Array as PropType<RequiredFilter[]>,
      required: true
    },
    popoverClass: {
      type: String as PropType<string>,
      default: () => ''
    },
    popoverPanelClass: {
      type: String as PropType<string>,
      default: () => ''
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const onSubmit = (newValue: RequiredFilter) => {
      const model = [...props.modelValue]
      model.push(newValue)
      emit('update:modelValue', model)
    }

    const onUpdate = (i: number, newValue: RequiredFilter) => {
      const model = [...props.modelValue]
      model[i] = newValue
      emit('update:modelValue', model)
    }

    const onRemove = (i: number) => {
      const model = [...props.modelValue]
      model.splice(i, 1)
      emit('update:modelValue', model)
    }

    return {
      t,
      onSubmit,
      onUpdate,
      onRemove
    }
  }
})
</script>
