interface Size {
  width: number
  height: number
}

export default {
  sm: {
    table: {
      width: 6,
      height: 7
    },
    line: {
      width: 6,
      height: 7
    },
    singleMetric: {
      width: 3,
      height: 3
    },
    pie: {
      width: 3,
      height: 6
    },
    bar: {
      width: 6,
      height: 7
    },
    map: {
      width: 6,
      height: 7
    },
    text: {
      width: 3,
      height: 2
    },
    comparativeTable: {
      width: 6,
      height: 7
    },
    seatsBoosters: {
      width: 4,
      height: 9
    }
  },
  md: {
    table: {
      width: 6,
      height: 10
    },
    line: {
      width: 6,
      height: 8
    },
    singleMetric: {
      width: 4,
      height: 3
    },
    pie: {
      width: 4,
      height: 6
    },
    bar: {
      width: 8,
      height: 8
    },
    map: {
      width: 8,
      height: 8
    },
    text: {
      width: 4,
      height: 2
    },
    comparativeTable: {
      width: 6,
      height: 10
    },
    seatsBoosters: {
      width: 4,
      height: 9
    }
  }
} as { sm: Record<string, Size>, md: Record<string, Size> }
