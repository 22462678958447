import { RouteRecordRaw } from 'vue-router'

import { rolesGroup } from '@/static/roles'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

export function users (context: ContextType): RouteRecordRaw[] {
  return [
    {
      path: 'users',
      name: `${context}.users.index`,
      component: () => import('./views/Index.vue'),
      meta: {
        requireContext: true,
        title: i18n.global.t('labels.user', '2'),
        roles: rolesGroup.management,
        feature: 'users'
      },
      children: [
        {
          path: ':userId/edit',
          name: `${context}.users.edit`,
          component: () => import('./views/Edit.vue'),
          meta: {
            title: i18n.global.t('users.edit'),
            feature: 'users'
          },
          props: route => ({ userId: route.params.userId })
        },
        {
          path: ':userId/reset-password',
          name: `${context}.users.resetPassword`,
          component: () => import('./views/ResetPassword.vue'),
          meta: {
            title: i18n.global.t('passwords.resetOtherUserPassword'),
            feature: 'users'
          },
          props: route => ({ userId: route.params.userId })
        },
        {
          path: ':userId/delete',
          name: `${context}.users.delete`,
          component: () => import('./views/Delete.vue'),
          meta: {
            title: i18n.global.t('users.revoke'),
            feature: 'users'
          },
          props: route => ({ userId: route.params.userId })
        }
      ]
    }
  ]
}
