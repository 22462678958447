import { DebouncedFunc } from 'lodash'
import debounce from 'lodash/debounce'
import { reactive, watch, WatchStopHandle, toRaw, InjectionKey, Ref } from 'vue'

import { DateRangePreset, DateRange as PickerDateRange, DATE_RANGE_PRESETS, ComparisonRangePreset, COMPARISON_DATE_RANGE_PRESETS } from '@/components/DateRangePicker/dateRange'

import { DataDefinition, deserializeDataDefinition, serializeDataDefinition } from './definition'
import { Dimension, TimeGranularityName, TIME_GRANULARITIES } from './dimensions'
import { DateRange, Filters, FilterValues, ComparisonGroup, Source } from './source'
import CFWorker from './worker/worker?worker'

import { MetricsCache, Cache, StoreState, WorkerOperation, WorkerMessage, SerializedStore, Serializable, Metric, RequiredFilter, serializeDateRange, deserializeDateRange, deserializeComparisonPeriod } from '.'

export class Store implements Serializable<SerializedStore> {
  uid: string
  name: string
  source: Source
  definition: DataDefinition
  timeDimensions: string[]
  refreshDimensions: string[]
  requiredFilters: RequiredFilter[]
  isComparison: boolean
  disableComparison: boolean
  periodOverride?: DateRangePreset | PickerDateRange
  comparisonPeriodOverride?: ComparisonRangePreset
  granularity: TimeGranularityName

  state: {state: StoreState}
  refreshFunction: DebouncedFunc<() => void>
  cache: Cache
  metricsCache: MetricsCache
  comparisonGroups: ComparisonGroup[]
  filters: Record<string, FilterValues>
  dateRange?: DateRange
  dateRangeRef: DateRange // The reference date range for comparison stores
  lastDateRange?: DateRange // The date range for the last batch of processed data received from the Source (can be used for chart rendering)
  stopWatchFilters: WatchStopHandle
  stopWatchDate?: WatchStopHandle
  stopWatchComparisonState?: WatchStopHandle

  worker: Worker
  shouldRefresh: boolean // Defines if refreshData should be called when the worker is ready
  onError?: (e: any) => void
  onDataLimit?: () => void

  // eslint-disable-next-line no-use-before-define
  comparisonStore?: Store

  disposed: boolean

  constructor (
    uid: string,
    name: string,
    source: Source,
    definition: DataDefinition,
    timeDimensions: string[], // The dimensions used for time filtering
    requiredFilters: RequiredFilter[], // If all required dimensions don't have at least one filter, store won't refresh data
    refreshDimensions: string[], // The dimensions that will trigger a refresh when a filter on this dimension is used
    comparisonGroups: ComparisonGroup[],
    filters: Filters,
    granularity: TimeGranularityName = 'day',
    isComparison: boolean = false,
    disableComparison: boolean = false,
    periodOverride?: DateRangePreset | PickerDateRange,
    comparisonPeriodOverride?: ComparisonRangePreset,
    onError?: (e: any) => void,
    onDataLimit?: () => void) {
    // TODO refactor constructor, it has way too many parameters
    this.uid = uid
    this.name = name
    this.source = source
    this.definition = definition
    this.timeDimensions = timeDimensions
    this.refreshDimensions = refreshDimensions
    this.requiredFilters = requiredFilters
    this.isComparison = isComparison
    this.disableComparison = disableComparison
    this.periodOverride = periodOverride
    this.comparisonPeriodOverride = comparisonPeriodOverride
    this.granularity = granularity
    this.cache = reactive({} as Cache)
    this.metricsCache = reactive({} as MetricsCache)
    this.comparisonGroups = comparisonGroups
    this.filters = filters.filters
    this.shouldRefresh = false
    this.dateRangeRef = filters.dateRange
    this.state = reactive({ state: StoreState.PREPARING })
    this.disposed = false
    this.refreshFunction = debounce(() => {
      this.refreshData()
    }, 1000)
    this.onError = onError
    this.onDataLimit = onDataLimit
    this.worker = new CFWorker()
    this.worker.onerror = (e) => {
      console.error(this.uid, 'Worker error', e)
      if (onError) {
        onError(new Error('Worker error'))
      }
    }
    this.worker.onmessage = m => this.onWorkerMessage(m)
    this.worker.postMessage({
      op: WorkerOperation.INIT,
      params: {
        uid: this.uid,
        filters: toRaw(filters.filters),
        definition: serializeDataDefinition(definition)
      }
    })
    this.stopWatchFilters = watch(
      () => filters.filters,
      f => {
        this.filterAll(f)
      },
      { deep: true }
    )
    this.watchDate(filters)
    if (!isComparison && !disableComparison) {
      this.comparisonStore = new Store(uid + '_comparison', name, source, definition, timeDimensions, requiredFilters, refreshDimensions, comparisonGroups, filters, granularity, true, false, periodOverride, comparisonPeriodOverride, this.onError, this.onDataLimit)
    }
  }

  watchDate (filters: Filters): void {
    // FIXME if periodOverride or comparisonPeriodOverride are changed dynamically, the store won't refresh as expected
    if (this.stopWatchDate !== undefined) {
      this.stopWatchDate()
      this.stopWatchDate = undefined
    }
    if (this.isComparison) {
      if (this.comparisonPeriodOverride !== undefined) {
        if (this.periodOverride !== undefined) {
          // Case 1: periodOverride + comparisonPeriodOverride -> no watch
          return
        }
        // Case 2: only comparionPeriodOverride
        this.stopWatchDate = watch(
          () => filters.dateRange,
          d => {
            this.dateRangeRef = d
            this.refreshFunction()
          },
          { deep: true }
        )
        return
      }
      // Case 3: only periodOverride or no override
      this.stopWatchDate = watch(
        () => filters.comparisonDateRange,
        d => {
          this.setDateRange(d)
          this.refreshFunction()
        },
        { deep: true }
      )
      return
    }
    if (this.periodOverride === undefined) {
      this.stopWatchDate = watch(
        () => filters.dateRange,
        d => {
          this.setDateRange(d)
          this.refreshFunction()
        },
        { deep: true }
      )
    }
  }

  onWorkerMessage (m: MessageEvent<WorkerMessage>): void {
    switch (m.data.op) {
      case WorkerOperation.READY:
        this.state.state = this.shouldRefresh ? StoreState.LOADING : StoreState.WORKER_READY
        if (this.shouldRefresh) {
          this.shouldRefresh = false
          this.refreshFunction()
        }
        break
      case WorkerOperation.DATA_READY:
        this.clearCache()
        this.state.state = StoreState.READY
        // console.log(this.uid, 'Store data refreshed')
        break
      case WorkerOperation.DIMENSION_REDUCED:
        if (!this.cache[m.data.params.dimensionName]) {
          this.cache[m.data.params.dimensionName] = {}
        }
        this.cache[m.data.params.dimensionName][m.data.params.dependentID] = m.data.params.result
        break
      case WorkerOperation.METRIC_REDUCED:
        if (!this.metricsCache[m.data.params.metricName]) {
          this.metricsCache[m.data.params.metricName] = {}
        }
        this.metricsCache[m.data.params.metricName][m.data.params.dependentID] = m.data.params.result
        break
      case WorkerOperation.DIMENSION_DISPOSED:
        // console.log(this.uid, `Dimension ${m.data.params.dimensionName} has no dependent left and is being removed`)
        delete this.cache[m.data.params.dimensionName]
        break
      default:
        console.warn(this.uid, `Store: Unsupported WorkerOperation "${m.data.op.toString()}"`)
    }
  }

  onFilterClicked (dimensions: string | string[], filters: Record<string, FilterValues>): void {
    let shouldRefresh = false
    if (Array.isArray(dimensions)) {
      shouldRefresh = dimensions.some(d => this.refreshDimensions.includes(d))
    } else {
      shouldRefresh = this.refreshDimensions.includes(dimensions)
    }
    // TODO flag to force refresh every time a filter is changed
    // If store exceeds 50000 records (dimension multiplication), switch to force refresh
    // Force refresh mode = pagination (+ order by)
    if (shouldRefresh) {
      this.filters = filters
      this.refreshFunction()
      if (this.comparisonStore) {
        this.comparisonStore.onFilterClicked(dimensions, filters)
      }
    }
  }

  requiredFiltersFulfilled (): boolean {
    return this.requiredFilters.every(d => {
      let count = 0
      const values = this.filters[d.dimension]
      if (values) {
        if (values.operator !== 'equals') {
          return false
        }
        count += this.filters[d.dimension].values.length
      }
      if (this.source.globalFilters[d.dimension]) {
        count += this.source.globalFilters[d.dimension].length
      }
      return count <= d.max && count >= d.min
    })
  }

  getPeriodOverride (): DateRange | undefined {
    if (this.periodOverride !== undefined) {
      if (typeof this.periodOverride === 'object') { // We have a manual DateRange
        return {
          from: this.periodOverride.start,
          to: this.periodOverride.end
        }
      } else {
        const period = DATE_RANGE_PRESETS[this.periodOverride]()
        return {
          from: period.start,
          to: period.end
        }
      }
    }
    return undefined
  }

  /**
   * @returns The date range to use in source.query, taking override settings into account
   */
  getDateRange (): DateRange | undefined {
    const periodOverride = this.getPeriodOverride()
    if (this.isComparison) {
      if (this.comparisonPeriodOverride !== undefined) {
        let refDateRange = this.dateRangeRef
        if (periodOverride !== undefined) {
          // Case 1: periodOverride + comparisonPeriodOverride -> use the periodOverride as ref date range
          refDateRange = periodOverride
        }
        // Case 2: only comparionPeriodOverride -> use the normal period as ref date range
        const range = COMPARISON_DATE_RANGE_PRESETS[this.comparisonPeriodOverride]({ start: refDateRange.from, end: refDateRange.to })
        return { from: range.start, to: range.end }
      } else if (this.periodOverride !== undefined) {
        // Case 3: only periodOverride -> use the normal comparison period
        return this.dateRange
      }
    }
    return periodOverride || this.dateRange
  }

  refreshData (): void {
    if (this.disposed) {
      return
    }
    if (this.state.state === StoreState.PREPARING) {
      this.shouldRefresh = true
      return
    }

    const dateRange = this.getDateRange()
    if (!dateRange || !this.requiredFiltersFulfilled()) {
      if (this.state.state === StoreState.WORKER_READY) {
        return
      }
      this.state.state = StoreState.PREPARING
      this.clearCache()
      this.worker.postMessage({
        op: WorkerOperation.CLEAR_DATA,
        params: {}
      } as WorkerMessage)
      // console.log(this.uid, 'Store clear data because dateRange undefined or required filters not fulfilled')
      return
    }
    // console.log(this.uid, 'Refreshing store data')
    this.state.state = StoreState.LOADING
    this.source.query(this.uid, this.definition, this.timeDimensions, this.refreshDimensions, this.comparisonGroups, { filters: this.filters, dateRange, comparisonDateRange: {} }, this.granularity)
      .then(res => {
        if (res) {
          this.setData(res.records)
          this.lastDateRange = res.dateRange
          if (res.records.length >= 50000 && this.onDataLimit) {
            this.onDataLimit()
          }
        }
      })
      .catch(err => {
        if (this.onError) {
          this.onError(err)
        }
        console.error(this.uid, err)
        this.clearCache()
        this.worker.postMessage({
          op: WorkerOperation.CLEAR_DATA,
          params: {}
        } as WorkerMessage)
      })
  }

  setData (data: Array<Record<string, any>>): void {
    this.worker.postMessage({
      op: WorkerOperation.SET_DATA,
      params: {
        records: data
      }
    } as WorkerMessage)
  }

  setDateRange (dateRange?: Partial<DateRange>): void {
    // Note: this DateRange can be ignored if there is a period override
    if (dateRange === undefined || dateRange.from === undefined || dateRange.to === undefined) {
      this.dateRange = undefined
      return
    }

    this.dateRange = dateRange as DateRange
  }

  addDimension (dimension: Dimension, metrics: Metric[], dependentID?: string): void {
    if (dependentID !== undefined) {
      if (this.cache[dimension.name] !== undefined && this.cache[dimension.name][dependentID] !== undefined) {
        return
      }
      // console.log(this.uid, `Widget ${dependentID} asks for dependency on dimension ${dimension.name}`)
    }
    this.worker.postMessage({
      op: WorkerOperation.ADD_DIMENSION,
      params: {
        dimension: dimension.serialize(),
        metrics: metrics.map(m => ({ name: m.name, reducer: m.ReducerConstructor.serialize() })),
        dependentID
      }
    } as WorkerMessage)
  }

  disposeDimension (dimensionName: string, dependentID: string): void {
    // console.log(this.uid, `Widget ${dependentID} revokes its dependency on dimension ${dimensionName}`)
    this.worker.postMessage({
      op: WorkerOperation.DISPOSE_DIMENSION,
      params: {
        dimensionName,
        dependentID
      }
    } as WorkerMessage)
    if (this.cache[dimensionName]) {
      delete this.cache[dimensionName][dependentID]
    }
  }

  addMetric (metric: Metric, dependentID: string): void {
    if (this.metricsCache[metric.name] !== undefined && this.metricsCache[metric.name][dependentID] !== undefined) {
      return
    }
    // console.log(this.uid, `Widget ${dependentID} asks for dependency on metric ${metricName}`)
    this.worker.postMessage({
      op: WorkerOperation.ADD_METRIC,
      params: {
        name: metric.name,
        reducer: metric.ReducerConstructor.serialize(),
        dependentID
      }
    } as WorkerMessage)
  }

  disposeMetric (metricName: string, dependentID: string): void {
    // console.log(this.uid, `Widget ${dependentID} revokes its dependency on metric ${metricName}`)
    this.worker.postMessage({
      op: WorkerOperation.DISPOSE_METRIC,
      params: {
        metricName,
        dependentID
      }
    } as WorkerMessage)
    if (this.metricsCache[metricName]) {
      delete this.metricsCache[metricName][dependentID]
    }
  }

  filterAll (filters: Record<string, FilterValues>): void {
    // console.log(this.uid, 'Refilter all dimensions')
    this.filters = filters
    this.worker.postMessage({
      op: WorkerOperation.FILTER_ALL,
      params: {
        filters: toRaw(filters)
      }
    } as WorkerMessage)
  }

  clearCache (): void {
    // Clear keys manually to avoid losing reactivity.
    for (const key in this.cache) {
      delete this.cache[key]
    }
    for (const key in this.metricsCache) {
      delete this.metricsCache[key]
    }
  }

  // Important! Call this when you're done with the store, for example
  // when you unmount a component that is using a Store.
  dispose (): void {
    this.disposed = true
    if (this.comparisonStore) {
      this.comparisonStore.dispose()
    }
    this.stopWatchFilters()
    if (this.stopWatchDate !== undefined) {
      this.stopWatchDate()
    }
    this.worker.terminate()
  }

  serialize (): SerializedStore {
    return {
      uid: this.uid,
      name: this.name,
      source: this.source.uid,
      definition: serializeDataDefinition(this.definition),
      requiredFilters: [...this.requiredFilters],
      refreshDimensions: [...this.refreshDimensions],
      timeDimensions: [...this.timeDimensions],
      granularity: this.granularity,
      disableComparison: this.disableComparison,
      periodOverride: this.periodOverride ? serializeDateRange(this.periodOverride) : undefined,
      comparisonPeriodOverride: this.comparisonPeriodOverride
    }
  }

  static deserialize (def: SerializedStore, sources: Source[], comparisonGroups: ComparisonGroup[], filters: Filters, onError?: (e: any) => void, onDataLimit?: () => void): Store {
    if (def.uid === undefined || typeof def.uid !== 'string') {
      throw new Error('Invalid SerializedStore: missing or invalid uid')
    }
    if (def.name === undefined) {
      def.name = def.uid
    }
    if (def.name === undefined || typeof def.name !== 'string') {
      throw new Error('Invalid SerializedStore: missing or invalid name')
    }
    if (def.definition === undefined) {
      throw new Error('Invalid SerializedStore: missing data definition')
    }
    if (def.refreshDimensions === undefined || !Array.isArray(def.refreshDimensions)) {
      throw new Error('Invalid SerializedStore: missing refresh dimensions')
    }
    if (def.requiredFilters === undefined || !Array.isArray(def.requiredFilters)) {
      throw new Error('Invalid SerializedStore: missing required filters')
    }
    const source = sources.find(s => s.uid === def.source)
    if (source === undefined) {
      throw new Error(`Invalid SerializedStore: source "${def.source}" doesn't exist`)
    }
    const granularity = def.granularity || 'day'
    if (TIME_GRANULARITIES[granularity] === undefined) {
      throw new Error(`Invalid SerializedStore: time granularity "${granularity}" doesn't exist`)
    }
    const periodOverride = def.periodOverride !== undefined ? deserializeDateRange(def.periodOverride) : undefined
    const comparisonPeriodOverride = deserializeComparisonPeriod(def.comparisonPeriodOverride)
    return new Store(def.uid, def.name, source, deserializeDataDefinition(def.definition), [...def.timeDimensions], [...def.requiredFilters], [...def.refreshDimensions], comparisonGroups, filters, granularity, false, def.disableComparison === true, periodOverride, comparisonPeriodOverride, onError, onDataLimit)
  }
}

// The Symbol used for provide/inject of the stores list.
export const StoresInjectKey: InjectionKey<Ref<Store[]>> = Symbol('stores')
