import { RouteRecordRaw } from 'vue-router'

import { rolesGroup } from '@/static/roles'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

export function apikeys (context: ContextType): RouteRecordRaw[] {
  return [
    {
      path: 'apikeys',
      name: `${context}.apikeys.index`,
      component: () => import('./views/Index.vue'),
      meta: {
        requireContext: true,
        title: i18n.global.t('app.apiKey', 2),
        feature: 'apikeys',
        roles: rolesGroup.management,
        requireService: context === ContextType.GROUPS
      },
      children: [
        {
          path: 'create',
          name: `${context}.apikeys.create`,
          component: () => import('./views/Create.vue'),
          meta: {
            title: i18n.global.t('apiKeys.create'),
            feature: 'apikeys',
            roles: rolesGroup.management
          }
        },
        {
          path: ':apiKeyId/delete',
          name: `${context}.apikeys.delete`,
          component: () => import('./views/Delete.vue'),
          props: route => ({ apiKeyId: route.params.apiKeyId, redirectTo: route.params.redirectTo }),
          meta: {
            title: i18n.global.t('apiKeys.delete'),
            roles: rolesGroup.management,
            feature: 'apikeys'
          }
        }
      ]
    }
  ]
}
