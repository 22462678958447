import tags from '@/static/tags'

import i18n from '@/plugins/i18n'

import { Mediatype } from './enum/mediatype'

export type TagPackage = {
  [key:string]: string | undefined
}

export interface Tag {
  id: number
  name: string
  mediatype: Mediatype
  tag: TagPackage
  packageRuleName: string
  packageRuleId: number
  repackagingId: number
  mappingDate: string
}

export interface CreateTag {
  // name: string
  mediatypes: Mediatype[]
  tag: TagPackage
  // mappingDate: string
  packageRuleName: string | null
  packageRuleId: number | null
  repackagingId: number | null
}

export const getHumanTagName = (columnName: string, bidderCode?: string): string => {
  const defaultName = i18n.global.t('labels.' + columnName, 0, { missingWarn: false, fallbackWarn: false, default: columnName })
  if (!bidderCode) {
    return defaultName
  }

  return tags[bidderCode]?.[columnName]?.bidderHumanName || defaultName
}

export const getTagFromHumanName = (humanName: string, bidderCode: string): string => { // TODO use this to recover real tag name from imported CSV
  const bidderTags = tags[bidderCode]
  if (!bidderTags) {
    return humanName
  }
  return Object.values(bidderTags).find(t => t.bidderHumanName === humanName)?.key || humanName
}
