import { AxiosResponse } from 'axios'
import omit from 'lodash/omit'

import { Paginate } from '@/types/paginate'

import { CreateSeatsBoosterRuleForm, SeatsBoosterRule, UpdateSeatsBoosterRuleForm } from '@/models/seatsBoosterRule'

import api from '@/api'

export async function fetchSeatsBoosterRules (queryParams?: Object): Promise<AxiosResponse<Paginate<SeatsBoosterRule[]>>> {
  return api.get<Paginate<SeatsBoosterRule[]>>('/seats-booster-rules', { params: queryParams })
}

export async function fetchSeatsBoosterRule (id: number): Promise<AxiosResponse<SeatsBoosterRule>> {
  return api.get<SeatsBoosterRule>(`/seats-booster-rules/${id}`)
}

export async function createSeatsBoosterRule (data: CreateSeatsBoosterRuleForm): Promise<AxiosResponse<SeatsBoosterRule>> {
  return api.post<SeatsBoosterRule>('/seats-booster-rules', omit(data, 'estimatedCompletedAt'))
}

export async function updateSeatsBoosterRule (id: number, data: UpdateSeatsBoosterRuleForm): Promise<AxiosResponse<SeatsBoosterRule>> {
  return api.patch<SeatsBoosterRule>(`/seats-booster-rules/${id}`, omit(data, 'estimatedCompletedAt'))
}

export async function abortSeatsBoosterRule (id: number): Promise<AxiosResponse<void>> {
  return api.delete(`/seats-booster-rules/${id}/abort`)
}

export async function pauseSeatsBoosterRule (id: number): Promise<AxiosResponse<SeatsBoosterRule>> {
  return api.patch<SeatsBoosterRule>(`/seats-booster-rules/${id}/pause`, undefined)
}

export async function resumeSeatsBoosterRule (id: number): Promise<AxiosResponse<SeatsBoosterRule>> {
  return api.patch<SeatsBoosterRule>(`/seats-booster-rules/${id}/resume`, undefined)
}
