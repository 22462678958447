import { format, isDate, isValid } from 'date-fns'
import escape from 'lodash/escape'
import snakeCase from 'lodash/snakeCase'
import without from 'lodash/without'
import { Ref, ref, watch } from 'vue'
import { Router } from 'vue-router'

import { ColumnProperty, Filter, Sort } from '@/plugins/filters'

import { View } from '@/models/view'

import { CondFilter } from './operators'

export type QueryBuilderParams = {
  search?: any
  sort?: any
  page?: any
  view?: any
  filter?: any
  or?: any
}

export class QueryBuilder {
  queryObject: Ref<QueryBuilderParams>

  constructor (router?: Router) {
    this.queryObject = ref({})

    if (router) {
      watch(
        this.queryObject,
        () => {
          router.replace({ ...router.currentRoute.value, query: this.queryObject.value })
        }, { deep: true }
      )
    }
  }

  formatField (fieldKey: string): string {
    let params = ''
    const tmp = fieldKey.split('.')
    if (tmp.length > 1) {
      params = tmp[0][0].toUpperCase() + tmp[0].substring(1) + '.' + snakeCase(tmp[1])
    } else {
      params = snakeCase(fieldKey)
    }
    return params
  }

  setSearch (value: string): void {
    if (value.length > 0) {
      this.queryObject.value.search = escape(value)
    } else {
      delete this.queryObject.value.search
    }
  }

  setSorts (value: Sort[]): void {
    this.queryObject.value.sort = value.map((s) => {
      return `${this.formatField(s.field)},${s.sortOrder}`
    })
  }

  setPagination (value: number): void {
    if (value > 0) {
      this.queryObject.value.page = value
    } else {
      delete this.queryObject.value.page
    }
  }

  setView (value: View | undefined | null, updateColumns: (columns: ColumnProperty[]) => void = (): void => {}): void {
    if (value) {
      this.queryObject.value.view = value.id

      if (value.content) {
        if (value.content.properties) {
          updateColumns(value.content.properties)
        }

        if (value.content.filter) {
          this.queryObject.value.filter = this.append(this.queryObject.value.filter, value.content.filter)
        }

        if (value.content.or) {
          this.queryObject.value.or = this.append(this.queryObject.value.or, value.content.or)
        }

        if (value.content.sort) {
          this.queryObject.value.sort = this.append(this.queryObject.value.sort, value.content.sort)
        }
      }
    } else {
      delete this.queryObject.value.view
    }
  }

  private append (queryObject: string[] | undefined, items: string | string[]): string[] {
    const values = Array.isArray(items) ? items : [items]

    if (queryObject) {
      const newValues = without(values, ...queryObject)
      queryObject.push(...newValues)
      return queryObject
    }
    return values
  }

  setFilters (value: Filter[], filtersCond: CondFilter): void {
    const filterKey = filtersCond === CondFilter.AND ? 'filter' : 'or'

    const opositeFilterKey = filterKey === 'filter' ? 'or' : 'filter'

    const queryObject: { [key: string]: any } = { [filterKey]: [] }

    value.forEach((f) => {
      let param = `${this.formatField(f.fieldKey)}||${f.operator}`

      if (f.value === 0 || f.value) {
        if (Array.isArray(f.value)) {
          param = `${param}||${f.value.join(',')}`
        } else if (isDate(f.value) && isValid(f.value)) {
          param = `${param}||${format(f.value, 'yyyy-MM-dd')}`
        } else if (typeof (f.value) === 'object') {
          if (f.value.start && f.value.end) {
            param = `${param}||${format(f.value.start, 'yyyy-MM-dd')},${format(f.value.end, 'yyyy-MM-dd')}`
          }
        } else {
          param = `${param}||${f.value}`
        }
      }

      queryObject[filterKey] = [
        ...queryObject[filterKey],
        param
      ]
    })

    this.queryObject.value[filterKey] = queryObject[filterKey]

    if (this.queryObject.value[opositeFilterKey]) {
      delete this.queryObject.value[opositeFilterKey]
    }
  }
}
