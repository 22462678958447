<template>
  <div
    :class="wrapperClass"
    class="relative"
  >
    <Tooltip
      :disabled="(fieldErrors?.$errors?.length || 0) <= 0"
      :closeable="true"
      :force-show="true"
    >
      <div class="flex flex-col">
        <label
          v-if="label"
          :for="idValue"
          class="flex w-full text-xs font-semibold text-text-primary"
          :aria-label="label"
        >
          <span
            v-if="label"
            :class="[
              mode === 'materialize' ? 'hidden' : 'mb-2',
              $attrs.required || isRequired ? `after:content-['*']` : ''
            ]"
          >
            {{ label }}
          </span>
          <span
            v-if="hints.textTop"
            class="mb-2 ml-auto text-xs font-normal text-slate-400"
          > {{ hints.textTop }}</span>
        </label>

        <div class="relative flex flex-col w-full">
          <input
            :id="idValue"
            ref="input"
            v-model="value"
            v-bind="$attrs"
            :type="inputType"
            :class="[
              inputClasses,
              mode === 'materialize' ? 'peer order-2 transition-all focus:pt-[1.375rem] focus:pb-1 autofill:pt-[1.375rem] autofill:pb-1' : '',
              withIcon ? 'pl-10' : '',
              hints.tooltip || type === 'password' ? '!pr-10' : '',
              textLimitation ? '!pr-12' : '',
              hints.tooltip && type === 'password' ? '!pr-16' : ''
            ]"
            :aria-invalid="fieldErrors?.$errors?.length ? true : undefined"
            :aria-describedby="fieldErrors?.$errors?.length ? `${name}-error` : undefined"
            :aria-required="isRequired && !$attrs.required ? true : undefined"
            :maxlength="textLimitation ? textLimitation : undefined"
            @change="onChange"
          >
          <span
            v-if="textLimitation > 0"
            class="absolute px-1.5 py-0.5 text-xs font-medium text-center bg-gray-200 rounded right-4 top-1/4 text-primary-900"
          >
            {{ currentTextLimitation }}
          </span>
          <span
            v-if="mode=== 'materialize'"
            class="order-1 absolute top-[50%] -mt-[11px] left-[1px] pointer-events-none transition-all block pl-5 pr-2.5 text-base leading-5.5 text-slate-500 font-semibold border-none
                peer-focus:text-xs peer-focus:top-[6px] peer-focus:mt-0 peer-focus:leading-4.5
                peer-autofill:text-xs peer-autofill:top-[6px] peer-autofill:mt-0 peer-autofill:leading-4.5
                w-full truncate"
            :class="[
              (value !== '' && value !== null) ? '!text-xs !top-[6px] !mt-0 !leading-4.5' : '',
              $attrs.required || isRequired ? `after:content-['*']` : ''
            ]"
          >
            {{ label }}
          </span>
          <span v-if="withIcon">
            <slot
              name="icon"
              :classes="'absolute top-1/2 -translate-y-1/2 left-2 h-4 w-4 text-gray-500'"
            />
          </span>
        </div>
      </div>

      <template
        v-if="(fieldErrors?.$errors?.length || 0) > 0"
        #title
      >
        <div class="flex flex-col gap-0.5">
          <p
            v-for="(err, i) in fieldErrors!.$errors"
            :key="i"
            class="inline-block text-xs font-normal"
          >
            {{ err }}
          </p>
        </div>
      </template>
    </Tooltip>
    <div
      v-if="hints.tooltip || hints.textBottom || type === 'password'"
      class="absolute top-4 right-0 flex items-center pr-3.5  gap-2"
    >
      <button
        v-if="type === 'password'"
        type="button"
        @click="inputType = inputType === 'password' ? 'text' : 'password'"
      >
        <EyeSlashIcon
          v-if="inputType === 'text'"
          key="eye-slash-icon"
          class="w-4 h-4 text-slate-500"
        />
        <EyeIcon
          v-else
          key="eye-icon"
          class="w-4 h-4 text-slate-500"
        />
      </button>
      <Tooltip
        v-if="hints.tooltip"
        key="hints-tooltip"
      >
        <QuestionMarkOutline class="w-4 h-4 text-slate-500" />
        <template #content>
          <p class="text-sm whitespace-pre-line">
            {{ hints.tooltip }}
          </p>
        </template>
      </Tooltip>
    </div>
    <FormHelpText
      v-if="hints.textBottom"
      :help-text="hints.textBottom"
    />
  </div>
</template>

<script lang="ts">
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/solid'
import { ErrorObject } from '@vuelidate/core'
import { computed, defineComponent, PropType, onMounted, onBeforeUnmount, ref } from 'vue'

import { useVuelidateError } from '@/plugins/form'

import { randomString } from '@/utils/utils'

import Tooltip from '@/components/Tooltip/Tooltip.vue'

import QuestionMarkOutline from '../Icons/QuestionMarkOutline.vue'

import FormHelpText from './FormHelpText.vue'

import { FormInputHints, FormInputMode } from '.'

export default defineComponent({
  components: {
    EyeIcon,
    EyeSlashIcon,
    Tooltip,
    FormHelpText,
    QuestionMarkOutline
  },
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: [String, Number] as PropType<string | number | null>,
      required: false,
      default: ''
    },
    errors: {
      type: [Array] as PropType<ErrorObject[]>,
      required: false,
      default: () => []
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    fieldClass: { // @todo remove ?
      type: String,
      required: false,
      default: ''
    },
    wrapperClass: {
      type: String,
      required: false,
      default: ''
    },
    mode: {
      type: String as PropType<FormInputMode | 'datatable'>,
      required: false,
      default: 'materialize'
    },
    hints: {
      type: Object as PropType<FormInputHints>,
      required: false,
      default: () => ({})
    },
    withIcon: {
      type: Boolean,
      required: false,
      default: () => false
    },
    onChange: {
      type: Function as PropType<(e: any) => void>,
      required: false,
      default: () => {}
    },
    focusOnMount: {
      type: Boolean,
      required: false,
      default: false
    },
    nullIfEmpty: {
      type: Boolean,
      default: false
    },
    textLimitation: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  emits: ['update:modelValue'],
  setup (props, { attrs, emit }) {
    const value = computed<string | number | null>({
      get () {
        return props.modelValue
      },
      set (value) {
        if (props.nullIfEmpty && value === '') {
          value = null
        }
        emit('update:modelValue', value)
      }
    })

    const currentTextLimitation = computed(() => {
      if (!value.value) {
        return props.textLimitation
      }

      const currentValue: string = typeof value.value === 'number' ? value.value.toString() : value.value

      const limitation = props.textLimitation - currentValue.length

      if (limitation < 0) {
        return 0
      }

      return limitation
    })

    const inputType = ref(props.type)

    const input = ref<HTMLInputElement | null>(null)

    const idValue = `${props.name}-${randomString(12)}`

    const inputClasses = computed(() => {
      const css = [
        'block', 'w-full',
        'outline-none', 'focus:outline-none', 'autofill:outline-none',
        'ring-0', 'focus:ring-0', 'autofill:ring-0',
        'placeholder:text-slate-400', 'placeholder:font-normal',
        'disabled:bg-gray-200',
        'read-only:bg-gray-200'
      ]

      switch (props.mode) {
        case 'default':
          css.push('rounded-lg', 'pl-5', 'pr-2.5',
            'font-normal', 'text-base', 'leading-[1.375rem]', 'text-text-primary',
            'border', 'border-gray-border')
          break
        case 'datatable':
          css.push('font-normal', 'text-sm', 'leading-none', 'text-text-primary', 'py-1 px-3', 'rounded-sm', 'border-none')
          break
        case 'materialize':
          css.push('rounded-lg', 'pl-5', 'pr-2.5',
            'text-base', 'leading-[1.375rem]', 'text-text-primary',
            'border', 'border-gray-border')
          css.push('h-12.125')

          if (value.value !== '') {
            css.push('pt-[1.375rem]', 'pb-1', 'font-semibold')
          }
          break
      }

      if (!attrs.readonly) {
        css.push('focus:border-primary-500', 'autofill:border-primary-500')
      }

      if (fieldErrors.value?.$errors?.length) {
        css.push('!border-red-400')
      }

      if (props.fieldClass) {
        css.push(props.fieldClass)
      }

      return css.join(' ')
    })

    onMounted(() => {
      if (props.focusOnMount === true) {
        // focus forces reflow, making it bad for performance.
        // Waiting for the DOM to be correctly updated after mount
        // reduces the load by a lot. jQueryUI does that too apparently.
        setTimeout(() => {
          const el = document.getElementById(idValue)
          if (el) {
            el.focus()
          }
        }, 1)
      }
    })

    onBeforeUnmount(() => {
      if (input.value) {
        input.value.blur()
      }
    })

    const fieldErrors = computed(() => useVuelidateError(props.errors))

    return {
      idValue,
      input,
      inputClasses,
      value,
      fieldErrors,
      currentTextLimitation,
      inputType
    }
  }
})
</script>
