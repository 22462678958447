<template>
  <Collapse header-class="py-4">
    <template #header>
      {{ t('labels.store', 2) }}
    </template>
    <div class="flex flex-col items-center justify-center gap-4 xl:flex-row xl:items-start">
      <StoreForm
        :sources="sources"
        :stores="stores"
        @submit="$emit('submit', $event)"
      />
      <div class="flex flex-col w-full xl:flex-row xl:items-start">
        <div class="flex flex-row flex-wrap self-stretch flex-shrink-0 text-sm border-b xl:border-r xl:border-b-0 xl:flex-col">
          <div
            v-for="store in stores"
            :key="store.uid"
            class="flex items-center justify-between p-1 px-2 break-all rounded-t cursor-pointer max-w-48 hover:bg-gray-200 xl:rounded-t-none xl:rounded-l group"
            :class="selected === store ? 'text-white !bg-primary-500' : ''"
            @click="selected = store"
          >
            <span>{{ store.name }}</span>
            <button
              class="flex items-center justify-center p-1 rounded opacity-0 cursor-pointer group-hover:opacity-100"
              :class="selected === store ? 'hover:bg-primary-400': 'hover:bg-gray-400'"
              type="button"
              @click.stop="deleting = store; deleteOpen = true"
            >
              <TrashIcon class="inline-block w-4 h-4" />
            </button>
          </div>
        </div>
        <div
          v-if="selected !== undefined"
          class="grid flex-grow text-sm grid-cols-vertical-form auto-rows-min-9 striped"
        >
          <div class="flex items-center h-full p-1 font-medium xl:pl-3">
            {{ t('labels.uid') }}
          </div>
          <div class="flex items-center justify-between p-1 font-mono text-gray-700">
            <span>{{ selected.uid }}</span>
            <button
              class="flex items-center justify-center p-1 text-black rounded cursor-pointer hover:bg-gray-300"
              type="button"
              :title="t('actions.duplicate', [t('app.store')])"
              @click="onDuplicateClicked"
            >
              <Square2StackIcon class="inline-block w-4 h-4" />
            </button>
          </div>
          <div class="flex items-center h-full p-1 font-medium xl:pl-3">
            {{ t('labels.name') }}
          </div>
          <div class="flex items-center p-1 text-gray-700">
            <input
              v-model="selected.name"
              :placeholder="t('labels.name')"
              class="min-w-36 h-7 px-2.5 py-0.5 border border-gray-400 text-gray-700 focus:ring-primary-500 focus:border-primary-500 rounded focus:outline-none w-full"
              @change="onNameChange(selected!)"
            >
          </div>
          <div class="flex items-center h-full p-1 font-medium xl:pl-3">
            {{ t('labels.source') }}
          </div>
          <div class="flex items-center p-1 text-gray-700">
            <span>{{ selected.source.name }}</span>
          </div>
          <div class="flex items-center h-full p-1 font-medium xl:pl-3">
            {{ t('labels.dataDimensions') }}
          </div>
          <div class="p-1 text-gray-700">
            <Multiselect
              :model-value="selected.definition.dimensions.map(d => d.name) || []"
              mode="tags"
              compact
              name="definitionDimensions"
              :options="availableDimensions"
              class="ml-2"
              @update:model-value="setDimensions"
            />
          </div>
          <div class="flex items-center h-full p-1 font-medium xl:pl-3">
            {{ t('labels.requiredFilters') }}
          </div>
          <div class="p-1 text-gray-700">
            <div class="relative flex items-center">
              <span class="flex-grow">
                {{ selected.requiredFilters.map(f => `${f.dimension} (${f.min} ≤ n ≤ ${f.max})`).join(', ') }}
              </span>
              <RequiredFilterEditForm
                v-if="selected.source.definition"
                v-model="selected.requiredFilters"
                :definition="selected.source.definition"
                popover-panel-class="right-0"
              />
            </div>
          </div>
          <div class="flex items-center h-full p-1 font-medium xl:pl-3">
            {{ t('labels.refreshDimensions') }}
          </div>
          <div class="p-1 text-gray-700">
            <Multiselect
              v-model="selected.refreshDimensions"
              mode="tags"
              compact
              name="refreshDimensions"
              :options="availableRefreshDimensions"
            />
          </div>
          <div class="flex items-center h-full p-1 font-medium xl:pl-3">
            {{ t('labels.metric', 2) }}
          </div>
          <div class="p-1 text-gray-700">
            <Multiselect
              :model-value="Object.keys(selected.definition.metrics) || []"
              mode="tags"
              compact
              name="definitionMetrics"
              :options="selected.source.definition ? Object.keys(selected.source.definition.metrics) : []"

              @update:model-value="setMetrics"
            />
          </div>
          <div class="flex items-center h-full p-1 font-medium xl:pl-3">
            {{ t('labels.granularity') }}
          </div>
          <div class="p-1 text-gray-700">
            <Multiselect
              v-model="selected.granularity"
              name="granularity"
              mode="single"
              :close-on-select="true"
              :options="granularityMultiselectOptions"
              :classes="multiselectTailwindClassesCompact"
              :can-clear="false"
              :can-deselect="false"
              :disabled="true"
            />
          </div>
          <div class="flex items-center h-full p-1 font-medium xl:pl-3">
            {{ t('labels.periodOverride') }}
          </div>
          <div class="p-1 text-gray-700">
            <DefaultPeriodPicker
              v-model="selected.periodOverride"
              :none-label="t('labels.noOverride')"
              button-classes="!h-7 mt-0"
              class="whitespace-nowrap"
            />
          </div>
          <div class="flex items-center h-full p-1 font-medium xl:pl-3">
            {{ t('labels.comparisonPeriodOverride') }}
          </div>
          <div class="p-1 text-gray-700">
            <Multiselect
              name="comparisonPeriodOverride"
              :model-value="selected.comparisonPeriodOverride || comparisonOverrideNone"
              mode="single"
              :close-on-select="true"
              :options="comparisonOverrideOptions"
              :classes="multiselectTailwindClassesCompact"
              :can-clear="false"
              :can-deselect="false"
              @input="selected!.comparisonPeriodOverride = $event !== comparisonOverrideNone ? $event : undefined"
            />
          </div>
          <div class="flex items-center h-full p-1 font-medium xl:pl-3">
            {{ t('labels.comparisonDisabled') }}
          </div>
          <div class="flex items-center p-1 text-gray-700">
            <input
              type="checkbox"
              class="w-4 h-4 mr-1 border-gray-400 rounded cursor-pointer text-primary-600 focus:ring-primary-500"
              :checked="selected.disableComparison"
              :title="t('dashboards.effectAfterRefresh')"
              @input="selected!.disableComparison = !selected!.disableComparison"
            >
            <span
              class="cursor-pointer select-none"
              @click="selected!.disableComparison = !selected!.disableComparison"
            >
              {{ selected.disableComparison ? t('labels.yes') : t('labels.no') }}
            </span>
          </div>
          <div class="flex items-center h-full p-1 font-medium xl:pl-3">
            {{ t('labels.processedDimensions') }}
          </div>
          <div class="flex items-center p-1 text-gray-700">
            {{ Object.keys(selected.cache).join(', ') }}
          </div>
        </div>
      </div>
    </div>
    <Modal v-model:open="deleteOpen">
      <template #title>
        <ExclamationTriangleIcon class="self-center inline-block w-8 h-8 mr-1 text-amber-500" />
        {{ t('dashboards.deleteStore') }}
      </template>

      <div class="px-6 py-3 ">
        <div class="flex-grow">
          <p>
            <span class="font-medium">{{ t('labels.uid') }}:</span>&nbsp;<span class="font-mono">{{ deleting?.uid }}</span>
          </p>
          <p>
            <span class="font-medium">{{ t('labels.name') }}:</span>&nbsp;<span class="font-mono">{{ deleting?.name }}</span>
          </p>
          <p>
            {{ t('messages.deleteConfirm', [t('app.store')]) }}
          </p>
          <p>
            {{ t('dashboards.deleteStoreNotice') }}
          </p>
        </div>
      </div>
      <div class="flex flex-row justify-end gap-1 p-3">
        <button
          type="button"
          class="px-3 py-1 text-white bg-indigo-400 rounded-md"
          @click="deleteOpen = false"
        >
          {{ t('actions.cancel') }}
        </button>
        <button
          type="button"
          class="flex items-center px-3 py-1 text-white bg-red-700 rounded-md"
          @click="$emit('delete', deleting); deleteOpen = false"
        >
          <TrashIcon class="inline-block w-4 h-4 align-center" />&nbsp;{{ t('actions.delete') }}
        </button>
      </div>
    </Modal>
  </Collapse>
</template>

<script lang="ts">
import { Square2StackIcon, TrashIcon } from '@heroicons/vue/24/outline'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid'
import { v1 as uuidv1 } from 'uuid'
import { PropType, computed, defineComponent, nextTick, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { Store, granularityMultiselectOptions } from '@/plugins/dashboard'
import { Source } from '@/plugins/dashboard/source'

import { useNotificationsStore } from '@/store/notifications.store'

import Collapse from '@/components/Collapse/Collapse.vue'
import { COMPARISON_DATE_RANGE_PRESETS } from '@/components/DateRangePicker/dateRange'
import { PRESET_TRANSLATIONS } from '@/components/DateRangePicker/translations'
import Multiselect from '@/components/Form/FormMultiselect.vue'
import Modal from '@/components/Modal/Modal.vue'
import { multiselectTailwindClassesCompact } from '@/components/Multiselect'

import DefaultPeriodPicker from './DefaultPeriodPicker.vue'
import RequiredFilterEditForm from './RequiredFilterEditForm.vue'
import StoreForm from './StoreForm.vue'

export default defineComponent({
  components: {
    Multiselect,
    RequiredFilterEditForm,
    DefaultPeriodPicker,
    Modal,
    TrashIcon,
    ExclamationTriangleIcon,
    Square2StackIcon,
    StoreForm,
    Collapse
  },
  props: {
    stores: {
      type: Array as PropType<Store[]>,
      required: true
    },
    sources: {
      type: Array as PropType<Source[]>,
      required: true
    }
  },
  emits: ['submit', 'delete', 'duplicate'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const notificationsStore = useNotificationsStore()

    // Data
    const comparisonOverrideNone = 'none'
    const deleteOpen = ref(false)
    const selected = ref(undefined as Store | undefined)
    const deleting = ref(undefined as Store | undefined)

    // Methods
    const dimensionsOptions = (source: Source, includeEnrichment: boolean) => {
      if (source.definition === undefined) {
        return []
      }
      return source.definition.dimensions
        .filter(d => (includeEnrichment || !d.enrichment) && !source.definition!.hidden.includes(d.name))
        .map(d => {
          let { name } = d
          if (d.enrichment) {
            const dependency = source.findDependency(d.name)
            if (dependency !== undefined) {
              name += t('dashboards.requiresDimension', [dependency])
            }
          }
          return {
            name,
            id: d.name
          }
        })
    }

    const setDimensions = (dimensions: string[]) => {
      if (selected.value !== undefined) {
        selected.value.definition.dimensions = selected.value.source.definition ? selected.value.source.definition.dimensions.filter(d => dimensions.includes(d.name)) : []
      }
    }

    const setMetrics = (metrics: string[]) => {
      if (selected.value !== undefined && selected.value.source.definition !== undefined) {
        selected.value.definition.metrics = {}
        metrics.forEach(m => {
          selected.value!.definition.metrics[m] = selected.value!.source.definition!.metrics[m]
        })
      }
    }

    const loadTableDefinition = () => {
      if (selected.value !== undefined) {
        const source = selected.value.source
        source.getTableDefinition()
          .catch((err: any) => {
            notificationsStore.add({
              title: t('labels.error'),
              message: t('dashboards.loadTableDefinitionError', [source.uid, err.toString()]),
              type: 'error'
            })
            console.error(err.toString())
          })
      }
    }

    const onNameChange = (store: Store) => {
      if (store.name === '') {
        store.name = store.uid
      }
    }

    const onDuplicateClicked = () => {
      if (selected.value === undefined) {
        return
      }
      const event = selected.value.serialize()
      event.uid = uuidv1()
      event.name += ' (copy)'
      emit('duplicate', event)
      nextTick(() => {
        const newStore = props.stores.find(s => s.uid === event.uid)
        if (newStore !== undefined) {
          selected.value = newStore
        }
      })
    }

    const dimensionList = (dimensions: string[]) => {
      if (selected.value === undefined) {
        return []
      }
      const options = dimensionsOptions(selected.value.source, true)
      // We also add the current dimensions even if they don't exist anymore (so they can be removed)
      dimensions.filter(d => !options.some(o => o.id === d)).forEach(d => options.push({
        name: d,
        id: d
      }))
      return options
    }

    // Computed
    const availableDimensions = computed(() => {
      return selected.value !== undefined ? dimensionList(selected.value.definition.dimensions.map(d => d.name)) : []
    })

    const availableRefreshDimensions = computed(() => {
      return selected.value !== undefined ? dimensionList(selected.value.refreshDimensions) : []
    })

    const comparisonOverrideOptions = computed(() => {
      const options = Object.keys(COMPARISON_DATE_RANGE_PRESETS).map(p => {
        return {
          id: p,
          name: PRESET_TRANSLATIONS[p],
          disabled: false
        }
      })
      return [
        {
          id: comparisonOverrideNone,
          name: t('labels.noOverride'),
          disabled: false
        },
        ...options
      ]
    })

    // Watch
    watch(
      () => props.stores.length,
      () => {
        if (props.stores.length > 0) {
          if (selected.value === undefined || !props.stores.some(s => s.uid === selected.value!.uid)) {
            selected.value = props.stores[0]
          }
        } else {
          selected.value = undefined
        }
      },
      { immediate: true }
    )

    watch(
      () => selected.value,
      () => {
        loadTableDefinition()
      },
      { immediate: true }
    )

    watch(
      () => deleteOpen.value,
      () => {
        if (deleteOpen.value === false) {
          deleting.value = undefined
        }
      }
    )

    return {
      // Data
      selected,
      comparisonOverrideNone,
      deleteOpen,
      deleting,

      // Computed
      availableDimensions,
      availableRefreshDimensions,
      comparisonOverrideOptions,

      // Methods
      setDimensions,
      setMetrics,
      onNameChange,
      onDuplicateClicked,

      // Misc
      multiselectTailwindClassesCompact,
      granularityMultiselectOptions,
      t
    }
  }
})
</script>
<style scoped>
@tailwind base;
@tailwind utilities;

@layer utilities {
  .grid.striped > div:nth-child(4n+1),
  .grid.striped > div:nth-child(4n+2) {
    @apply bg-gray-100
  }
}
</style>
