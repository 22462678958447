<template>
  <div class="relative flex flex-col items-end w-full h-full pt-1 mt-1 overflow-hidden text-sm">
    <div class="fixed right-6">
      <button
        type="button"
        class="inline-block p-1 align-middle rounded-full cursor-pointer hover:bg-gray-300"
        :title="t('actions.clipboard')"
        @click="onClipboardClick"
      >
        <ClipboardDocumentIcon class="w-6 h-6" />
      </button>
    </div>
    <textarea
      v-model="editableJSON"
      spellcheck="false"
      class="flex-grow w-full font-mono text-sm border-none resize-none focus:ring-0"
    />
    <div class="p-3">
      <button
        type="button"
        :title="t('actions.cancel')"
        class="px-3 py-1 mr-1 text-white rounded-md bg-amber-500"
        @click="$emit('cancel')"
      >
        🚫&nbsp;{{ t('actions.cancel') }}
      </button>
      <button
        type="button"
        :title="t('actions.confirm')"
        class="px-3 py-1 text-white bg-green-500 rounded-md focus:outline-none"
        @click="onSubmit()"
      >
        ✔️&nbsp;{{ t('actions.confirm') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { ClipboardDocumentIcon } from '@heroicons/vue/24/outline'
import { defineComponent, PropType, ref, watch } from 'vue'
import useClipboard from 'vue-clipboard3'
import { useI18n } from 'vue-i18n'

import { SerializedDashboard } from '@/plugins/dashboard'

import { useNotificationsStore } from '@/store/notifications.store'

export default defineComponent({
  components: {
    ClipboardDocumentIcon
  },
  props: {
    json: {
      type: Object as PropType<SerializedDashboard>,
      required: true
    }
  },
  emits: ['submit', 'cancel'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const editableJSON = ref('')

    const notificationsStore = useNotificationsStore()

    const { toClipboard } = useClipboard()

    watch(
      () => props.json,
      () => {
        editableJSON.value = JSON.stringify(props.json, null, 2)
      },
      { immediate: true }
    )

    const onSubmit = () => {
      try {
        const dashboard = JSON.parse(editableJSON.value) as SerializedDashboard
        if (typeof dashboard !== 'object' || Array.isArray(dashboard)) {
          throw new Error('Dashboard JSON must be an object')
        }
        emit('submit', dashboard)
      } catch (e: any) {
        notificationsStore.add({
          title: t('labels.error'),
          message: e.message,
          type: 'error'
        })
      }
    }

    const onClipboardClick = async () => {
      try {
        await toClipboard(editableJSON.value)
        notificationsStore.add({
          title: t('labels.success'),
          message: t('dashboards.jsonClipboardSuccess'),
          type: 'success',
          duration: 4000
        })
      } catch (e: any) {
        notificationsStore.add({
          title: t('labels.error'),
          message: t('dashboards.jsonClipboardFailure', [e.message]),
          type: 'error'
        })
      }
    }

    return {
      editableJSON,
      onSubmit,
      onClipboardClick,
      t
    }
  }
})
</script>
