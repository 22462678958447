import { Component, SelectHTMLAttributes } from 'vue'

import { FormInputHints } from '@/components/Form'
import FormComponent from '@/components/Form/FormNativeSelect.vue'

import { DefaultProps, FormRenderer } from '.'

interface Props extends DefaultProps {
  options?: Array<{ id: any, name: string }>
  onSelect?: (payload: Event) => void
  store?: () => Promise<any>
  attr?: SelectHTMLAttributes
  emptyValue?: boolean
  hints?: FormInputHints
}

export class FormNativeSelect implements FormRenderer<Props> {
  defaultProps: Props

  constructor (props: Props) {
    this.defaultProps = props
  }

  get wrapperClass (): string {
    return this.defaultProps.wrapperClass || ''
  }

  props (): Props {
    const attrs = { ...this.defaultProps.attr }
    const cleanProps = { ...this.defaultProps }
    delete cleanProps.attr

    return { ...cleanProps, ...attrs }
  }

  component (): Component {
    return FormComponent
  }
}
