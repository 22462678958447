import { RouteRecordRaw } from 'vue-router'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

export function bidderAdstxt (context: ContextType): RouteRecordRaw[] {
  return [
    {
      path: 'adstxts',
      name: `${context}.adstxts.index`,
      component: () => import('./views/Index.vue'),
      meta: {
        title: i18n.global.t('labels.supplyChain'),
        featuresPermissions: ['adstxts'],
        requireService: true
      }
    }
  ]
}
