import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { CreateView, UpdateView, View } from '@/models/view'

import * as viewsService from '@/services/views'

export enum ViewsType {
  NO_CONTEXT = 'noContext',
  WITH_CONTEXT = 'withContext'
}

export const useViewStore = defineStore('view', () => {
  const views = ref<View[]>([])

  const listableViews = computed<View[]>(() => views.value.filter((v) => !v.isDefault))
  const defaultViews = computed<View[]>(() => views.value.filter((v) => v.isDefault))
  const pinnedViews = computed<View[]>(() =>
    views.value
      .filter((v) => v.isPinned)
      .sort((v, ov) => (v.pinOrder && ov.pinOrder ? v.pinOrder - ov.pinOrder : v.id - ov.id))
  )

  const addViews = (newViews: View[]): void => {
    newViews.forEach(v => {
      const i = views.value.findIndex(view => view.id === v.id)
      if (i !== -1) {
        views.value[i] = v
      } else {
        views.value.push(v)
      }
    })
  }

  const fetchViews = async (
    type: ViewsType
  ): Promise<{ views: View[]; defaultViews: View[] } | unknown> => {
    const requireContext = type === ViewsType.WITH_CONTEXT

    try {
      const response = await viewsService.fetchViews(requireContext)

      if (response.data) {
        addViews(response.data)
        return { views, defaultViews: defaultViews.value }
      }
    } catch (err) {
      return Promise.reject(err)
    }
  }

  const createView = async (form: CreateView): Promise<View | unknown> => {
    const requireContext = form.resourceId !== undefined && form.resourceType !== undefined

    try {
      const response = await viewsService.createView(form, requireContext)

      if (response.data) {
        views.value.push(response.data)

        return response.data
      }
    } catch (err) {
      return Promise.reject(err)
    }
  }

  const updateView = async ({
    id,
    form
  }: {
    id: number
    form: UpdateView
  }): Promise<View | unknown> => {
    try {
      const response = await viewsService.updateView(id, form)

      if (response.data) {
        const viewIndex = views.value.findIndex((v) => v.id === id)

        if (viewIndex > -1) {
          views.value[viewIndex] = response.data
        }

        return response.data
      }
    } catch (err) {
      return Promise.reject(err)
    }
  }

  const deleteView = async (id: number): Promise<View[] | undefined> => {
    try {
      const response = await viewsService.deleteView(id)

      if (response.status === 204) {
        const newViews = views.value.filter((v) => v.id !== id)

        views.value = newViews

        return newViews
      }
    } catch (err) {
      return Promise.reject(err)
    }
  }

  return {
    views,
    listableViews,
    defaultViews,
    pinnedViews,
    fetchViews,
    createView,
    updateView,
    deleteView
  }
})
