import { RouteRecordRaw } from 'vue-router'

import { rolesGroup } from '@/static/roles'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

export function demandLibrary (): RouteRecordRaw[] {
  const context = ContextType.PUBLISHERS

  return [
    {
      path: 'demand-library',
      name: `${context}.demand-library.index`,
      component: () => import('./views/Index.vue'),
      meta: {
        title: i18n.global.t('labels.demandLibrary', 100),
        feature: 'demandLibrary',
        roles: rolesGroup.user
      }
    }
  ]
}
