<template>
  <Popover
    v-slot="{ open }"
    class="relative"
  >
    <PopoverButton
      as="div"
      class="box-border flex items-center justify-between w-full h-10 mx-auto mt-1 leading-snug border border-gray-400 rounded outline-none cursor-pointer"
      :class="buttonClasses + ' ' + (open ? multiselectTailwindClasses.containerActive + ' ' + multiselectTailwindClasses.containerOpen : '')"
    >
      <span
        v-if="inputLabel !== undefined"
        class="ml-2.5"
      >
        {{ inputLabel }}
      </span>
      <div
        v-else-if="typeof modelValue === 'object'"
        class="ml-2.5 max-h-full flex flex-row items-center"
      >
        <span>{{ format(modelValue.start, 'yyyy-MM-dd') }}</span>
        <template v-if="startOfDay(modelValue.start).getTime() !== startOfDay(modelValue.end).getTime()">
          <span class="contents">
            <ArrowLongRightIcon
              class="w-4 h-4 inline-block mx-0.5"
              aria-hidden="true"
            />
          </span>
          <span>{{ format(modelValue.end, 'yyyy-MM-dd') }}</span>
        </template>
      </div>
      <span :class="multiselectTailwindClasses.caret + ' ml-0.5 ' + (open ? multiselectTailwindClasses.caretOpen : '')" />
    </PopoverButton>
    <PopoverPanel class="absolute right-0 z-20 min-w-full -mt-px text-black select-none sm:text-sm">
      <div class="flex flex-col text-sm bg-white border border-gray-100 rounded-b shadow-lg ring-1 ring-black ring-opacity-5 min-w-64">
        <TabGroup :default-index="typeof modelValue === 'object' ? 1 : 0">
          <TabList class="flex flex-wrap mb-1 whitespace-nowrap">
            <Tab
              v-slot="{ selected }"
              as="template"
            >
              <p
                class="p-2 cursor-pointer rounded-b-md"
                :class="selected ? 'bg-primary-500 text-white' : '' "
              >
                {{ t('labels.preset', 2) }}
              </p>
            </Tab>
            <Tab
              v-slot="{ selected }"
              as="template"
            >
              <p
                class="p-2 cursor-pointer rounded-b-md"
                :class="selected ? 'bg-primary-500 text-white' : '' "
              >
                {{ t('labels.custom') }}
              </p>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <div class="flex flex-col overflow-y-scroll max-h-60">
                <div
                  v-for="option in presetOptions"
                  :key="option.value"
                  :class="modelValue === option.value || (modelValue === undefined && option.value === noneOptionValue) ? '!bg-primary-500 text-white' : ''"
                  class="w-full px-2 py-1 cursor-pointer hover:bg-gray-100"
                  @click="onPresetInput(option.value)"
                >
                  {{ option.label }}
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <DatePicker
                v-model.range="range"
                is-expanded
                color="indigo"
                :attributes="[
                  {
                    key: 'today',
                    highlight: 'gray',
                    dates: startOfToday()
                  }
                ]"
                @update:model-value="onCustomInput"
              />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </PopoverPanel>
  </Popover>
</template>

<script lang="ts">
import { Popover, PopoverButton, PopoverPanel, Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
import { ArrowLongRightIcon } from '@heroicons/vue/24/outline'
import { endOfDay, format, startOfDay, startOfToday } from 'date-fns'
import { DatePicker } from 'v-calendar'
import { PropType, computed, defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import i18n from '@/plugins/i18n'

import { DATE_RANGE_PRESETS, DateRange, DateRangePreset } from '@/components/DateRangePicker/dateRange'
import { PRESET_TRANSLATIONS } from '@/components/DateRangePicker/translations'
import { multiselectTailwindClasses } from '@/components/Multiselect'

// TODO close on select ?

export default defineComponent({
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    DatePicker,
    ArrowLongRightIcon
  },
  props: {
    modelValue: {
      type: [String, Object] as PropType<DateRange | DateRangePreset>,
      default: () => undefined
    },
    required: {
      type: Boolean,
      default: () => false
    },
    noneLabel: {
      type: String,
      default: () => i18n.global.t('labels.none')
    },
    buttonClasses: {
      type: String,
      default: () => ''
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const noneOptionValue = 'none'
    const noneOption = {
      value: noneOptionValue,
      label: props.noneLabel,
      disabled: false
    }

    // Data
    const range = ref(undefined as DateRange | undefined)

    // Computed
    const presetOptions = computed(() => {
      const options = Object.entries(DATE_RANGE_PRESETS).map(e => {
        return {
          value: e[0] as DateRangePreset,
          label: PRESET_TRANSLATIONS[e[0]],
          disabled: false
        }
      })
      return props.required ? options : [noneOption, ...options]
    })

    const inputLabel = computed(() => {
      if (props.modelValue === undefined) {
        return noneOption.label
      }
      return typeof props.modelValue === 'string' ? PRESET_TRANSLATIONS[props.modelValue] : undefined
    })

    // Watch
    watch(
      () => props.modelValue,
      () => {
        range.value = typeof props.modelValue === 'object' ? props.modelValue : undefined
      },
      { immediate: true }
    )

    // Methods
    const onPresetInput = (value: string) => {
      emit('update:modelValue', value !== noneOptionValue ? value : undefined)
    }

    const onCustomInput = (event: DateRange) => {
      const date: DateRange = {
        start: startOfDay(event.start),
        end: endOfDay(event.end)
      }
      emit('update:modelValue', date)
    }

    return {
      // Data
      range,
      noneOptionValue,

      // Computed
      presetOptions,
      inputLabel,

      // Methods
      onPresetInput,
      onCustomInput,

      // Misc
      startOfToday,
      format,
      startOfDay,
      multiselectTailwindClasses,
      t
    }
  }
})
</script>
<style scoped>
@tailwind base;

.vc-container {
  @apply border-0 mx-0;
}
</style>
