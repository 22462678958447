<template>
  <Collapse header-class="py-4">
    <template #header>
      {{ t('labels.source', 2) }}
    </template>

    <div class="flex flex-col">
      <SourceForm
        :sources="sources"
        @submit="$emit('submit', $event)"
      />
      <p
        v-if="sources.length > 0"
        class="mt-2"
      >
        {{ t('labels.source', 2) }}
      </p>
      <div
        v-if="sources.length > 0"
        class="w-full max-w-full text-sm grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-1"
      >
        <div
          v-for="s in sources"
          :key="s.uid"
          class="p-1 flex flex-col lg:grid lg:grid-cols-vertical-form auto-rows-min lg:place-items-center-stretch rounded border border-gray-400"
        >
          <div class="p-1 font-medium flex flex-row justify-between">
            {{ t('labels.uid') }}
            <button
              type="button"
              class="cursor-pointer outline-none lg:hidden"
              :title="t('actions.delete', [t('app.source')])"
              @click.stop="selected = s; deleteOpen = true"
            >
              ❌
            </button>
          </div>
          <div class="p-1 flex flex-row justify-between min-w-0 items-start">
            <div class="break-all font-mono">
              {{ s.uid }}
            </div>
            <button
              type="button"
              class="cursor-pointer outline-none hidden lg:inline-block"
              :title="t('actions.delete', [t('app.source')])"
              @click.stop="selected = s; deleteOpen = true"
            >
              ❌
            </button>
          </div>
          <div class="p-1 font-medium flex flex-row justify-between">
            {{ t('labels.name') }}
          </div>
          <div class="p-1 break-all min-w-0">
            <input
              v-model="s.name"
              :placeholder="t('labels.name')"
              class="min-w-36 h-7 px-2.5 py-0.5 border border-gray-400 text-gray-700 focus:ring-primary-500 focus:border-primary-500 rounded focus:outline-none w-full"
              @change="onSourceNameChange(s)"
            >
          </div>
          <div class="p-1 font-medium">
            {{ t('labels.table') }}
          </div>
          <div class="p-1 break-all min-w-0">
            {{ s.table }}
          </div>
          <div class="p-1 font-medium flex flex-row justify-between">
            <span>{{ t('labels.enrichment', 2) }}</span>
            <button
              type="button"
              class="cursor-pointer outline-none lg:hidden"
              :title="t('actions.add', [t('app.enrichment')])"
              @click.stop="selected = s; addEnrichmentOpen = true"
            >
              ➕
            </button>
          </div>
          <div class="p-1 flex flex-row justify-between">
            <div class="flex flex-row flex-wrap">
              <div
                v-for="(e, i) in s.enrichments"
                :key="e.dimension + '|' + e.table + '|' + e.foreignKey"
                class="py-0.5 px-1 bg-gray-300 m-0.5 rounded text-xs break-words select-none inline-block flex flex-row justify-between"
              >
                <span>{{ `${e.foreignDimensions.map(fd => fd.alias).join(', ')} on ${e.dimension} = ${e.table}.${e.foreignKey}` }}</span>
                <button
                  type="button"
                  :title="t('actions.remove', [t('app.enrichment')])"
                  class="ml-1"
                  @click="deleteEnrichment(s, i)"
                >
                  ❌
                </button>
              </div>
            </div>
            <button
              type="button"
              class="cursor-pointer outline-none hidden lg:inline-block self-start"
              :title="t('actions.add', [t('app.enrichment')])"
              @click.stop="selected = s; addEnrichmentOpen = true"
            >
              ➕
            </button>
          </div>
        </div>
      </div>
    </div>
    <Modal v-model:open="deleteOpen">
      <template #title>
        <ExclamationTriangleIcon class="h-8 w-8 mr-1 inline-block text-amber-500 self-center" />
        {{ t('dashboards.deleteSource') }}
      </template>

      <div class=" px-6 py-3">
        <div class="flex-grow">
          <p>
            <span class="font-medium">{{ t('labels.uid') }}:</span>&nbsp;<span class="font-mono">{{ selected?.uid }}</span>
          </p>
          <p>
            <span class="font-medium">{{ t('labels.name') }}:</span>&nbsp;<span class="font-mono">{{ selected?.name }}</span>
          </p>
          <p>
            {{ t('messages.deleteConfirm', [t('app.source')]) }}
          </p>
          <p>
            {{ t('dashboards.deleteSourceNotice') }}
          </p>
        </div>
      </div>
      <div class="flex flex-row justify-end p-3 gap-1">
        <button
          type="button"
          class="bg-indigo-400 rounded-md py-1 px-3 text-white"
          @click="deleteOpen = false"
        >
          {{ t('actions.cancel') }}
        </button>
        <button
          type="button"
          class="bg-red-700 rounded-md py-1 px-3 text-white flex items-center"
          @click="$emit('delete', selected); deleteOpen = false"
        >
          <TrashIcon class="w-4 h-4 inline-block align-center" />&nbsp;{{ t('actions.delete') }}
        </button>
      </div>
    </Modal>
    <Modal
      v-model:open="addEnrichmentOpen"
      css="!overflow-visible"
      container-css="!overflow-visible"
    >
      <template #title>
        {{ t('dashboards.addEnrichment') }}
      </template>

      <EnrichmentForm
        v-if="selected && tableDefinition"
        :definition="tableDefinition"
        class="p-2"
        @submit="selected?.enrichments.push($event); addEnrichmentOpen = false"
      />
    </Modal>
  </Collapse>
</template>

<script lang="ts">
import { ExclamationTriangleIcon, TrashIcon } from '@heroicons/vue/24/solid'
import { defineComponent, PropType, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { Store } from '@/plugins/dashboard'
import { DataDefinition } from '@/plugins/dashboard/definition'
import { Source } from '@/plugins/dashboard/source'

import Collapse from '@/components/Collapse/Collapse.vue'
import Modal from '@/components/Modal/Modal.vue'

import EnrichmentForm from './EnrichmentForm.vue'
import SourceForm from './SourceForm.vue'

export default defineComponent({
  components: {
    SourceForm,
    Collapse,
    Modal,
    TrashIcon,
    ExclamationTriangleIcon,
    EnrichmentForm
  },
  props: {
    sources: {
      type: Array as PropType<Source[]>,
      required: true
    },
    stores: {
      type: Array as PropType<Store[]>,
      required: true
    }
  },
  emits: ['submit', 'delete'],
  setup (props) {
    const { t } = useI18n()
    const deleteOpen = ref(false)
    const addEnrichmentOpen = ref(false)
    const selected = ref(undefined as Source | undefined)
    const tableDefinition = ref(undefined as DataDefinition | undefined)

    const unselect = (b : boolean) => {
      if (b === false) {
        selected.value = undefined
      }
    }

    const deleteEnrichment = (source: Source, i: number) => {
      const enrichment = source.enrichments[i]
      source.enrichments.splice(i, 1)
      // Remove the data and refresh dimensions using this enrichment in existing stores
      props.stores
        .filter(s => s.source.uid === source.uid)
        .forEach(s => {
          const removedForeignDimensions = enrichment.foreignDimensions.map(fd => fd.alias)
          s.definition.dimensions = s.definition.dimensions.filter(d => !removedForeignDimensions.includes(d.name))
          s.refreshDimensions = s.refreshDimensions.filter(d => !removedForeignDimensions.includes(d))
        })
    }

    const onSourceNameChange = (source: Source) => {
      if (source.name === '') {
        source.name = source.uid
      }
    }

    watch(
      () => deleteOpen.value,
      unselect
    )
    watch(
      () => addEnrichmentOpen.value,
      unselect
    )
    watch(
      () => selected.value,
      async () => {
        if (selected.value !== undefined) {
          tableDefinition.value = await selected.value.getTableDefinition()
        } else {
          tableDefinition.value = undefined
        }
      }
    )

    return {
      // Data
      deleteOpen,
      addEnrichmentOpen,
      selected,
      tableDefinition,

      // Methods
      deleteEnrichment,
      onSourceNameChange,

      // Misc
      t
    }
  }
})
</script>
