import { Source, SerializedSource, CubeSource } from '.'

export const SOURCE_CONSTRUCTORS: Record<string, any> = {
  CubeSource
}

export function deserializeSource (def: SerializedSource): Source {
  const transformer = SOURCE_CONSTRUCTORS[def.constructor]
  if (transformer) {
    return transformer.deserialize(def)
  } else {
    throw new Error(`Invalid SerializedSource: constructor "${def.constructor}" doesn't exist`)
  }
}

export function deserializeSources (def: SerializedSource[]): Source[] {
  return def.map(d => deserializeSource(d))
}
