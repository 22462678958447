<template>
  <WidgetShell
    :loading="isLoading"
    :title="title"
    :definition="definition"
    :edit-mode="editMode"
    :compact="compact"
    :show-expand="!compact"
    :enlarged="enlarged"
    :has-data="hasData"
    @json-editor-input="onJSONEditorInput"
    @remove-clicked="$emit('remove-clicked')"
    @export="onExport(title, $event)"
    @save-image="onSaveAsImageClicked(title)"
    @expand-clicked="onExpandClicked"
  >
    <template
      v-if="!compact"
      #header
    >
      <MultiSelect
        v-model="metric"
        name="metric"
        mode="single"
        :close-on-select="true"
        :options="definition.metrics.map(m => ({ id: m.name, name: translateDBName(m.name.toString()) }))"
        :classes="multiselectTailwindClasses"
        :can-clear="false"
        :can-deselect="false"
      />
    </template>

    <template #controls>
      <button
        v-if="title !== '' && hasComparisonData"
        type="button"
        class="inline-block mx-0.5 cursor-pointer align-middle invisible group-hover:visible"
        :title="hideComparison ? t('dashboards.showComparison') : t('dashboards.hideComparison') "
        @click="hideComparison = !hideComparison"
      >
        <EyeIcon
          v-if="hideComparison"
          class="w-4 h-4"
        />
        <EyeSlashIcon
          v-else
          class="w-4 h-4"
        />
      </button>
    </template>

    <template v-if="!isLoading">
      <v-chart
        v-if="!empty"
        ref="chart"
        class="flex-grow min-h-px"
        :init-options="initOptions"
        :option="chartOptions"
        :theme="ECHARTS_THEME_NAME"
        :update-options="updateOptions"
        autoresize
      />
      <template v-else>
        <div class="flex h-full">
          <p class="m-auto leading-relaxed">
            {{ t('dashboards.nothingToShow') }}
          </p>
        </div>
      </template>
    </template>
  </WidgetShell>
</template>

<script lang="ts">
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/outline'
import { Grouping } from 'crossfilter2'
import { EChartsOption, SeriesOption, XAXisComponentOption } from 'echarts'
import { BarSeriesOption, BarChart } from 'echarts/charts'
import { use } from 'echarts/core'
import { defineComponent, ref, watch, computed, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'

import { echartsTooltipFormatter, TemporalFormatter, TemporalFormatterOptions, translateDBName, WidgetDefinition } from '@/plugins/dashboard'
import { CompositeDimension, DateRollupDimension, TIME_GRANULARITIES } from '@/plugins/dashboard/dimensions'

import { localToUTC } from '@/utils/dates'

import MultiSelect from '@/components/Form/FormMultiselect.vue'

import { ECHARTS_BAR_THEME } from '../theme'

import ChartWidget from './ChartWidgetMixin'
import { setupWidget, WidgetSettings, multiselectTailwindClasses, selectMetric } from './Widget'
import WidgetShell from './WidgetShell.vue'

use(BarChart)

export default defineComponent({
  components: {
    WidgetShell,
    MultiSelect,
    EyeIcon,
    EyeSlashIcon
  },
  mixins: [ChartWidget],
  // Note: for multiline charts, need first dimension to be time dimension
  props: {
    compact: {
      type: Boolean,
      default: false
    }
  },
  emits: ['remove-clicked', 'definition-changed'],
  setup (props, { emit }) {
    const { t } = useI18n()
    let timeDimensionName: string
    let cachedGroup: ReadonlyArray<Grouping<any, Record<string, any>>> | undefined
    let cachedComparisonGroup: ReadonlyArray<Grouping<any, Record<string, any>>> | undefined

    // Data
    const dimension = ref(props.definition.dimensions[0])
    const metric = ref(selectMetric(props.queryBuilder.widgetMetrics[props.definition.uid], props.definition))
    const chartOptions = ref<EChartsOption>({})
    const hideComparison = ref(false)

    // Computed
    const updateOptions = computed(() => {
      // See: https://echarts.apache.org/en/api.html#echartsInstance.setOption
      // By default, options are merged, meaning that if a series is removed, it will
      // still show on the chart. Using replace solves this issue albeit being slightly more expensive.
      return { notMerge: true }
    })

    const createDimension = () => {
      timeDimensionName = props.definition.dimensions[0].name + '_by_' + props.definition.store.granularity
      const dateRollup = new DateRollupDimension(timeDimensionName, TIME_GRANULARITIES[props.definition.store.granularity], props.definition.dimensions[0].column)
      if (props.definition.dimensions.length > 1) {
        dimension.value = new CompositeDimension(dateRollup, ...props.definition.dimensions.slice(1))
      } else {
        dimension.value = dateRollup
      }
    }
    createDimension()

    const updateChart = () => {
      if (!cachedGroup || commonWidget.isWaitingForBothData.value) {
        return
      }

      const currentMetric = props.definition.metrics.find(m => m.name === metric.value)!
      const options: TemporalFormatterOptions = {
        from: localToUTC(props.definition.store.lastDateRange!.from!),
        to: localToUTC(props.definition.store.lastDateRange!.to!),
        granularity: TIME_GRANULARITIES[props.definition.store.granularity],
        metric: metric.value,
        timeDimension: timeDimensionName
      }
      const formatter = new TemporalFormatter()
      const data = formatter.format(cachedGroup, options)

      const newOptions: EChartsOption = {
        xAxis: {
          type: 'category',
          data: Object.keys(data),
          show: false
        },
        yAxis: {
          type: 'value',
          show: false
        },
        grid: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        },
        tooltip: {
          trigger: dimension.value instanceof CompositeDimension ? 'item' : 'axis',
          appendToBody: true,
          axisPointer: {
            type: 'shadow'
          },
          formatter: echartsTooltipFormatter(currentMetric.formatter)
        }
      }

      const series = [{
        data: [] as any[],
        type: 'bar',
        barCategoryGap: 2,
        name: props.definition.title,
        itemStyle: {
          color: ECHARTS_BAR_THEME.currentSerieColor
        },
        areaStyle: {}
      } as BarSeriesOption]

      let max = 0
      let comparisonData = null

      for (const date in data) {
        Object.values(data[date]).forEach((e) => {
          max = Math.max(max, e);
          (series[0] as BarSeriesOption).data!.push(e === Infinity ? '-' : e)
        })
      }

      if (cachedComparisonGroup && !hideComparison.value) {
        const comparisonOptions: TemporalFormatterOptions = {
          from: new Date(props.definition.store.comparisonStore!.lastDateRange!.from!),
          to: new Date(props.definition.store.comparisonStore!.lastDateRange!.to!),
          granularity: TIME_GRANULARITIES[props.definition.store.granularity],
          metric: metric.value,
          timeDimension: timeDimensionName
        }
        comparisonData = formatter.format(cachedComparisonGroup, comparisonOptions)

        newOptions.xAxis = [
          newOptions.xAxis as XAXisComponentOption,
          {
            type: 'category',
            data: Object.keys(comparisonData),
            show: false
          }
        ]

        series.push({
          data: [] as any[],
          type: 'bar',
          stack: 'x',
          barCategoryGap: 2,
          barGap: '-100%',
          name: 'Previous',
          xAxisIndex: 1,
          itemStyle: {
            color: 'transparent'
          }
        } as BarSeriesOption,
        {
          data: [] as any[],
          type: 'bar',
          stack: 'x',
          barCategoryGap: 2,
          name: 'NONE',
          xAxisIndex: 1,
          itemStyle: {
            color: ECHARTS_BAR_THEME.previousSerieColor
          }
        } as BarSeriesOption)
      }

      for (const date in comparisonData) {
        Object.values(comparisonData[date]).forEach((e) => {
          max = Math.max(max, e);
          (series[1] as BarSeriesOption).data!.push(e)
        })
        Object.values(comparisonData[date]).forEach(() => {
          (series[2] as BarSeriesOption).data!.push(max / 25)
        })
      }

      newOptions.series = series as SeriesOption[]
      chartOptions.value = newOptions
    }

    const empty = computed(() => !chartOptions.value)

    const updateFunc = (v: ReadonlyArray<Grouping<any, Record<string, any>>> | undefined) => {
      cachedGroup = v
      updateChart()
    }
    const comparisonUpdateFunc = (v: ReadonlyArray<Grouping<any, Record<string, any>>> | undefined) => {
      cachedComparisonGroup = v
      updateChart()
    }

    // Watch
    watch(
      () => props.definition,
      () => {
        cachedGroup = undefined
        cachedComparisonGroup = undefined
        createDimension()
      }
    )
    watch(
      () => [metric.value, hideComparison.value],
      () => updateChart()
    )

    watch(
      () => metric.value,
      () => props.queryBuilder.setMetric(props.definition.uid, metric.value !== props.definition.metrics[0].name ? metric.value : undefined),
      { immediate: true }
    )

    // Methods
    const setMetric = (m: string) => {
      metric.value = m
    }

    const onJSONEditorInput = (newDefinition: WidgetDefinition) => {
      emit('definition-changed', newDefinition)
    }

    const settings: WidgetSettings = {
      props,
      dimension,
      updateFunc,
      comparisonUpdateFunc
    }
    const commonWidget = setupWidget(settings)
    watch(
      () => commonWidget.enlarged.value,
      () => {
        nextTick(() => setTimeout(() => {
          updateChart()
        }, 500))
      }
    )

    return {
      // Data
      chartOptions,
      metric,
      hideComparison,

      // Computed
      updateOptions,
      empty,

      // Methods
      setMetric,
      onJSONEditorInput,

      // Misc
      TIME_GRANULARITIES,
      multiselectTailwindClasses,
      translateDBName,
      t,

      ...commonWidget
    }
  }
})
</script>
