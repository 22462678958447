import { Reducer, ReducerConstructor, SerializedReducer } from '.'

/**
 * Reducer for dates. Converts the field to a Date instance. Initalizes to `new Date(0)`.
 */
export class DateReducer implements Reducer {
  metric?: string

  constructor (metric?: string) {
    this.metric = metric
  }

  key (key: string): string {
    return this.metric || key
  }

  toDate (value: string): Date {
    return new Date(value?.endsWith('Z') ? value : value + 'Z')
  }

  add (p: Record<string, any>, key: string, v: Record<string, any>): void { p[key] = this.toDate(v[this.key(key)]) }
  remove (p: Record<string, any>, key: string, v: Record<string, any>): void { p[key] = this.toDate(v[this.key(key)]) }
  init (p: Record<string, any>, key: string): void { p[key] = new Date(0) }

  static deserialize (m: SerializedReducer): ReducerConstructor {
    if (m.params === undefined) {
      throw new Error('Invalid SerializedReducer (DateReducer): missing parameters')
    }
    return DateReducerConstructor(m.params.metric)
  }
}

/**
 * @param metric the name of the metric used
 * @returns StringReducer constructor using this metric
 */
export function DateReducerConstructor (metric: string): ReducerConstructor {
  return class extends DateReducer {
    constructor () { super(metric) }

    static serialize (): SerializedReducer {
      return {
        constructor: 'DateReducer',
        params: {
          metric
        }
      }
    }
  }
}
