<template>
  <div
    class="flex justify-end flex-shrink-0 gap-1 p-5 border-t flex-4"
  >
    <slot name="beforeButtons" />
    <AppButton
      appearance="clear"
      :disabled="isLoading || isSubmitting"
      @click.prevent="onCancel()"
    >
      {{ (isViewOnly === true) ? t('actions.close') : t('actions.cancel') }}
    </AppButton>
    <slot name="betweenButtons" />
    <AppButton
      v-if="!isViewOnly"
      type="submit"
      :disabled="isLoading || isDisabled || isSubmitting"
      :is-loading="isSubmitting"
    >
      {{ (isFormTypeCreate === true) ? t('actions.save') : t('actions.update') }}
    </AppButton>
    <slot name="afterButtons" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAppStore } from '@/store/app.store'

import AppButton from '@/components/Buttons/AppButton.vue'

export default defineComponent({
  components: {
    AppButton
  },
  props: {
    isSubmitting: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false,
      required: false
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    isViewOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    isFormTypeCreate: {
      type: Boolean,
      required: false,
      default: false
    },
    onCancel: {
      type: Function as PropType<() => void>,
      default: () => {
        const appStore = useAppStore()
        appStore.closePanel()
      }
    }
  },
  setup () {
    const { t } = useI18n()

    return {
      t
    }
  }
})
</script>
