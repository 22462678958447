<template>
  <Popper
    v-if="availableSortableColumns.length"
    :transition-props="{
      'enterActiveClass': 'transition-opacity duration-150 ease-out',
      'enterFromClass': 'opacity-0',
      'enterToClass': 'opacity-100',
      'leaveActiveClass': 'transition-opacity duration-150 ease-in',
      'leaveFromClass': 'opacity-100',
      'leaveToClass': 'opacity-0'
    }"
    :reference-props="{
      'class': 'p-2 relative cursor-pointer select-none ' + (hasSorts ? 'text-primary-500 hover:text-primary-700' : 'text-text-primary hover:text-primary-500')
    }"
    :popper-props="{
      'class': 'absolute bg-white border border-gray-border rounded-md z-30 w-[400px] max-w-full max-h-96 overflow-hidden flex flex-col'
    }"
    trigger="click-to-toggle"
    placement="bottom-end"
    :teleport-props="{ to: 'body' }"
  >
    <template #reference>
      <BarsArrowUpIcon class="w-4 h-4" />
      <span
        v-if="hasSorts"
        class="absolute top-0 right-0 font-extrabold text-2xs text-text-primary"
      >
        {{ filters.sorts.value.length }}
      </span>
    </template>

    <template #default>
      <div class="flex items-center justify-between">
        <div class="flex items-center p-5 pb-2 space-x-2">
          <BarsArrowUpIcon class="w-4 h-4 text-text-primary" />
          <span class="text-sm font-semibold capitalize">{{ t('app.sort', 100) }}</span>
        </div>

        <div
          v-if="canAddNewSort && filtersController.sorts.value.length > 0"
          class="p-5 pb-2"
        >
          <AppButton
            type="button"
            appearance="clear"
            size="sm"
            @click.stop="addNewSort()"
          >
            {{ t('actions.add') }}
          </AppButton>
        </div>
      </div>

      <draggable
        ref="sortsList"
        v-model="filtersController.sorts.value"
        :class="[
          'flex-1 flex flex-col overflow-auto p-5 pt-1 border-t',
          scrolled ? 'border-gray-border' : 'border-transparent'
        ]"
        item-key="field"
        @scroll.passive="onScroll()"
      >
        <template #item="{element}">
          <div
            class="flex flex-row items-center justify-between w-full py-2 text-sm cursor-pointer"
          >
            <div class="flex flex-row items-center w-full space-x-1">
              <span class="flex items-center">
                <EllipsisVerticalIcon class="w-3.5 h-3.5 -ml-1 -mr-1 text-gray-600" />
                <EllipsisVerticalIcon class="w-3.5 h-3.5 -ml-1 text-gray-600" />
              </span>
              <div class="w-full">
                <select
                  v-model="element.field"
                  class="px-2 py-0.5 border-none bg-gray-200 text-sm rounded w-full focus:ring-gray-500"
                >
                  <option
                    v-for="column in availableSortableColumns"
                    :key="column.field"
                    :value="column.filterKey || column.field"
                    :disabled="!isFieldAvailable(column.field)"
                  >
                    {{ column.name }}
                  </option>
                </select>
              </div>
              <div class="w-full">
                <select
                  v-model="element.sortOrder"
                  class="px-2 py-0.5 border-none bg-gray-200 text-sm rounded w-full focus:ring-gray-500"
                >
                  <option :value="SortOrder.ASC">
                    {{ t('labels.asc') }}
                  </option>
                  <option :value="SortOrder.DESC">
                    {{ t('labels.desc') }}
                  </option>
                </select>
              </div>
              <div>
                <button
                  class="px-2 py-1 rounded hover:bg-gray-200"
                  @click.stop="onRemoveSort(element.field)"
                >
                  <TrashIcon class="w-4 h-5" />
                </button>
              </div>
            </div>
          </div>
        </template>
      </draggable>

      <div
        v-if="filtersController.sorts.value.length === 0"
        class="flex items-center justify-center w-full py-12"
      >
        <AppButton
          type="button"
          appearance="primary"
          size="sm"
          @click.stop="addNewSort()"
        >
          {{ t('actions.add') }}
        </AppButton>
      </div>
    </template>
  </Popper>
</template>

<script lang="ts">
import { BarsArrowUpIcon, EllipsisVerticalIcon, TrashIcon } from '@heroicons/vue/24/solid'
import throttle from 'lodash/throttle'
import { defineComponent, computed, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import draggable from 'vuedraggable'

import { Column } from '@/plugins/datatable/datatable.d'
import { SortOrder } from '@/plugins/filters'
import { BaseFilters } from '@/plugins/filters/filters'

import Popper from '@/components/Tooltip/Popper.vue'

import AppButton from '../Buttons/AppButton.vue'

export default defineComponent({
  components: {
    Popper,
    BarsArrowUpIcon,
    EllipsisVerticalIcon,
    TrashIcon,
    draggable,
    AppButton
  },
  props: {
    filters: {
      type: Object as PropType<BaseFilters>,
      required: true
    },
    columns: {
      type: Array as PropType<Column[]>,
      required: false,
      default: () => []
    }
  },
  emits: ['update:filters'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const filtersController = computed({
      get () {
        return props.filters
      },
      set (filters: BaseFilters) {
        emit('update:filters', filters)
      }
    })

    const sortsList = ref<any>()
    const scrolled = ref(false)

    const onScroll = throttle(() => {
      if (sortsList.value?.targetDomElement?.scrollTop == null) {
        return false
      }
      scrolled.value = sortsList.value?.targetDomElement?.scrollTop > 10
    }, 250)

    const columnNameByColumnField = (field: string) => {
      const column = props.columns.find((c) => c.field === field)

      if (column) {
        return column.name
      }

      return field
    }

    const availableSortableColumns = computed(() => props.columns.filter((c) => c.sortable))

    const isFieldAvailable = (field: string): boolean => {
      return !filtersController.value.sorts.value.find((s) => s.field === field)
    }

    const canAddNewSort = computed(() => filtersController.value.sorts.value.length < availableSortableColumns.value.length)

    const addNewSort = () => {
      const columns = availableSortableColumns.value.filter((c) => isFieldAvailable(c.field))

      if (columns.length) {
        filtersController.value.sorts.value.push({ field: columns[0].field, sortOrder: SortOrder.ASC })
        if (sortsList.value?.targetDomElement?.scrollTop != null) {
          sortsList.value.targetDomElement.scrollTop = sortsList.value?.targetDomElement.scrollHeight
        }
      }
    }

    const onRemoveSort = (field: string) => {
      const sortIdx = filtersController.value.sorts.value.findIndex((s) => s.field === field)

      if (sortIdx > -1) {
        filtersController.value.sorts.value.splice(sortIdx, 1)
      }
    }

    const hasSorts = computed(() => filtersController.value.sorts.value.length > 0)

    return {
      t,
      columnNameByColumnField,
      isFieldAvailable,
      addNewSort,
      onRemoveSort,
      canAddNewSort,
      availableSortableColumns,
      filtersController,
      SortOrder,
      hasSorts,
      onScroll,
      sortsList,
      scrolled
    }
  }
})
</script>
