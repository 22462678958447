<template>
  <div
    :class="[
      'relative group',
      { 'is-open': searchBoxOpen}
    ]"
  >
    <form
      class="flex flex-row w-full group-[&.is-open]:border-2 rounded"
      @submit.prevent="onSearch"
    >
      <button
        ref="toggleBoxButton"
        type="button"
        class="p-2 appearence-none"
        @click="toggleSearchBox()"
      >
        <MagnifyingGlassIcon
          :class="[
            'h-4 w-4',
            !searchBoxOpen ? 'text-text-primary hover:text-primary-500' : 'text-primary-500 hover:text-primary-700'
          ]"
        />
      </button>
      <div
        :class="[
          'relative overflow-hidden transition-all whitespace-nowrap flex w-full flex-1 justify-center',
          !searchBoxOpen ? 'max-w-0' : !fullWidth ? 'max-w-48' : ''
        ]"
      >
        <div
          v-if="searchBoxOpen"
          class="flex items-center justify-start flex-1 gap-1 px-1"
        >
          <input
            ref="searchInput"
            v-model="search"
            class="w-full p-0 text-sm bg-transparent border-none focus:ring-0 text-text-primary"
            type="text"
            :placeholder="t('filters.searchPanel.placeholder')"
            @keyup="onSearchChange($event)"
            @blur="onBlur"
          >

          <div class="flex flex-col items-center justify-center w-6 ml-auto aspect-1">
            <span
              v-if="isLoading"
              class="block p-1"
            >
              <Spinner
                class="w-4 h-4 animate-spin text-slate-500"
              />
            </span>
            <button
              v-if="hasSearchValue && !isLoading"
              type="button"
              :class="[
                'apperance-none p-1 relative transition-opacity opacity-100 text-slate-300 focus:text-slate-500',
              ]"
              @click="clear"
            >
              <XMarkIcon class="w-4 h-4 " />
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/vue/24/solid'
import debounce from 'lodash/debounce'
import { computed, defineComponent, nextTick, onBeforeMount, onMounted, onUnmounted, PropType, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { BaseFilters } from '@/plugins/filters/filters'

import Spinner from '../Spinner/Spinner.vue'

export default defineComponent({
  components: {
    MagnifyingGlassIcon,
    XMarkIcon,
    Spinner
  },
  props: {
    filters: {
      type: Object as PropType<BaseFilters>,
      required: true
    },
    alwaysOpen: {
      type: Boolean,
      required: false,
      default: false
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:filters'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const filtersController = computed({
      get () {
        return props.filters
      },
      set (filters) {
        emit('update:filters', filters)
      }
    })

    const search = ref('')
    const searchInput = ref<HTMLInputElement>()
    const toggleBoxButton = ref<HTMLButtonElement>()

    const hasSearchValue = computed(() => !!(search.value))
    const searchBoxOpen = ref(props.alwaysOpen)

    /**
     * Fake loader used temporary.
     * @todo Remove when Filters can handle their own loading state
     */
    const isLoading = ref(false)
    const lastSearchValue = ref('')

    const toggleSearchBox = (state?: boolean) => {
      // if we have a value in searchbox, we don't want to close the box
      if (!search.value) {
        searchBoxOpen.value = props.alwaysOpen || (typeof state === 'undefined') ? !searchBoxOpen.value : state
      }

      nextTick(() => {
        if (searchBoxOpen.value === true) {
          searchInput.value?.focus()
        }
      })
    }

    const onSearch = () => {
      filtersController.value.search.value = search.value
    }

    const keyboardKeysBlacklist = [
      'Escape', 'Enter', 'Meta', 'Alt', 'Tab', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'
    ]

    const onSearchChange = debounce((e?: KeyboardEvent) => {
      if (e && keyboardKeysBlacklist.includes(e.key)) {
        return true
      }

      if (search.value || (!search.value && lastSearchValue.value)) {
        isLoading.value = true
        setTimeout(() => {
          isLoading.value = false
        }, 250)
      }

      lastSearchValue.value = search.value

      filtersController.value.search.value = search.value
    }, 500)

    const onBlur = () => {
      if (!search.value) {
        searchBoxOpen.value = false || props.alwaysOpen
      }
    }

    // Sync SearchBox with FiltersController search value
    watch(
      () => filtersController.value.search.value,
      () => {
        if (!filtersController.value.search.value) {
          search.value = ''
        } else {
          search.value = filtersController.value.search.value
        }
      }
    )

    const clear = () => {
      search.value = ''
      onSearchChange()
      searchInput.value?.focus()
    }

    const onCtrlK = (e: KeyboardEvent) => {
      if (e.metaKey && e.key === 'k') {
        toggleSearchBox()
      }
    }

    const onSearchInputEscape = () => {
      toggleSearchBox()
      toggleBoxButton.value?.focus()
    }

    onBeforeMount(() => {
      search.value = filtersController.value.search.value

      if (search.value) {
        searchBoxOpen.value = true
      }
    })

    onMounted(() => {
      document.addEventListener('keydown', onCtrlK)
    })

    onUnmounted(() => {
      document.removeEventListener('keydown', onCtrlK)
    })

    return {
      filtersController,
      search,
      searchInput,
      hasSearchValue,
      toggleBoxButton,
      onSearch,
      onBlur,
      onSearchInputEscape,
      onSearchChange,
      isLoading,
      clear,
      toggleSearchBox,
      searchBoxOpen,
      t
    }
  }
})
</script>
