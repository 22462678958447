import { ValidationArgs } from '@vuelidate/core'
import { AxiosResponse } from 'axios'
import { computed, ComputedRef, ref, Ref } from 'vue'

import { FormType } from '@/types/form'

import { useAppStore } from '@/store/app.store'
import { useNotificationsStore } from '@/store/notifications.store'

import { BulkUpdateForm } from '@/models/bulk'

import { removeEmptyValues } from '@/utils/utils'

import { FieldTemplate, useForm } from '../form'
import { FormDate, FormInput, FormMultiselect, FormRenderer, FormRoles, FormSection } from '../form/renderer'
import { ServerErrors } from '../form/serverErrors'
import i18n from '../i18n'

import { Column, FieldType, Row } from './datatable.d'

const booleanOptions = [
  { id: 'true', name: i18n.global.t('labels.true') },
  { id: 'false', name: i18n.global.t('labels.false') }
]

export interface BulkOptions {
  selectedRows: Ref<Row[]>
  columns: Column[]
  prefix?: string
  onSuccess?: () => void
  onError?: (error: any, serverErrors?: ServerErrors) => void
  api?: (form: any) => Promise<AxiosResponse>
  submitHandler?: (form: Record<string, any>) => Promise<AxiosResponse<any> | Array<AxiosResponse<any>>>
}

function rendererFromColumn (c: Column): FormRenderer<unknown> {
  switch (c.editableType) {
    case FieldType.NUMBER:
      return new FormInput({ name: c.field, label: c.name, attr: { type: 'number', ...c.editableAttrs } })
    case FieldType.STRING:
      return new FormInput({ name: c.field, label: c.name, attr: { type: 'text', ...c.editableAttrs } })
    case FieldType.STRING_LIST:
      return new FormMultiselect({ name: c.field, label: c.name, attr: { mode: 'single', closeOnSelect: true, ...c.editableAttrs }, options: c.editableOptions ? c.editableOptions() : [] })
    case FieldType.BOOLEAN:
      return new FormMultiselect({ name: c.field, label: c.name, attr: { mode: 'single', closeOnSelect: true, canClear: true, ...c.editableAttrs }, options: booleanOptions })
    case FieldType.ROLES:
      return new FormRoles({})
    case FieldType.DATE:
      return new FormDate({ name: c.field, label: c.name, mode: 'materialize', time: false, checkIsBefore: false })
  }
  throw new Error('Bulk Inline Edit: unsupported editableType ' + c.editableType)
}

export function useBulkForm (opts: BulkOptions): any {
  const notificationsStore = useNotificationsStore()
  const appStore = useAppStore()

  const filteredColumns = opts.columns.filter(c => c.isBulkEditable)
  const rules = (): ComputedRef<ValidationArgs> => {
    const r: ValidationArgs<{ [k: string]: any }> = {}
    // Add a dumb validation rule to make vuelidate's externalErrors work
    // See: https://github.com/vuelidate/vuelidate/issues/1015
    filteredColumns.forEach(c => { r[c.field] = { true: () => true } })
    return ref(r) as ComputedRef<ValidationArgs>
  }

  const fields = computed<Array<FieldTemplate<unknown>>>(() => [
    {
      renderer: new FormSection({ title: i18n.global.t('labels.generalSettings'), mode: 'disclosure', isDefaultOpen: true, wrapperClass: 'p-5 rounded-md' }),
      wrapperClass: 'space-y-2.5',
      isSlotComponent: true,
      fields: filteredColumns.map(c => ({
        key: c.field,
        renderer: rendererFromColumn(c)
      }))
    }
  ])

  const defaultForm = computed(() => {
    const defaultForm: Record<string, any> = {}
    filteredColumns.forEach(c => {
      defaultForm[c.field] = c.editableType === FieldType.ROLES || c.editableType === FieldType.ARRAY ? [] : null
    })
    return defaultForm
  })

  const form = useForm({
    defaultForm,
    formType: FormType.EDIT,
    fieldPrefix: opts.prefix || 'fields',
    rules,
    submitHandler: opts.submitHandler !== undefined
      ? opts.submitHandler
      : (form) => {
          const f: BulkUpdateForm = {
            ids: opts.selectedRows.value.map(r => r.data.id),
            fields: removeEmptyValues(form)
          }
          return opts.api!(f)
        },
    onSuccess: () => {
      notificationsStore.add({
        message: i18n.global.t('messages.changesSaved'),
        type: 'success'
      })

      appStore.closePanel()
      form.reset()
      if (opts.onSuccess) {
        opts.onSuccess()
      }
    },
    onError: (err, serverErrors) => {
      if (err.response.status !== 422) {
        notificationsStore.add({
          title: i18n.global.t('labels.error'),
          message: serverErrors?.message || err,
          type: 'error'
        })
      }

      if (opts.onError) {
        opts.onError(err, serverErrors)
      }
    }
  })
  return {
    ...form,
    filteredColumns,
    fields
  }
}
