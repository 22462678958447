import { AxiosResponse } from 'axios'

import { Invite, InviteCreateAccountForm } from '@/models/invite'

import api from '@/api'

import { LoginResponse } from '../models/auth'

export async function fetchInviteByToken (token: string): Promise<AxiosResponse<Invite>> {
  return api.get<Invite>(`/invite/${token}`)
}

export async function createAccount (form: InviteCreateAccountForm, token: string): Promise<AxiosResponse<LoginResponse>> {
  return api.post<LoginResponse>(`/invite/create-account/${token}`, form, { withCredentials: true })
}

export async function acceptInvite (token: string): Promise<AxiosResponse<void>> {
  return api.get(`/invite/accept/${token}`)
}
