<template #warning="{ row }">
  <div>
    <span
      v-if="error === true"
      class="flex justify-center"
      :title="text || t('app.error')"
    >
      <ExclamationTriangleIcon
        class="w-5 h-5 handle text-amber-500"
        aria-hidden="true"
      />
      <span class="sr-only">{{ text || t('app.error') }}</span>
    </span>
  </div>
</template>

<!--
  MEDIATYPE
  <span class="bg-gray-100 px-1.5 py-0.5 rounded">{{ value }}</span>
-->

<!--
  SELLER TYPE
  <span class="inline-flex rounded px-2 py-1 text-xs font-semibold bg-primary-100 text-primary-500">{{ value.toUpperCase() }}</span>
-->

<script lang="ts">
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    ExclamationTriangleIcon
  },
  props: {
    error: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      required: false,
      default: undefined
    }
  },
  setup () {
    const { t } = useI18n()
    return {
      t
    }
  }
})
</script>
