<template>
  <div class="flex-1 flex items-center gap-2.5">
    <Badge
      is="div"
      v-if="status"
      :icon="getStatusIcon(status)"
      :theme="theme"
    >
      <span
        class="flex-1 truncate"
        :title="getStatusLabel(status)"
      >
        {{ getStatusLabel(status) }}
      </span>
    </Badge>
    <slot />
  </div>
</template>

<script lang="ts">

import { computed, defineComponent, PropType } from 'vue'

import { bidderLinesBadgeTheme } from '@/models/bidderLines'
import { getStatusLabel, getStatusIcon, BidderLinesStatus } from '@/models/enum/bidderLinesStatus'

import Badge from '@/components/Badge/Badge.vue'

export default defineComponent({
  components: { Badge },
  props: {
    status: {
      type: String as PropType<BidderLinesStatus | null>,
      default: null
    }
  },
  setup (props) {
    const theme = computed(() => bidderLinesBadgeTheme(props.status))

    return {
      theme,
      getStatusLabel,
      getStatusIcon
    }
  }
})
</script>
