<template>
  <teleport
    :to="target"
    :close-panel="closePanel"
  >
    <div
      class="panel-content"
    >
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__slideInRight"
        leave-active-class="animate__animated animate__slideOutRight"
        @after-leave="onAfterLeave"
        @after-enter="onAfterEnter"
      >
        <div
          v-if="isOpen"
          ref="panelContent"
          :class="panelClasses"
        >
          <slot
            v-if="header"
            name="panelHeader"
          >
            <div
              :class="[
                'relative z-10',
                isNewLayout ? 'px-10 py-9' : 'p-5 pt-7.5' ,
                hasHeaderBorder ? 'border-b border-gray-border' : ''
              ]"
            >
              <div class="flex flex-wrap items-center justify-between gap-3">
                <span class="max-w-full grow text-text-primary">
                  <slot
                    v-if="!isLoading"
                    name="panelTitle"
                  >
                    <slot name="header-action" />
                    <div class="flex items-center space-x-2.5 mt-[1px]">
                      <span
                        class="font-bold leading-relaxed truncate"
                      >{{ title }}</span>
                      <Badge
                        v-if="itemId"
                        :theme="BadgeTheme.BASE"
                      >
                        #&nbsp;{{ itemId }}
                      </Badge>

                      <Badge
                        v-if="status != null && typeof status.value === 'boolean'"
                        :theme="status.value ? BadgeTheme.SUCCESS : BadgeTheme.DANGER"
                      >
                        {{ status.value === true ? status.textThruly || statusDefault.textThruly : status.textFalsy || statusDefault.textFalsy }}
                      </Badge>
                      <slot name="header" />
                    </div>
                  </slot>
                  <span
                    v-else
                    class="block bg-gray-300 rounded-sm"
                  >
                    &nbsp;
                  </span>
                </span>
                <slot name="headerRight" />
              </div>
            </div>
          </slot>

          <!-- Callout -->
          <div
            v-if="slots.callout"
            class="px-5"
          >
            <slot name="callout" />
          </div>

          <!-- Content -->
          <div
            class="relative flex-col flex-1 overflow-y-auto divide-y divide-gray-200"
            @scroll.stop.prevent
          >
            <div
              v-if="isLoading"
              class="absolute w-full h-full"
            >
              <div class="flex flex-row items-center justify-center w-full h-full">
                <Spinner class="w-12 h-12 text-slate-400" />
              </div>
            </div>
            <slot
              v-if="!isLoading|| showContentOnLoading"
              :close-panel="closePanel"
            />
          </div>
        </div>
      </transition>
    </div>
  </teleport>
</template>

<script lang="ts">
import { PropType, computed, defineComponent, onMounted, onUnmounted, ref, useSlots } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouteLocationRaw, useRouter } from 'vue-router'

import { useAppStore } from '@/store/app.store'

import Badge from '@/components/Badge/Badge.vue'
import { BadgeTheme } from '@/components/Badge/theme'
import Spinner from '@/components/Spinner/Spinner.vue'

import { PANEL_CLOSE_EVENT, PanelCloseEvent, panelEmitter } from './panel'

function getPanelsRoot () {
  const existingRoot = document.getElementById('panels-root')
  if (existingRoot) return existingRoot

  const root = document.createElement('div')
  root.setAttribute('id', 'panels-root')

  // We append the panels-root container on #app as we defined "#app" as "important" scope in tailwind.config.js
  const appContainer = document.getElementById('app')

  if (!appContainer) {
    console.warn('Container for panels-root is missing')
    return
  }
  return appContainer.appendChild(root)
}

export default defineComponent({
  components: {
    Spinner,
    Badge
  },
  props: {
    openOnMount: {
      type: Boolean,
      required: false,
      default: true
    },
    header: {
      type: Boolean,
      required: false,
      default: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    itemId: {
      type: String,
      required: false,
      default: ''
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    showContentOnLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    redirectTo: {
      type: Object as PropType<RouteLocationRaw>,
      required: false,
      default: undefined
    },
    afterClose: {
      type: Function,
      required: false,
      default: () => {}
    },
    status: {
      type: Object as PropType<{value: boolean | undefined, textThruly?: string, textFalsy?: string} | undefined>,
      required: false,
      default: undefined
    },
    appearance: {
      type: String as PropType<'default' | 'full'>,
      required: false,
      default: 'default',
      validator: (val: string) => [
        'default',
        'full'
      ].includes(val)
    },
    hasHeaderBorder: {
      type: Boolean,
      default: false
    },
    isNewLayout: { // Temporary.
      type: Boolean,
      default: false
    }
  },
  emits: [
    'close:panel'
  ],
  setup (props, { emit }) {
    const appStore = useAppStore()

    const { t } = useI18n()

    const slots = useSlots()
    const router = useRouter()
    const target = ref(getPanelsRoot())

    const panelContent = ref<HTMLElement>()

    const isOpen = computed(() => appStore.panelOpen)

    const statusDefault = {
      textThruly: t('labels.isActive'),
      textFalsy: t('labels.isInactive')
    }

    const panelClasses = [
      'fixed top-0 right-0 bottom-0 z-30 bg-white shadow-xl flex flex-col h-full w-full focus:outline-none focus-visible:outline-none'
    ]

    if (props.appearance === 'full') {
      panelClasses.push('w-full md:max-w-[80%]')
    } else {
      panelClasses.push('md:w-2/3 lg:w-2/3 2xl:w-1/2')
    }

    const closePanel = () => appStore.closePanel()

    const onAfterLeave = () => {
      if (props.afterClose) {
        props.afterClose()
      }

      if (props.redirectTo) {
        router.push(props.redirectTo)
      }
    }

    const onAfterEnter = () => {
      panelContent.value?.focus()
    }

    const handleKeydown = (e: KeyboardEvent) => {
      setTimeout(() => {
        if (e.key === 'Escape' && !e.defaultPrevented) {
          const event: PanelCloseEvent = {
            keyEvent: e,
            cancelled: false
          }
          panelEmitter.emit(PANEL_CLOSE_EVENT, event)

          if (event.cancelled) {
            return
          }
          emit('close:panel')
          closePanel()
        }
      }, 0)
    }

    onMounted(() => {
      if (props.openOnMount === true) {
        appStore.openPanel()
      }
      window.document.addEventListener('keydown', handleKeydown, true)
    })

    onUnmounted(() => {
      if (isOpen.value) {
        closePanel()
      }
      window.document.removeEventListener('keydown', handleKeydown)
    })

    return {
      t,
      BadgeTheme,
      onAfterLeave,
      onAfterEnter,
      closePanel,
      panelContent,
      isOpen,
      target,
      statusDefault,
      panelClasses,
      slots
    }
  }
})
</script>
