import { AxiosResponse } from 'axios'

import { BidderNotification } from '@/models/bidderNotification'

import { fetchForSelectFilters, FilterResponse } from '@/services/datatableFilters'

import api from '../api'
import { Bidder, CreateBidderForm, UpdateBidderForm } from '../models/bidder'
import { Paginate } from '../types/paginate'

export async function fetchBidders (queryParams?: Record<string, any>): Promise<AxiosResponse<Paginate<Bidder[]>>> {
  return api.get<Paginate<Bidder[]>>('/bidders', { params: queryParams })
}

export async function fetchBidderById (id: number, requireContext?: boolean): Promise<AxiosResponse<Bidder>> {
  return api.get<Bidder>(`/bidders/${id}`, { requireContext })
}

export async function updateBidder (id: number, data: UpdateBidderForm): Promise<AxiosResponse<Bidder>> {
  return api.patch<Bidder>(`/bidders/${id}`, data)
}

export async function createBidder (data: CreateBidderForm): Promise<AxiosResponse<Bidder>> {
  return api.post<Bidder>('/bidders', data)
}

export async function fetchBidderForSelectFilters (field: string, query: object): Promise<AxiosResponse<FilterResponse[]>> {
  return fetchForSelectFilters('/bidders', field, query, false)
}

export async function fetchBidderNotifications (bidderId: number): Promise<AxiosResponse<BidderNotification>> {
  return api.get<BidderNotification>(`/bidders/${bidderId}/notifications`)
}
