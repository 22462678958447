import { SerializedMetric } from '@/plugins/dashboard'
import { DataDefinition } from '@/plugins/dashboard/definition'

export interface Preset {
  apply(definition: DataDefinition): SerializedMetric
  isAvailable(definitiion: DataDefinition): boolean
}

function sum (metric: string, formatter: string = 'INTEGER'): Preset {
  return {
    apply (): SerializedMetric {
      return {
        formatter,
        name: metric,
        reducer: {
          constructor: 'FormulaReducer',
          params: {
            formula: '$' + metric
          }
        }
      }
    },
    isAvailable (definition: DataDefinition): boolean {
      return definition.metrics[metric] !== undefined
    }
  }
}

export const METRIC_PRESETS: Preset[] = [
  sum('adcalls'),
  sum('adresponses'),
  sum('impressions'),
  sum('netRevenue', 'CURRENCY_DOLLAR'),
  sum('measured'),
  sum('viewed'),
  {
    apply (): SerializedMetric {
      return {
        formatter: 'FLOAT',
        name: 'timeInViewSum',
        reducer: {
          constructor: 'FormulaReducer',
          params: {
            formula: '$timeInViewSum * 1000'
          }
        }
      }
    },
    isAvailable (definition: DataDefinition): boolean {
      return definition.metrics.timeInViewSum !== undefined
    }
  },
  {
    apply (): SerializedMetric {
      return {
        formatter: 'PERCENT',
        name: 'adresponseRate',
        reducer: {
          constructor: 'FormulaReducer',
          params: {
            formula: '$adresponses / $adcalls'
          }
        }
      }
    },
    isAvailable (definition: DataDefinition): boolean {
      return definition.metrics.adresponses !== undefined && definition.metrics.adcalls !== undefined
    }
  },
  {
    apply (): SerializedMetric {
      return {
        formatter: 'FLOAT',
        name: 'timeInViewSum',
        reducer: {
          constructor: 'FormulaReducer',
          params: {
            formula: '$timeInViewSum * 1000'
          }
        }
      }
    },
    isAvailable (definition: DataDefinition): boolean {
      return definition.metrics.timeInViewSum !== undefined
    }
  },
  {
    apply (): SerializedMetric {
      return {
        formatter: 'INTEGER',
        name: 'normalized_not_viewed',
        reducer: {
          constructor: 'FormulaReducer',
          params: {
            formula: '($measured - $viewed) / ($measured / $adcalls)'
          }
        }
      }
    },
    isAvailable (definition: DataDefinition): boolean {
      return definition.metrics.measured !== undefined &&
        definition.metrics.viewed !== undefined &&
        definition.metrics.adcalls !== undefined
    }
  },
  sum('adrequests'),
  {
    apply (): SerializedMetric {
      return {
        formatter: 'INTEGER',
        name: 'totaladrequests',
        reducer: {
          constructor: 'FormulaReducer',
          params: {
            formula: '$totaladrequests'
          }
        }
      }
    },
    isAvailable (definition: DataDefinition): boolean {
      return definition.metrics.adrequests !== undefined
    }
  }
]
