import { AxiosResponse } from 'axios'

import { Paginate } from '@/types/paginate'

import api from '@/api'

import { AlgorithmTemplate } from '../models/algorithmTemplate.base'

export async function fetchAlgorithmTemplates (queryParams?: Object): Promise<AxiosResponse<Paginate<AlgorithmTemplate[]>>> {
  return api.get<Paginate<AlgorithmTemplate[]>>('/algorithm-templates', { requireContext: false, params: queryParams })
}
