<template>
  <WidgetShell
    :loading="isLoading"
    :title="title"
    :definition="definition"
    :edit-mode="editMode"
    :enlarged="enlarged"
    :has-data="hasData"
    :show-only-selected-rows-export-input="!isComposite"
    controls-position="horizontal"
    @json-editor-input="$emit('definition-changed', $event)"
    @remove-clicked="$emit('remove-clicked')"
    @export="onExport(title, $event)"
    @expand-clicked="onExpandClicked"
    @scroll="onScroll"
  >
    <template #controls>
      <button
        v-if="title !== '' && hasData"
        type="button"
        class="focus:outline-none inline-block mx-0.5 cursor-pointer align-middle invisible group-hover:visible"
        :title="showOnlySelectedLines ? t('dashboards.showAllLines') : t('dashboards.showOnlySelectedLines')"
        @click="showOnlySelectedLines = !showOnlySelectedLines"
      >
        <EyeIcon
          v-if="showOnlySelectedLines"
          class="w-4 h-4"
        />
        <EyeSlashIcon
          v-else
          class="w-4 h-4"
        />
      </button>
    </template>

    <div
      ref="container"
      :style="{'height': height + 'px'}"
      class="relative flex flex-row items-start min-h-full min-w-min"
    >
      <template v-if="cluster && cluster.length > 0">
        <div
          ref="dimensionGridRef"
          class="z-10 grid text-sm text-left"
          :class="{'sticky left-0': clientWidth >= 2*(dimensionGridRef?.clientWidth || 0) }"
          :style="{ 'grid-template-columns': `repeat(${dimensionColumnCount}, min-content)`, 'transform': `translateY(${translation}px)` }"
        >
          <div
            v-if="filterOnClick"
            class="z-10 px-1 py-2 text-center bg-white shadow-sm"
            :style="{ 'transform': `translateY(${headerTranslation}px)` }"
          >
            <input
              type="checkbox"
              :indeterminate="selectedCount !== 0 && selectedCount !== (sortedSegments?.length || 0) ? true : undefined"
              :checked="selectedCount === (sortedSegments?.length || Infinity) ? true : undefined"
              class="w-4 h-4 border-gray-500 rounded cursor-pointer text-primary-600 focus:ring-transparent"
              @click="onGlobalSelectCheckboxInput"
            >
          </div>
          <!-- Dimension header -->
          <template
            v-for="(key, cellIndex) in dimensionKeys"
            :key="key.name"
          >
            <div
              class="z-10 font-medium bg-white shadow-sm max-w-36 h-9 whitespace-nowrap"
              :class="{ 'border-r-2 border-black border-opacity-5': cellIndex === dimensionKeys.length - 1 }"
              :title="key.label"
              :style="{ 'transform': `translateY(${headerTranslation}px)` }"
            >
              <div
                class="flex flex-row items-center justify-between p-2 cursor-pointer select-none hover:bg-gray-200"
                @click="sort(key.name)"
              >
                <span class="flex-grow overflow-hidden overflow-ellipsis">{{ key.label }}</span>
                <template v-if="sortMetric === key.name">
                  <BarsArrowUpIcon
                    v-if="sortOrder"
                    class="inline-block w-4 h-4 ml-1 min-w-4"
                  />
                  <BarsArrowDownIcon
                    v-else
                    class="inline-block w-4 h-4 ml-1 min-w-4"
                  />
                </template>
              </div>
            </div>
          </template>
          <!-- Dimension content -->
          <template
            v-for="segment in cluster"
            :key="segment.iterationKey"
          >
            <div
              v-if="filterOnClick"
              class="bg-white"
              :class="{'!bg-primary-100': segment.selected}"
            />
            <template
              v-for="(key, cellIndex) in dimensionKeys"
              :key="key.name"
            >
              <div
                v-for="(formattedValue, i) in [format(key.name, segment.value[key.name])]"
                :key="i"
                class="p-2 pl-3 overflow-hidden text-gray-800 bg-white h-9 w-36 overflow-ellipsis lining-nums whitespace-nowrap"
                :class="{'text-right font-mono': typeof segment.value[key.name] === 'number', '!bg-primary-100': segment.selected, 'cursor-pointer': filterOnClick, 'border-r-2 border-black border-opacity-5': cellIndex === dimensionKeys.length - 1 }"
                :title="formattedValue"
                @click.stop="onSegmentClicked($event, segment)"
              >
                <span>{{ formattedValue }}</span>
              </div>
            </template>
          </template>
          <!-- Dimension footer -->
          <div
            v-if="filterOnClick"
            class="bg-white shadow-top"
            :style="{ 'transform': `translateY(${footerTranslation}px)` }"
          />
          <template
            v-for="(key, cellIndex) in dimensionKeys"
            :key="key"
          >
            <div
              v-for="(formattedValue, i) in [INTEGER(singleMetrics[key.name])]"
              :key="i"
              class="p-2 pl-3 overflow-hidden text-right bg-white h-9 max-w-36 overflow-ellipsis shadow-top whitespace-nowrap"
              :class="{'border-r-2 border-black border-opacity-5': cellIndex === dimensionKeys.length - 1}"
              :title="formattedValue"
              :style="{ 'transform': `translateY(${footerTranslation}px)` }"
            >
              <template v-if="singleMetrics[key.name] !== undefined">
                <span>{{ formattedValue }}</span>
              </template>
            </div>
          </template>
        </div>
        <div
          ref="metricsGridRef"
          class="grid flex-grow text-sm text-left"
          :style="{ 'grid-template-columns': `repeat(${columnCount}, minmax(min-content, 1fr))`, 'transform': `translateY(${translation}px)` }"
        >
          <!-- Header -->
          <template
            v-for="name in metricKeys"
            :key="name"
          >
            <div
              v-for="(translatedName, i) in [translateDBName(name)]"
              :key="i"
              class="z-10 w-full font-medium bg-white shadow-sm whitespace-nowrap"
              :class="{'text-right': typeof cluster[0].value[name] === 'number' }"
              :style="{ 'transform': `translateY(${headerTranslation}px)` }"
              :title="translatedName"
            >
              <div
                class="flex flex-row items-center justify-between p-2 pl-3 cursor-pointer select-none h-9 hover:bg-gray-200"
                :class="{'pr-0': hasComparison, '!justify-end': typeof cluster[0].value[name] === 'number'}"
                @click="sort(name)"
              >
                <span class="flex-grow overflow-hidden max-w-36 overflow-ellipsis">{{ translatedName }}</span>
                <template v-if="sortMetric === name">
                  <BarsArrowUpIcon
                    v-if="sortOrder"
                    class="inline-block w-4 h-4 ml-1 min-w-4"
                  />
                  <BarsArrowDownIcon
                    v-else
                    class="inline-block w-4 h-4 ml-1 min-w-4"
                  />
                </template>
              </div>
            </div>
            <div
              v-if="hasComparison"
              class="z-10 font-normal bg-white shadow-sm whitespace-nowrap min-w-17"
              :style="{ 'transform': `translateY(${headerTranslation}px)` }"
            >
              <div
                class="flex flex-row justify-end cursor-pointer select-none h-9 hover:bg-gray-200"
                :class="{'p-2': typeof cluster[0].value[name] === 'number'}"
                @click="sort(name+'_comparison')"
              >
                <template v-if="sortMetric === name + '_comparison'">
                  <BarsArrowUpIcon
                    v-if="sortOrder"
                    class="self-center inline-block w-4 h-4 ml-1"
                  />
                  <BarsArrowDownIcon
                    v-else
                    class="self-center inline-block w-4 h-4 ml-1"
                  />
                </template>
              </div>
            </div>
          </template>

          <!-- Content -->
          <template
            v-for="segment in cluster"
            :key="segment.iterationKey"
          >
            <template
              v-for="key in metricKeys"
              :key="key"
            >
              <div
                v-for="(formattedValue, i) in [format(key, segment.value[key])]"
                :key="i"
                class="w-full p-2 pl-3 text-gray-800 h-9 lining-nums whitespace-nowrap"
                :class="{'text-right font-mono': typeof segment.value[key] === 'number', 'pr-0': hasComparison, '!bg-primary-100': segment.selected, 'cursor-pointer': filterOnClick}"
                :title="formattedValue"
                @click.stop="onSegmentClicked($event, segment)"
              >
                <div
                  class="overflow-hidden max-w-36 overflow-ellipsis"
                  :class="{'ml-auto': typeof segment.value[key] === 'number'}"
                >
                  <span>{{ formattedValue }}</span>
                </div>
              </div>
              <div
                v-if="hasComparison"
                class="py-2 pl-4 pr-2 font-mono text-xs leading-5 text-left h-9"
                :class="{'!bg-primary-100': segment.selected, 'cursor-pointer': filterOnClick }"
                @click.stop="onSegmentClicked($event, segment)"
              >
                <template
                  v-for="(comparisonSegment, i) in [segment.value[key+'_comparison']]"
                  :key="i"
                >
                  <span
                    v-if="comparisonSegment !== undefined"
                    class="px-2 py-1 font-semibold rounded"
                    :class="comparisonSegment >= 0 ? 'bg-green-100 text-green-600': 'bg-red-100 text-red-600'"
                  >
                    {{ SIGNED_PERCENT(comparisonSegment) }}
                  </span>
                </template>
              </div>
            </template>
          </template>

          <!-- Footer -->
          <template
            v-for="key in metricKeys"
            :key="key"
          >
            <div
              v-for="(formattedValue, i) in [format(key, singleMetrics[key])]"
              :key="i"
              class="p-2 pl-3 bg-white h-9 shadow-top whitespace-nowrap"
              :class="{'text-right font-mono': typeof singleMetrics[key] === 'number', 'pr-0': hasComparison}"
              :title="formattedValue"
              :style="{ 'transform': `translateY(${footerTranslation}px)` }"
            >
              <template v-if="singleMetrics[key] !== undefined">
                <div
                  class="overflow-hidden max-w-36 overflow-ellipsis"
                  :class="{'ml-auto': typeof singleMetrics[key] === 'number'}"
                >
                  <span>{{ formattedValue }}</span>
                </div>
              </template>
            </div>
            <div
              v-if="hasComparison"
              class="py-2 pl-1 pr-2 font-mono text-xs leading-5 text-left bg-white shadow-top whitespace-nowrap"
              :style="{ 'transform': `translateY(${footerTranslation}px)` }"
            >
              <span
                v-if="singleMetrics[key] !== undefined && singleMetricsComparison[key] !== undefined"
                :class="(relativeComparison(singleMetrics[key], singleMetricsComparison[key]) || 0) >= 0 ? 'text-green-700': 'text-red-800'"
              >
                {{ SIGNED_PERCENT(relativeComparison(singleMetrics[key], singleMetricsComparison[key])) }}
              </span>
            </div>
          </template>
        </div>
      </template>
      <template v-else-if="showOnlySelectedLines">
        <div class="flex flex-col gap-2 m-auto">
          <p class="leading-relaxed">
            {{ t('dashboards.nothingToShow') }}
          </p>
          <button
            class="text-sm text-blue-500 underline cursor-pointer"
            @click.stop="showOnlySelectedLines = false"
          >
            {{ t('dashboards.showAllLines') }}
          </button>
        </div>
      </template>
    </div>
  </WidgetShell>
</template>

<script lang="ts">
import { BarsArrowDownIcon, BarsArrowUpIcon, EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/outline'
import { Grouping, NaturallyOrderedValue } from 'crossfilter2'
import { format as dateformat } from 'date-fns'
import { cloneDeep } from 'lodash'
import { PropType, computed, defineComponent, nextTick, onUnmounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { ExportForm, METRIC_FORMATTERS, MetricFormatter, SegmentClickedEvent, WidgetFilter, download, relativeComparison, toCSVDataURL, translateDBName } from '@/plugins/dashboard'
import { CompositeDimension, CompositeDimensionKey, DateDimension, DateRollupDimension, Dimension } from '@/plugins/dashboard/dimensions'
import { FilterDimension } from '@/plugins/dashboard/source'

import { useNotificationsStore } from '@/store/notifications.store'

import { WIDGET_PROPS, WidgetSettings, canSortOn, setupWidget } from './Widget'
import WidgetShell from './WidgetShell.vue'

type ProcessedSegment = {
  iterationKey: string,
  selected: boolean
} & Grouping<NaturallyOrderedValue, any>

// FIXME bad performance somewhere (see https://app.adagio.io/groups/1/dashboards/32)
// According to profiler, it's because of a watch on the store cache (after a onWorkerMessage)

export default defineComponent({
  components: {
    WidgetShell,
    BarsArrowUpIcon,
    BarsArrowDownIcon,
    EyeIcon,
    EyeSlashIcon
  },
  props: {
    dimensionValues: {
      type: Object as PropType<FilterDimension[]>,
      default: () => {}
    },
    ...WIDGET_PROPS
  },
  emits: ['segment-clicked', 'remove-clicked', 'definition-changed'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const segments = ref(undefined as ReadonlyArray<Grouping<any, Record<string, any>>> | undefined)
    const computedSegments = ref(undefined as ProcessedSegment[] | undefined)

    const notificationsStore = useNotificationsStore()

    const sortOrder = ref(false) // false = DESC, true = ASC
    const sortMetric = ref(null as string | null)
    const showOnlySelectedLines = ref(false)
    const formatters: Record<string, MetricFormatter> = {}

    const dimensionGridRef = ref(null as Element | null) // Used to calculate row height
    const metricsGridRef = ref(null as Element | null) // Used to calculate row height
    const container = ref(null as Element | null)
    let scrollContainer: HTMLElement | null = null

    const scrollTop = ref(0)
    const clientHeight = ref(0)
    const clientWidth = ref(0)

    const singleMetrics = reactive({} as Record<string, any>)
    const singleMetricsComparison = reactive({} as Record<string, any>)

    let lastClick = 0
    let enrichmentDimensions: Record<string, FilterDimension> = {}
    let resizeObserver: ResizeObserver

    const updateFormatters = () => {
      props.definition.metrics.forEach(m => {
        formatters[m.name] = m.formatter
      })
      const dimension = props.definition.dimensions[0]
      if (dimension instanceof CompositeDimension) {
        dimension.dimensions
          .filter(d => d instanceof DateDimension || d instanceof DateRollupDimension)
          .forEach(d => { formatters[d.name] = METRIC_FORMATTERS.DATE })
      } else if (dimension instanceof DateDimension || dimension instanceof DateRollupDimension) {
        formatters[dimension.name] = METRIC_FORMATTERS.DATE
      }
    }

    const onResize = () => {
      const element = container.value?.parentElement
      clientHeight.value = element?.clientHeight || 0
      clientWidth.value = element?.clientWidth || 0
      const maxScroll = height.value - clientHeight.value
      if (maxScroll > 0 && scrollTop.value > maxScroll) {
        scrollTop.value = maxScroll
        if (element) {
          element.scrollTo(element.scrollLeft, maxScroll)
        }
      }
    }

    onUnmounted(() => {
      if (resizeObserver !== undefined) {
        resizeObserver.disconnect()
      }
    })

    watch(
      () => container.value,
      () => {
        if (container.value?.parentElement) {
          clientHeight.value = container.value.parentElement.clientHeight || 0
          clientWidth.value = container.value.parentElement.clientWidth || 0
          if (container.value !== null) {
            if (resizeObserver !== undefined && scrollContainer !== null) {
              resizeObserver.unobserve(scrollContainer)
            }
            resizeObserver = new ResizeObserver(onResize)
            resizeObserver.observe(container.value.parentElement)
          }
          scrollContainer = container.value.parentElement
        }
      }
    )

    watch(
      () => props.definition,
      () => {
        updateFormatters()
        const querySort = props.queryBuilder.widgetSorts[props.definition.uid]
        if (querySort && canSortOn(querySort[0], props.definition)) {
          sortMetric.value = querySort[0]
          sortOrder.value = querySort[1] === true
          return
        }
        sortMetric.value = props.definition.defaultSort !== undefined ? props.definition.defaultSort.column : null
        sortOrder.value = props.definition.defaultSort !== undefined ? props.definition.defaultSort.order === 'ASC' : false
      },
      { immediate: true }
    )

    const isComposite = computed(() => props.definition.dimensions[0] instanceof CompositeDimension)
    const filterOnClick = computed(() => !(props.definition.dimensions[0] instanceof DateDimension) && !(props.definition.dimensions[0] instanceof DateRollupDimension))

    const updateEnrichmentDimension = () => {
      enrichmentDimensions = {}
      const dimension = props.definition.dimensions[0]
      const dimensions: Dimension[] = []
      if (dimension instanceof CompositeDimension) {
        dimension.dimensions.forEach(d => dimensions.push(d))
      } else {
        dimensions.push(dimension)
      }
      dimensions.forEach(d => {
        if (d.enrichment) {
          const enrichmentDimension = props.dimensionValues.find(dv => dv.name === d.name)
          if (enrichmentDimension) {
            enrichmentDimensions[enrichmentDimension.name] = enrichmentDimension
          }
        }
      })
    }

    const recomputeSegments = () => {
      if (segments.value) {
        const arr = segments.value.filter(s => (s.key.comparisonGroup ? s.key.comparisonGroup : s.key).includes('Reference'))

        // Create a Map containing entries of the reference group
        const references = new Map(
          arr.map(obj => {
            let key = cloneDeep(obj.key)

            if (key.comparisonGroup) {
              delete key.comparisonGroup
            } else {
              key = ''
            }

            return [JSON.stringify(key), obj.value]
          })
        )

        // Iterate over the segments starting after the reference group lines
        // to add the comparison values
        for (let j = arr.length; j < segments.value.length; j++) {
          const value = segments.value[j].value
          let key = cloneDeep(segments.value[j].key)

          if (key.comparisonGroup) {
            delete key.comparisonGroup
          } else {
            key = ''
          }

          const refValue = references.get(JSON.stringify(key))

          // No entry in the reference group, no possible comparison
          if (!refValue) {
            continue
          }

          for (const c in value) {
            if (typeof value[c] === 'number' && refValue[c] !== undefined) {
              value[c + '_comparison'] = relativeComparison(value[c], refValue[c])
            }
          }
        }
      }

      updateEnrichmentDimension()

      computedSegments.value = segments.value?.slice().map(s => {
        return {
          key: s.key,
          value: s.value,
          iterationKey: isComposite.value ? new CompositeDimensionKey(s.key).valueOf() : s.key,
          selected: isSelected(s)
        }
      })
    }

    const update = (v: ReadonlyArray<Grouping<any, Record<string, any>>> | undefined) => {
      segments.value = v
      if (segments.value === undefined) {
        lastClick = 0
      }
      recomputeSegments()
    }

    const updateMetrics = (container: Record<string, any>, metric: string, value: any) => {
      if (value === undefined) {
        delete container[metric]
        return
      }
      container[metric] = value
    }

    const updateSingleMetrics = (metric: string, value: any) => {
      updateMetrics(singleMetrics, metric, value)
    }

    const updateSingleMetricsComparison = (metric: string, value: any) => {
      updateMetrics(singleMetricsComparison, metric, value)
    }

    const sortBy = (metric: string, asc: boolean = true) => { // TODO replace with lodash?
      return (e1: Grouping<any, Record<string, any>>, e2: Grouping<any, Record<string, any>>) => {
        const val1 = typeof e1.value[metric] === 'string' ? e1.value[metric].toLowerCase() : e1.value[metric]
        const val2 = typeof e2.value[metric] === 'string' ? e2.value[metric].toLowerCase() : e2.value[metric]
        if (val1 === undefined || Number.isNaN(val1) || val1 < val2) {
          return asc ? -1 : 1
        }
        if (val2 === undefined || Number.isNaN(val2) || val1 > val2) {
          return asc ? 1 : -1
        }
        return 0
      }
    }

    // Computed
    const columnCount = computed(() => {
      let count = props.definition.metrics.length
      if (hasComparison.value) {
        count *= 2
      }
      return count
    })

    const dimensionColumnCount = computed(() => filterOnClick.value ? dimensionKeys.value.length + 1 : dimensionKeys.value.length)

    const selectedCount = computed((): number => {
      return computedSegments.value?.filter(s => s.selected).length || 0
    })

    watch(
      () => showOnlySelectedLines.value,
      () => {
        if (showOnlySelectedLines.value) {
          scrollTop.value = 0
          container.value?.parentElement?.scrollTo(0, 0)
        }
      }
    )

    const sortedSegments = computed(() => {
      const segments = showOnlySelectedLines.value
        ? computedSegments.value?.filter(s => s.selected)
        : computedSegments.value
      return sortMetric.value !== null ? segments?.slice().sort(sortBy(sortMetric.value, sortOrder.value)) : segments
    })

    const headerHeight = computed(() => {
      if (dimensionGridRef.value === null || dimensionGridRef.value.childElementCount === 0) {
        return 1
      }
      return dimensionGridRef.value.children[0].clientHeight
    })

    const rowHeight = computed(() => {
      if (dimensionGridRef.value === null || dimensionGridRef.value.childElementCount < dimensionColumnCount.value) {
        return Infinity
      }
      return dimensionGridRef.value.children[dimensionColumnCount.value].clientHeight
    })

    const height = computed(() => {
      return rowHeight.value * ((sortedSegments.value?.length || 0) + 1) + headerHeight.value
    })

    const start = computed(() => {
      return 2 * Math.floor((scrollTop.value / rowHeight.value) / 2)
    })

    const end = computed(() => {
      return start.value + Math.round(clientHeight.value / rowHeight.value) + 1
    })

    const translation = computed(() => {
      return start.value * rowHeight.value
    })

    const headerTranslation = computed(() => {
      return scrollTop.value - translation.value
    })

    const footerTranslation = computed(() => { // FIXME on mac the bottom line is wrongly placed (opaque scrollbar background)
      if (cluster.value === undefined) {
        return 0
      }

      const offsetTop = cluster.value.length * rowHeight.value + headerHeight.value
      return -offsetTop + clientHeight.value - rowHeight.value + scrollTop.value - translation.value
    })

    const cluster = ref(undefined as ProcessedSegment[] | undefined)
    const updateCluster = () => {
      // This avoids the cluster value to be replaced every time a scroll event is received
      cluster.value = sortedSegments.value?.slice(start.value, end.value)
    }
    watch(() => height.value, () => {
      if (clientHeight.value + scrollTop.value >= height.value) {
        let newScroll = height.value - clientHeight.value
        if (newScroll < 0) {
          newScroll = 0
        }
        scrollTop.value = newScroll
        container.value?.parentElement?.scrollTo(0, newScroll)
      }
    })
    watch(() => sortedSegments.value, updateCluster)
    watch(() => start.value, updateCluster)
    watch(() => end.value, updateCluster)

    watch(
      () => cluster.value,
      () => {
        setTimeout(() => {
          if (container.value !== null) {
            clientHeight.value = container.value.parentElement?.clientHeight || 0
            clientWidth.value = container.value.parentElement?.clientWidth || 0
          }
        }, 0)
      }
    )

    const hasComparison = computed(() => props.definition.dimensions.some(d => d.name.indexOf('comparisonGroup') > -1))

    const dimensionKeys = computed(() => {
      const dimension = props.definition.dimensions[0]
      return isComposite.value ? (dimension as CompositeDimension).dimensions.map(d => ({ name: d.name, label: translateDBName(d.name) })) : [{ name: dimension.name, label: translateDBName(dimension.name) }]
    })

    const metricKeys = computed(() => {
      return props.definition.metrics.map(m => m.name)
    })

    // Methods
    const isSingleDimensionSelected = (dimension: Dimension, key: string): boolean => {
      const enrichmentDimension = enrichmentDimensions[dimension.name]
      if (enrichmentDimension) {
        const val = enrichmentDimension.values[key]
        if (val && enrichmentDimension.enrichment!.oneToOne) {
          const values = props.filters.filters[enrichmentDimension.enrichment!.name]
          return values !== undefined && values.operator === 'equals' && values.values.includes(val[0])
        }
      }

      const values = props.filters.filters[dimension.name]
      return values !== undefined && values.operator === 'equals' && values.values.includes(key)
    }

    const isSelected = (segment: Grouping<NaturallyOrderedValue, any>): boolean => {
      const dimension = props.definition.dimensions[0]
      if (dimension instanceof CompositeDimension) {
        const key = segment.key as Record<string, any>
        return dimension.dimensions.every(d => isSingleDimensionSelected(d, key[d.name]))
      }
      return isSingleDimensionSelected(dimension, segment.key as string)
    }

    const toWidgetFilter = (segment: Grouping<NaturallyOrderedValue, any>): WidgetFilter[] => {
      const dimension = props.definition.dimensions[0]
      const wf: WidgetFilter[] = []
      if (dimension instanceof CompositeDimension) {
        const key = segment.key as Record<string, any>
        dimension.dimensions.forEach(d => {
          wf.push({ name: d.name, value: key[d.name] })
        })
      } else {
        wf.push({ name: dimension.name, value: segment.key.toString() })
      }

      return wf
    }

    const onSegmentClicked = (e: MouseEvent, segment: ProcessedSegment) => {
      if (!filterOnClick.value || window.getSelection()?.toString().length !== 0) {
        // Don't trigger the event if the dimension is a time dimension
        // It's too difficult to handle composite dimension selection properly
        return
      }
      e.preventDefault()
      nextTick(() => window.getSelection()?.empty())
      const event: SegmentClickedEvent = {
        originStore: props.definition.store.uid,
        ctrl: e.metaKey || e.ctrlKey,
        shift: e.shiftKey,
        dimensions: []
      }

      const index = sortedSegments.value!.indexOf(segment)
      const lastClickSegment = sortedSegments.value![lastClick]
      if (e.shiftKey) {
        if (isComposite.value) {
          notificationsStore.add({
            title: t('labels.warning'),
            message: t('dashboards.shiftSelectionDisabled'),
            type: 'warning'
          })
          return
        }
        const min = Math.min(lastClick, index)
        const max = Math.max(lastClick, index)
        for (let i = min; i <= max; i++) {
          const s = sortedSegments.value![i]
          if (event.ctrl && s.selected === lastClickSegment.selected) {
            continue
          }
          event.dimensions.push(toWidgetFilter(s))
        }
        emit('segment-clicked', event)
        return
      }
      if (event.ctrl && isComposite.value && (!sortedSegments.value![index].selected || selectedCount.value > 1)) {
        notificationsStore.add({
          title: t('labels.warning'),
          message: t('dashboards.selectCompositeNotice'),
          type: 'warning'
        })
        return
      }
      lastClick = index

      event.dimensions.push(toWidgetFilter(segment))

      emit('segment-clicked', event)
    }

    const onGlobalSelectCheckboxInput = (e: Event) => {
      if (sortedSegments.value === undefined) {
        return
      }
      const event: SegmentClickedEvent = {
        originStore: props.definition.store.uid,
        ctrl: true,
        shift: false,
        dimensions: []
      }

      // Deselect all
      sortedSegments.value.filter(s => s.selected)
        .map(toWidgetFilter)
        .forEach(v => event.dimensions.push(v))

      if (event.dimensions.length > 0) {
        setTimeout(() => {
          emit('segment-clicked', event)
        }, 0)
      }
      e.preventDefault()
    }

    const onScroll = (e: Event) => {
      const target = e.target! as Element
      scrollTop.value = target.scrollTop // TODO not smooth on firefox because of async scrolling
    }

    const sort = (metric: string) => {
      if (sortMetric.value === metric) {
        if (sortOrder.value) { // if Asc, remove sort
          sortMetric.value = null
          sortOrder.value = false
        } else {
          sortOrder.value = !sortOrder.value
        }
        return
      }

      sortMetric.value = metric
      sortOrder.value = false
    }

    const format = (key: string, value: any): string => {
      const formatter = formatters[key]
      if (formatter) {
        return formatter === METRIC_FORMATTERS.DATE ? formatter(value, props.definition.store.granularity) : formatter(value)
      }
      return value
    }

    const settings: WidgetSettings = {
      props,
      dimension: computed(() => props.definition.dimensions[0]),
      updateFunc: update,
      singleMetricUpdateFunc: updateSingleMetrics,
      singleMetricComparisonUpdateFunc: updateSingleMetricsComparison
    }
    const commonWidget = setupWidget(settings)
    const onExpandClicked = () => {
      commonWidget.onExpandClicked()
      nextTick(() => {
        clientHeight.value = container.value?.parentElement?.clientHeight || 0
      })
    }

    const onExport = (title: string, form: ExportForm) => {
      if (computedSegments.value) {
        const records = form.onlySelected ? computedSegments.value.filter(s => s.selected) : computedSegments.value
        const columns = []
        if (settings.dimension.value instanceof CompositeDimension) {
          columns.push(...settings.dimension.value.dimensions.map(d => d.name))
        } else {
          columns.push(settings.dimension.value.name)
        }
        columns.push(...form.metrics)
        const csvDataURL = toCSVDataURL(records, columns, form.format)
        const titleWithPeriods = title + '_' + dateformat(props.definition.store.lastDateRange!.from, 'yyyy-MM-dd') + ' - ' + dateformat(props.definition.store.lastDateRange!.to, 'yyyy-MM-dd')
        download(titleWithPeriods, csvDataURL)
      }
    }

    watch(
      () => [sortOrder.value, sortMetric.value],
      () => {
        props.queryBuilder.setSort(props.definition.uid, sortMetric.value, sortOrder.value)
      },
      { immediate: true }
    )

    return {
      // Refs
      container,
      dimensionGridRef,
      metricsGridRef,

      // Data
      sortOrder,
      sortMetric,
      singleMetrics,
      singleMetricsComparison,
      showOnlySelectedLines,
      clientWidth,

      // Computed
      height,
      hasComparison,
      sortedSegments,
      cluster,
      translation,
      headerTranslation,
      isComposite,
      filterOnClick,
      selectedCount,
      columnCount,
      dimensionColumnCount,
      footerTranslation,

      // Methods
      onSegmentClicked,
      metricKeys,
      dimensionKeys,
      onScroll,
      sort,
      format,
      onGlobalSelectCheckboxInput,

      // Misc
      SIGNED_PERCENT: METRIC_FORMATTERS.SIGNED_PERCENT,
      INTEGER: METRIC_FORMATTERS.INTEGER,
      translateDBName,
      relativeComparison,
      t,

      ...commonWidget,
      onExpandClicked,
      onExport
    }
  }
})
</script>
