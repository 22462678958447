<template>
  <TransitionRoot
    as="template"
    :show="open"
    appear
  >
    <DialogComponent
      as="div"
      auto-reopen="true"
      class="fixed inset-0 z-[52] overflow-y-auto"
      :initial-focus="cancelButtonRef?.$el"
      @close="onClose()"
    >
      <div
        class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >&#8203;</span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          >
            <div class="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div
                  v-if="icon"
                  :class="[
                    'flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto rounded-full sm:mx-0 sm:h-10 sm:w-10',
                    iconClasses.background
                  ]"
                >
                  <component
                    :is="icon"
                    :class="['w-6 h-6', iconClasses.text]"
                    aria-hidden="true"
                  />
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <DialogTitle
                    as="h3"
                    class="mt-2 text-lg font-semibold leading-6 text-text-primary"
                  >
                    {{ title }}
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-base text-text-primary">
                      <slot />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="px-4 py-3 bg-gray-50 sm:px-6 sm:pb-4 sm:flex sm:flex-row-reverse"
            >
              <AppButton
                :appearance="confirmButtonAppearance"
                class="sm:ml-2"
                :disabled="isSubmitting"
                @click.prevent.stop="onConfirm()"
                @pointerdown.stop
              >
                {{ confirmButtonText || t('actions.confirm') }}

                <Spinner
                  v-if="isSubmitting"
                  class="w-4 h-4 ml-2 text-white"
                />
              </AppButton>

              <AppButton
                ref="cancelButtonRef"
                appearance="secondary"
                @click.prevent.stop="onClose"
                @pointerdown.stop
              >
                {{ cancelButtonText || t('actions.cancel') }}
              </AppButton>
            </div>
          </div>
        </TransitionChild>
      </div>
    </DialogComponent>
  </TransitionRoot>
</template>

<script lang="ts">
import { Dialog as DialogComponent, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
import { Component, FunctionalComponent, PropType, computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouteLocationRaw, useRouter } from 'vue-router'

import AppButton from '@/components/Buttons/AppButton.vue'
import { ButtonAppearance } from '@/components/Buttons/types'
import Spinner from '@/components/Spinner/Spinner.vue'

import { IconAppearance } from './types'

export default defineComponent({
  components: {
    DialogComponent,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    Spinner,
    AppButton
  },
  props: {
    open: {
      type: Boolean,
      required: false,
      default: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    confirmButtonText: {
      type: String,
      default: undefined
    },
    cancelButtonText: {
      type: String,
      default: undefined
    },
    confirmButtonAppearance: {
      type: String as PropType<ButtonAppearance>,
      default: 'danger'
    },
    icon: {
      type: [Object, Function, null, undefined] as PropType<Component | FunctionalComponent | null | undefined>,
      default: ExclamationTriangleIcon
    },
    iconAppearance: {
      type: String as PropType<IconAppearance>,
      default: 'danger'
    },
    redirectTo: {
      type: Object as PropType<RouteLocationRaw>,
      required: false,
      default: undefined
    },
    isSubmitting: {
      type: Boolean,
      default: false
    }
  },
  emits: ['confirm', 'close'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const router = useRouter()

    const cancelButtonRef = ref<any | null>(null)

    const ICON_CLASSES = {
      danger: {
        background: 'bg-red-100',
        text: 'text-red-500'
      },
      info: {
        background: 'bg-primary-100',
        text: 'text-primary-500'
      }
    }

    const iconClasses = computed(() => ICON_CLASSES[props.iconAppearance])

    const onConfirm = () => {
      emit('confirm')
    }

    const onClose = () => {
      if (props.redirectTo) {
        router.push(props.redirectTo)
      }
      emit('close')
    }

    return {
      t,
      cancelButtonRef,
      onConfirm,
      onClose,

      iconClasses
    }
  }
})
</script>
