<template>
  <div
    class="relative flex-grow flex items-center overflow-hidden cursor-pointer min-h-10 md:h-10 px-8 border rounded border-gray-300 text-gray-700 select-none"
    :class="{'!ring-primary-500 !border-primary-500': open}"
  >
    <div class="w-full flex items-end flex-wrap md:flex-nowrap">
      <template v-if="label !== undefined">
        <span class="font-medium whitespace-nowrap">{{ label }}</span>
      </template>
      <template v-else>
        <span class="font-medium whitespace-nowrap">{{ format(value.start, showTime ? 'yyyy-MM-dd Ka' : 'yyyy-MM-dd') }}</span>
        <template v-if="startOfDay(value.start).getTime() !== startOfDay(value.end).getTime()">
          <span class="flex self-center">
            <ArrowLongRightIcon
              class="w-4 h-4 inline-block mx-1"
              aria-hidden="true"
            />
          </span>
          <span class="font-medium">{{ format(value.end, showTime ? 'yyyy-MM-dd Ka' : 'yyyy-MM-dd') }}</span>
        </template>
      </template>
      <template v-if="comparisonLabel !== undefined">
        <span class="mx-1 text-gray-600 text-sm whitespace-nowrap">
          {{ t('dates.comparedWith') }}
        </span>
        <span class="font-medium text-sm whitespace-nowrap">{{ comparisonLabel }}</span>
      </template>
      <template v-if="timezone">
        <span class="font-medium text-gray-600 ml-1 whitespace-nowrap">({{ timezone }})</span>
      </template>
      <CalendarIcon
        class="mx-2 h-full w-5 absolute left-0 top-0"
        aria-hidden="true"
      />
      <ChevronDownIcon
        class="mx-2 h-full w-5 absolute right-0 top-0"
        aria-hidden="true"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { CalendarIcon, ArrowLongRightIcon } from '@heroicons/vue/24/outline'
import { ChevronDownIcon } from '@heroicons/vue/24/solid'
import { format, getHours, startOfDay } from 'date-fns'
import { computed, defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

import { DateRange } from './dateRange'

export default defineComponent({
  name: 'DateRangeInput',
  components: {
    CalendarIcon,
    ArrowLongRightIcon,
    ChevronDownIcon
  },
  props: {
    value: {
      type: Object as PropType<DateRange>,
      required: true
    },
    open: {
      type: Boolean,
      required: true
    },
    label: {
      type: String as PropType<string | undefined>,
      default: () => undefined
    },
    comparisonLabel: {
      type: String as PropType<string | undefined>,
      default: () => undefined
    },
    time: {
      type: Boolean,
      default: false
    },
    timezone: {
      type: String,
      default: () => undefined
    }
  },
  setup (props) {
    const { t } = useI18n()
    const showTime = computed(() => {
      return !(getHours(props.value.start) === 0 && getHours(props.value.end) === 23)
    })

    return {
      showTime,
      format,
      startOfDay,
      t
    }
  }
})
</script>
