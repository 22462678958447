<template>
  <button
    class="inline-flex text-gray-600 rounded-md hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
    type="button"
    @click="onRemove()"
  >
    <span class="sr-only">Close</span>
    <XMarkIcon
      class="w-5 h-5"
      aria-hidden="true"
    />
  </button>
</template>

<script lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/solid'
import { defineComponent, PropType } from 'vue'

import { Notification } from '@/types/notifications'

import { useNotificationsStore } from '@/store/notifications.store'

export default defineComponent({
  components: {
    XMarkIcon
  },
  props: {
    notification: {
      type: Object as PropType<Notification>,
      required: true
    }
  },
  setup (props) {
    const notificationsStore = useNotificationsStore()
    const onRemove = () => {
      notificationsStore.remove(props.notification)
    }

    return {
      onRemove
    }
  }
})
</script>
