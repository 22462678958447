import { AxiosResponse } from 'axios'

import { LoginResponse } from '@/models/auth'
import { ForgotPasswordForm, ResetPasswordForm } from '@/models/password'

import api from '@/api'

export function fetchResetPasswordByToken (token: string): Promise<AxiosResponse<any>> {
  return api.get(`/password/reset/${token}`)
}

export function forgotPassword (form: ForgotPasswordForm): Promise<AxiosResponse<any>> {
  return api.post('/password/forgot', form)
}

export function resetPassword (token: string, form: ResetPasswordForm): Promise<AxiosResponse<LoginResponse>> {
  return api.post<LoginResponse>(`/password/reset/${token}`, form, { withCredentials: true })
}
