<template>
  <div
    v-if="isDisabled"
    class="relative overflow-auto"
  >
    <slot
      class="h-full min-w-full"
      style="contain: content;"
      :rows="items"
      :is-disabled="isDisabled"
    />
  </div>
  <div
    v-else
    ref="elementRef"
    data-element="virtual-scroller"
    class="relative flex flex-col h-full overflow-auto will-change-scroll"
    :tabindex="0"
    style="contain: strict; outline: 0 none; transform: translateZ(0);"
    @scroll="onScroll"
  >
    <div class="relative h-full">
      <slot
        :item-size="itemSize"
        :rows="loadedItems"
        :spacer-style="spacerStyle"
        :content-style="contentStyle"
        :is-disabled="isDisabled"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { useResizeObserver } from '@vueuse/core'
import { defineComponent, ref, onMounted, computed, PropType, watch } from 'vue'

import { calculateScrollPos, calculateCurrentIndex, calculateTriggerIndex, calculateFirstIndex, calculateLastIndex, calculateTranslateVal } from './utils'

export default defineComponent({
  name: 'VirtualScroller',
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      default: null
    },
    itemSize: {
      type: Number,
      default: 0
    },
    contentElement: {
      type: Object as PropType<HTMLElement | null>,
      required: false,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: { // Temporary property. It has been added as we don't want to waste time to adjust properly the VS behavior during the 1st iteration
      type: Boolean,
      default: true
    },
    // tableHeightAdjustment is the value added to the height of the "main content" during its own computation
    // to avoid the last lines to be hidden by the sticky footer.
    tableHeightAdjustment: {
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const elementRef = ref<HTMLElement | null>(null)
    const contentRef = computed<HTMLElement | null>(() => props.contentElement)

    const lastScrollPos = ref(0)

    const first = ref(0)
    const last = ref(0)
    const numItemsInViewport = ref(0)
    const dNumToleratedItems = ref(0)
    const spacerStyle = ref({})

    const contentStyle = ref({})

    const getContentPosition = () => {
      if (contentRef.value) {
        const style = getComputedStyle(contentRef.value)
        const left = parseInt(style.paddingLeft, 10) + Math.max(parseInt(style.left, 10), 0)
        const right = parseInt(style.paddingRight, 10) + Math.max(parseInt(style.right, 10), 0)
        const top = parseInt(style.paddingTop, 10) + Math.max(parseInt(style.top, 10), 0)
        const bottom = parseInt(style.paddingBottom, 10) + Math.max(parseInt(style.bottom, 10), 0)

        return { left, right, top, bottom, x: left + right, y: top + bottom }
      }

      return { left: 0, right: 0, top: 0, bottom: 0, x: 0, y: 0 }
    }

    const getLast = (last = 0) => {
      if (props.items.length) {
        return Math.min((props.items.length), last)
      }

      return 0
    }

    const calculateOptions = () => {
      const calculateNumToleratedItems = (numItems: number) => Math.ceil(numItems / 2)
      const calculateLast = (first: number, num: number, numT: number) => getLast(first + num + ((first < numT ? 2 : 3) * numT))

      const contentPos = getContentPosition()

      const contentHeight = elementRef.value ? elementRef.value.offsetHeight - contentPos.top : 0

      const computeNumItemsInViewport = Math.ceil(contentHeight / props.itemSize)
      const numToleratedItems = dNumToleratedItems.value || calculateNumToleratedItems(computeNumItemsInViewport)
      const computeLast = calculateLast(first.value, computeNumItemsInViewport, numToleratedItems)

      last.value = computeLast
      numItemsInViewport.value = computeNumItemsInViewport
      dNumToleratedItems.value = numToleratedItems
    }

    // setSpacerSize compute the height of the main content (tbody rows) to be used
    // as CSS property.
    // If the height of the VirtualScroller root element is larger than the main content,
    // then we use a 'height: 100%'.
    //
    // @todo compute the `extraRows` height
    const setSpacerSize = () => {
      const items = props.items

      const h = ((items || []).length * props.itemSize) + props.tableHeightAdjustment

      if (h && elementRef.value?.offsetHeight && h > elementRef.value.offsetHeight) {
        spacerStyle.value = { ...spacerStyle.value, ...{ height: `${h}px` } }
      } else {
        spacerStyle.value = { ...spacerStyle.value, ...{ height: '100%' } }
      }
    }

    watch(
      () => props.items.length,
      () => {
        calculateOptions()
        setSpacerSize()
      }
    )

    onMounted(() => {
      calculateOptions()
      setSpacerSize()
    })

    useResizeObserver(elementRef, () => {
      calculateOptions()
      setSpacerSize()
    })

    // ELEMENT
    const setContentPosition = (pos: { first: number, last: number }) => {
      if (contentRef.value) {
        const newFirst = pos ? pos.first : first.value

        const translateVal = calculateTranslateVal(newFirst, props.itemSize)

        contentStyle.value = { ...contentStyle.value, ...{ transform: `translate3d(0, ${translateVal}px, 0)` } }
      }
    }

    // ITEMS
    const loadedItems = computed<any[]>(() => {
      if (props.items.length) {
        return props.items.slice(first.value, last.value)
      }

      return []
    })

    // SCROLL

    const onScrollPositionChange = (event: Event) => {
      const target = event.target as Element | null
      const contentPos = getContentPosition()

      let scrollTop = 0

      if (target) {
        scrollTop = calculateScrollPos(target.scrollTop, contentPos.top)
      }

      let newFirst = 0
      let newLast = last.value
      let isRangeChanged = false

      const scrollPos = scrollTop
      const isScrollBottom = lastScrollPos.value <= scrollPos

      const currentIndex = calculateCurrentIndex(scrollPos, props.itemSize)

      const triggerIndex = calculateTriggerIndex(currentIndex, first.value, last.value, numItemsInViewport.value, dNumToleratedItems.value, isScrollBottom)

      newFirst = calculateFirstIndex(currentIndex, triggerIndex, first.value, dNumToleratedItems.value, isScrollBottom)
      newLast = calculateLastIndex(currentIndex, newFirst, last.value, numItemsInViewport.value, dNumToleratedItems.value, getLast)
      isRangeChanged = newFirst !== first.value && newLast !== last.value
      lastScrollPos.value = scrollPos

      return {
        first: newFirst,
        last: newLast,
        isRangeChanged
      }
    }

    const onScrollChange = (event: Event) => {
      const { first: newFirst, last: newLast, isRangeChanged } = onScrollPositionChange(event)

      if (isRangeChanged) {
        const newState = { first: newFirst, last: newLast }
        setContentPosition(newState)
        first.value = newFirst
        last.value = newLast
      }
    }

    const onScroll = (event: Event) => {
      onScrollChange(event)
    }

    return {
      contentRef,
      elementRef,
      spacerStyle,
      loadedItems,
      contentStyle,
      onScroll
    }
  }
})
</script>
