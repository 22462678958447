import { Grouping } from 'crossfilter2'

import { ComparisonRangePreset, DateRangePreset, DateRange as PickerDateRange } from '@/components/DateRangePicker/dateRange'

import { DataDefinition } from './definition'
import { Dimension, TimeGranularityName } from './dimensions'
import { Source } from './source'
import { Store } from './store'

import { Metric } from '.'

export type WidgetFilter = {
  name: string // The name of the dimension
  value: string
}

export type RequiredFilter = {
  dimension: string
  min: number,
  max: number,
}

/**
 * The value of the event triggered by widgets when a segment is clicked.
 * Used to update filters.
 */
export type SegmentClickedEvent = {
  originStore: string,
  ctrl: boolean,
  shift: boolean,
  dimensions: WidgetFilter[][]
}

export type StoreFormSubmitEvent = {
  uid: string
  name: string
  source: Source,
  definition: DataDefinition,
  requiredFilters: RequiredFilter[],
  refreshDimensions: string[],
  timeDimensions: string[],
  granularity: TimeGranularityName,
  disableComparison: boolean,
  periodOverride?: DateRangePreset | PickerDateRange
  comparisonPeriodOverride?: ComparisonRangePreset
}

/**
 * Cache for processed segments (group) used by Store, associating a dimension name with an Indexable
 * having a dependent as a key, and a grouping (Group) as a value.
 *
 * The reason this is a boudle Indexable structure is that multiple widgets can depend on the same dimension
 * but need different metrics or metrics reduced differently.
 */
export type Cache = Record<string, Record<string, ReadonlyArray<Grouping<any, Record<string, any>>>>>

/**
 * Cache for single metrics (groupAll) used by Store, associating a metric with an Indexable having
 * a dependent as a key, and a grouping (GroupAll) as a value.
 *
 * The reason this is a double Indexable structure is that multiple widgets can depend on the same metric
 * but need it reduced differently.
 */
export type MetricsCache = Record<string, Record<string, any>>

export type VisualizationType = 'table' | 'singleMetric' | 'line' | 'pie' | 'bar' | 'map' | 'text' | 'comparativeTable' | 'seatsBoosters'

/**
 * Metadata for Widget instantiation.
 */
export type WidgetDefinition = {
  uid: string
  store: Store
  dimensions: Dimension[]
  metrics: Metric[]
  vizType: VisualizationType,
  title?: string,
  disableComparison?: boolean
  defaultSort?: {
    column: string,
    order: 'ASC' | 'DESC'
  }
}

/**
 * Position data for vue-grid-layout.
 */
export type LayoutItem = {
  x: number,
  y: number,
  w: number,
  h: number,
  i: string
}

/**
 * Layout definitions for medium+ and small display breakpoints.
 */
export type ResponsiveLayout = {
  md: LayoutItem[],
  sm: LayoutItem[]
}

export type PositionedWidget = {
  widget: WidgetDefinition,
  position: LayoutItem
}

/**
 * Identifiers for the different types of operations in worker messages.
 * These operations can be used for communicating both ways (store to worker or worker to store)
 */
export enum WorkerOperation {
  INIT,
  READY,
  SET_DATA,
  CLEAR_DATA,
  DATA_READY,
  ADD_DIMENSION,
  DISPOSE_DIMENSION,
  ADD_METRIC,
  DISPOSE_METRIC,
  FILTER_ALL,
  DIMENSION_REDUCED,
  METRIC_REDUCED,
  DIMENSION_DISPOSED
}

export type WorkerMessage = {
  op: WorkerOperation,
  params: Record<string, any>
}

export enum StoreState {
  PREPARING, // The store is instantiated but the worker is not ready yet
  WORKER_READY, // The worker is ready, store can start working on data
  LOADING, // The store has requested its source, is waiting for its response then waiting for the store to finish preparing the data
  READY // The worker has finished preparing the data, the store is ready to receive requests from widgets
}

export type CSVFormat = {
  separator: string,
  numberFormat: (n: number) => string
}

export type ExportForm = {
  format: CSVFormat,
  metrics: string[],
  onlySelected: boolean
}
