<template>
  <div :class="[theme.wrapper, 'relative flex-1 flex flex-col overflow-hidden justify-between', 'mt-3']">
    <div
      v-if="hasError"
      class="flex flex-col items-center w-full max-h-full gap-2"
    >
      <Callout
        :title="t('labels.error')"
        :theme="CalloutTheme.DANGER"
        :is-dismissable="false"
      >
        <p class="text-sm font-medium text-slate-500">
          {{ t('labels.errorRetry') }}
        </p>

        <template #actions>
          <AppButton
            appearance="clear-danger"
            :left-icon="ArrowPathIcon"
            :is-disabled="isLoading"
            @click="$emit('refresh')"
          >
            {{ t('actions.refresh') }}
          </AppButton>
        </template>
      </Callout>
    </div>
    <VirtualScroller
      v-else
      v-slot="slotProps"
      :items="items"
      :is-disabled="!virtualScroll"
      class="flex flex-col flex-1 max-h-full"
      :item-size="itemSize"
      :content-element="tbodyRef"
      :is-loading="isLoading"
      :table-height-adjustment="cTableHeightAdjustement"
    >
      <table
        ref="tableRef"
        class="min-w-full border-collapse table-auto"
        :style="[slotProps.spacerStyle]"
      >
        <thead
          ref="tableHeader"
          :class="[ 'flex flex-1 sticky top-0 z-20', theme.headerHead ]"
        >
          <tr
            :class="[ 'inline-flex flex-nowrap items-stretch min-w-full', theme.headerRow ]"
            :style="{ height: itemSize ? `${itemSize}px` : undefined }"
          >
            <th
              v-if="selectWith === 'checkbox'"
              ref="checkboxRef"
              :class="[theme.headerColumn, theme.checkboxCell]"
              style="width: 60px;"
              data-group="checkbox"
            >
              <input
                type="checkbox"
                :class="theme.checkbox"
                :indeterminate="isIndeterimate"
                :checked="isChecked"
                @click.stop.prevent="onSelectAll()"
              >
            </th>
            <th
              v-if="subDatatable"
              ref="groupRef"
              :class="[theme.headerColumn]"
              style="position sticky; left: 40px"
            />
            <th
              v-for="(column, columnIdx) in visibleColumns"
              :key="column.field"
              :class="[ columnIdx === 0 ? theme.firstColumn : '', 'relative select-none', getHeaderColumnClass(column) ]"
              :style="[getColumnWidth(column), column.style ? column.style : '']"
              :title="column.name"
              :data-group="column.groupKey"
              @click.exact.prevent="onColumnClick(column)"
              @click.meta.prevent="onColumnMetaClick(column)"
            >
              <Tooltip
                :disabled="!column.info || !column.hideInfoQuestionMark"
                trigger="hover"
                :reference-props="{
                  title: '',
                  class: 'relative inline-flex'
                }"
                :teleport-props="{ to: 'body' }"
                :force-show="false"
              >
                <template #default>
                  <LockClosedIcon
                    v-if="column.locked"
                    class="w-3 h-3 mr-1 shrink-0"
                    aria-hidden="true"
                  />
                  <span :class="[theme.headerColumnTitle]">{{ column.name + (column.required ? '*' : '') }}</span>
                </template>
                <template #title>
                  <span
                    class="text-sm font-normal"
                  >
                    {{ column.info }}
                  </span>
                </template>
              </Tooltip>
              <TooltipInfo
                v-if="column.info && !column.hideInfoQuestionMark"
                class="ml-1 text-slate-500"
                :message="column.info"
                :teleport-props="{ to: 'body' }"
              />
              <!-- We are forced to teleport to body because the tooltips may be too large to fit into the datatable with hidden overlow. -->
              <!-- Teleporting to the parent of the Datatable div container introduces fatal rendering bugs so this is not an option. -->

              <div
                v-if="column.sortable"
                class="absolute right-4"
              >
                <span
                  v-if="getColumnSort(column)"
                  class="block"
                >
                  <span
                    v-if="getColumnSort(column)"
                    class="font-extrabold text-2xs text-text-primary"
                  >
                    {{ getColumnSortIndex(column) }}
                  </span>

                  <ChevronUpIcon
                    v-if="getColumnSort(column)?.sortOrder === SortOrder.ASC"
                    class="w-3 h-3 -mt-1.5"
                  />
                  <ChevronDownIcon
                    v-else
                    class="w-3 h-3 -mt-1.5"
                  />
                </span>
                <span v-else>
                  <ChevronUpIcon class="block w-3 h-3 -mb-1.5" />
                  <ChevronDownIcon class="block w-3 h-3 -mt-1.5" />
                </span>
              </div>
            </th>
            <th :class="[ theme.lastColumn, theme.headerColumn, 'flex flex-1' ]" />
          </tr>
        </thead>

        <!-- Frozen rows -->
        <tbody
          v-if="frozenItems.length"
          class="box-border sticky z-20 block"
          :style="frozenRowsStyle"
        >
          <tr
            v-for="(row, index) in frozenItems"
            :id="row.id"
            :key="`row-${index}`"
            :class="[
              getRowClass(row),
              theme.row
            ]"
            :style="{ height: itemSize ? `${itemSize}px` : undefined }"
            @click.exact.prevent="onRowClick(row)"
            @click.meta.prevent="onRowMetaClick(row)"
          >
            <td
              v-if="selectWith === 'checkbox'"
              :class="getCheckboxCellClass(row)"
              style="width: 60px;"
            >
              <input
                type="checkbox"
                :disabled="selectDisabledIf !== undefined && selectDisabledIf(row)"
                :checked="row.isSelected.value"
                :class="theme.checkbox"
                @click.stop="onSelect(row, $event)"
              >
            </td>
            <td
              v-for="column in visibleColumns"
              :key="`row-${row.id}-${column.field}`"
              :class="['datatable-cell', theme.cell]"
              :style="getColumnWidth(column)"
              tabindex="-1"
              :data-group="column.groupKey"
              @focus="onRowColumnClick($event, row, column)"
            >
              <template
                v-for="(cellValue, i) in [getCellValue(row, column)]"
                :key="i"
              >
                <slot
                  v-if="rowHasColumnSlot(column)"
                  :name="`${column.field.replaceAll('.', '__')}`"
                  :row="row"
                  :column="column"
                  :value="cellValue"
                />
                <span
                  v-else
                  class="truncate"
                  :title="cellValue"
                >
                  {{ cellValue }}
                </span>
              </template>
            </td>
            <!-- Last column to fill the void -->
            <td
              :class="[
                theme.cell,
                'flex-1',
                {
                  [theme.rowSelected]: rowSelected === row.id
                }]"
            />
          </tr>
        </tbody>

        <!-- Main rows -->
        <tbody
          v-if="slotProps.rows.length > 0"
          ref="tbodyRef"
          :class="[ theme.body, 'block min-w-full', !slotProps.isDisabled ? 'will-change-transform absolute left-0' : '' ]"
          style="contain: layout;"
          role="rowgroup"
          :style="[
            tbodyStyle,
            slotProps.contentStyle,
            !slotProps.isDisabled ? { top: `${itemSize}px !important` } : ''
          ]"
        >
          <template
            v-for="row in slotProps.rows"
            :key="`row-${row.id}`"
          >
            <tr
              :id="row.id"
              :class="getRowClass(row)"
              :style="{ height: itemSize ? `${itemSize}px` : undefined }"
              style="transition: none;"
              @click.exact="onRowClick(row)"
              @click.meta="onRowMetaClick(row)"
            >
              <td
                v-if="selectWith === 'checkbox'"
                :class="getCheckboxCellClass(row)"
                style="width: 60px;"
              >
                <input
                  type="checkbox"
                  :disabled="selectDisabledIf !== undefined && selectDisabledIf(row)"
                  :class="theme.checkbox"
                  :checked="isRowSelected(row)"
                  @click.stop="onSelect(row, $event)"
                >
              </td>
              <td
                v-if="subDatatable"
                class="flex items-center pl-2 cursor-pointer "
                style="position sticky; left: 40px"
              >
                <Spinner
                  v-if="row.isLoading.value"
                  class="w-5 h-5 text-primary-500"
                />
                <ChevronDownIcon
                  v-else-if="row.isOpen.value"
                  class="w-5 h-5"
                  @click.stop="onSubDatatableClose(row)"
                />
                <ChevronRightIcon
                  v-else
                  class="w-5 h-5"
                  @click.stop="onSubDatatableOpen(row)"
                />
              </td>
              <td
                v-for="column in visibleColumns"
                :key="`row-${row.id}-${column.field}`"
                :class="[
                  'datatable-cell',
                  theme.cell,
                  getColumnFrozenClass(row, column)
                ]"
                :style="getColumnWidth(column)"
                tabindex="-1"
                @click="onRowColumnClick($event, row, column)"
              >
                <component
                  :is="cell.errs || cell.warns || cell.infos ? Tooltip : 'div'"
                  v-for="(cell, i) in [getCell(row, column)]"
                  :key="i"
                  reference-is="div"
                  :class="cell.class"
                  :reference-props="{
                    class: cell.class
                  }"
                  placement="auto"
                  :teleport-props="tableRef?.parentElement ? { to: tableRef?.parentElement } : undefined"
                  :modifiers="tooltipModifiers"
                >
                  <!-- Please do not add truncate class here -->
                  <span
                    v-if="rowHasColumnSlot(column)"
                    class="flex flex-col justify-center flex-1 w-full"
                    :class="isRowColumnEditable(row, column) ? 'select-none cursor-text' : ''"
                  >
                    <slot
                      :key="`slot-${row.id}-${column.field}`"
                      :name="`${column.field.replaceAll('.', '__')}`"
                      :row="row"
                      :column="column"
                      :value="cell.value"
                    />
                  </span>
                  <span
                    v-else
                    class="flex flex-col justify-center flex-1"
                    :class="isRowColumnEditable(row, column) ? 'select-none cursor-text' : ''"
                    :title="cell.value"
                  >
                    {{ cell.value }}
                  </span>

                  <template #title>
                    <span class="text-sm whitespace-pre-line">{{ cell.errs?.join('\n') || cell.warns?.join('\n') || cell.infos?.join('\n') }}</span>
                  </template>
                </component>
              </td>
              <!-- Last column to fill the void -->
              <td
                :class="[
                  theme.cell,
                  'flex-1',
                  {
                    [theme.rowSelected]: rowSelected === row.id
                  }]"
              />
            </tr>

            <!-- Display sub rows -->
            <template
              v-if="subDatatable && row.isOpen.value"
            >
              <tr class="flex flex-1 border-l-2 border-black bg-blue-50">
                <FormSubDatatable
                  name="subDatatable"
                  :api="subDatatable.api"
                  :api-args="subDatatable.apiArgs(row)"
                  :query-params="subDatatable.queryParams(row)"
                  :columns="subDatatable.columns"
                  :is-loading="row.isLoading.value"
                  @update:loading="(loading) => onSubDatatableLoadingUpdate(row, loading)"
                />
              </tr>
            </template>
          </template>
        </tbody>

        <!-- Extra Rows -->
        <tbody
          v-if="extraRows?.length"
          :class="[ theme.body, 'block box-border min-w-full', !slotProps.isDisabled ? 'will-change-transform absolute top-0 left-0' : '' ]"
          style="contain: layout;"
          role="rowgroup"
          :style="[tbodyStyle, slotProps.contentStyle]"
        >
          <tr
            v-for="(row, rIdx) in extraRows"
            :key="`extra-row-${rIdx}`"
            :style="{ height: itemSize ? `${itemSize}px` : undefined }"
            :class="theme.row"
          >
            <td
              v-for="(column, cIdx) in row"
              :key="`extra-row-${rIdx}-${cIdx}`"
              :class="[
                theme.cell,
                getColumnFrozenClass(null, column)
              ]"
              :style="`width: ${column.size}px`"
            >
              <slot
                v-if="extraRowHasColumnSlot(cIdx)"
                :name="`extra-row-${cIdx}`"
                :row="row"
                :column="column"
                :value="column.value"
              />
              <div
                v-else
                :class="[
                  'text-text-primary font-regular text-sm',
                  'relative flex flex-col w-full h-full px-2.5',
                  column.align ? `text-${column.align}` : '',
                  Array.isArray(column.classes) ? column.classes.join(' ') : column.classes
                ]"
              >
                <span class="flex flex-col justify-center flex-1">{{ column.value || '&nbsp;' }}</span>
              </div>
            </td>
            <td :class="[theme.cell, 'flex flex-1' ]" />
          </tr>
        </tbody>

        <!-- Empty rows -->
        <tbody
          v-else-if="slotProps.rows.length === 0"
          ref="tbodyRef"
          :class="[ theme.body, 'block box-border min-w-full', !slotProps.isDisabled ? 'will-change-transform absolute top-0 left-0' : '' ]"
          style="contain: layout;"
          :style="[tbodyStyle, slotProps.contentStyle]"
        >
          <tr class="box-border flex w-full h-full flex-nowrap">
            <td
              class="flex items-center justify-center w-full h-full px-4 py-8"
              :colspan="columns.length"
            >
              <slot name="no-records">
                <div class="text-sm">
                  {{ t('messages.noRecords') }}
                </div>
              </slot>
            </td>
          </tr>
        </tbody>

        <!-- Footer -->
        <tfoot
          v-if="footer || allowNewLines || hasFooterActionSlot()"
          class="sticky bottom-0 z-20 w-full"
          :style="`height: ${cFooterHeight}px`"
        >
          <tr
            v-if="allowNewLines || hasFooterActionSlot()"
            class="box-border flex w-full bg-white flex-nowrap"
          >
            <td
              v-if="allowNewLines"
              class="sticky left-0 flex bg-white"
              :style="{ height: itemSize ? `${itemSize}px` : undefined }"
            >
              <AppButton
                type="button"
                size="sm"
                appearance="transparent"
                @click="addRow"
              >
                <span class="font-semibold">{{ t('datatable.newLine') }}</span> <PlusIcon class="w-3 h-3 ml-1" />
              </AppButton>
            </td>
            <slot
              name="footer-action"
              :item-size="itemSize"
            />
          </tr>

          <template v-if="footer && footerLines">
            <tr
              v-for="n in footerLines"
              :key="n"
              :class="[ 'flex flex-nowrap w-full', theme.footerRow ]"
            >
              <th
                v-if="selectWith === 'checkbox'"
                :class="['z-50', theme.checkboxCell]"
                @click="onSelectAll()"
              >
                <input
                  type="checkbox"
                  :class="theme.checkbox"
                  :indeterminate="hasDatatableSelectedRows"
                  :checked="hasDatatableSelectedRows"
                >
              </th>
              <th
                v-for="column in visibleColumns"
                :key="`footer-${column.field}${n > 1 ? '-' + n : ''}`"
                :class="[theme.footerColumn, getFooterColumnClass(column)]"
                :style="getColumnWidth(column)"
              >
                <slot
                  :name="`footer-${column.field}${n > 1 ? '-' + n : ''}`"
                  :column="column"
                />
              </th>
              <th :class="[ theme.footerColumn, 'flex flex-1' ]" />
            </tr>
          </template>
        </tfoot>
      </table>
    </VirtualScroller>

    <div
      v-if="isLoading"
      class="absolute z-30 flex items-center justify-center w-full h-full top left"
    >
      <span class="absolute top-0 left-0 block w-full h-full bg-white opacity-50" />
      <slot name="loading">
        <Spinner class="w-10 h-10 text-primary-600" />
      </slot>
    </div>

    <Pagination
      v-if="pagination"
      v-model:pagination="datatablePagination"
      :class="theme.pagination"
    />

    <teleport :to="editableTeleportTarget">
      <DatatableCellEdit
        v-if="isEditing"
        :row="editCurrentRow"
        :column="editCurrentColumn"
        :dom-rect="editCurrentPosition"
        @click-out="isEditing = false"
      >
        <!-- TODO check update callback handling -->
        <slot
          :name="editCurrentSlotName"
          :row="editCurrentRow"
          :column="editCurrentColumn"
          :value="getCellValue(editCurrentRow, editCurrentColumn)"
          :update="(v: any) => onCellUpdate(v, editCurrentRow, editCurrentColumn)"
        />
      </DatatableCellEdit>
    </teleport>
  </div>
</template>

<script lang="ts">

import { ChevronDownIcon, ChevronRightIcon, ChevronUpIcon, LockClosedIcon, PlusIcon } from '@heroicons/vue/20/solid'
import { ArrowPathIcon } from '@heroicons/vue/24/solid'
import chunk from 'lodash/chunk'
import get from 'lodash/get'
import set from 'lodash/set'
import { computed, defineComponent, nextTick, onMounted, PropType, ref, WritableComputedRef } from 'vue'
import { useI18n } from 'vue-i18n'

import { PaginateWithoutRecords } from '@/types/paginate'

import { Sort, SortOrder } from '@/plugins/filters'
import VirtualScroller from '@/plugins/VirtualScroller/VirtualScroller.vue'

import AppButton from '@/components/Buttons/AppButton.vue'
import Callout from '@/components/Callout/Callout.vue'
import { CalloutTheme } from '@/components/Callout/theme'
import FormSubDatatable from '@/components/Form/FormSubDatatable.vue'
import Spinner from '@/components/Spinner/Spinner.vue'
import Tooltip from '@/components/Tooltip/Tooltip.vue'
import TooltipInfo from '@/components/Tooltip/TooltipInfo.vue'

import { Column, DatatableValidation, ExtraColumn, Row, SubDatatable } from './datatable.d'
import DatatableCellEdit from './DatatableCellEdit.vue'
import Pagination from './DatatablePagination.vue'
import theme from './theme'
import { getRowColumnValue } from './utils'

function getEditableTeleportRoot () {
  const existingRoot = document.getElementById('editable-root')
  if (existingRoot) return existingRoot

  const root = document.createElement('div')
  root.setAttribute('id', 'editable-root')

  // We append the panels-root container on #app as we defined "#app" as "important" scope in tailwind.config.js
  const appContainer = document.getElementById('app')

  if (!appContainer) {
    console.warn('Container for editable-root is missing')
    return
  }
  return appContainer.appendChild(root)
}

export default defineComponent({
  components: {
    AppButton,
    VirtualScroller,
    Spinner,
    Pagination,
    DatatableCellEdit,
    ChevronDownIcon,
    ChevronUpIcon,
    ChevronRightIcon,
    PlusIcon,
    LockClosedIcon,
    TooltipInfo,
    FormSubDatatable,
    Callout,
    Tooltip
  },
  props: {
    columns: {
      type: Array as PropType<Column[]>,
      required: true
    },
    items: {
      type: Array as PropType<Row[]>,
      required: false,
      default: () => []
    },
    extraRows: {
      // extraRows is used to add additionnal rows after the main content (items) of the table.
      // Using `extraRows` will create a new totally independant <tbody>.
      // A usage example is to add some adjustement lines after all the revenue lines of an invoice_request.
      //
      // @todo: refactor to reflect the main functionnalities of the columns/rows datatable.
      type: Array as PropType<ExtraColumn[][]>,
      required: false,
      default: undefined
    },
    frozenItems: {
      type: Array as PropType<Row[]>,
      required: false,
      default: () => []
    },
    sortedColumns: {
      type: Array as PropType<Sort[]>,
      required: false,
      default: () => []
    },
    selectedRows: {
      type: Array as PropType<Row[]>,
      required: false,
      default: () => []
    },
    selectedBy: {
      type: String,
      default: null
    },
    selectWith: {
      type: String as PropType<'checkbox' | 'row' | 'none'>,
      required: false,
      default: 'row'
    },
    selectDisabledIf: {
      type: Function as PropType<(row: Row) => boolean | undefined>,
      required: false,
      default: undefined
    },
    multipleSelect: {
      type: Boolean,
      default: false
    },
    footer: {
      type: Boolean,
      default: false
    },
    footerLines: {
      type: Number,
      default: 1
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    validation: {
      type: Object as PropType<DatatableValidation>,
      required: false,
      default: null
    },
    height: {
      type: Number,
      required: false,
      default: 600
    },
    // RENAME
    heightByLine: {
      type: Number,
      required: false,
      default: 100
    },
    // itemSize is the height of a cell.
    itemSize: {
      type: Number,
      required: false,
      default: 40
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    rowSelected: {
      type: String,
      required: false,
      default: null
    },
    pagination: {
      type: Object as PropType<PaginateWithoutRecords>,
      required: false,
      default: null
    },
    allowNewLines: {
      type: Boolean,
      required: false,
      default: false
    },
    onRowClickRedirectTo: {
      type: Function as PropType<(row: Row) => Promise<void> | void | undefined>,
      required: false,
      default: undefined
    },
    virtualScroll: {
      type: Boolean,
      required: false,
      default: false
    },
    subDatatable: {
      type: Object as PropType<SubDatatable>,
      required: false,
      default: undefined
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: [
    'columnReorder',
    'rowReorder',
    'rowSelect',
    'rowSelect',
    'rowSelectRange',
    'rowSelectAll',
    'update:selectedRows',
    'update:items',
    'update:sortedColumns',
    'update:pagination',
    'rowClick',
    'addItem',
    'removeItem',
    'refresh'
  ],
  setup (props, { emit, slots }) {
    const { t } = useI18n()

    const tableHeader = ref<HTMLElement | null>(null)
    const checkboxRef = ref<HTMLElement | null>(null)
    const tableRef = ref<HTMLElement | null>(null)
    const tbodyRef = ref<HTMLElement | null>(null)

    // cFooterHeight is used to explicitly set the footer height.
    // This one ensures footer has the expected size in Firefox.
    const cFooterHeight = computed(() => {
      let h = 0

      if (props.allowNewLines) {
        h += props.itemSize
      }

      if (props.footer && props.footerLines) {
        h += props.footerLines * props.itemSize
      }

      if (hasFooterActionSlot()) {
        h += props.itemSize
      }

      return h
    })

    // cTableHeightAdjustement is used in VirtualScroller to adjust the height of the main
    // component (table) to avoid sticky rows to hide some content.
    // Basically it takes the optional footer height + the thead height (wich is equal to props.itemSize)
    const cTableHeightAdjustement = computed(() => {
      return cFooterHeight.value + props.itemSize
    })

    const frozenRowsStyle = ref<{ [key: string]: string }>({})
    const tbodyStyle = ref<{ [key: string]: string }>({})

    // Slots
    const rowHasColumnSlot = (column: Column): boolean => {
      if (column.field) {
        const f = column.field.replaceAll('.', '__')
        return !!slots[f]
      }

      return false
    }

    const extraRowHasColumnSlot = (columnIndex: Number): boolean => {
      const f = `extra-row-${columnIndex}`
      return !!slots[f]
    }

    const hasFooterActionSlot = () => !!slots['footer-action']

    // Sorts
    const sorts: WritableComputedRef<Sort[]> = computed({
      get () {
        return props.sortedColumns
      },
      set (sorts: Sort[]) {
        emit('update:sortedColumns', sorts)
      }
    })

    // Pagination
    const datatablePagination = computed({
      get: () => {
        return props.pagination
      },
      set: (newPagination: PaginateWithoutRecords) => {
        emit('update:pagination', newPagination)
      }
    })

    const getColumnSort = (column: Column) => {
      return sorts.value.find((s) => s.field === (column.filterKey || column.field))
    }

    const getColumnSortIndex = (column: Column) => {
      return sorts.value.findIndex((s) => s.field === (column.filterKey || column.field)) + 1
    }

    // Selected
    const datatableSelectedRows: WritableComputedRef<Row[]> = computed<Row[]>({
      get () {
        return props.selectedRows
      },
      set (rows: Row[]) {
        emit('update:selectedRows', rows)
      }
    })

    const isRowSelected = (row: Row) => {
      if (props.selectedBy) {
        return datatableSelectedRows.value.includes(get(row.data, props.selectedBy))
      } else {
        return row.isSelected.value
      }
    }

    const selectableRows = computed(() => props.selectDisabledIf !== undefined ? props.items.filter(r => !props.selectDisabledIf!(r)) : props.items)

    const isIndeterimate = computed(() => datatableSelectedRows.value.length > 0 && datatableSelectedRows.value.length < selectableRows.value.length)

    const isChecked = computed(() => selectableRows.value.length > 0 && datatableSelectedRows.value.length === selectableRows.value.length)

    const hasDatatableSelectedRows = computed(() => datatableSelectedRows.value.length > 0)

    const onSelect = (row: Row, event?: MouseEvent) => {
      emit('rowSelect', row, row.data)

      if (isRowSelected(row)) {
        if (props.selectedBy) {
          datatableSelectedRows.value = datatableSelectedRows.value.filter((r: Row) => r !== get(row.data, props.selectedBy))
        } else {
          datatableSelectedRows.value = datatableSelectedRows.value.filter((r: Row) => r.id !== row.id)
        }
        row.isSelected.value = false
      } else {
        if (props.selectedBy) {
          datatableSelectedRows.value.push(get(row.data, props.selectedBy))
        } else {
          datatableSelectedRows.value.push(row)
        }
        row.isSelected.value = true
      }

      if (event?.shiftKey) {
        onSelectRange(row)
      }
    }

    const onSelectRange = (row: Row) => {
      setTimeout(() => {
        // Use setTimeout so the checkbox state is enforced, otherwise it uses the native event's state
        emit('rowSelectRange')

        let start = 0
        let end = row.index.value

        if (datatableSelectedRows.value.length > 1) {
          const indexes = datatableSelectedRows.value.map(r => r.index)
          start = Math.min(...indexes)
          end = Math.min(Math.max(...indexes), end)
        }

        unselectAll()

        let filteredRange = props.items
        if (props.selectDisabledIf !== undefined) {
          filteredRange = props.items.filter(r => !props.selectDisabledIf!(r))
        }
        selectRange(filteredRange.filter(i => i.index.value >= start && i.index.value <= end))
      })
    }

    const onSelectAll = () => {
      setTimeout(() => {
        // Use setTimeout so the checkbox state is enforced, otherwise it uses the native event's state
        emit('rowSelectAll')

        if (datatableSelectedRows.value.length === selectableRows.value.length) {
          unselectAll()
        } else if (datatableSelectedRows.value.length >= 0) {
          selectRange(selectableRows.value)
        }
      }, 0)
    }

    const unselectAll = () => {
      props.items.forEach(i => {
        i.isSelected.value = false
      })
      datatableSelectedRows.value = []
    }

    const selectRange = (range: Row[]) => {
      if (props.selectedBy) {
        datatableSelectedRows.value = range.map(i => get(i.data, props.selectedBy))
      } else {
        datatableSelectedRows.value = range.map(i => {
          i.isSelected.value = true
          return i
        })
      }
    }

    const onSubDatatableOpen = (row: Row) => {
      row.isLoading.value = true
      row.isOpen.value = true
    }

    const onSubDatatableClose = (row: Row) => {
      row.isOpen.value = false
    }

    const onSubDatatableLoadingUpdate = (row: Row, loading: boolean) => {
      row.isLoading.value = loading
    }

    // Edit
    const editableTeleportTarget = ref(getEditableTeleportRoot())
    const onEdit = ref()
    const isEditing = ref(false)
    const editCurrentSlotName = ref()
    const editCurrentRow = ref()
    const editCurrentColumn = ref()
    const editCurrentValue = ref()
    const editCurrentSlot = ref()
    const editCurrentPosition = ref<Pick<DOMRect, 'top' | 'left' | 'height' | 'width'> & { offsetHeight: number}>()

    const isRowColumnEditable = (row: Row, column: Column) => {
      const editable = props.isEditable && slots[`editable-${column.field.replaceAll('.', '__')}`]

      if (editable && (typeof column.isEditable === 'function')) {
        return column.isEditable(row)
      }

      return editable
    }

    // Columns
    const visibleColumns = computed(() => props.columns?.filter((c) => c.isVisible))

    const getHeaderColumnClass = (column: Column) => {
      return [
        'flex items-center',
        theme.headerColumn,
        column.class,
        {
          'sticky z-20': column.frozen,
          'cursor-pointer user-none': column.sortable,
          [theme.headerColumnSortable]: column.sortable,
          'justify-end': column.isNumber,
          'left-0 z-30': column.frozen && column.frozenAlign !== 'right',
          'right-0 z-30': column.frozen && column.frozenAlign === 'right',
          '!left-[60px]': column.frozen && column.frozenAlign !== 'right' && props.selectWith === 'checkbox'
        }
      ]
    }

    const getFooterColumnClass = (column: Column) => {
      return [
        'flex items-center',
        theme.footerColumn,
        column.class,
        {
          'sticky z-20': column.frozen,
          'justify-end': column.isNumber,
          'left-0 z-30': column.frozen && column.frozenAlign !== 'right',
          'right-0 z-30': column.frozen && column.frozenAlign === 'right'
        }
      ]
    }

    const getRowClass = (row: Row) => {
      return [
        theme.row,
        {
          [theme.rowSelected]: row.isSelected.value || (props.rowSelected ? props.rowSelected === row.id : false)
        },
        props.onRowClickRedirectTo ? 'cursor-pointer' : 'cursor-default'
      ]
    }

    // Returns class for the cell wrapper: td > div|span|…
    const getRowCellClass = (row: Row, column: Column) => {
      const isCellEditable = isRowColumnEditable(row, column)
      return [
        'text-text-primary font-regular text-sm',
        'relative flex flex-col w-full h-full px-2.5',
        column.rowCellClass,
        column.validator && props.validation ? isCellValid(row, column) ? (props.isEditable) ? theme.isEditable.cellValid : theme.cellValid : theme.cellNotValid : '',
        {
          [theme.cellEditable]: isCellEditable,
          [theme.cellWarning]: props.validation?.cellHasWarnings(row, column),
          [theme.cellInfo]: props.validation?.cellHasInfos(row, column),
          [theme.cellLocked]: column.locked,
          'justify-end': column.isNumber,
          // Note `text-right` class is not used in `getHeaderColumnClass()`, `getFooterColumnClass()` to avoid potential side-effects in their `th` children components
          'text-right': column.isNumber
        },
        isCellEditable ? '!cursor-default' : props.onRowClickRedirectTo ? 'cursor-pointer' : 'cursor-default'
      ]
    }

    const getCheckboxCellClass = (row: Row) => {
      return [
        theme.checkboxCell,
        {
          [theme.checkboxCellRowSelected]: row.isSelected.value
        },
        props.onRowClickRedirectTo ? 'cursor-pointer' : 'cursor-default'
      ]
    }

    const getColumnFrozenClass = (row: Row | null, column: Partial<Pick<Column, 'frozen' | 'frozenAlign' | 'frozenBackground'>>) => {
      if (column.frozen) {
        return [
          column?.frozenBackground ? column.frozenBackground : 'bg-white',
          theme.frozenCell,
          {
            'left-0 z-30': column.frozen && column.frozenAlign !== 'right',
            'right-0 z-30': column.frozen && column.frozenAlign === 'right',
            '!left-[60px]': props.selectWith === 'checkbox',
            [theme.rowSelected]: row?.isSelected.value || (props.rowSelected ? props.rowSelected === row?.id : false)
          }
        ]
      }
    }

    const getColumnWidth = (column: Column) => {
      if (column.width) {
        if (column.width === 'auto') {
          return ''
        }
        return `width: ${column.width}px;`
      }

      return 'width: 200px;'
    }

    // Lifecycle
    onMounted(() => {
      if (tableHeader.value) {
        if (props.frozenItems.length) {
          frozenRowsStyle.value.top = `${tableHeader.value.clientHeight}px`
        }

        if (tbodyRef.value) {
          tbodyStyle.value.top = `${tableHeader.value.clientHeight}px`
        }
      }

      validate()
    })

    const validate = () => {
      if (props.validation) {
        setTimeout(() => {
          nextTick(async () => {
            // Validate rows by chunks and wait for next available frame to increase responsiveness
            const validationFuncs = []
            for (const column of props.columns.filter(c => c.validator)) {
              const localCol = column
              for (const row of props.items) {
                const localRow = row
                validationFuncs.push(() => props.validation.validCell(localRow, localCol, getRowColumnValue(localRow.data, localCol), false))
              }
            }
            const chunks = chunk(validationFuncs, 500)
            for (const chunk of chunks) {
              for (const func of chunk) {
                func()
              }
              await new Promise(resolve => setTimeout(resolve, 0))
            }
          })
        }, 0)
      }
    }

    const onColumnMetaClick = (column: Column) => {
      if (column.sortable) {
        const sortIndex = sorts.value.findIndex((s) => s.field === column.field)

        if (sortIndex > -1) {
          sorts.value = sorts.value.map(
            (s) => s.field === column.field
              ? ({ field: column.filterKey || column.field, caseInsensitive: column.sortableCaseInsensitive, sortOrder: s.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC })
              : s
          )
        } else {
          if (column.field) {
            sorts.value.push({ field: column.field, caseInsensitive: column.sortableCaseInsensitive, sortOrder: SortOrder.ASC })
          }
        }
      }
    }

    const onColumnClick = (column: Column) => {
      if (column.sortable) {
        const sort = sorts.value.find((s) => s.field === (column.filterKey || column.field))

        if (sort) {
          sorts.value = [{ field: sort.field, caseInsensitive: column.sortableCaseInsensitive, sortOrder: sort.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC }]
        } else {
          if (column.field) {
            sorts.value = [{ field: column.filterKey || column.field, caseInsensitive: column.sortableCaseInsensitive, sortOrder: SortOrder.ASC }]
          }
        }
      }
    }

    const onRowColumnClick = (e: FocusEvent | KeyboardEvent, row: Row, column: Column) => {
      const slotName = `editable-${column.field.replaceAll('.', '__')}`

      if (isRowColumnEditable(row, column)) {
        e.stopPropagation()

        isEditing.value = !isEditing.value

        if (isEditing.value === false) {
          return
        }

        // get clicked element position
        let cell = e.target as HTMLElement | null
        while (cell && !cell.classList.contains('datatable-cell')) {
          cell = cell.parentElement
        }
        if (cell) {
          const { top, left, height, width } = cell.getBoundingClientRect()
          editCurrentPosition.value = {
            top,
            left,
            height,
            width,
            offsetHeight: cell.offsetHeight
          }
        }

        editCurrentSlotName.value = slotName
        editCurrentRow.value = row
        editCurrentColumn.value = column

        isEditing.value = true
      }
    }

    const onRowMetaClick = (row: Row) => {
      if (props.selectWith === 'row' && props.multipleSelect) {
        onSelect(row)
      }
    }

    const onRowClick = (row: Row) => {
      if (props.onRowClickRedirectTo) {
        props.onRowClickRedirectTo(row)
      }

      emit('rowClick', row)

      if (props.selectWith === 'row') {
        if (row.isSelected.value) {
          datatableSelectedRows.value = []
        } else {
          if (props.selectedBy) {
            datatableSelectedRows.value.push(get(row.data, props.selectedBy))
          } else {
            const found = props.items.find(i => i.id === row.id)
            if (found) {
              datatableSelectedRows.value.push(found)
            }
          }
        }

        emit('rowSelect', row.data)
      }
    }

    const addRow = () => {
      emit('addItem')
    }

    const removeRow = (rowId: string) => {
      emit('removeItem', rowId)
    }

    const isCellValid = (row: Row, column: Column) => {
      if (props.validation && column.validator) {
        return props.validation.isCellValid(row, column)
      }
    }

    const onCellUpdate = (value: any, row: Row, column: Column) => {
      if (column.field) {
        set(row.data, column.field, value)
      }
    }

    const getCellValue = (row: Row, column: Column) => {
      return getRowColumnValue(row.data, column)
    }

    const getCell = (row: Row, column: Column) => {
      return {
        value: getCellValue(row, column),
        errs: props.validation?.getCellErrors(row, column),
        warns: props.validation?.getCellWarnings(row, column),
        infos: props.validation?.getCellInfos(row, column),
        class: getRowCellClass(row, column)
      }
    }

    const tooltipModifiers = [
      {
        name: 'preventOverflow',
        options: {
          mainAxis: true,
          tether: false
        }
      }
    ]

    return {
      // Refs
      tableHeader,
      checkboxRef,
      tableRef,
      tbodyRef,

      cFooterHeight,
      cTableHeightAdjustement,

      tbodyStyle,

      Tooltip,
      tooltipModifiers,
      theme,
      getRowClass,
      getRowCellClass,
      getCheckboxCellClass,
      getRowColumnValue,

      // Data
      getCellValue,
      getCell,
      validate,

      // Columns
      visibleColumns,
      getColumnWidth,
      getColumnFrozenClass,
      getHeaderColumnClass,
      getFooterColumnClass,
      onColumnClick,
      onColumnMetaClick,
      onRowColumnClick,
      onRowClick,
      onRowMetaClick,
      addRow,
      removeRow,
      onSubDatatableOpen,
      onSubDatatableClose,
      onSubDatatableLoadingUpdate,

      // Pagination
      datatablePagination,

      // Sorts
      sorts,
      SortOrder,
      getColumnSort,
      getColumnSortIndex,

      // Selected
      datatableSelectedRows,
      hasDatatableSelectedRows,
      isIndeterimate,
      isChecked,
      isRowSelected,
      onSelect,
      onSelectAll,

      // Slots
      hasFooterActionSlot,
      rowHasColumnSlot,
      extraRowHasColumnSlot,

      // Edit
      editableTeleportTarget,
      onEdit,
      isRowColumnEditable,
      onCellUpdate,
      isEditing,
      editCurrentSlotName,
      editCurrentRow,
      editCurrentColumn,
      editCurrentValue,
      editCurrentSlot,
      editCurrentPosition,

      // Frozen Rows
      frozenRowsStyle,

      // Misc
      CalloutTheme,
      ArrowPathIcon,
      t
    }
  }
})
</script>
