<template>
  <div class="flex">
    <Badge
      v-if="v"
      :icon="icon"
      :theme="theme"
    >
      {{ v }}
    </Badge>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import { aggregatedApprovalStatusTypeTextOverrides } from '@/static/approvalStatus'

import { AggregatedApprovalStatusType, ApprovalStatusType, getApprovalStatusBadgeTheme, getApprovalStatusIcon } from '@/models/enum/approvalStatus'

import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter'

import Badge from '@/components/Badge/Badge.vue'

export default defineComponent({
  components: { Badge },
  props: {
    value: {
      type: String as PropType<ApprovalStatusType | AggregatedApprovalStatusType | undefined | null>,
      required: false,
      default: undefined
    },
    textOverride: {
      type: Object as PropType<Record<string, string> | undefined>,
      default: undefined
    },
    aggregated: {
      type: Boolean,
      default: false
    },
    showIcon: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const theme = computed(() => getApprovalStatusBadgeTheme(props.value))

    const icon = computed(() => {
      if (!props.showIcon || !props.value) {
        return null
      }
      return getApprovalStatusIcon(props.value)
    })

    const overrides = computed((): Record<string, string> | undefined => {
      if (props.textOverride) {
        return props.textOverride
      }
      if (props.aggregated) {
        return aggregatedApprovalStatusTypeTextOverrides
      }
      return undefined
    })
    const v = computed(() => props.value ? capitalizeFirstLetter(overrides.value?.[props.value] || props.value) : '')
    return {
      theme,
      icon,
      v
    }
  }
})
</script>
