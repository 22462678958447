import { RouteRecordRaw } from 'vue-router'

import { rolesGroup } from '@/static/roles'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

export function homePublishers (context: ContextType): RouteRecordRaw[] {
  return [
    {
      path: 'publishers',
      name: `${context}.index`,
      component: () => import('./views/Index.vue'),
      meta: {
        layout: 'heightFull',
        title: i18n.global.t('labels.publisher', 100),
        feature: 'publishers'
      },
      children: [
        {
          path: 'create',
          name: `${context}.create`,
          component: () => import('./views/Create.vue'),
          meta: {
            title: i18n.global.t('publishers.create'),
            requireService: true,
            feature: 'publishers'
          }
        }
      ]
    }
  ]
}

export function publishers (context: ContextType): RouteRecordRaw[] {
  const r: RouteRecordRaw[] = [
    {
      path: '',
      name: `${context}.home`,
      component: () => import('./views/Home.vue'),
      meta: {
        title: i18n.global.t('labels.home'),
        hideTitle: true
      }
    },
    {
      path: 'settings',
      name: `${context}.settings`,
      component: () => import('./views/Settings.vue'),
      props: route => ({ publisherId: route.params.publisherId }),
      meta: {
        layout: 'base',
        title: i18n.global.t('labels.setting', 2),
        roles: rolesGroup.operation
      }
    }
  ]

  r[0].redirect = (to) => {
    return { name: `${context}.dashboards.show`, params: { publisherId: to.params.publisherId, dashboardId: '41' } }
  }

  return r
}
