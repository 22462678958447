import { Reducer, ReducerConstructor, SerializedReducer } from '.'

/**
 * Reducer for strings. Simply sets the value without any calculation. Initializes to empty string.
 */
export class StringReducer implements Reducer {
  metric?: string

  constructor (metric?: string) {
    this.metric = metric
  }

  key (key: string): string {
    return this.metric || key
  }

  add (p: Record<string, any>, key: string, v: Record<string, any>): void { p[key] = v[this.key(key)] }
  remove (p: Record<string, any>, key: string, v: Record<string, any>): void { p[key] = v[this.key(key)] } // FIXME this should take the previous value (otherwise assigning a removed value)
  init (p: Record<string, any>, key: string): void { p[key] = '' }

  static deserialize (m: SerializedReducer): ReducerConstructor {
    if (m.params === undefined) {
      throw new Error('Invalid SerializedReducer (StringReducer): missing parameters')
    }
    return StringReducerConstructor(m.params.metric)
  }
}

/**
 * @param metric the name of the metric used
 * @returns StringReducer constructor using this metric
 */
export function StringReducerConstructor (metric: string): ReducerConstructor {
  return class extends StringReducer {
    constructor () { super(metric) }

    static serialize (): SerializedReducer {
      return {
        constructor: 'StringReducer',
        params: {
          metric
        }
      }
    }
  }
}
