import { RouteRecordRaw } from 'vue-router'

import i18n from '@/plugins/i18n'

export const invites: RouteRecordRaw[] = [
  {
    path: 'join',
    name: 'invitationJoin',
    component: () => import('./views/Join.vue'),
    props: route => ({ token: route.query.token }),
    meta: {
      title: i18n.global.t('invites.joinWithInvitation')
    }
  },
  {
    path: 'create-account',
    name: 'invitationCreateAccount',
    component: () => import('./views/CreateAccount.vue'),
    props: route => ({ token: route.query.token }),
    meta: {
      title: i18n.global.t('invites.createAccount')
    }
  }
]
