import { RouteRecordRaw } from 'vue-router'

import { rolesGroup } from '@/static/roles'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

export function optins (context: ContextType): RouteRecordRaw[] {
  return [
    {
      path: 'optins',
      name: `${context}.optins.index`,
      component: () => import('./views/Index.vue'),
      meta: {
        title: i18n.global.t('labels.publisher', 2),
        feature: 'optins',
        requireService: true
      },
      children: [
        {
          path: ':publisherId/seat/:seatId/edit',
          name: `${context}.optins.edit`,
          component: () => import('./views/Edit.vue'),
          meta: {
            title: i18n.global.t('optins.edit', { name: ':publisherName' }),
            pattern: ':publisherName',
            feature: 'optins',
            roles: rolesGroup.bidderOperation
          },
          props: route => ({ publisherId: route.params.publisherId, seatId: route.params.seatId })
        }
      ]
    }
  ]
}
