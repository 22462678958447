<template>
  <div
    v-for="(field, index) in filteredFields"
    :key="`field-${index}-${field.key || 'wrapper'}`"
    :class="[ field.wrapperClass ]"
    :style="[ getColumnWidth(field.columnGrid) ]"
  >
    <component
      :is="field.renderer.component()"
      v-if="field.renderer"
      :model-value="getValue(field)"
      v-bind="field.renderer.props()"
      :readonly="isComponentReadOnly(field, canEdit)"
      :errors="field.receiveFullErrorObject ? errors : field.isSlotComponent ? getSectionErrors(field) : getErrors(field.key)"
      :class="[ field.renderer.wrapperClass ]"
      v-on="field.renderer?.events ? field.renderer?.events() : {}"
      @update:modelValue="updateValue(field.key, $event)"
    >
      <FormFields
        v-if="field.isSlotComponent && field.fields && field.fields.length"
        v-model="model"
        :fields="field.fields.filter(f => f.placement !== 'sectionHeader')"
        :errors="errors"
        :can-edit="canEdit"
      />

      <template #sectionHeader>
        <template
          v-for="(headerFields, i) in [field.fields?.filter(f => f.placement === 'sectionHeader')!].filter(f => f !== undefined)"
          :key="i"
        >
          <div class="flex flex-row justify-end pr-5 grow">
            <FormFields
              v-model="model"
              :fields="headerFields"
              :errors="errors"
              :can-edit="canEdit"
            />
          </div>
        </template>
      </template>
    </component>

    <FormFields
      v-if="!field.isSlotComponent && field.fields && field.fields.length"
      v-model="model"
      :fields="field.fields"
      :errors="errors"
      :can-edit="canEdit"
    />
  </div>
</template>

<script lang="ts">
import { ErrorObject } from '@vuelidate/core'
import get from 'lodash/get'
import set from 'lodash/set'
import { computed, defineComponent, PropType } from 'vue'

import { FieldTemplate, ColumnGrid } from '@/plugins/form'

export default defineComponent({
  name: 'FormFields',
  props: {
    fields: {
      type: Array as PropType<Array<FieldTemplate<any>>>,
      required: true
    },
    modelValue: {
      type: Object as PropType<{ [key: string]: any }>,
      required: true
    },
    errors: {
      type: Array as PropType<ErrorObject[]>,
      required: false,
      default: () => []
    },
    canEdit: {
      type: Boolean as PropType<boolean | undefined>,
      required: false,
      default: true
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const model = computed({
      get () {
        return props.modelValue
      },
      set (value: any) {
        emit('update:modelValue', value)
      }
    })

    const getColumnWidth = (columnGrid?: ColumnGrid): string => {
      return columnGrid ? `grid-column: span ${columnGrid} / span ${columnGrid};` : ''
    }

    const getErrors = (key: string | undefined) => {
      return props.errors.filter((e) => e.$propertyPath === key)
    }

    const getSectionKeys = (field: FieldTemplate<any>): string[] => {
      const keys: string[] = []
      field.fields?.forEach(f => {
        if (f.fields) {
          keys.push(...getSectionKeys(f))
        }
        if (f.key) {
          keys.push(f.key)
        }
      })
      return keys
    }

    const getSectionErrors = (field: FieldTemplate<any>): ErrorObject[] => {
      const keys: string[] = getSectionKeys(field)
      return props.errors.filter((e) => keys.includes(e.$propertyPath))
    }

    const updateValue = (key: string | undefined, value: any) => {
      if (key) {
        set(model.value, key, value)
      }
    }

    const getValue = (field: FieldTemplate<any>) => {
      if (field.key) {
        const val = get(model.value, field.key)

        if (field.transformValue) {
          return field.transformValue(val)
        }

        return val
      }
      return model.value
    }

    const isComponentReadOnly = (field: Omit<FieldTemplate<string>, 'fields'>, canEdit: boolean | undefined) => {
      const props = field.renderer?.props() as Record<string, any>

      if (typeof props?.readonly === 'boolean') {
        return props?.readonly
      }

      return typeof canEdit === 'undefined' ? false : !canEdit
    }

    const filteredFields = computed(() => props.fields.filter(f => !f.condition ? true : f.condition()))

    return {
      model,
      filteredFields,
      getValue,
      updateValue,
      getColumnWidth,
      getErrors,
      getSectionErrors,
      isComponentReadOnly
    }
  }
})
</script>
