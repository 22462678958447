<template>
  <div class="grid w-full text-sm grid-cols-vertical-form place-items-center-stretch">
    <div class="p-1 font-medium">
      {{ t('labels.dimension') }}
    </div>
    <div class="p-1">
      <Multiselect
        v-model="selectedDimension"
        name="selectedDimension"
        mode="single"
        class="min-w-36"
        :close-on-select="true"
        :options="definition.dimensions.filter(d => !(d instanceof DateDimension) && !(d instanceof DateRollupDimension) && !d.enrichment && !definition.hidden.includes(d.name)).map(d => d.name)"
        :classes="multiselectTailwindClassesCompact"
        :can-clear="false"
        :can-deselect="false"
      />
    </div>
    <div class="p-1 font-medium">
      {{ t('labels.min') }}
    </div>
    <div class="p-1">
      <input
        v-model="requiredFilterMin"
        type="number"
        min="0"
        class="h-7 px-2.5 py-0.5 text-sm border border-gray-400 text-gray-700 focus:ring-primary-500 focus:border-primary-500 rounded focus:outline-none w-full"
      >
    </div>
    <div class="p-1 font-medium">
      {{ t('labels.max') }}
    </div>
    <div class="p-1">
      <input
        v-model="requiredFilterMax"
        type="number"
        min="0"
        class="h-7 px-2.5 py-0.5 text-sm border border-gray-400 text-gray-700 focus:ring-primary-500 focus:border-primary-500 rounded focus:outline-none w-full"
      >
    </div>
    <div class="flex flex-row justify-between col-span-2 gap-1 p-1">
      <button
        type="button"
        class="bg-green-500 rounded py-0.5 px-2 text-white disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap focus:outline-none"
        :disabled="!validateRequiredFilter"
        @click="submit"
      >
        <span v-if="modelValue === undefined">
          {{ t('actions.addFilter') }}
        </span>
        <span v-else>
          {{ t('actions.updateFilter') }}
        </span>
      </button>
      <button
        v-if="modelValue !== undefined"
        type="button"
        class="bg-red-500 rounded py-0.5 px-2 text-white disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap focus:outline-none"
        @click="$emit('remove')"
      >
        {{ t('actions.remove') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, unref, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import { RequiredFilter } from '@/plugins/dashboard'
import { DataDefinition } from '@/plugins/dashboard/definition'
import { DateDimension, DateRollupDimension } from '@/plugins/dashboard/dimensions'

import Multiselect from '@/components/Form/FormMultiselect.vue'
import { multiselectTailwindClassesCompact } from '@/components/Multiselect'

export default defineComponent({
  components: {
    Multiselect
  },
  props: {
    modelValue: {
      type: Object as PropType<RequiredFilter>,
      default: undefined
    },
    definition: {
      type: Object as PropType<DataDefinition>,
      required: true
    }
  },
  emits: ['update:modelValue', 'submit', 'remove'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const selectedDimension = ref('')
    const requiredFilterMin = ref(0)
    const requiredFilterMax = ref(1)

    const validateRequiredFilter = computed((): boolean => {
      return selectedDimension.value !== '' && requiredFilterMin.value >= 0 && requiredFilterMax.value >= 0 && requiredFilterMax.value >= requiredFilterMin.value
    })

    const reset = () => {
      selectedDimension.value = ''
      requiredFilterMin.value = 0
      requiredFilterMax.value = 1
    }

    const submit = () => {
      const newValue: RequiredFilter = {
        dimension: unref(selectedDimension),
        min: unref(requiredFilterMin),
        max: unref(requiredFilterMax)
      }
      if (props.modelValue === undefined) {
        reset()
        emit('submit', newValue)
      } else {
        emit('update:modelValue', newValue)
      }
    }

    const onModelValueChanged = () => {
      if (props.modelValue !== undefined) {
        selectedDimension.value = props.modelValue.dimension
        requiredFilterMin.value = props.modelValue.min
        requiredFilterMax.value = props.modelValue.max
      } else {
        reset()
      }
    }

    onMounted(() => {
      onModelValueChanged()
    })

    return {
      // Data
      selectedDimension,
      requiredFilterMin,
      requiredFilterMax,

      // Computed
      validateRequiredFilter,

      // Methods
      submit,

      // Misc
      multiselectTailwindClassesCompact,
      DateDimension,
      DateRollupDimension,
      t
    }
  }
})
</script>
