// -- Whitelist / Blacklist actions

//
export const WHITELIST_ACTION = 'wl'
export const BLACKLIST_ACTION = 'bl'

const WHITELIST_BLACKLIST_ACTIONS = [
  WHITELIST_ACTION,
  BLACKLIST_ACTION
] as const

type WhitelistBlacklistActions = typeof WHITELIST_BLACKLIST_ACTIONS

export type WhitelistBlacklistAction = WhitelistBlacklistActions[number]

export const isWhitelistBlacklistAction = (value: string): value is WhitelistBlacklistAction => {
  return WHITELIST_BLACKLIST_ACTIONS.includes(value as WhitelistBlacklistAction)
}

// -- Media Types
//
export const MEDIA_TYPE_BANNER = 'ban'
export const MEDIA_TYPE_VIDEO_OUTSTREAM = 'vidout'
export const MEDIA_TYPE_VIDEO_INSTREAM = 'vidin'
export const MEDIA_TYPE_NATIVE = 'nat'

export const MEDIA_TYPES = [
  MEDIA_TYPE_BANNER,
  MEDIA_TYPE_VIDEO_OUTSTREAM,
  MEDIA_TYPE_VIDEO_INSTREAM,
  MEDIA_TYPE_NATIVE
] as const

type MediaTypes = typeof MEDIA_TYPES

export type MediaType = MediaTypes[number]

export const isMediaType = (value: string): value is MediaType => {
  return MEDIA_TYPES.includes(value as MediaType)
}
