import { registerTheme } from 'echarts/core'

import ECHARTS_THEME from './echarts_theme_macarons.json'

const ECHARTS_THEME_NAME = 'macarons'
const ECHARTS_BAR_THEME = {
  currentSerieColor: '#d1ebfa',
  previousSerieColor: '#58697b'
}
export { ECHARTS_THEME_NAME, ECHARTS_THEME, ECHARTS_BAR_THEME }
registerTheme(ECHARTS_THEME_NAME, ECHARTS_THEME)
