/**
 * @see https://www.iab.com/wp-content/uploads/2016/03/OpenRTB-API-Specification-Version-2-5-FINAL.pdf
 * Section: 5.21 Device Type
 */
export default [
  { id: '1', name: 'Mobile/Tablet' },
  { id: '2', name: 'Personal Computer' },
  { id: '3', name: 'Connected TV' },
  { id: '4', name: 'Phone' },
  { id: '5', name: 'Tablet' },
  { id: '6', name: 'Connected Device' },
  { id: '7', name: 'Set Top Box' }
  // { id: '8', name: 'OOH Device'} // OpenRTB 2.6 (April 2022) https://github.com/InteractiveAdvertisingBureau/AdCOM/blob/master/AdCOM%20v1.0%20FINAL.md#list--device-types-
]
