<template>
  <div
    ref="target"
    class="absolute z-50 bg-white border-2 border-white p-[3px] rounded shadow-full"
    :style="position"
  >
    <!-- <pre class="fixed top-0 left-0 text-xs bg-red-200">{{ domRect }}</pre> -->
    <slot
      v-if="open"
    />
  </div>
</template>
<script lang="ts">

import { onClickOutside } from '@vueuse/core'
import { computed, defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  components: {},
  props: {
    row: {
      type: Object,
      required: true
    },
    column: {
      type: Object,
      required: true
    },
    domRect: {
      type: Object as PropType<Pick<DOMRect, 'top' | 'left' | 'height' | 'width'> & {offsetHeight: number} | undefined>,
      required: true
    }
  },
  emits: ['update:modelValue', 'clickOut'],
  setup (props, { emit }) {
    const target = ref<HTMLElement | null>(null)

    const position = computed(() => {
      if (props.domRect) {
        const { top, left, height, width, offsetHeight } = props.domRect

        let topPos = '0'
        let leftPos = '0'
        const cWidth = `${width}px`
        const cHeight = `${height}px`

        if (top >= 0 && offsetHeight >= 0) {
          topPos = top.toFixed() + 'px'
        }

        if (left >= 0) {
          leftPos = left.toFixed() + 'px'
        }

        return `top: ${topPos}; left: ${leftPos}; width: ${cWidth}; height: ${cHeight}`
      }

      return ''
    })

    onClickOutside(target, (e: PointerEvent) => {
      if (e.defaultPrevented) {
        return
      }
      emit('clickOut')
    })

    return {
      target,
      position,
      open: true
    }
  }
})
</script>
