import { InformationCircleIcon, CheckCircleIcon, ExclamationTriangleIcon, ExclamationCircleIcon, ArrowPathIcon, TrashIcon, QuestionMarkCircleIcon, NoSymbolIcon } from '@heroicons/vue/20/solid'
import { h } from 'vue'

import i18n from '@/plugins/i18n'

import Bypass from '@/components/Icons/Bypass.vue'
import Inactive from '@/components/Icons/Inactive.vue'

export enum WebsiteStatus {
  LOADING = '000_loading',
  ERROR = '100_error',
  WARNING = '200_warning',
  INFO = '250_info',
  BYPASSED = '300_bypassed',
  VALID = '400_valid',
  READY = '450_ready',
  INACTIVE = '500_inactive',
  PENDING = '600_pending',
  ARCHIVED = '700_archived',
  NOT_APPLICABLE = '800_not_applicable',
  UNKNOWN = '900_unknown'
}

export function getStatusLabel (value: string | null): string {
  switch (value) {
    case WebsiteStatus.LOADING: return i18n.global.t('labels.loading')
    case WebsiteStatus.VALID: return i18n.global.t('labels.valid')
    case WebsiteStatus.READY: return i18n.global.t('labels.ready')
    case WebsiteStatus.INACTIVE: return i18n.global.t('labels.inactive')
    case WebsiteStatus.BYPASSED: return i18n.global.t('labels.bypassed')
    case WebsiteStatus.WARNING: return i18n.global.t('labels.warning')
    case WebsiteStatus.INFO: return i18n.global.t('labels.info')
    case WebsiteStatus.ERROR: return i18n.global.t('labels.error')
    case WebsiteStatus.PENDING: return i18n.global.t('labels.pending')
    case WebsiteStatus.ARCHIVED: return i18n.global.t('labels.archived')
    case WebsiteStatus.NOT_APPLICABLE: return i18n.global.t('labels.notApplicable')
    case WebsiteStatus.UNKNOWN: return i18n.global.t('labels.unknown')
    default: return ''
  }
}

export function getStatusIcon (value: string | null): any {
  switch (value) {
    case WebsiteStatus.LOADING: return ArrowPathIcon
    case WebsiteStatus.VALID: return CheckCircleIcon
    case WebsiteStatus.READY: return CheckCircleIcon
    case WebsiteStatus.INACTIVE: return Inactive
    case WebsiteStatus.BYPASSED: return Bypass
    case WebsiteStatus.WARNING: return ExclamationTriangleIcon
    case WebsiteStatus.INFO: return InformationCircleIcon
    case WebsiteStatus.ERROR: return ExclamationCircleIcon
    case WebsiteStatus.PENDING: return ArrowPathIcon
    case WebsiteStatus.ARCHIVED: return TrashIcon
    case WebsiteStatus.NOT_APPLICABLE: return NoSymbolIcon
    case WebsiteStatus.UNKNOWN: return QuestionMarkCircleIcon
    default: return h('span')
  }
}

export function getStatusIconColor (value: string | null): string {
  switch (value) {
    case WebsiteStatus.LOADING: return 'text-blue-500'
    case WebsiteStatus.VALID: return 'text-green-600'
    case WebsiteStatus.READY: return 'text-green-600'
    case WebsiteStatus.INACTIVE: return 'text-slate-500'
    case WebsiteStatus.BYPASSED: return 'text-primary-500'
    case WebsiteStatus.WARNING: return 'text-amber-600'
    case WebsiteStatus.INFO: return 'text-primary-500'
    case WebsiteStatus.ERROR: return 'text-red-600'
    case WebsiteStatus.PENDING: return 'text-slate-500'
    case WebsiteStatus.ARCHIVED: return 'text-slate-500'
    case WebsiteStatus.NOT_APPLICABLE: return 'text-slate-500'
    case WebsiteStatus.UNKNOWN: return 'text-slate-500'
    default: return ''
  }
}

export const websiteStatus = [
  { id: WebsiteStatus.VALID, name: i18n.global.t('labels.valid') },
  { id: WebsiteStatus.READY, name: i18n.global.t('labels.ready') },
  { id: WebsiteStatus.INACTIVE, name: i18n.global.t('labels.inactive') },
  { id: WebsiteStatus.BYPASSED, name: i18n.global.t('labels.bypassed') },
  { id: WebsiteStatus.WARNING, name: i18n.global.t('labels.warning') },
  { id: WebsiteStatus.INFO, name: i18n.global.t('labels.info') },
  { id: WebsiteStatus.ERROR, name: i18n.global.t('labels.error') },
  { id: WebsiteStatus.PENDING, name: i18n.global.t('labels.pending') },
  { id: WebsiteStatus.ARCHIVED, name: i18n.global.t('labels.archived') },
  { id: WebsiteStatus.NOT_APPLICABLE, name: i18n.global.t('labels.notApplicable') },
  { id: WebsiteStatus.UNKNOWN, name: i18n.global.t('labels.unknown') }
]
