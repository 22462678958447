import { ComparisonGroup } from '@/plugins/dashboard/source'
import i18n from '@/plugins/i18n'

export function createComparisonGroup (name: string): ComparisonGroup {
  return {
    name,
    label: `${i18n.global.t('labels.group')} ${name + (name === 'A' ? ' (Reference)' : '')}`,
    filters: {
    }
  }
}

export function getComparisonGroupColor (value: string | null): string {
  switch (value) {
    case 'A': return 'blue'
    case 'B': return 'amber'
    case 'C': return 'red'
    default: return 'slate'
  }
}
