import { NumberReducer, Reducer, ReducerConstructor, SerializedReducer } from '.'

/**
 * Number reducer calculating an average weighted with the given factor and calculated based on
 * the two given metrics.
 *
 * Calculates: (sum(metric1) * factor) / sum(metric2)
 */
export class WeightedAverageReducer extends NumberReducer implements Reducer {
  metric1: string
  metric2: string
  factor: number
  sum1: Map<Record<string, any>, number>
  sum2: Map<Record<string, any>, number>

  constructor (metric1: string, metric2: string, factor: number) {
    super()
    this.metric1 = metric1
    this.metric2 = metric2
    this.factor = factor
    this.sum1 = new Map()
    this.sum2 = new Map()
  }

  add (p: Record<string, any>, key: string, v: Record<string, any>): void {
    const sum1 = this.sum1.get(p)! + v[this.metric1]
    const sum2 = this.sum2.get(p)! + v[this.metric2]
    this.sum1.set(p, sum1)
    this.sum2.set(p, sum2)
    p[key] = sum2 ? (this.factor * sum1) / sum2 : 0
  }

  remove (p: Record<string, any>, key: string, v: Record<string, any>): void {
    const sum1 = this.sum1.get(p)! - v[this.metric1]
    const sum2 = this.sum2.get(p)! - v[this.metric2]
    this.sum1.set(p, sum1)
    this.sum2.set(p, sum2)
    p[key] = sum2 ? (this.factor * sum1) / sum2 : 0
  }

  init (p: Record<string, any>, key: string): void {
    super.init(p, key)
    this.sum1.set(p, 0)
    this.sum2.set(p, 0)
  }

  static deserialize (m: SerializedReducer): ReducerConstructor {
    if (m.params === undefined ||
      m.params.metric1 === undefined || typeof m.params.metric1 !== 'string' ||
      m.params.metric2 === undefined || typeof m.params.metric2 !== 'string' ||
      m.params.factor === undefined || typeof m.params.factor !== 'number'
    ) {
      throw new Error('Invalid SerializedReducer (WeightedAverageReducer): missing parameters')
    }
    return WeightedAverageReducerConstructor(m.params.metric1, m.params.metric2, m.params.factor)
  }
}

/**
 * @param metric1 the name of the first metric
 * @param metric2 the name of the second metric
 * @param factor the weight factor
 * @returns WeightedAverageReducer using the given metrics and factor
 */
export function WeightedAverageReducerConstructor (metric1: string, metric2: string, factor: number): ReducerConstructor {
  return class extends WeightedAverageReducer {
    constructor () { super(metric1, metric2, factor) }

    static serialize (): SerializedReducer {
      return {
        constructor: 'WeightedAverageReducer',
        params: {
          metric1,
          metric2,
          factor
        }
      }
    }
  }
}
