<template #name="{ value }">
  <div>
    <div class="min-w-0 flex-1 flex items-center">
      <div class="flex-shrink-0">
        <span class="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500">
          <span class="font-medium leading-none text-white">{{ value[0].toUpperCase() }}</span>
        </span>
      </div>
      <div class="truncate min-w-0 px-4 md:gap-4">
        <span class="text-sm font-medium text-primary-600">
          {{ value }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: String,
      required: true
    }
  }
})
</script>
