<template>
  <Popper
    :transition-props="{
      'enterActiveClass': 'transition-opacity duration-150 ease-out',
      'enterFromClass': 'opacity-0',
      'enterToClass': 'opacity-100',
      'leaveActiveClass': 'transition-opacity duration-150 ease-in',
      'leaveFromClass': 'opacity-100',
      'leaveToClass': 'opacity-0'
    }"
    :reference-props="{
      'class': 'p-2 relative cursor-pointer select-none text-text-primary hover:text-primary-500'
    }"
    :popper-props="{
      'class': 'absolute bg-white border border-gray-border rounded-md z-30 max-h-96 overflow-hidden flex flex-col max-w-full'
    }"
    trigger="click-to-toggle"
    placement="bottom-end"
    :teleport-props="{ to: 'body' }"
  >
    <template #reference>
      <TableCellsIcon class="h-4 w-4" />
    </template>

    <template #default>
      <div class="flex items-center gap-2 p-5 pb-2">
        <TableCellsIcon class="h-4 w-4 text-text-primary" />
        <span class="text-sm font-semibold">{{ t('labels.property', 100) }}</span>
      </div>
      <draggable
        ref="propertiesList"
        v-model="dColumns"
        :class="[
          'flex-1 flex flex-col overflow-auto p-5 pt-1 border-t',
          scrolled ? 'border-gray-border' : 'border-transparent'
        ]"
        item-key="field"
        @scroll.passive="onScroll()"
      >
        <template #item="{element}">
          <div
            class="min-w-56 py-2 flex flex-row items-center justify-between text-sm cursor-pointer"
          >
            <div class="flex flex-row items-center space-x-1">
              <span class="flex items-center">
                <EllipsisVerticalIcon class="w-3.5 h-3.5 -ml-1 -mr-1 text-gray-600" />
                <EllipsisVerticalIcon class="w-3.5 h-3.5 -ml-1 text-gray-600" />
              </span>
              <div class="truncate pr-3">
                {{ element.name }}
              </div>
            </div>

            <FormToggle
              v-model="element.isVisible"
              name="is-visible"
              label=""
              size="sm"
              truthy-color="primary"
            />
          </div>
        </template>
      </draggable>
    </template>
  </Popper>
</template>

<script lang="ts">
import { TableCellsIcon, EllipsisVerticalIcon } from '@heroicons/vue/24/solid'
import throttle from 'lodash/throttle'
import { defineComponent, PropType, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import draggable from 'vuedraggable'

import { Column } from '@/plugins/datatable/datatable.d'

import Popper from '@/components/Tooltip/Popper.vue'

import FormToggle from '../Form/FormToggle.vue'

export default defineComponent({
  components: {
    Popper,
    draggable,
    TableCellsIcon,
    EllipsisVerticalIcon,
    FormToggle
  },
  props: {
    columns: {
      type: Array as PropType<Column[]>,
      required: true
    }
  },
  emits: ['update:columns'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const dColumns = computed({
      get () {
        return props.columns.filter((c) => {
          if (c.isActive !== undefined) {
            return c.isActive
          }

          return true
        })
      },
      set (columns: Column[]) {
        emit('update:columns', columns)
      }
    })

    const propertiesList = ref<any>()
    const scrolled = ref(false)

    const onScroll = throttle(() => {
      if (propertiesList.value?.targetDomElement?.scrollTop == null) {
        return false
      }
      scrolled.value = propertiesList.value?.targetDomElement?.scrollTop > 10
    }, 250)

    return {
      t,
      dColumns,
      onScroll,
      propertiesList,
      scrolled
    }
  }
})
</script>
