<template>
  <MaybeTeleport :teleport-props="enlarged ? { to: 'body' } : undefined">
    <div
      :id="id"
      class="relative h-full"
      :class="containerClass"
    >
      <div
        class="h-full"
        :class="!compact ? ['bg-white p-4 rounded-md'] : []"
      >
        <Loading
          :is-loading="!compact && loading"
          class="h-full"
          :text="''"
          loading-class="p-4 bg-gray-100 rounded-md bg-opacity-80"
        >
          <div class="flex flex-col h-full group">
            <div
              v-if="editMode"
              class="flex flex-row gap-0.5 text-xs whitespace-nowrap"
            >
              <WidgetEditor
                :value="definition"
                @update:value="$emit('json-editor-input', $event)"
              />
              <button
                :title="t('actions.duplicate')"
                class="align-middle focus:outline-none"
                @click="$emit('duplicate-clicked')"
              >
                <Square2StackIcon class="w-4 h-4" />
              </button>
              <button
                :title="t('actions.delete')"
                class="focus:outline-none"
                @click="$emit('remove-clicked')"
              >
                <TrashIcon class="w-4 h-4" />
              </button>
            </div>
            <div
              class="flex flex-row flex-wrap justify-between"
              :class="{'pointer-events-none': editMode, 'absolute': definition.vizType === 'singleMetric' && title === '', 'top-4': editMode}"
            >
              <div
                class="flex self-center"
                :class="{'absolute': compact, 'z-10': compact, 'flex-col': controlsPosition === 'vertical' }"
              >
                <h4
                  v-if="!compact && title !== ''"
                  class="inline mr-1 text-sm font-bold text-gray-900"
                >
                  {{ title }}
                </h4>
                <div class="flex gap-0.5">
                  <slot name="before-controls" />
                  <WidgetExporter
                    v-if="hasData && showExport"
                    :definition="definition"
                    button-class="invisible inline-block align-middle cursor-pointer group-hover:visible focus:outline-none"
                    :show-only-selected-rows-input="showOnlySelectedRowsExportInput"
                    @submit="$emit('export', $event)"
                  />
                  <button
                    v-if="title !== '' && showExport && definition.vizType !== 'table' && definition.vizType !== 'comparativeTable'"
                    type="button"
                    class="invisible inline-block align-middle cursor-pointer group-hover:visible focus:outline-none"
                    :title="t('actions.saveAs', ['image'])"
                    @click="$emit('save-image')"
                  >
                    <PhotoIcon class="w-4 h-4" />
                  </button>
                  <button
                    v-if="title !== '' && showExpand && !editMode"
                    type="button"
                    class="invisible inline-block align-middle cursor-pointer group-hover:visible focus:outline-none"
                    :title="t('actions.expand')"
                    @click="$emit('expand-clicked')"
                  >
                    <ArrowsPointingOutIcon class="w-4 h-4" />
                  </button>
                  <WidgetInfo
                    v-if="showInfo"
                    :definition="definition"
                    button-class="invisible inline-block align-middle cursor-pointer group-hover:visible focus:outline-none"
                  />
                  <slot name="controls" />
                </div>
              </div>
              <div class="flex flex-row items-center max-w-full ">
                <slot name="header" />
                <div v-if="enlarged">
                  <button
                    type="button"
                    class="inline-block p-1 ml-2 align-middle rounded-full cursor-pointer hover:bg-gray-200"
                    :title="t('actions.close')"
                    @click="$emit('expand-clicked')"
                  >
                    <XMarkIcon class="w-6 h-6" />
                  </button>
                </div>
              </div>
            </div>
            <div
              class="flex-grow mt-2"
              :class="{'pointer-events-none': editMode, 'overflow-auto': (!editMode || definition.vizType === 'table' || definition.vizType === 'comparativeTable') && !compact, '!overflow-hidden': editMode || definition.vizType === 'singleMetric'}"
              @scroll="$emit('scroll', $event)"
            >
              <div
                v-if="!loading && !hasData"
                class="flex h-full text-center"
              >
                <div class="m-auto">
                  <p>{{ t('messages.noData') }}.</p>
                  <p
                    v-if="!definition.store.requiredFiltersFulfilled()"
                    class="whitespace-pre-line"
                  >
                    {{ definition.store.requiredFilters.map(f => t('dashboards.requiredFilters', {min: f.min, max: f.max, dimension: translateDBName(f.dimension)})).join('\n') }}
                  </p>
                </div>
              </div>
              <slot v-else />
            </div>
          </div>
        </Loading>
      </div>
    </div>
  </MaybeTeleport>
</template>

<script lang="ts">
import { ArrowsPointingOutIcon, PhotoIcon, Square2StackIcon, TrashIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { PropType, computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import MaybeTeleport from 'vue-use-popperjs/src/MaybeTeleport.vue'

import { WidgetDefinition, translateDBName } from '@/plugins/dashboard'

import Loading from '@/components/Loading/Loading.vue'

import WidgetEditor from './WidgetEditor.vue'
import WidgetExporter from './WidgetExporter.vue'
import WidgetInfo from './WidgetInfo.vue'

export default defineComponent({
  components: {
    Loading,
    WidgetEditor,
    WidgetInfo,
    WidgetExporter,
    PhotoIcon,
    ArrowsPointingOutIcon,
    XMarkIcon,
    Square2StackIcon,
    TrashIcon,
    MaybeTeleport
  },
  props: {
    id: {
      type: String,
      default: () => undefined
    },
    class: {
      type: String,
      default: () => undefined
    },
    loading: {
      type: Boolean,
      required: true
    },
    hasData: {
      type: Boolean,
      default: () => true
    },
    compact: {
      type: Boolean,
      default: () => false
    },
    enlarged: {
      type: Boolean,
      default: () => false
    },
    showExport: {
      type: Boolean,
      default: () => true
    },
    showExpand: {
      type: Boolean,
      default: () => true
    },
    showInfo: {
      type: Boolean,
      default: () => true
    },
    showOnlySelectedRowsExportInput: {
      type: Boolean,
      default: () => false
    },
    title: {
      type: String,
      default: ''
    },
    definition: {
      type: Object as PropType<WidgetDefinition>,
      required: true
    },
    editMode: {
      type: Boolean,
      default: false
    },
    controlsPosition: {
      type: String as PropType<'vertical' | 'horizontal'>,
      default: 'vertical'
    }
  },
  emits: ['json-editor-input', 'remove-clicked', 'export', 'expand-clicked', 'save-image', 'scroll', 'duplicate-clicked'],
  setup (props) {
    const { t } = useI18n()

    const containerClass = computed(() => {
      return {
        '!fixed !translate-y-0 !inset-0 !w-screen !h-screen z-40 !rounded-none !bg-gray-600 !bg-opacity-70': props.enlarged,
        [props.class || '']: true,
        'p-2': !props.compact
      }
    })

    return {
      translateDBName,
      containerClass,
      t
    }
  }
})
</script>
