<template>
  <WidgetShell
    :loading="isLoading"
    :definition="definition"
    :edit-mode="editMode"
    :has-data="hasData"
    :title="definition.title"
    controls-position="horizontal"
    :show-export="false"
    :show-expand="false"
    @json-editor-input="onJSONEditorInput"
    @remove-clicked="$emit('remove-clicked')"
  >
    <div
      v-if="!isLoading"
      class="flex flex-row items-center justify-between h-full gap-2"
    >
      <div class="relative flex flex-col h-full">
        <div
          class="flex items-center flex-grow text-3xl lining-nums"
        >
          <span class="font-light whitespace-nowrap">{{ segment !== undefined ? definition.metrics.find(m => m.name === currentMetric)!.formatter(segment) : '' }}</span>
        </div>
        <div
          class="bottom-0 text-xl text-bold lining-nums"
        >
          <span
            v-if="comparison !== undefined"
            class="rounded-md text-xs py-0.5 px-1 whitespace-nowrap"
            :class="comparisonBadgeClass"
          >
            <ChevronRightIcon
              v-if="Math.round(Math.abs(comparison)*100) == 0"
              class="inline-block w-3 h-3"
            />
            <ChevronUpIcon
              v-else-if="comparison > 0"
              class="inline-block w-3 h-3"
            />
            <ChevronDownIcon
              v-else
              class="inline-block w-3 h-3"
            />
            {{ COMPACT_PERCENT(Math.abs(comparison)) }}
          </span>
        </div>
      </div>
      <div
        v-if="hasBarChart"
        class="w-6/12 h-full max-h-16"
      >
        <VerticalBarChart
          ref="line"
          :definition="lineDefinition"
          :query-builder="queryBuilder"
          :filters="filters"
          :compact="true"
        />
      </div>
    </div>
  </WidgetShell>
</template>

<script lang="ts">
import { ChevronRightIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/vue/24/outline'
import { defineComponent, watch, ref, WatchStopHandle, onMounted, onBeforeUnmount, computed } from 'vue'

import { METRIC_FORMATTERS, relativeComparison, StoreState, WidgetDefinition } from '@/plugins/dashboard'
import { DateDimension, DateRollupDimension } from '@/plugins/dashboard/dimensions'

import VerticalBarChart from './VerticalBarChart.vue'
import { WIDGET_PROPS } from './Widget'
import WidgetShell from './WidgetShell.vue'

export default defineComponent({
  components: {
    WidgetShell,
    VerticalBarChart,
    ChevronRightIcon,
    ChevronUpIcon,
    ChevronDownIcon
  },
  props: {
    ...WIDGET_PROPS,
    defaultMetric: {
      type: String,
      required: true
    }
  },
  emits: ['remove-clicked', 'definition-changed'],
  setup (props, { emit }) {
    let stopWatch: WatchStopHandle
    let stopWatchStoreState: WatchStopHandle
    let stopWatchComparison: WatchStopHandle
    let stopWatchComparisonStoreState: WatchStopHandle
    const loading = ref(false)
    const currentMetric = ref(props.defaultMetric.toString())
    const segment = ref(undefined as any | undefined)
    const comparisonSegment = ref(undefined as any | undefined)
    const line = ref(null as any)

    const hasData = computed(() => segment.value !== undefined)

    const mounted = () => {
      if (props.definition.store.state.state === StoreState.READY) {
        props.definition.store.addMetric(props.definition.metrics.find(m => m.name === currentMetric.value)!, props.definition.uid.toString())
      }
      if (!props.definition.disableComparison && props.definition.store.comparisonStore && props.definition.store.comparisonStore.state.state === StoreState.READY) {
        props.definition.store.comparisonStore.addMetric(props.definition.metrics.find(m => m.name === currentMetric.value)!, props.definition.uid.toString())
      }
    }

    const beforeUnmount = () => {
      props.definition.metrics.forEach(m => props.definition.store.disposeMetric(m.name, props.definition.uid.toString()))
      if (!props.definition.disableComparison && props.definition.store.comparisonStore) {
        props.definition.metrics.forEach(m => props.definition.store.comparisonStore!.disposeMetric(m.name, props.definition.uid.toString()))
      }
    }

    const onCacheUpdated = () => {
      if (props.definition.store.metricsCache[currentMetric.value] === undefined) {
        segment.value = undefined
        if (props.definition.store.state.state === StoreState.READY) {
          loading.value = true
          props.definition.store.addMetric(props.definition.metrics.find(m => m.name === currentMetric.value)!, props.definition.uid.toString())
        }
      } else {
        if (segment.value !== undefined && segment.value === props.definition.store.metricsCache[currentMetric.value][props.definition.uid]) {
          return
        }
        segment.value = props.definition.store.metricsCache[currentMetric.value][props.definition.uid]
        loading.value = false
      }
    }

    const onComparisonCacheUpdated = () => {
      if (props.definition.store.comparisonStore!.metricsCache[currentMetric.value] === undefined) {
        comparisonSegment.value = undefined
        if (props.definition.store.comparisonStore!.state.state === StoreState.READY) {
          props.definition.store.comparisonStore!.addMetric(props.definition.metrics.find(m => m.name === currentMetric.value)!, props.definition.uid.toString())
        }
      } else {
        if (comparisonSegment.value !== undefined && comparisonSegment.value === props.definition.store.comparisonStore!.metricsCache[currentMetric.value][props.definition.uid]) {
          return
        }
        comparisonSegment.value = props.definition.store.comparisonStore!.metricsCache[currentMetric.value][props.definition.uid]
      }
    }

    const init = () => {
      currentMetric.value = props.defaultMetric.toString()
      stopWatchStoreState = watch(
        () => props.definition.store.state.state,
        state => {
          if (state === StoreState.READY) {
            loading.value = true
            props.definition.store.addMetric(props.definition.metrics.find(m => m.name === currentMetric.value)!, props.definition.uid.toString())
          } else if (state === StoreState.LOADING) {
            segment.value = undefined
          }
        }
      )

      stopWatch = watch(
        () => props.definition.store.metricsCache[currentMetric.value],
        onCacheUpdated,
        { deep: true }
      )

      if (!props.definition.disableComparison && props.definition.store.comparisonStore) {
        stopWatchComparisonStoreState = watch(
          () => props.definition.store.comparisonStore!.state.state,
          state => {
            if (state === StoreState.READY) {
              props.definition.store.comparisonStore!.addMetric(props.definition.metrics.find(m => m.name === currentMetric.value)!, props.definition.uid.toString())
            } else if (state === StoreState.LOADING) {
              comparisonSegment.value = undefined
            }
          }
        )

        stopWatchComparison = watch(
          () => props.definition.store.comparisonStore!.metricsCache[currentMetric.value],
          onComparisonCacheUpdated,
          { deep: true }
        )
      }
    }

    watch(
      () => currentMetric.value,
      v => {
        stopWatch()
        stopWatch = watch(
          () => props.definition.store.metricsCache[currentMetric.value],
          onCacheUpdated,
          { deep: true }
        )
        segment.value = undefined
        if (props.definition.store.metricsCache[v]) {
          segment.value = props.definition.store.metricsCache[v][props.definition.uid]
        }
        loading.value = false
        if (segment.value === undefined) {
          loading.value = true
          props.definition.store.addMetric(props.definition.metrics.find(m => m.name === currentMetric.value)!, props.definition.uid.toString())
        }
        if (!props.definition.disableComparison && props.definition.store.comparisonStore && props.definition.store.comparisonStore!.state.state === StoreState.READY) {
          stopWatchComparison()
          stopWatchComparison = watch(
            () => props.definition.store.comparisonStore!.metricsCache[currentMetric.value],
            onComparisonCacheUpdated,
            { deep: true }
          )
          comparisonSegment.value = undefined
          if (props.definition.store.comparisonStore.metricsCache[v]) {
            comparisonSegment.value = props.definition.store.comparisonStore.metricsCache[v][props.definition.uid]
          }
          if (comparisonSegment.value === undefined) {
            props.definition.store.comparisonStore.addMetric(props.definition.metrics.find(m => m.name === currentMetric.value)!, props.definition.uid.toString())
          }
        }
        if (line.value !== null) {
          line.value.setMetric(currentMetric.value)
        }
      }
    )

    onMounted(mounted)
    onBeforeUnmount(beforeUnmount)
    init()

    watch(
      () => props.definition,
      () => {
        stopWatch()
        stopWatchStoreState()
        if (props.definition.store.comparisonStore) {
          stopWatchComparison()
          stopWatchComparisonStoreState()
        }
        beforeUnmount()
        segment.value = undefined
        comparisonSegment.value = undefined
        init()
        mounted()
      }
    )

    const comparison = computed(() => {
      return relativeComparison(segment.value, comparisonSegment.value)
    })

    const hasBarChart = computed((): boolean => {
      return props.definition.dimensions.length > 0 && (props.definition.dimensions[0] instanceof DateDimension || props.definition.dimensions[0] instanceof DateRollupDimension)
    })

    const isWaitingForBothData = computed(() => {
      if (props.definition.store.comparisonStore === undefined || props.definition.disableComparison || !props.definition.store.comparisonStore.requiredFiltersFulfilled() || !props.definition.store.comparisonStore.getDateRange()) {
        return false
      }
      const comparisonLoading = !comparisonSegment.value && props.definition.store.comparisonStore.state.state !== StoreState.WORKER_READY && props.definition.store.comparisonStore.state.state !== StoreState.READY
      const mainDataLoading = !segment.value && props.definition.store.state.state !== StoreState.WORKER_READY && props.definition.store.state.state !== StoreState.READY
      return mainDataLoading || comparisonLoading
    })

    const isLoading = computed((): boolean => {
      const localLoading = props.definition.store.state.state === StoreState.PREPARING || props.definition.store.state.state === StoreState.LOADING || props.definition.store.shouldRefresh || loading.value || isWaitingForBothData.value
      if (line.value) {
        return localLoading || line.value.loading
      }
      return localLoading
    })

    const comparisonBadgeClass = computed((): string[] => {
      return (comparison.value || 0) >= 0 ? ['bg-green-350', 'text-green-850', 'font-semibold'] : ['bg-red-350', 'text-red-850', 'font-semibold']
    })

    const lineDefinition = computed((): WidgetDefinition => {
      const def = Object.assign({}, props.definition)
      def.uid += 'line'
      def.vizType = 'line'
      return def
    })

    const onJSONEditorInput = (newDefinition: WidgetDefinition) => {
      emit('definition-changed', newDefinition)
    }

    return {
      // refs
      line,

      // Data
      segment,
      currentMetric,

      // Computed
      hasData,
      comparison,
      hasBarChart,
      isLoading,
      comparisonBadgeClass,
      lineDefinition,

      // Methods
      onJSONEditorInput,

      // Misc
      StoreState,
      COMPACT_PERCENT: METRIC_FORMATTERS.COMPACT_PERCENT
    }
  }
})
</script>
