<template>
  <router-view />

  <div
    aria-live="assertive"
    class="fixed inset-0 z-50 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start top-16"
  >
    <div class="flex flex-col items-center w-full space-y-4 sm:items-end">
      <transition-group
        enter-active-class="transition duration-300 ease-out transform"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition duration-300 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        appear
      >
        <component
          :is="notificationTypes[notification.type]"
          v-for="(notification, index) in notifications"
          :key="`${notification}-${index}`"
          :notification="notification"
        />
      </transition-group>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { notificationTypes } from '@/types/notifications'

import { useAppStore } from '@/store/app.store'
import { useNotificationsStore } from '@/store/notifications.store'

export default defineComponent({
  name: 'App',
  setup () {
    const { notifications } = useNotificationsStore()
    const appStore = useAppStore()

    appStore.generateClientId()

    return {
      notifications,
      notificationTypes
    }
  }
})
</script>
