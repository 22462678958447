<template>
  <svg
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.82087 4.95254C7.74792 4.14108 9.25208 4.14108 10.1791 4.95254C11.107 5.764 11.107 7.07975 10.1791 7.89121C10.0184 8.03292 9.83871 8.14929 9.64871 8.24113C9.05892 8.52692 8.50079 9.032 8.50079 9.6875V10.2813M15.625 8.5C15.625 9.43567 15.4407 10.3622 15.0826 11.2266C14.7246 12.0911 14.1998 12.8765 13.5381 13.5381C12.8765 14.1998 12.0911 14.7246 11.2266 15.0826C10.3622 15.4407 9.43567 15.625 8.5 15.625C7.56433 15.625 6.63783 15.4407 5.77338 15.0826C4.90894 14.7246 4.12348 14.1998 3.46186 13.5381C2.80025 12.8765 2.27542 12.0911 1.91736 11.2266C1.55929 10.3622 1.375 9.43567 1.375 8.5C1.375 6.61033 2.12567 4.79806 3.46186 3.46186C4.79806 2.12567 6.61033 1.375 8.5 1.375C10.3897 1.375 12.2019 2.12567 13.5381 3.46186C14.8743 4.79806 15.625 6.61033 15.625 8.5ZM8.5 12.6563H8.50633V12.6626H8.5V12.6563Z"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
