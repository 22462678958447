import { Mediatype } from './enum/mediatype'
import { PackageRule } from './packageRule'

export interface BidderRepackaging {
  id: number
  bidderId: number
  repackagingId: number
  seatId: number
  mediatype: Mediatype
}

export interface Repackaging {
  id: number
  name: string
  isActive: boolean
  packageRules: PackageRule[]
}

export const RepackagingPassthrough = 'passthrough'
