import { stringify } from 'csv-stringify/browser/esm/sync'

export function csvStringify<T> (records: T[]): string {
  return stringify(records, {
    delimiter: ',',
    header: true,
    cast: {
      boolean: (val: boolean): string => {
        return val ? 'true' : 'false'
      },
      object: (val: Object): string => {
        if (Array.isArray(val)) {
          return val.join(',')
        }
        return JSON.stringify(val)
      }
    }
  })
}
