import i18n from '@/plugins/i18n'

import { DashboardType } from '@/models/enum/dashboard'

export const dashboardTypes = [
  { id: DashboardType.PUBLISHERS, name: i18n.global.t('dashboards.types.publishers') },
  { id: DashboardType.BIDDERS, name: i18n.global.t('dashboards.types.bidders') },
  { id: DashboardType.GROUPS, name: i18n.global.t('dashboards.types.groups') },
  { id: DashboardType.GROUP_PUBLISHERS, name: i18n.global.t('dashboards.types.groupPublishers') },
  { id: DashboardType.GROUP_BIDDERS, name: i18n.global.t('dashboards.types.groupBidders') }
]
