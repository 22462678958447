import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import { ref } from 'vue'

export const useAppStore = defineStore('app', () => {
  const loading = ref(false)
  const sidebarOpen = ref(true)
  const mobile = ref(false)
  const panelOpen = ref(false)

  // Indicate if the layout main-content section must fit the h-screen.
  // This option, is largely used in features/*/Index.vue, this is why the default value is `true`
  const isContentHeightFull = ref(true)

  // Client ID
  const clientId = useLocalStorage<string | null>('clientId', null)

  const generateClientId = (): void => {
    if (!clientId.value) {
      const id = uuidv4()
      clientId.value = id
    }
  }

  // Sidebar

  const openSidebar = (): void => {
    sidebarOpen.value = true
  }

  const closeSidebar = (): void => {
    sidebarOpen.value = false
  }

  const toggleSidebar = (): void => {
    sidebarOpen.value = !sidebarOpen.value
  }

  // Panel

  const openPanel = (): void => {
    panelOpen.value = true
  }

  const closePanel = (): void => {
    panelOpen.value = false
  }

  // Misc

  const setLoading = (value: boolean): void => {
    loading.value = value
  }

  const setIsContentHeightFull = (value: boolean): void => {
    isContentHeightFull.value = value
  }

  const setMobile = (value: boolean): void => {
    mobile.value = value
  }

  return {
    loading,
    sidebarOpen,
    mobile,
    panelOpen,
    isContentHeightFull,
    clientId,
    generateClientId,
    openSidebar,
    closeSidebar,
    setMobile,
    toggleSidebar,
    openPanel,
    closePanel,
    setIsContentHeightFull,
    setLoading
  }
})
