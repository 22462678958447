import { format } from 'date-fns'

import i18n from '@/plugins/i18n'

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

// Gets the offset in milliseconds between the time zone and Universal Coordinated Time (UTC)
function deriveOffsetFromUTC (timeZone: string, date?: Date | number): number {
  const targetDate = date ? new Date(date) : new Date()
  const utcOffset = targetDate.getTimezoneOffset() * 60 * 1000
  const timeZoneOffset = Intl.DateTimeFormat(undefined, { timeZone }).resolvedOptions().timeZone
  const localTime = Date.parse(targetDate.toLocaleString('en-US', { timeZone: timeZoneOffset }))
  const targetTime = Math.floor(targetDate.getTime() / 1000) * 1000
  return localTime - targetTime - utcOffset
}

const offset = deriveOffsetFromUTC(timezone, new Date())

export function displayDate (date: Date, locale: string = 'en-US'): string {
  return Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  }).format(date)
}

export function toUTC (value: Date, formatString: string = 'yyyy-MM-dd'): string {
  return format(value, formatString)
}

export function localToUTC (value: Date): Date {
  if (value === undefined || value === null) {
    return new Date(0)
  }
  if (isNaN(value?.getTime())) {
    return value
  }
  return new Date(format(value, "yyyy-MM-dd'T'HH:mm:ss.SSS") + 'Z')
}

export function dateOffsetToUTC (value: Date): Date {
  return new Date(value.getTime() + offset)
}

export function diffDatesLimit (date1: string | null, date2: string | null, limit: number): boolean {
  if (!date1 || !date2) {
    return true
  }
  const result = Math.abs((new Date(date1)).getTime() - (new Date(date2)).getTime()) / (1000 * 3600 * 24) > limit
  return result
}

export interface Interval {
  months: number
  days: number
  milliseconds: number
}
const intervalRegex = /((?<months>\d+) mon )?((?<days>\d+) day )?(?<hours>\d{2}):(?<minutes>\d{2}):(?<seconds>\d{2}).(?<microseconds>\d{6})/

export function parseInterval (value: string): Interval {
  const res = intervalRegex.exec(value)
  if (res === null || res.groups === undefined) {
    return {
      months: 0,
      days: 0,
      milliseconds: 0
    }
  }
  const { groups: { months, days, hours, minutes, seconds, microseconds } } = res

  const milliseconds = ((hours ? parseInt(hours) : 0) * 3.6e+6) +
    ((minutes ? parseInt(minutes) : 0) * 60000) +
    ((seconds ? parseInt(seconds) : 0) * 1000) +
    Math.floor((microseconds ? parseInt(microseconds) : 0) / 1000)

  return {
    months: months !== undefined ? parseInt(months) : 0,
    days: days !== undefined ? parseInt(days) : 0,
    milliseconds
  }
}

/**
 * @forceToday is used to display the word "today" instead of "n hours ago".
 */
export function fromNow (date: Date, forceToday: boolean = false): string {
  const SECOND = 1000
  const MINUTE = 60 * SECOND
  const HOUR = 60 * MINUTE
  const DAY = 24 * HOUR
  const WEEK = 7 * DAY
  const MONTH = 30 * DAY
  const YEAR = 365 * DAY
  const units = [
    { max: 30 * SECOND, divisor: 1, past1: 'justNow', pastN: 'justNow', future1: 'justNow', futureN: 'justNow' },
    { max: MINUTE, divisor: SECOND, past1: 'aSecondAgo', pastN: 'secondsAgo', future1: 'inASecond', futureN: 'inSeconds' },
    { max: HOUR, divisor: MINUTE, past1: 'aMinuteAgo', pastN: 'minutesAgo', future1: 'inAMinute', futureN: 'inMinutes' },
    { max: DAY, divisor: HOUR, today: 'today', past1: 'anHourAgo', pastN: 'hoursAgo', future1: 'inAnHour', futureN: 'inHours' },
    { max: WEEK, divisor: DAY, past1: 'yesterday', pastN: 'daysAgo', future1: 'tomorrow', futureN: 'inDays' },
    { max: 4 * WEEK, divisor: WEEK, past1: 'aWeekAgo', pastN: 'weeksAgo', future1: 'inAWeek', futureN: 'inWeeks' },
    { max: YEAR, divisor: MONTH, past1: 'aMonthAgo', pastN: 'monthsAgo', future1: 'inAMonth', futureN: 'inMonths' },
    { max: 100 * YEAR, divisor: YEAR, past1: 'aYearAgo', pastN: 'yearsAgo', future1: 'inAYear', futureN: 'inYears' },
    { max: 1000 * YEAR, divisor: 100 * YEAR, past1: 'aCenturyAgo', pastN: 'centuriesAgo', future1: 'inACentury', futureN: 'inCenturies' },
    { max: Infinity, divisor: 1000 * YEAR, past1: 'aMilleniumAgo', pastN: 'milleniaAgo', future1: 'inAMillenium', futureN: 'inMillenia' }
  ]
  const diff = Date.now() - (typeof date === 'object' ? date : new Date(date)).getTime()
  const diffAbs = Math.abs(diff)
  for (const unit of units) {
    if (diffAbs < unit.max) {
      const isFuture = diff < 0
      const x = Math.round(Math.abs(diff) / unit.divisor)
      if (x <= 1) {
        return isFuture ? i18n.global.t(`dates.${unit.future1}`) : i18n.global.t(`dates.${forceToday && unit.today ? unit.today : unit.past1}`)
      }
      return (isFuture ? i18n.global.t(`dates.${unit.futureN}`, [x]) : i18n.global.t(`dates.${forceToday && unit.today ? unit.today : unit.pastN}`, [x]))
    }
  }
  return ''
};
