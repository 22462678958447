import { deserializeDimensions, Dimension, SerializedDimension } from '../dimensions'

export declare type Constructor = StringConstructor | NumberConstructor | BooleanConstructor | DateConstructor

/**
 * Definition of a resource. Should match Cube (if used) or table schema.
 * The `metrics` part is used to convert the received data into its proper type in case the
 * transmission used only strings.
 */
export interface DataDefinition {
  dimensions: Dimension[]
  metrics: Record<string, Constructor>,
  hidden: string[],
  oneToOne: string[]
}

export type SerializedDataDefinition = {
  dimensions: SerializedDimension[]
  metrics: Record<string, string>
  hidden: string[]
  oneToOne: string[]
}

export function serializeDataDefinition (def: DataDefinition): SerializedDataDefinition {
  const metrics: Record<string, string> = {}
  for (const key in def.metrics) {
    metrics[key] = def.metrics[key].name // It works even minimized because it's a JS built-in type
  }
  return {
    dimensions: def.dimensions.map(d => d.serialize()),
    metrics,
    hidden: [...def.hidden],
    oneToOne: [...def.oneToOne]
  }
}

export function deserializeDataDefinition (def: SerializedDataDefinition): DataDefinition {
  if (def.dimensions === undefined || !Array.isArray(def.dimensions)) {
    throw new Error('Invalid SerializedDataDefinition: missing dimensions')
  }
  if (def.hidden === undefined) {
    def.hidden = []
  }
  if (!Array.isArray(def.hidden)) {
    throw new Error('Invalid SerializedDataDefinition: hidden is not an array')
  }
  if (def.oneToOne === undefined) {
    def.oneToOne = []
  }
  if (!Array.isArray(def.oneToOne)) {
    throw new Error('Invalid SerializedDataDefinition: oneToOne is not an array')
  }
  const metrics: Record<string, Constructor> = {}
  for (const key in def.metrics) {
    switch (def.metrics[key]) {
      case 'String':
        metrics[key] = String
        break
      case 'Number':
        metrics[key] = Number
        break
      case 'Boolean':
        metrics[key] = Boolean
        break
      case 'Date':
        metrics[key] = Date
        break
      default:
        throw new Error(`Invalid SerializedDataDefinition: metric type "${def.metrics[key]}" doesn't exist`)
    }
  }
  return {
    dimensions: deserializeDimensions(def.dimensions),
    metrics,
    hidden: [...def.hidden],
    oneToOne: []
  }
}
