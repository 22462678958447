import { Grouping } from 'crossfilter2'

import { toUTC } from '@/utils/dates'

import { CSVFormat, translateDBName } from '.'

export const CSV_FORMATS: Record<string, CSVFormat> = {
  CSV_US: {
    separator: ',',
    numberFormat: (n: number) => n.toString()
  },
  CSV_EU: {
    separator: ';',
    numberFormat: (n: number) => n.toString().replaceAll('.', ',')
  },
  TSV: {
    separator: '\t',
    numberFormat: (n: number) => n.toString()
  }
}

function toCSVCell (value: any, numberFormat: (n: number) => string): string {
  if (value === null || value === undefined) {
    return '"unknown"'
  }
  if (value instanceof Date) {
    return isNaN(value.getTime()) ? '-' : toUTC(value, 'yyyy-MM-dd HH:mm:ss')
  }
  switch (typeof value) {
    case 'string':
      return `"${value.replaceAll('"', '""')}"`
    case 'number':
      return numberFormat(value)
    default:
      return value.toString()
  }
}

/**
 * @param records the list of records
 * @param columns the selected columns. Expected to be in the same order as in the record.
 * @param format the CSV format
 * @returns an URI-encoded data URL containing the generated CSV
 */
export function toCSVDataURL (records: ReadonlyArray<Grouping<any, Record<string, any>>> | undefined, columns: string[], format: CSVFormat = CSV_FORMATS.CSV_US): string {
  // TODO export single metrics as well ? (TableWidget)
  let csv = ''
  if (records) {
    if (records.length) {
      csv += columns.map(c => `"${translateDBName(c)}"`).join(format.separator)
    }
    records.forEach(r => {
      csv += '\n' + Object.entries(r.value)
        .filter(e => columns.includes(e[0]))
        .map(e => toCSVCell(e[1], format.numberFormat))
        .join(format.separator)
    })
  }
  const mime = format.separator === '\t' ? 'text/tab-separated-values' : 'text/csv'
  return `data:${mime};charset=UTF-8,` + encodeURI(csv)
}

export function download (title: string, dataURL: string): void {
  const ext = getExt(dataURL)
  const fileName = (title ? title.replaceAll(' ', '_') : 'chart') + ext
  if (typeof MouseEvent === 'function') {
    const $a = document.createElement('a')
    $a.download = fileName
    $a.target = '_blank'

    // FIXME limitations with the maximum allowed length + other caveats
    // I hope there will be a clean standard solution for file downloads in the future
    $a.href = dataURL

    const evt = new MouseEvent('click', {
      view: document.defaultView,
      bubbles: true,
      cancelable: false
    })
    $a.dispatchEvent(evt)
  } else {
    throw new Error('Chart export: unsupported browser')
  }
}

function getExt (dataURL: string): string {
  if (dataURL.startsWith('data:text/csv')) {
    return '.csv'
  }
  if (dataURL.startsWith('data:text/tab-separated-values')) {
    return '.tsv'
  }
  if (dataURL.startsWith('data:image/svg+xml')) {
    return '.svg'
  }
  if (dataURL.startsWith('data:image/jpeg')) {
    return '.jpg'
  }
  if (dataURL.startsWith('data:image/png')) {
    return '.png'
  }
  if (dataURL.startsWith('data:image/webp')) {
    return '.webp'
  }
  if (dataURL.startsWith('data:image/bmp')) {
    return '.bmp'
  }

  return ''
}
