<template>
  <WidgetShell
    :loading="false"
    :definition="definition"
    :edit-mode="editMode"
    :has-data="true"
    :show-export="false"
    :show-info="false"
    @json-editor-input="$emit('definition-changed', $event)"
    @remove-clicked="$emit('remove-clicked')"
  >
    <div class="w-full h-full flex flex-col align-center justify-center">
      <span class="leading-5 text-lg font-medium text-center">{{ definition.title }}</span>
    </div>
  </WidgetShell>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { WIDGET_PROPS } from './Widget'
import WidgetShell from './WidgetShell.vue'

export default defineComponent({
  components: { WidgetShell },
  props: {
    ...WIDGET_PROPS
  },
  emits: ['remove-clicked', 'definition-changed']
})
</script>
