<template>
  <FormFields
    v-model="form"
    :fields="fields"
    :errors="errors"
    :can-edit="true"
  />
</template>

<script lang="ts">
import { ErrorObject } from '@vuelidate/core'
import { PropType, computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

import { ContextType } from '@/plugins/context/context.enum'
import { FieldTemplate } from '@/plugins/form'
import { FormInput, FormNativeSelect, FormSection, FormToggle } from '@/plugins/form/renderer'

import { useContextStore } from '@/store/context.store'

import { CreateDashboardForm } from '@/models/dashboard'
import { DashboardType } from '@/models/enum/dashboard'

import FormFields from '@/components/FormBuilder/FormFields.vue'

export default defineComponent({
  components: {
    FormFields
  },
  props: {
    modelValue: {
      type: Object as PropType<{ [key: string]: any }>,
      required: true
    },
    errors: {
      type: Array as PropType<ErrorObject[]>,
      required: false,
      default: () => []
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const form = computed({
      get () {
        return props.modelValue
      },
      set (value: any) {
        emit('update:modelValue', value)
      }
    })

    const contextStore = useContextStore()

    const { t } = useI18n()

    const fields = computed(() => {
      const section: FieldTemplate<Partial<CreateDashboardForm>> = {
        renderer: new FormSection({ title: t('labels.generalSettings'), mode: 'base', isDefaultOpen: true, wrapperClass: 'p-5 rounded-md' }),
        wrapperClass: 'space-y-2.5',
        isSlotComponent: true,
        fields: [
          {
            wrapperClass: 'gap-2.5 grid grid-cols-12',
            fields: [
              {
                key: 'name',
                columnGrid: 9,
                renderer: new FormInput({ name: 'name', mode: 'materialize', label: t('labels.name'), attr: { type: 'text' } })
              },
              {
                key: 'isPublic',
                wrapperClass: 'pt-3',
                columnGrid: 3,
                renderer: new FormToggle({ name: 'isPublic', label: t('labels.public') })
              },
              {
                key: 'dashboardType',
                columnGrid: 12,
                condition: () => contextStore.contextType === ContextType.GROUPS,
                renderer: new FormNativeSelect({
                  name: 'dashboardType',
                  label: t('labels.dashboardType'),
                  emptyValue: false,
                  options: [
                    { id: DashboardType.PUBLISHERS, name: t('labels.publisher', 2) },
                    { id: DashboardType.BIDDERS, name: t('labels.bidder', 2) },
                    { id: DashboardType.GROUPS, name: t('labels.serviceGroup', 2) },
                    { id: DashboardType.GROUP_PUBLISHERS, name: t('labels.publisherGroup', 2) },
                    { id: DashboardType.GROUP_BIDDERS, name: t('labels.bidderGroup', 2) }
                  ]
                })
              }
            ]
          }
        ]
      }
      return [section]
    })

    return {
      form,
      fields
    }
  }
})

</script>
