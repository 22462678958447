import moment from 'moment'

import { InstantReportForm } from './report'

export interface Export {
  source: string
  timezone: string
  dimensions: string[]
  metrics: string[]
  dateFrom: string
  dateTo: string
}

export interface ExportResponse {
  url: string
}

export const transformToExport = (form: InstantReportForm): Export => {
  return {
    source: form.source,
    timezone: form.timezone,
    dimensions: form.dimensions,
    metrics: form.metrics,
    dateFrom: moment(form.dateRange.start).format('YYYY-MM-DDTHH:mm:ss'),
    dateTo: moment(form.dateRange.end).format('YYYY-MM-DDTHH:mm:ss')
  }
}
