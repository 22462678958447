import { NumberReducer, Reducer, ReducerConstructor, SerializedReducer } from '.'

/**
 * Counts the unique occurrences of the given metric. It is recommended to use this reducer
 * on another metric reduced using StringReducer or a dimension.
 */
export class UniqueReducer extends NumberReducer implements Reducer {
  metric: string
  uniqueCount: Map<Record<string, any>, Record<any, number>>

  constructor (metric: string) {
    super()
    this.metric = metric
    this.uniqueCount = new Map()
  }

  add (p: Record<string, any>, key: string, v: Record<string, any>): void {
    let set = this.uniqueCount.get(p)
    if (set === undefined) {
      set = {}
      this.uniqueCount.set(p, set)
    }
    if (set[v[this.metric]] === undefined) {
      set[v[this.metric]] = 0
    }
    set[v[this.metric]]++
  }

  remove (p: Record<string, any>, key: string, v: Record<string, any>): void {
    const set = this.uniqueCount.get(p)!
    if (set[v[this.metric]] !== undefined) {
      set[v[this.metric]]--
      if (set[v[this.metric]] === 0) {
        delete set[v[this.metric]]
      }
    }
  }

  post (p: Record<string, any>, key: string): void {
    const count = this.uniqueCount.get(p)
    if (count) {
      p[key] = Object.keys(count).length
    }
  }

  static deserialize (m: SerializedReducer): ReducerConstructor {
    if (m.params === undefined || m.params.metric === undefined || typeof m.params.metric !== 'string') {
      throw new Error('Invalid SerializedReducer (UniqueReducer): missing parameters')
    }
    return UniqueReducerConstructor(m.params.metric)
  }
}

/**
 * @param metric the name of the metric used
 * @returns UniqueReducer constructor using this metric
 */
export function UniqueReducerConstructor (metric: string): ReducerConstructor {
  return class extends UniqueReducer {
    constructor () { super(metric) }

    static serialize (): SerializedReducer {
      return {
        constructor: 'UniqueReducer',
        params: {
          metric
        }
      }
    }
  }
}
