<template>
  <div class="absolute bottom-0 w-full h-1 bg-gray-400">
    <div
      class="h-1 origin-left animate-shrink-horizontal"
      :class="loadingClass"
      :style="{'animation-duration': `${notification.duration || 10000}ms`}"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType } from 'vue'

import { Notification } from '@/types/notifications'

import { useNotificationsStore } from '@/store/notifications.store'

export default defineComponent({
  props: {
    notification: {
      type: Object as PropType<Notification>,
      required: true
    },
    loadingClass: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const notificationsStore = useNotificationsStore()

    onMounted(() => {
      setTimeout(() => {
        notificationsStore.remove(props.notification)
      }, props.notification.duration || 10000)
    })

    return {}
  }
})
</script>
