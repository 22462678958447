import { AxiosResponse } from 'axios'

import { FieldType } from '@/plugins/datatable/datatable.d'

import { FilterResponse } from '@/services/datatableFilters'

import { CondFilter, CondOperator } from './operators'

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export const VIRTUAL_COLUMN_VALIDATION = '_validation'

export interface Sort {
  sortOrder: SortOrder
  field: string
  caseInsensitive?: boolean
}

export interface ColumnFilter {
  fieldKey: string
  fieldName: string
  fieldType: FieldType
  options?: () => Array<{ id: string, name: string }>
  dateOptions?: {
    maxDate: Date | 'no'
  }
  asyncOptions?: (fieldKey: string, queryParams :Object) => Promise<AxiosResponse<FilterResponse[]>>
}

export interface Filter {
  fieldKey: string
  fieldType: FieldType
  operator: CondOperator
  value?: any
}

export interface Filters {
  cond: CondFilter
  filters: Filter[]
}

export interface ColumnProperty {
  field: string
  isVisible: boolean
}
