import { RouteRecordRaw } from 'vue-router'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

export function seatsBoosterRules (): RouteRecordRaw[] {
  const context = ContextType.GROUPS
  return [
    {
      path: 'seats-boosters',
      name: `${context}.seatsBoosterRules.index`,
      component: () => import('./views/Index.vue'),
      meta: {
        title: i18n.global.t('labels.seatsBoosterRule', 2),
        feature: 'seats_booster_rules',
        requireService: true
      },
      children: [
        {
          path: 'create',
          name: `${context}.seatsBoosterRules.create`,
          component: () => import('./views/Create.vue'),
          meta: {
            title: i18n.global.t('actions.create', [i18n.global.t('app.seatsBoosterRule')]),
            feature: 'seats_booster_rules'
          }
        },
        {
          path: ':seatsBoosterRuleId/edit',
          name: `${context}.seatsBoosterRules.edit`,
          component: () => import('./views/Edit.vue'),
          meta: {
            title: i18n.global.t('actions.edit', [':seatsBoosterRuleName']),
            pattern: ':seatsBoosterRuleName',
            feature: 'seats_booster_rules'
          },
          props: route => ({ seatsBoosterRuleId: route.params.seatsBoosterRuleId })
        }
      ]
    }
  ]
}
