import { Dimension, GenericDimension, DateDimension, DateRollupDimension, CompositeDimension, ComparisonGroupDimension, SerializedDimension } from '.'

export const DIMENSION_CONSTRUCTORS: Record<string, any> = {
  GenericDimension,
  DateDimension,
  DateRollupDimension,
  CompositeDimension,
  ComparisonGroupDimension
}

export function deserializeDimension (def: SerializedDimension): Dimension {
  const transformer = DIMENSION_CONSTRUCTORS[def.constructor]
  if (transformer) {
    return transformer.deserialize(def)
  }
  throw new Error(`Invalid SerializedDimension: constructor "${def.constructor}" doesn't exist`)
}

export function deserializeDimensions (def: SerializedDimension[]): Dimension[] {
  return def.map(d => deserializeDimension(d))
}
