import { AxiosResponse } from 'axios'

import { Paginate } from '@/types/paginate'
import { ObjectLike } from '@/types/utils'

import { ServerErrors } from '@/plugins/form/serverErrors'

import { CreatePublisherForm, Publisher, UpdatePublisherForm } from '@/models/publisher'
import { PublisherNotification } from '@/models/publisherNotification'
import { SelectResponse } from '@/models/select'

import api from '@/api'

import { fetchForSelectFilters, FilterResponse } from './datatableFilters'

export async function fetchPublishers (queryParams?: Object): Promise<AxiosResponse<Paginate<Publisher[]>>> {
  return api.get<Paginate<Publisher[]>>('/publishers', { params: queryParams })
}

export async function fetchPublishersForSelect (queryParams?: ObjectLike): Promise<AxiosResponse<SelectResponse[]>> {
  return api.get<SelectResponse[]>('/publishers?format=select', { params: queryParams })
}

export async function fetchPublisherById (id: string): Promise<AxiosResponse<Publisher>> {
  return api.get<Publisher>(`/publishers/${id}`)
}

export async function fetchPublisherNotifications (publisherId: number): Promise<AxiosResponse<PublisherNotification>> {
  return api.get<Publisher>(`/publishers/${publisherId}/notifications`)
}

export async function createPublisher (data: CreatePublisherForm): Promise<AxiosResponse<Publisher>> {
  return api.post<Publisher>('/publishers', data)
}

export async function updatePublisher (id: number, data: UpdatePublisherForm): Promise<AxiosResponse<Publisher>> {
  return api.patch<Publisher>(`/publishers/${id}`, data)
}

export async function fetchPublishersForSelectFilters (field: string, query: object): Promise<AxiosResponse<FilterResponse[]>> {
  return fetchForSelectFilters('/publishers', field, query, false)
}

export async function refreshSellersjson (publisherId: string): Promise<AxiosResponse<ServerErrors>> {
  return api.patch<ServerErrors>(`/publishers/${publisherId}/sellersjson`, {}, {
    requireContext: false
  })
}

export async function supplyChainReport (): Promise<AxiosResponse<any>> {
  return api.get('/supply-chain-report', { requireContext: true, responseType: 'blob' })
}
