import { RouteLocationNormalized } from 'vue-router'

import { useContextStore } from '@/store/context.store'
import { useContextNotificationsStore } from '@/store/contextNotifications.store'
import { ViewsType, useViewStore } from '@/store/view.store'

import { fetchBidderById } from '@/services/bidders'
import { fetchGroupById } from '@/services/groups'
import { fetchPublisherById } from '@/services/publishers'

/**
 * hasPublisher
 * Check if the user can access to the publisher specified by the id in the route
 * @param to RouteLocationNormalized
 * @returns boolean
 */
export const hasPublisher = async (to: RouteLocationNormalized): Promise<boolean> => {
  const contextStore = useContextStore()
  const viewStore = useViewStore()
  const contextNotificationsStore = useContextNotificationsStore()

  if (to.params.publisherId) {
    try {
      contextNotificationsStore.clearInterval()

      const response = await fetchPublisherById(to.params.publisherId as string)

      if (response.data) {
        contextStore.selectPublisher(response.data)

        const promises: Array<Promise<any>> = [
          viewStore.fetchViews(ViewsType.WITH_CONTEXT),
          contextNotificationsStore.fetchNotifications()
        ]
        await Promise.all(promises)

        contextNotificationsStore.startInterval()

        return true
      }
    } catch {
      return false
    }
  }

  return false
}

/**
 * hasBidder
 * Check if the user can access to the bidder specified by the id in the route
 * @param to RouteLocationNormalized
 * @returns boolean
 */
export const hasBidder = async (to: RouteLocationNormalized): Promise<boolean> => {
  const contextStore = useContextStore()
  const viewStore = useViewStore()
  const contextNotificationsStore = useContextNotificationsStore()

  if (typeof to.params.bidderId === 'string') {
    try {
      contextNotificationsStore.clearInterval()

      const response = await fetchBidderById(parseInt(to.params.bidderId, 10))

      if (response.data) {
        contextStore.selectBidder(response.data)

        const promises: Array<Promise<any>> = [
          viewStore.fetchViews(ViewsType.WITH_CONTEXT),
          contextNotificationsStore.fetchNotifications()
        ]
        await Promise.all(promises)

        contextNotificationsStore.startInterval()

        return true
      }
    } catch {
      return false
    }
  }

  return false
}

/**
 * hasGroup
 * Check if the user can access to the group specified by the id in the route
 * @param to RouteLocationNormalized
 * @returns boolean
 */
export const hasGroup = async (to: RouteLocationNormalized): Promise<boolean> => {
  const contextStore = useContextStore()
  const viewStore = useViewStore()
  const contextNotificationsStore = useContextNotificationsStore()

  if (to.params.groupId) {
    try {
      contextNotificationsStore.clearInterval()

      const response = await fetchGroupById(to.params.groupId as string)

      if (response.data) {
        contextStore.selectGroup(response.data)

        await viewStore.fetchViews(ViewsType.WITH_CONTEXT)

        return true
      }
    } catch {
      return false
    }
  }

  return false
}
