<template>
  <div
    class="flex items-center"
  >
    <div class="flex items-center">
      <span class="text-xs font-normal text-text-primary">
        {{ t('datatable.pagination.rowsDisplayed', {...counters}) }}
      </span>
    </div>
    <div class="flex items-center ml-auto space-x-2.5">
      <button
        type="button"
        :disabled="datatablePagination.currentPage === 1"
        :class="[ datatablePagination.currentPage === 1 ? 'text-slate-200 cursor-not-allowed' : 'text-slate-400 hover:text-slate-300' ]"
        class="relative inline-flex items-center p-0.5"
        :aria-label="t('labels.previous')"
        @click="onPreviousPageClick()"
      >
        <ChevronLeftIcon
          class="h-5 w-5"
          aria-hidden="true"
        />
      </button>
      <button
        type="button"
        :disabled="datatablePagination.currentPage === datatablePagination.maxPage"
        :class="[ datatablePagination.currentPage === datatablePagination.maxPage ? 'text-slate-200 cursor-not-allowed' : 'text-slate-400 hover:text-slate-300' ]"
        class="relative inline-flex items-center p-0.5"
        :aria-label="t('labels.next')"
        @click="onNextPageClick()"
      >
        <ChevronRightIcon
          class="h-5 w-5"
          aria-hidden="true"
        />
      </button>
    </div>
    <div class="hidden">
      <div>
        <nav
          class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          <button
            type="button"
            :disabled="datatablePagination.currentPage === 1"
            :class="[ datatablePagination.currentPage === 1 ? 'bg-gray-50 cursor-not-allowed' : 'bg-white']"
            class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium text-gray-500 hover:bg-gray-50"
            @click="onPreviousPageClick()"
          >
            <span class="sr-only">{{ t('labels.previous') }}</span>
            <ChevronLeftIcon
              class="h-5 w-5"
              aria-hidden="true"
            />
          </button>
          <button
            v-for="page in datatablePagination.maxPage"
            :key="page"
            type="button"
            :aria-current="datatablePagination.currentPage === page ? 'page' : undefined"
            :class="[ datatablePagination.currentPage === page ? 'z-10 bg-primary-50 border-primary-500 text-primary-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-4 py-2 border text-sm font-medium' ]"
            @click="onPageClick(page)"
          >
            {{ page }}
          </button>
          <button
            type="button"
            :disabled="datatablePagination.currentPage === datatablePagination.maxPage"
            :class="[ datatablePagination.currentPage === datatablePagination.maxPage ? 'bg-gray-50 cursor-not-allowed' : 'bg-white']"
            class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium text-gray-500 hover:bg-gray-50"
            @click="onNextPageClick()"
          >
            <span class="sr-only">{{ t('labels.next') }}</span>
            <ChevronRightIcon
              class="h-5 w-5"
              aria-hidden="true"
            />
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/solid'
import { defineComponent, computed, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

import { PaginateWithoutRecords } from '@/types/paginate'

export default defineComponent({
  components: {
    ChevronLeftIcon,
    ChevronRightIcon
  },
  props: {
    pagination: {
      type: Object as PropType<PaginateWithoutRecords>,
      required: true
    }
  },
  emits: ['update:pagination'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const datatablePagination = computed({
      get: () => {
        return props.pagination
      },
      set: (newPagination: PaginateWithoutRecords) => {
        emit('update:pagination', newPagination)
      }
    })

    const onPageClick = (page: number) => {
      datatablePagination.value.currentPage = page
    }

    const onPreviousPageClick = () => {
      if (datatablePagination.value.currentPage > 1) {
        datatablePagination.value.currentPage = datatablePagination.value.currentPage - 1
      }
    }

    const onNextPageClick = () => {
      if (datatablePagination.value.currentPage < datatablePagination.value.maxPage) {
        datatablePagination.value.currentPage = datatablePagination.value.currentPage + 1
      }
    }

    const counters = computed(() => {
      const current = datatablePagination.value.currentPage
      const maxPage = datatablePagination.value.maxPage
      const pageSize = datatablePagination.value.pageSize
      const total = datatablePagination.value.total

      const from = (current > 1) ? ((current - 1) * pageSize) + 1 : total === 0 ? 0 : 1
      const to = (current === maxPage) ? total : current * pageSize

      return { from, to, total }
    })

    return {
      t,
      onPageClick,
      onPreviousPageClick,
      onNextPageClick,
      datatablePagination,
      counters
    }
  }
})
</script>
