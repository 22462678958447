<template>
  <div>
    <span
      v-if="numberValue !== null && numberValue !== undefined && !isNaN(numberValue)"
      :title="priority"
    >{{ priority }}</span>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: [String, Number, null, undefined] as PropType<string | number | null | undefined>,
      required: true
    }
  },
  setup (props) {
    const numberValue = computed(() => {
      if (typeof props.value === 'string') {
        return parseFloat(props.value)
      }
      return props.value
    })

    const priority = computed(() => {
      return `P${numberValue.value}`
    })

    return {
      numberValue,
      priority
    }
  }
})
</script>
