import { RouteRecordRaw } from 'vue-router'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

export function homeGroups (context: ContextType): RouteRecordRaw[] {
  return [
    {
      path: 'groups',
      name: `${context}.index`,
      component: () => import('./views/Index.vue'),
      meta: {
        layout: 'heightFull',
        title: i18n.global.t('labels.group', 100),
        feature: 'groups'
      },
      children: [
        {
          path: 'create',
          name: `${context}.create`,
          component: () => import('./views/Create.vue'),
          meta: {
            title: i18n.global.t('actions.create'),
            requireService: true,
            feature: 'groups'
          }
        }
      ]
    }
  ]
}

export const groups: RouteRecordRaw[] = [
  {
    path: '',
    name: 'groups.home',
    component: () => import('./views/Home.vue'),
    meta: {
      title: i18n.global.t('labels.home'),
      hideTitle: true,
      feature: 'groups'
    }
  },
  {
    path: 'settings',
    component: () => import('./views/Edit.vue'),
    meta: {
      title: i18n.global.t('groups.edit'),
      requireService: true,
      feature: 'groups'
    }
  }
]
