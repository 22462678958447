<template>
  <route
    v-slot="{ hasAccess }"
    :to="to"
    :disable="!hideIfMissingPermission"
  >
    <router-link
      v-slot="{ href, navigate }"
      :to="to"
      custom
    >
      <component
        :is="isLoading || isDisabled || !hasAccess ? 'button' : 'a'"
        :href="href"
        :class="[
          buttonSize,
          buttonAppearance,
          isLoading ? ['cursor-not-allowed'] : '',
          isFullwidth ? 'flex justify-center items-center w-full' : 'inline-flex justify-center items-center',
        ]"
        :disabled="isLoading || isDisabled || !hasAccess"
        v-bind="$attrs"
        @click="onClick($event, isLoading || isDisabled || !hasAccess, navigate)"
      >
        <Spinner
          v-if="isLoading"
          :class="[leftIconClass]"
        />
        <component
          :is="leftIcon"
          v-if="leftIcon"
          :class="leftIconClass"
        />
        <component
          :is="uniqueIcon"
          v-if="uniqueIcon"
          :class="uniqueIconClass"
        />
        <slot />
        <component
          :is="rightIcon"
          v-if="rightIcon"
          :class="rightIconClass"
        />
      </component>
    </router-link>
  </route>
</template>

<script lang="ts">
import { ref, defineComponent, Component, PropType } from 'vue'
import { RouteLocationRaw } from 'vue-router'

import Spinner from '@/components/Spinner/Spinner.vue'

export default defineComponent({
  components: {
    Spinner
  },
  inheritAttrs: false,
  props: {
    appearance: {
      type: String as PropType<'primary' | 'secondary' | 'clear' | 'dark' | 'danger' | 'clear-danger' | 'success' | 'warning' | 'link' | 'none'>,
      required: false,
      default: 'primary',
      validator: (val: string) => [
        'primary',
        'secondary',
        'clear',
        'dark',
        'danger',
        'clear-danger',
        'success',
        'warning',
        'link',
        'none'
      ].includes(val)
    },
    size: {
      type: String as PropType<'base' | 'xs' | 'sm'>,
      default: 'base',
      validator: (val: string) => [
        'xs',
        'sm',
        'base'
      ].includes(val)
    },
    isFullwidth: {
      type: Boolean,
      required: false,
      default: false
    },
    isSubmit: {
      type: Boolean,
      required: false,
      default: false
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    to: {
      type: Object as PropType<RouteLocationRaw>,
      required: true
    },
    leftIcon: {
      type: [Object, Function] as PropType<Component>,
      required: false,
      default: undefined
    },
    rightIcon: {
      type: [Object, Function] as PropType<Component>,
      required: false,
      default: undefined
    },
    uniqueIcon: {
      type: [Object, Function] as PropType<Component>,
      required: false,
      default: undefined
    },
    hideIfMissingPermission: {
      type: Boolean,
      default: () => false
    }
  },

  setup (props, { attrs }) {
    const leftIconClass = ref('-ml-1 mr-2')
    const rightIconClass = ref('ml-2 -mr-1')
    const uniqueIconClass = ref('')

    const buttonSize = []
    const buttonAppearance = []

    switch (props.size) {
      case 'xs':
        buttonSize.push('text-sm leading-none font-medium antialiased')
        leftIconClass.value = '-ml-0.5 mr-1 h-3 w-3'
        rightIconClass.value = 'ml-1 -mr-0.5 h-3 w-3'
        uniqueIconClass.value = 'h-3 w-3'
        if (props.appearance !== 'none') {
          buttonSize.push(`${props.uniqueIcon ? 'px-1' : 'px-2'}  py-1`)
        }
        break
      case 'sm':
        buttonSize.push('text-sm leading-none font-medium antialiased')
        leftIconClass.value = '-ml-0.5 mr-2 h-3 w-3'
        rightIconClass.value = 'ml-2 -mr-0.5 h-3 w-3'
        uniqueIconClass.value = 'h-4 w-4'
        if (props.appearance !== 'none') {
          buttonSize.push(`${props.uniqueIcon ? 'px-2' : 'px-3.5'} py-2`)
        }
        break
      case 'base':
        buttonSize.push('leading-none  font-medium antialiased')
        leftIconClass.value = '-ml-0.5 mr-2 h-4 w-4'
        rightIconClass.value = 'ml-2 -mr-0.5 h-4 w-4'
        uniqueIconClass.value = 'h-4 w-4'
        if (props.appearance !== 'none') {
          buttonSize.push(`${props.uniqueIcon ? 'px-2' : 'px-3.5'} py-2`)
        }
        break
    }

    if (props.appearance !== 'none') {
      buttonAppearance.push(
        'text-center',
        'border rounded',
        'transition-colors',
        'focus:outline-none focus:ring-0',
        'disabled:cursor-not-allowed',
        // use it as:
        // `<div class="group-buttons"><AppLink /><AppLink /><AppButton /></div>`
        'group-buttons:rounded-none group-buttons:first:rounded-l group-buttons:last:rounded-r',
        'group-buttons:second-plus:border-l-white'
      )
      switch (props.appearance) {
        case 'primary':
          buttonAppearance.push(
            'text-white',
            'bg-primary-500 border-primary-500',
            'hover:bg-primary-400 hover:border-primary-400',
            'focus:bg-primary-400 focus:border-primary-400',
            'active:bg-primary-600 active:border-primary-600',
            'disabled:bg-gray-300 disabled:border-gray-300 disabled:text-gray-500')
          break
        case 'secondary':
          buttonAppearance.push(
            'text-text-primary',
            'bg-white border-gray-border',
            'hover:text-primary-500',
            'focus:text-primary-500',
            'active:border-primary-500 active:text-primary-500',
            'disabled:border-gray-border disabled:text-gray-500')
          break
        case 'clear':
          buttonAppearance.push(
            'text-text-primary',
            'bg-white border-white',
            'hover:text-text-primary',
            'focus:text-text-primary',
            'active:bg-primary-100 active:border-primary-100 active:text-text-primary',
            'disabled:bg-white disabled:border-white disabled:text-gray-500')
          break
        case 'link':
          buttonAppearance.push(
            'text-primary-500',
            'decoration-solid', 'underline',
            'bg-white border-white',
            'hover:text-primary-400',
            'focus:text-primary-600',
            'active:text-primary-600',
            'disabled:bg-white disabled:border-white disabled:text-gray-600')
          break
        case 'dark':
          buttonAppearance.push(
            'text-white',
            'bg-slate-800 border-white',
            'hover:bg-slate-400 hover:border-slate-400',
            'focus:bg-slate-400 focus:border-slate-400',
            'active:bg-slate-900 active:border-slate-900',
            'disabled:bg-gray-300 disabled:border-gray-300 disabled:text-gray-500')
          break
        case 'danger':
          buttonAppearance.push(
            'text-white',
            'bg-red-500 border-red-500',
            'hover:bg-red-600 hover:border-red-600',
            'focus:bg-red-600 focus:border-red-600',
            'active:bg-red-700 active:border-red-700',
            'disabled:bg-gray-300 disabled:border-gray-300 disabled:text-gray-500')
          break
        case 'clear-danger':
          buttonAppearance.push(
            'text-red-500',
            'bg-white border-white',
            'hover:text-red-600',
            'focus:text-red-600',
            'active:bg-red-100 active:border-red-100 active:text-red-500',
            'disabled:bg-white disabled:border-white disabled:text-gray-500')
          break
        case 'success':
          buttonAppearance.push(
            'text-text-primary',
            'bg-green-500 border-green-500',
            'hover:bg-green-600 hover:border-green-600',
            'focus:bg-green-600 focus:border-green-600',
            'active:bg-green-700 active:border-green-700',
            'disabled:bg-gray-300 disabled:border-gray-300 disabled:text-gray-500')
          break
        case 'warning':
          buttonAppearance.push(
            'text-text-primary',
            'bg-amber-400 border-amber-400',
            'hover:bg-amber-500 hover:border-amber-500',
            'focus:bg-amber-500 focus:border-amber-500',
            'active:bg-amber-600 active:border-amber-600',
            'disabled:bg-gray-300 disabled:border-gray-300 disabled:text-gray-500')
          break
      }
    }

    if (attrs.class) {
      buttonAppearance.push(attrs.class)
    }

    const onClick = (e: MouseEvent, disabled: boolean, navigate: any) => {
      if (disabled) {
        e.preventDefault()
        return
      }
      navigate(e)
    }

    return {
      buttonSize,
      leftIconClass,
      rightIconClass,
      uniqueIconClass,
      buttonAppearance,

      onClick
    }
  }
})
</script>
