import { Dimension } from '../dimensions'

import { NumberReducer, Reducer, ReducerConstructor, SerializedReducer } from '.'

/**
 * Calculates: (metric1 / sum(metric2)) * factor. The sum is global (not group-based like WeightedAverageReducer)
 * and based on the selected globalSumDimensions. Tip: give an empty globalSumDimensions array to calculate
 * an entirely ungrouped sum.
 *
 * Limitations:
 * - the dimensions in "globalSumDimensions" must be included in the widget's metric (or dimensions) list
 *   so the postprocessor can calculate the rate from it.
 * - the "conditionDimensionValue" must also be included in the widget's metric (or dimensions) list.
 *
 * This reducer covers three use-cases:
 * - Calculate a rate based on a global ungrouped sum.
 *   e.g. bidrate: bids / SUM(adcalls) with no globalSumDimensionSelected
 *   Here, "bids" is the grouped sum of bids (by dimension, like a regular SumReducer).
 *   SUM(adcalls) is the global sum of adcalls (like a single metric)
 * - Calculate a rate based on a global sum grouped by dimension.
 *   e.g. composite dimension environment|pagetype, calculate bidrate by environment: bids / SUM(adcalls)
 *   Here "bids" is the grouped sum of bids (by dimension, like a regular SumReducer).
 *   SUM(adcalls) is the grouped sum of bids by environment ONLY.
 * - Calcualte a rate like in the previous examples, but with a condition (one column contains the value of another column)
 *   e.g. global sum where mediatypes contains the value of the mediatype column. If mediatype = ban, we calculate using
 *   the sum of adcalls of lines where mediatypes contains "ban".
 */
export class RateReducer extends NumberReducer implements Reducer {
  metric1: string
  metric2: string
  factor: number

  sum: Map<Record<string, any>, number>

  conditionValueDimension?: string
  conditionDimension?: string
  conditionDimensionSeparator: string

  globalSumDimensions: string[]
  globalSum: Record<string, number>

  constructor (metric1: string, metric2: string, factor: number = 1, globalSumDimensions: string[] = [], conditionDimension?: string, conditionValueDimension?: string, conditionDimensionSeparator: string = ',') {
    super()
    this.metric1 = metric1
    this.metric2 = metric2
    this.factor = factor || 1
    this.sum = new Map()
    this.globalSumDimensions = [...globalSumDimensions]
    this.globalSum = {}

    this.conditionDimension = conditionDimension
    this.conditionValueDimension = conditionValueDimension
    this.conditionDimensionSeparator = conditionDimensionSeparator
  }

  addToGlobalSum (key: string, value: number): void {
    if (this.globalSum[key] === undefined) {
      this.globalSum[key] = 0
    }
    this.globalSum[key] += value
  }

  add (p: Record<string, any>, key: string, v: Record<string, any>): void {
    this.sum.set(p, this.sum.get(p)! + v[this.metric1])
    const sumKey = this.getKey(v)
    if (this.conditionDimension !== undefined) {
      (v[this.conditionDimension] as string).split(this.conditionDimensionSeparator).forEach(d => {
        this.addToGlobalSum(sumKey + '|' + d, v[this.metric2])
      })
      return
    }
    this.addToGlobalSum(sumKey, v[this.metric2])
  }

  remove (p: Record<string, any>, key: string, v: Record<string, any>): void {
    this.sum.set(p, this.sum.get(p)! - v[this.metric1])
    const sumKey = this.getKey(v)
    if (this.conditionDimension !== undefined) {
      (v[this.conditionDimension] as string).split(this.conditionDimensionSeparator).forEach(d => {
        this.addToGlobalSum(sumKey + '|' + d, -v[this.metric2])
      })
      return
    }
    this.addToGlobalSum(sumKey, -v[this.metric2])
  }

  init (p: Record<string, any>, key: string): void {
    super.init(p, key)
    this.sum.set(p, 0)
  }

  post (p: Record<string, any>, key: string): void {
    const sumKey = this.getKey(p)
    const globalSumKey = this.conditionValueDimension !== undefined ? sumKey + '|' + p[this.conditionValueDimension] : sumKey
    const globalSum = this.globalSum[globalSumKey] || 0
    p[key] = (globalSum !== 0 ? (this.sum.get(p)! / globalSum) : 0) * this.factor
  }

  getKey (p: Record<string, any>): string {
    return Object.entries(p)
      .filter(e => this.globalSumDimensions.includes(e[0]))
      .map(e => e[1])
      .join('|')
  }

  static deserialize (m: SerializedReducer): ReducerConstructor {
    if (m.params === undefined ||
      m.params.metric1 === undefined || typeof m.params.metric1 !== 'string' ||
      m.params.metric2 === undefined || typeof m.params.metric2 !== 'string' ||
      m.params.globalSumDimensions === undefined || !Array.isArray(m.params.globalSumDimensions)
    ) {
      throw new Error('Invalid SerializedReducer (RateReducer): missing parameters')
    }
    return RateReducerConstructor(m.params.metric1, m.params.metric2, m.params.factor, [...m.params.globalSumDimensions], m.params.conditionDimension, m.params.conditionValueDimension, m.params.conditionDimensionSeparator || ',')
  }
}

export function RateReducerConstructor (metric1: string, metric2: string, factor: number = 1, globalSumDimensions: string[] = [], conditionDimension?: string, conditionValueDimension?: string, conditionDimensionSeparator: string = ','): ReducerConstructor {
  return class extends RateReducer {
    constructor (dimension?: Dimension, singleMetric?: boolean) {
      if (!singleMetric) {
        super(metric1, metric2, factor, globalSumDimensions, conditionDimension, conditionValueDimension, conditionDimensionSeparator)
      } else {
        super(metric1, metric2, factor, [], undefined, undefined, undefined)
      }
    }

    static serialize (): SerializedReducer {
      return {
        constructor: 'RateReducer',
        params: {
          metric1,
          metric2,
          factor,
          globalSumDimensions,
          conditionValueDimension,
          conditionDimension,
          conditionDimensionSeparator
        }
      }
    }
  }
}
