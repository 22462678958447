import { RouteRecordRaw } from 'vue-router'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

export function invoices (context: ContextType): RouteRecordRaw[] {
  return [
    {
      path: 'invoices',
      name: `${context}.invoices.index`,
      component: () => import('./views/Index.vue'),
      meta: {
        requireContext: true,
        title: i18n.global.t('labels.invoice', 100),
        feature: 'invoices',
        featuresPermissions: ['invoice_requests']
      },
      children: [
        {
          path: ':invoiceId/edit',
          name: `${context}.invoices.edit`,
          component: () => import('./views/Edit.vue'),
          meta: {
            title: i18n.global.t('invoices.edit', { period: ':invoicePeriod' }),
            pattern: ':invoicePeriod',
            feature: 'invoices',
            featuresPermissions: ['invoice_requests']
          },
          props: route => ({ invoiceId: route.params.invoiceId })
        }
      ]
    }
  ]
}
