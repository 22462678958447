<template>
  <div v-if="tabs.length">
    <nav
      class="flex flex-wrap -mb-px gap-x-6 gap-y-4"
      aria-label="Tabs"
    >
      <button
        v-for="tab in tabs"
        :key="tab.name"
        type="button"
        :class="[isCurrentTab(tab.key) ? 'border-text-primary' : '', 'border-transparent text-text-primary hover:border-text-primary whitespace-nowrap flex py-2 px-1 border-b-2 font-medium text-sm flex-row gap-2 items-center']"
        :aria-current="isCurrentTab(tab.key) ? 'page' : undefined"
        @click.prevent="() => tab.onClick()"
      >
        <span>
          {{ tab.name }}
        </span>
        <Badge
          v-if="tab.counter"
          :icon="tab.counter.icon"
          :theme="getCounterBadgeTheme(tab.counter.type)"
          class="!py-0.5"
        >
          {{ formatLargeNumber(unref(tab.counter.count)) }}
        </Badge>
      </button>
    </nav>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, unref } from 'vue'

import { Counter, getCounterBadgeTheme } from '@/store/counter.store'

import { formatLargeNumber } from '@/utils/utils'

import Badge from '../Badge/Badge.vue'

export interface Tab {
  key: string
  name: string
  counter?: Counter
  onClick: () => void
}

export default defineComponent({
  components: { Badge },
  props: {
    tabs: {
      type: Array as PropType<Tab[]>,
      required: false,
      default: () => []
    },
    isCurrentTab: {
      type: Function as PropType<(key: string) => boolean>,
      required: false,
      default: () => { return false }
    }
  },
  setup () {
    return {
      unref,
      formatLargeNumber,
      getCounterBadgeTheme
    }
  }
})
</script>
