<template>
  <span
    v-if="value !== undefined && value !== null"
    :title="formattedValue"
  >
    {{ formattedValue }}
  </span>
</template>

<script lang="ts">
import numbro from 'numbro'
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: Number,
      required: true
    }
  },
  setup (props) {
    const formattedValue = computed(() => numbro(props.value).format({ average: true, lowPrecision: false, mantissa: 2, trimMantissa: true }).toUpperCase())
    return {
      formattedValue
    }
  }
})
</script>
