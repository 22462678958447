<template>
  <div
    v-if="datatablePagination.currentPage > 0 && datatablePagination.currentPage < datatablePagination.maxPage"
    class="flex items-center m-auto"
  >
    <AppButton
      appearance="none"
      size="xs"
      :class="['border border-black rounded px-2.5 py-1.5']"
      :is-loading="isLoading"
      :is-disabled="isLoading"
      :right-icon="isLoading ? undefined : ArrowPathIcon"
      @click.prevent="onSeeMoreClick()"
    >
      {{ t('actions.more') }}
    </AppButton>
  </div>
</template>

<script lang="ts">
import { ArrowPathIcon } from '@heroicons/vue/20/solid'
import { defineComponent, computed, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

import { PaginateWithoutRecords } from '@/types/paginate'

import AppButton from '@/components/Buttons/AppButton.vue'

export default defineComponent({
  components: {
    AppButton
  },
  props: {
    pagination: {
      type: Object as PropType<PaginateWithoutRecords>,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:pagination'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const datatablePagination = computed({
      get: () => {
        return props.pagination
      },
      set: (newPagination: PaginateWithoutRecords) => {
        emit('update:pagination', newPagination)
      }
    })

    const onSeeMoreClick = () => {
      if (datatablePagination.value.currentPage < datatablePagination.value.maxPage) {
        datatablePagination.value.currentPage++
      }
    }

    return {
      t,

      ArrowPathIcon,

      onSeeMoreClick,
      datatablePagination
    }
  }
})
</script>
