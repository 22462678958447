import { TinyEmitter } from 'tiny-emitter'

export const panelEmitter = new TinyEmitter()
export const PANEL_CLOSE_EVENT = 'close:panel'

// PanelCloseEvent triggered through the `panelEmitter` with the
// key `PANEL_CLOSE_EVENT` when pressing the ESC key.
// Set `cancelled` to true to handle panel closing manually.
// This can be used in forms where an extra action is required when the panel
// closes (reset) or to add a close confirmation modal.
export interface PanelCloseEvent {
  keyEvent: KeyboardEvent
  cancelled: boolean
}
