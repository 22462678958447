import get from 'lodash/get'
import pick from 'lodash/pick'

import { Paginate, PaginateWithoutRecords } from '@/types/paginate'

import { Column } from './datatable.d'

export function getRowColumnValue (row: any, column: Column): string {
  return get(row, column.field, undefined)
}

function extractRecords <T> (paginateRecords: Paginate<T>): T {
  return get(paginateRecords, 'records')
}

function extractPagination <T> (paginateRecords: Paginate<T>): PaginateWithoutRecords {
  return pick(paginateRecords, ['maxPage', 'total', 'pageSize', 'currentPage'])
}

export function extractResponse <T> (paginateRecords: Paginate<T>): { records: T, pagination: PaginateWithoutRecords} {
  return { records: extractRecords(paginateRecords), pagination: extractPagination(paginateRecords) }
}
