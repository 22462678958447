/**
 * This rule has been created in order to apply $externalResults message to a field.
 *
 * When a validation rule is defined as below, the $externalResults message can't be set.
 * ```
 * const r = computed(() => ({
 *   myField: {}
 * }))
 * ```
 */
export default (): boolean => {
  return true
}
