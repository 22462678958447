<template>
  <div class="space-y-4">
    <input
      v-model="search"
      class="w-full border-none px-2 py-0.5 text-sm rounded bg-gray-200 focus:ring-0"
      type="search"
      placeholder="Search a view"
    >

    <div class="flex items-center justify-between text-sm font-semibold capitalize">
      <div
        class="flex items-center"
      >
        <div>{{ t('app.view', 2) }}</div>
      </div>
    </div>

    <ul
      class="overflow-y-auto overflow-hidden h-32 w-full space-y-2 p-0.5"
    >
      <li class="flex items-center justify-between space-x-2">
        <a
          href=""
          :class="[
            'text-sm w-full px-2 py-1 rounded cursor-pointer capitalize',
            currentView === undefined || currentView === null || currentView.isDefault ? 'bg-primary-100 text-primary-500 hover:bg-primary-200' : 'text-text-primary hover:bg-primary-100'
          ]"
          @click.prevent="resetView"
        >
          {{ t('app.default') }}
        </a>
      </li>
      <li
        v-for="view in filteredView"
        :key="view.item.id"
        class="flex items-center justify-between w-full px-2 py-1 text-sm rounded cursor-pointer"
        :class="[ isActive(view.item) ? 'bg-primary-100 text-primary-500 hover:bg-primary-200' : 'text-text-primary hover:bg-primary-100']"
        @click="loadView(view.item)"
      >
        <div class="flex items-center space-x-1">
          <span class="w-full truncate">{{ view.item.name }}</span>
          <LockClosedIcon
            v-if="view.item.userId"
            class="w-4 h-4"
          />
          <StarIcon
            v-if="!view.item.resourceId"
            class="w-4 h-4"
          />
        </div>

        <Popper
          v-if="canEdit(view.item)"
          reference-is="div"
          popper-is="div"
          placement="bottom-end"
        >
          <template #reference>
            <EllipsisVerticalIcon
              class="w-4 h-4"
              aria-hidden="true"
            />
          </template>

          <div class="bg-white rounded-md shadow-lg w-28 ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div class="py-1">
              <div
                :class="['text-gray-700 block px-2 py-0.5 text-sm hover:bg-gray-200']"
                @click.stop="onEdit(view.item)"
              >
                {{ t('actions.edit') }}
              </div>
              <div
                :class="[
                  ' text-gray-700 block px-2 py-0.5 text-sm hover:bg-gray-200']"
                @click.stop="onDelete(view.item)"
              >
                {{ t('actions.delete') }}
              </div>
            </div>
          </div>
        </Popper>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { EllipsisVerticalIcon, LockClosedIcon, StarIcon } from '@heroicons/vue/24/solid'
import Fuse from 'fuse.js'
import { PropType, computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { Popper } from 'vue-use-popperjs'

import { rolesGroup } from '@/static/roles'

import { ServerFilters } from '@/plugins/filters/filters'
import { hasRole, useRoutePermissions } from '@/plugins/permissions'

import { useProfileStore } from '@/store/profile.store'
import { useViewStore } from '@/store/view.store'

import { View } from '@/models/view'

export default defineComponent({
  components: {
    EllipsisVerticalIcon,
    LockClosedIcon,
    StarIcon,
    Popper
  },
  props: {
    filters: {
      type: Object as PropType<ServerFilters>,
      required: true
    },
    defaultView: {
      type: Object as PropType<View>,
      required: false,
      default: null
    }
  },
  emits: ['updateEditView', 'update:filters', 'updateViewType'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const viewStore = useViewStore()
    const profileStore = useProfileStore()

    const currentRoute = useRoute()

    const { roles } = useRoutePermissions(currentRoute)

    const filtersController = computed({
      get () {
        return props.filters
      },
      set (filters: ServerFilters) {
        emit('update:filters', filters)
      }
    })

    const currentView = computed(() => filtersController.value.currentView.value)

    const search = ref('')

    const views = computed(() => viewStore.listableViews.filter((v) => v.pageContext === filtersController.value.tableName))

    const fuse = new Fuse(views.value, { keys: ['name'] })

    const filteredView = computed(() => search.value.length ? fuse.search(search.value) : views.value.map((v) => ({ item: v })))

    const loadView = (view: View) => {
      filtersController.value.currentView.value = view
    }

    const isActive = (view: View) => {
      return currentView.value ? currentView.value.id === view.id : false
    }

    const resetView = () => {
      filtersController.value.currentView.value = props.defaultView || null
    }

    const onDelete = async (view: View) => {
      emit('updateEditView', view)
      emit('updateViewType', 'DELETE')
    }

    const onEdit = (view: View) => {
      emit('updateEditView', view)
      emit('updateViewType', 'FORM')
    }

    const canEdit = (view: View) => {
      const currentUserId = profileStore.profile?.id

      // Private view can only be edited by the owner.
      if (view.userId === currentUserId) {
        return true
      }

      // Public view with context
      if (view.resourceType && view.resourceId) {
        return hasRole(roles, rolesGroup.operation)
      }

      // view without context (each publishers see them)
      return hasRole(roles, rolesGroup.adagio)
    }

    return {
      t,

      views,
      search,
      currentView,
      filteredView,
      loadView,
      isActive,
      resetView,

      canEdit,

      onEdit,
      onDelete
    }
  }
})
</script>
