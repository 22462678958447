import { AxiosResponse } from 'axios'

import { CreateView, UpdateView, View } from '@/models/view'

import api from '@/api'

export async function fetchViews (withContext = false): Promise<AxiosResponse<View[]>> {
  return api.get<View[]>('/views', { requireContext: withContext })
}

export async function createView (data: CreateView, withContext = false): Promise<AxiosResponse<View>> {
  return api.post<View>('/views', data, { requireContext: withContext })
}

export async function updateView (id: number, data: UpdateView): Promise<AxiosResponse<View>> {
  return api.patch<View>(`/views/${id}`, data)
}

export async function deleteView (id: number): Promise<AxiosResponse<void>> {
  return api.delete(`/views/${id}`)
}
