import { RouteRecordRaw } from 'vue-router'

import { rolesGroup } from '@/static/roles'

import { useContext } from '@/plugins/context'
import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

import Create from './views/Create.vue'
import Index from './views/Index.vue'
import Show from './views/Show.vue'

export function dashboards (context: ContextType): RouteRecordRaw[] {
  return [
    {
      path: 'dashboards',
      name: `${context}.dashboards.index`,
      component: Index,
      meta: {
        title: i18n.global.t('labels.dashboard', 100),
        roles: rolesGroup.user
      },
      children: [
        {
          path: 'create',
          name: `${context}.dashboards.create`,
          component: Create,
          meta: {
            title: i18n.global.t('dashboards.create'),
            hideTitle: true,
            requireService: true
          }
        }
      ]
    },
    {
      path: 'dashboards/:dashboardId',
      name: `${context}.dashboards.show`,
      component: Show,
      props: true,
      meta: {
        layout: 'base',
        title: ':dashboardName',
        pattern: ':dashboardName',
        roles: rolesGroup.user,
        breadcrumbParent: () => useContext('dashboards.index', {}).value
      },
      children: [
        {
          path: 'duplicate',
          name: `${context}.dashboards.duplicate`,
          component: Create,
          meta: {
            title: i18n.global.t('actions.duplicate', [':dashboardName']),
            pattern: ':dashboardName',
            requireService: true
          }
        }
      ]
    }
  ]
}
