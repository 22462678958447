<template>
  <div class="relative">
    <slot />
    <div
      v-if="isLoading"
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      :class="loadingClass"
    >
      <div class="flex flex-col justify-center items-center">
        <LoadingIcon class="h-6 w-6 text-primary-600" />

        <div
          v-if="text"
          class="p-4"
        >
          {{ t(text) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

import i18n from '@/plugins/i18n'

import LoadingIcon from './LoadingIcon.vue'

export default defineComponent({
  components: {
    LoadingIcon
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    loadingClass: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: String,
      required: false,
      default: i18n.global.t('labels.loading')
    }
  },
  setup () {
    const { t } = useI18n()
    return {
      t
    }
  }
})
</script>
