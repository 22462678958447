<template>
  <abbr
    :title="value"
    class="truncate no-underline"
  >
    {{ value }}
  </abbr>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: String,
      default: ''
    }
  }
})
</script>
