export enum BadgeTheme {
  BASE,
  DANGER,
  WARNING,
  SUCCESS,
  INFO,
  BASE_11,
  DANGER_11,
  WARNING_11,
  SUCCESS_11,
  INFO_11
}

export const BASE_BADGE_CLASSES = ['inline-flex', 'flex-row', 'gap-1', 'items-center', 'text-xs', 'rounded', 'transition-colors', 'leading-normal', 'hover:text-white', 'font-medium']
export const BASE_DEFAULT_CLASSES = ['py-1', 'px-1.5']
export const BASE_11_CLASSES = ['py-0.5', 'px-1', 'border-dashed', 'border']

export const BADGE_CLASSES: Record<BadgeTheme, string[]> = {
  [BadgeTheme.BASE]: BASE_DEFAULT_CLASSES.concat(['bg-slate-50', 'text-slate-700', 'hover:bg-slate-700']),
  [BadgeTheme.DANGER]: BASE_DEFAULT_CLASSES.concat(['bg-red-100', 'text-red-600', 'hover:bg-red-600']),
  [BadgeTheme.WARNING]: BASE_DEFAULT_CLASSES.concat(['bg-amber-100', 'text-amber-700', 'hover:bg-amber-500']),
  [BadgeTheme.SUCCESS]: BASE_DEFAULT_CLASSES.concat(['bg-green-100', 'text-green-700', 'hover:bg-green-600']),
  [BadgeTheme.INFO]: BASE_DEFAULT_CLASSES.concat(['bg-blue-100', 'text-blue-600', 'hover:bg-blue-500']),
  [BadgeTheme.BASE_11]: BASE_11_CLASSES.concat(['bg-slate-50', 'text-slate-700', 'hover:bg-slate-700', 'border-slate-700']),
  [BadgeTheme.DANGER_11]: BASE_11_CLASSES.concat(['bg-red-50', 'text-red-600', 'hover:bg-red-600', 'border-red-600']),
  [BadgeTheme.WARNING_11]: BASE_11_CLASSES.concat(['bg-amber-50', 'text-amber-700', 'hover:bg-amber-500', 'border-amber-700']),
  [BadgeTheme.SUCCESS_11]: BASE_11_CLASSES.concat(['bg-green-50', 'text-green-700', 'hover:bg-green-600', 'border-green-700']),
  [BadgeTheme.INFO_11]: BASE_11_CLASSES.concat(['bg-blue-15000', 'text-blue-600', 'hover:bg-blue-500', 'border-blue-600'])
}
