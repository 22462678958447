import i18n from '@/plugins/i18n'

export enum AdstxtLineRelationshipType {
  DIRECT = 'DIRECT',
  RESELLER = 'RESELLER'
}

export enum AdstxtLineType {
  OWNERDOMAIN = 'ownerdomain',
  MANAGERDOMAIN = 'managerdomain',
  OWNER = 'owner',
  ADAGIO = 'adagio',
  MANAGER = 'manager',
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

export enum AdstxtLineStatus {
  OK = 'OK',
  NOT_CRAWLED = '',
  NOT_FOUND = 'NOT_FOUND',
  NOT_DIRECT = 'NOT_DIRECT',
  NOT_RESELLER = 'NOT_RESELLER',
  CERT_EMPTY = 'CERT_EMPTY',
  CERT_PRESENT = 'CERT_PRESENT',
  UNKNOWN_RELATIONSHIP = 'UNKNOWN_RELATIONSHIP',
  DOMAIN_MISMATCH = 'DOMAIN_MISMATCH',

  // Special case for adstxt manager lines when the website's sellers.json account ID is null
  MISSING_MANAGER_ACCOUNT_ID = 'MISSING_MANAGER_ACCOUNT_ID'
}

export enum AdstxtLineStatusLevel {
  OK= 'ok',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export enum SellersjsonEntryStatus {
  OK = 'OK',
  NOT_CRAWLED = '',
  NOT_FOUND = 'NOT_FOUND',
  NOT_PUBLISHER = 'NOT_PUBLISHER',

  // Special case for adstxt intermediary lines when the website's sellers.json account ID is null
  MISSING_MANAGER_ACCOUNT_ID = 'MISSING_MANAGER_ACCOUNT_ID'
}

export enum AdstxtStatusLevel {
  OK = 'ok',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info'
}

export function getSellersjsonEntryStatusLabel (value: SellersjsonEntryStatus | null): string {
  switch (value) {
    case SellersjsonEntryStatus.OK: return ''
    case SellersjsonEntryStatus.NOT_CRAWLED: return i18n.global.t('supplyChain.sellersjsonEntryStatus.NOT_CRAWLED')
    case SellersjsonEntryStatus.NOT_FOUND: return i18n.global.t('supplyChain.sellersjsonEntryStatus.NOT_FOUND')
    case SellersjsonEntryStatus.NOT_PUBLISHER: return i18n.global.t('supplyChain.sellersjsonEntryStatus.NOT_PUBLISHER')
    case SellersjsonEntryStatus.MISSING_MANAGER_ACCOUNT_ID: return i18n.global.t('supplyChain.sellersjsonEntryStatus.MISSING_MANAGER_ACCOUNT_ID')
    default: return ''
  }
}
