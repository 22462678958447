import { BadgeTheme } from '@/components/Badge/theme'

import { AdstxtStatus, AdstxtWithStatus } from './adstxt'
import { BidderLinesStatus } from './enum/bidderLinesStatus'

export function bidderLinesStatus (adstxts: AdstxtWithStatus[]): BidderLinesStatus {
  if (!adstxts.length || adstxts.some(a => a.status === AdstxtStatus.PENDING)) {
    return BidderLinesStatus.NOT_APPLICABLE
  }

  let result = BidderLinesStatus.OK

  if (adstxts.some(a => a.status === AdstxtStatus.ERROR)) {
    result = BidderLinesStatus.ERROR
  } else if (adstxts.some(a => a.status === AdstxtStatus.WARNING)) {
    result = BidderLinesStatus.WARNING
  } else if (adstxts.some(a => a.status === AdstxtStatus.INFO)) {
    result = BidderLinesStatus.INFO
  } else if (adstxts.some(a => a.status === AdstxtStatus.UNKNOWN)) {
    result = BidderLinesStatus.NOT_CRAWLED
  }

  return result
}

export function bidderLinesBadgeTheme (status: BidderLinesStatus | null | undefined): BadgeTheme {
  switch (status) {
    case BidderLinesStatus.OK:
      return BadgeTheme.SUCCESS
    case BidderLinesStatus.INFO:
      return BadgeTheme.INFO
    case BidderLinesStatus.WARNING:
      return BadgeTheme.WARNING
    case BidderLinesStatus.ERROR:
      return BadgeTheme.DANGER
    case BidderLinesStatus.NOT_CRAWLED:
      return BadgeTheme.BASE
    case BidderLinesStatus.NOT_APPLICABLE:
      return BadgeTheme.BASE
    default: return BadgeTheme.BASE
  }
}
