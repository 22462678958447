import 'v-calendar/dist/style.css'
import './index.css'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { datadogRum } from '@datadog/browser-rum'
import { createPinia } from 'pinia'
import { setupCalendar } from 'v-calendar'
import { createApp } from 'vue'
import VueGridLayout from 'vue-grid-layout'
import { setGlobalOptions } from 'vue-request'

import i18n from '@/plugins/i18n'
import Route from '@/plugins/permissions/Route.vue'

import { useContextStore } from '@/store/context.store'

import AppLink from '@/components/Buttons/AppLink.vue'

import App from './App.vue'
import HeapAnalytics from './plugins/heapAnalytics'
import segment from './plugins/segmentio'
import { router } from './router'

setGlobalOptions({
  loadingDelay: 300
})

const mode = import.meta.env.MODE

const bugsnagMode = mode === 'prod' ? 'production' : mode
const bugsnagBypassErrorMessages = [
  'ResizeObserver loop completed with undelivered notifications.',
  'ResizeObserver loop limit exceeded',
  'Request failed with status code 429'
]

if (mode !== 'development') {
  HeapAnalytics.load()
  segment.load()
}

const piniaStore = createPinia()

const app = createApp(App)
  .use(piniaStore)

const contextStore = useContextStore()

Bugsnag.start({
  releaseStage: bugsnagMode,
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  enabledReleaseStages: ['production', 'staging', 'testing'],
  onError: (event) => {
    if (event.errors.length) {
      // Ignore some errors as having a lot of noise and we can't do anything about them.
      if (bugsnagBypassErrorMessages.includes(event.errors[0].errorMessage)) {
        return false
      }
    }

    if (contextStore) {
      if (contextStore.hasContext) {
        if (contextStore.selectedPublisher) {
          event.addMetadata('publisher', {
            id: contextStore.selectedPublisher.id,
            name: contextStore.selectedPublisher.name
          })
        }

        if (contextStore.selectedBidder) {
          event.addMetadata('bidder', {
            id: contextStore.selectedBidder.id,
            name: contextStore.selectedBidder.name
          })
        }

        if (contextStore.selectedGroup) {
          event.addMetadata('group', {
            id: contextStore.selectedGroup.id,
            name: contextStore.selectedGroup.name
          })
        }
      }
    }
  }
})

const bugsnagVue = Bugsnag.getPlugin('vue')

if (import.meta.env.VITE_DATADOG_ENABLED === 'true') {
  datadogRum.init({
    applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'spa',
    env: mode,
    version: import.meta.env.VITE_DATADOG_SPA_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      (url) =>
        url.startsWith(import.meta.env.VITE_API_BASE_URL) ||
        url.startsWith(import.meta.env.VITE_TASK_API_BASE_URL) ||
        url.startsWith(import.meta.env.VITE_DATA_API_BASE_URL)
    ]
  })
}

app.use(bugsnagVue!)
  .use(router)
  .use(i18n)
  .use(VueGridLayout)
  .use(setupCalendar, {})
  .component('route', Route)
  .component('app-link', AppLink)
  .mount('#app')
