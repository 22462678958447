import { addDays, differenceInDays, endOfDay, endOfMonth, endOfQuarter, endOfToday, endOfWeek, endOfYesterday, startOfDay, startOfMonth, startOfQuarter, startOfToday, startOfWeek, startOfYesterday, subDays, subMonths, subQuarters, subWeeks, subYears } from 'date-fns'

export type DateRange = {
  start: Date
  end: Date
}

export function dateRangeToday (): DateRange {
  return {
    start: startOfToday(),
    end: endOfToday()
  }
}

export function dateRangeYesterday (): DateRange {
  return {
    start: startOfYesterday(),
    end: endOfYesterday()
  }
}

export function dateRangeLastDays (n: number): DateRange {
  const endYesterday = endOfYesterday()
  return {
    start: startOfDay(subDays(endYesterday, n - 1)),
    end: endYesterday
  }
}

export function dateRangePastWeeks (n: number): DateRange {
  const thisWeek = startOfWeek(startOfToday(), { weekStartsOn: 1 })
  const lastWeek = subWeeks(thisWeek, 1)
  const endLastWeek = endOfWeek(lastWeek, { weekStartsOn: 1 })
  return {
    start: subWeeks(lastWeek, n - 1),
    end: endLastWeek
  }
}

export function dateRangeCurrentWeek (): DateRange {
  return {
    start: startOfWeek(startOfToday(), { weekStartsOn: 1 }),
    end: endOfToday()
  }
}

export function dateRangePastMonth (): DateRange {
  const lastMonth = subMonths(startOfMonth(startOfToday()), 1)
  return {
    start: lastMonth,
    end: endOfMonth(lastMonth)
  }
}

export function dateRangeCurrentMonth (): DateRange {
  return {
    start: startOfMonth(startOfToday()),
    end: endOfToday()
  }
}

export function dateRangePastQuarter (): DateRange {
  const lastQuarter = subQuarters(startOfQuarter(startOfToday()), 1)
  return {
    start: lastQuarter,
    end: endOfQuarter(lastQuarter)
  }
}

export function dateRangeCurrentQuarter (): DateRange {
  return {
    start: startOfQuarter(startOfToday()),
    end: endOfToday()
  }
}

export type DateRangePreset = 'today' | 'yesterday' | 'last_7_days' | 'last_14_days' | 'last_30_days' | 'past_week' | 'past_4_weeks' | 'past_12_weeks' | 'past_month' | 'past_quarter' | 'current_week' | 'current_month' | 'current_quarter' | 'custom'

export const DATE_RANGE_PRESETS: Record<string, () => DateRange> = {
  today: dateRangeToday,
  yesterday: dateRangeYesterday,
  last_7_days: () => dateRangeLastDays(7),
  last_14_days: () => dateRangeLastDays(14),
  last_30_days: () => dateRangeLastDays(30),

  past_week: () => dateRangePastWeeks(1),
  past_4_weeks: () => dateRangePastWeeks(4),
  past_12_weeks: () => dateRangePastWeeks(12),

  past_month: dateRangePastMonth,
  past_quarter: dateRangePastQuarter,

  current_week: dateRangeCurrentWeek,
  current_month: dateRangeCurrentMonth,
  current_quarter: dateRangeCurrentQuarter
}

export type ComparisonRange = (refDate: DateRange) => DateRange

export function compareLastSamePeriod (refDate: DateRange): DateRange {
  const diff = differenceInDays(refDate.end, refDate.start)
  const start = startOfDay(subDays(refDate.start, diff + 1))
  return {
    start,
    end: endOfDay(addDays(start, diff))
  }
}

export function compareDayBefore (refDate: DateRange): DateRange {
  return {
    start: startOfDay(subDays(refDate.start, 1)),
    end: endOfDay(subDays(refDate.end, 1))
  }
}

export function compareWeekBefore (refDate: DateRange): DateRange {
  const diff = differenceInDays(refDate.end, refDate.start)
  const start = startOfDay(subWeeks(refDate.start, 1))
  return {
    start,
    end: endOfDay(addDays(start, diff))
  }
}

export function compareMonthBefore (refDate: DateRange): DateRange {
  const diff = differenceInDays(refDate.end, refDate.start)
  const start = startOfDay(subMonths(refDate.start, 1))
  return {
    start,
    end: endOfDay(addDays(start, diff))
  }
}

export function compareQuarterBefore (refDate: DateRange): DateRange {
  const diff = differenceInDays(refDate.end, refDate.start)
  const start = startOfDay(subQuarters(refDate.start, 1))
  return {
    start,
    end: endOfDay(addDays(start, diff))
  }
}

export function compareYearBefore (refDate: DateRange): DateRange {
  const diff = differenceInDays(refDate.end, refDate.start)
  const start = startOfDay(subYears(refDate.start, 1))
  return {
    start,
    end: endOfDay(addDays(start, diff))
  }
}

export type ComparisonRangePreset = 'auto' | 'day_before' | 'week_before' | 'month_before' | 'quarter_before' | 'year_before'

export const COMPARISON_DATE_RANGE_PRESETS: Record<string, ComparisonRange> = {
  auto: compareLastSamePeriod,
  day_before: compareDayBefore,
  week_before: compareWeekBefore,
  month_before: compareMonthBefore,
  quarter_before: compareQuarterBefore,
  year_before: compareYearBefore
}
