<template>
  <WidgetShell
    :loading="loading"
    :title="title"
    :definition="definition"
    :edit-mode="editMode"
    :enlarged="enlarged"
    :has-data="true"
    :show-export="false"
    controls-position="horizontal"
    @json-editor-input="onJSONEditorInput"
    @expand-clicked="onExpandClicked"
    @remove-clicked="$emit('remove-clicked')"
  >
    <template #before-controls>
      <TooltipInfo
        class="self-center"
        :message="t('seatsBoosterRules.dashboardWidgetTooltip')"
      />
    </template>
    <template #header>
      <AppLink
        :to="{ name: 'groups.seatsBoosterRules.index', params: { groupId: 1 } }"
        appearance="secondary"
        size="xs"
      >
        {{ t('actions.seeAll') }}
      </AppLink>
    </template>
    <div
      class="flex flex-col flex-grow h-full overflow-hidden"
    >
      <SegmentControl
        v-model="selectedStatus"
        :segments="segments"
        :is-disabled="loading"
        disable-fragment
        class="justify-center my-2"
      />
      <Datatable
        v-model:items="rows"
        v-model:sorted-columns="datatableFilters.sorts.value"
        v-model:pagination="seatsBoosterRulesPagination"
        :columns="columns.columns.value"
        :is-loading="loading"
        select-with="none"
        class="flex-grow"
        :has-error="!!error"
        @refresh="refresh()"
      >
        <template #name="{ value }">
          <TruncateComponent :value="value" />
        </template>

        <template #startedAt="{ value }">
          <DateComponent :value="value" />
        </template>

        <template #estimatedCompletedAt="{ value }">
          <DateComponent :value="value" />
        </template>

        <template #websites="{ row, value }">
          <div
            v-if="row.data.targeting.websites"
            class="flex flex-row items-center gap-1 overflow-hidden flex-nowrap"
          >
            <TargetingSelectTag
              v-if="row.data.targeting.websites.action === 'wl'"
              class="text-green-600 bg-green-100 !m-0"
            >
              {{ t('targetings.whitelist') }}
            </TargetingSelectTag>
            <TargetingSelectTag
              v-else
              class="text-gray-700 bg-gray-200 !m-0"
            >
              {{ t('targetings.blacklist') }}
            </TargetingSelectTag>
            <ArrayComponent
              :value="value"
              truncate
            />
          </div>
        </template>

        <template #actions="{ row }">
          <AppLink
            :to="{ name: 'groups.seatsBoosterRules.edit', params: { groupId: 1, seatsBoosterRuleId: row.data.id } }"
            appearance="clear"
            size="xs"
            class="whitespace-nowrap !border-gray-border"
          >
            <span>
              <PlusSmallIcon class="inline-block w-4 h-4 mr-1" />
              {{ t('labels.checkRule') }}
            </span>
          </AppLink>
        </template>
      </Datatable>
    </div>
  </WidgetShell>
</template>

<script lang="ts">
import { PlusSmallIcon } from '@heroicons/vue/24/solid'
import snakeCase from 'lodash/snakeCase'
import { computed, defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRequest } from 'vue-request'

import { PaginateWithoutRecords, defaultPagination } from '@/types/paginate'

import { WidgetDefinition, translateDBName } from '@/plugins/dashboard'
import ArrayComponent from '@/plugins/datatable/Components/ArrayComponent.vue'
import DateComponent from '@/plugins/datatable/Components/DateComponent.vue'
import TruncateComponent from '@/plugins/datatable/Components/TruncateComponent.vue'
import { useDatatable, useDatatableColumns } from '@/plugins/datatable/datatable'
import { Column, FieldType } from '@/plugins/datatable/datatable.d'
import Datatable from '@/plugins/datatable/Datatable.vue'
import { extractResponse } from '@/plugins/datatable/utils'
import { SortOrder } from '@/plugins/filters'
import { ServerFilters } from '@/plugins/filters/filters'

import { useContextStore } from '@/store/context.store'

import { SeatsBoosterRule, SeatsBoosterRuleStatus } from '@/models/seatsBoosterRule'

import { fetchSeatsBoosterRules } from '@/services/seatsBoosterRules'

import AppLink from '@/components/Buttons/AppLink.vue'
import SegmentControl from '@/components/SegmentControl/SegmentControl.vue'
import { SegmentTemplate } from '@/components/SegmentControl/types'
import TargetingSelectTag from '@/components/TargetingSelect/TargetingSelectTag.vue'
import TooltipInfo from '@/components/Tooltip/TooltipInfo.vue'

import { WIDGET_PROPS, useEnlarged } from './Widget'
import WidgetShell from './WidgetShell.vue'

const supportedFilterDimensions = {
  inventoryPublisherId: 'publisher_id',
  inventoryPublisherName: 'Publisher.name'
}

export default defineComponent({
  components: {
    WidgetShell,
    TargetingSelectTag,
    TruncateComponent,
    DateComponent,
    ArrayComponent,
    AppLink,
    Datatable,
    PlusSmallIcon,
    TooltipInfo,
    SegmentControl
  },
  props: {
    ...WIDGET_PROPS
  },
  emits: ['remove-clicked', 'definition-changed'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const title = computed(() => props.definition.title || t('labels.seatsBoosterRule', 2))
    const contextStore = useContextStore()

    const selectedStatus = ref<SeatsBoosterRuleStatus>('planned')

    const segments: SegmentTemplate[] = [
      { id: 'planned', label: t('labels.comingSoon'), disabled: false },
      { id: 'running', label: t('labels.ongoing'), disabled: false },
      { id: 'completed', label: t('labels.finished'), disabled: false }
    ]

    const formatField = (fieldKey: string): string => {
      let params = ''
      const tmp = fieldKey.split('.')
      if (tmp.length > 1) {
        params = tmp[0][0].toUpperCase() + tmp[0].substring(1) + '.' + snakeCase(tmp[1])
      } else {
        params = snakeCase(fieldKey)
      }
      return params
    }

    const queryFilters = computed(() => {
      const sort: string[] = datatableFilters.sorts.value.map(s =>
        `${formatField(s.field)},${s.sortOrder === SortOrder.ASC ? 'ASC' : 'DESC'}`
      )
      let statusFilter = 'status||$eq||planned'
      switch (selectedStatus.value) {
        case 'running':
          statusFilter = 'status||$in||paused,running'
          break
        case 'completed':
          statusFilter = 'status||$in||aborted,completed'
          break
      }

      if (contextStore.selectedPublisher) {
        return {
          filter: [`publisher_id||$eq||${contextStore.selectedPublisher.id}`, statusFilter],
          sort
        }
      }

      if (props.filters.filters.inventoryPublisherId?.values?.length && props.filters.filters.inventoryPublisherName?.values?.length) {
        return {
          filter: [
            statusFilter,
            `${supportedFilterDimensions.inventoryPublisherId}||$in||${props.filters.filters.inventoryPublisherId.values.join(',')}`
          ],
          or: [
            statusFilter,
            `${supportedFilterDimensions.inventoryPublisherName}||$in||${props.filters.filters.inventoryPublisherName.values.join(',')}`
          ],
          sort
        }
      }

      const f: string[] = []
      Object.entries(supportedFilterDimensions).forEach(e => {
        if (props.filters.filters[e[0]]?.values?.length) {
          f.push(`${e[1]}||$in||${props.filters.filters[e[0]].values.join(',')}`)
        }
      })
      return { filter: [...f, statusFilter], sort }
    })

    const seatsBoosterRulesPagination = ref<PaginateWithoutRecords>(defaultPagination())
    const seatsBoosterRules = ref<SeatsBoosterRule[]>([])

    const fetch = () => fetchSeatsBoosterRules({ ...queryFilters.value, page: seatsBoosterRulesPagination.value.currentPage || 1 })

    const { runAsync, loading, error } = useRequest(fetch, {
      manual: true,

      onSuccess: (response) => {
        if (response) {
          if (response.data) {
            const { records, pagination } = extractResponse(response.data)
            seatsBoosterRules.value = records
            seatsBoosterRulesPagination.value = pagination
          }
        }
      }
    })

    const datatableColumns: Column[] = [
      {
        field: 'name',
        name: t('labels.name'),
        width: 160,
        sortable: true,
        filterableType: FieldType.STRING
      },
      {
        field: 'startedAt',
        name: t('labels.startedAt'),
        width: 150,
        sortable: true,
        filterableType: FieldType.DATE
      },
      {
        field: 'estimatedCompletedAt',
        name: t('labels.estimatedCompletedAt'),
        width: 200,
        sortable: true,
        filterable: false,
        filterableType: FieldType.DATE
      },
      {
        field: 'websites',
        name: t('seatsBoosterRules.targetedWebsites'),
        width: 240,
        sortable: false,
        filterableType: FieldType.ARRAY
      },
      {
        field: 'actions',
        name: t('labels.action', 2),
        width: 140,
        sortable: false,
        filterable: false
      }
    ]

    const columns = useDatatableColumns(datatableColumns)
    const datatableFilters = new ServerFilters(runAsync, columns, 'seatsBoosterRules', seatsBoosterRulesPagination, undefined, true)

    const { rows } = useDatatable(seatsBoosterRules, columns, datatableFilters, {
      sorts: 'server',
      onQueryUpdated: () => {
        runAsync()
      }
    })

    const onJSONEditorInput = (newDefinition: WidgetDefinition) => {
      emit('definition-changed', newDefinition)
    }

    const setupEnlarged = useEnlarged(props)

    const refresh = () => {
      return runAsync()
    }

    watch(
      () => queryFilters.value,
      () => refresh(),
      { deep: true }
    )

    return {
      title,
      loading,
      error,
      columns,
      rows,
      datatableFilters,
      seatsBoosterRulesPagination,
      selectedStatus,
      segments,
      ...setupEnlarged,

      onJSONEditorInput,
      refresh,
      translateDBName,
      t
    }
  }
})
</script>
