import { RouteRecordRaw } from 'vue-router'

import i18n from '@/plugins/i18n'

import { resetPasswordGuard } from '@/router/guards/resetPassword'

export const passwords: RouteRecordRaw[] = [
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () => import('./views/ForgotPassword.vue'),
    meta: {
      title: i18n.global.t('passwords.forgotPasswordTitle')
    }
  },
  {
    beforeEnter: resetPasswordGuard,
    path: '/reset-password/:token',
    name: 'resetPassword',
    component: () => import('./views/ResetPassword.vue'),
    props: route => ({ token: route.params.token }),
    meta: {
      title: i18n.global.t('passwords.forgotPasswordTitle')
    }
  }
]
