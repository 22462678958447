<template>
  <component
    :is="is"
    :class="classes"
    :type="is === 'button' ? 'button' : undefined"
  >
    <component
      :is="icon"
      v-if="icon"
      class="inline-flex w-4 h-4"
      aria-hidden="true"
    />
    <slot />
  </component>
</template>

<script lang="ts">
import { Component, DefineComponent, FunctionalComponent, PropType, computed, defineComponent } from 'vue'

import { BadgeTheme, BADGE_CLASSES, BASE_BADGE_CLASSES } from './theme'

export default defineComponent({
  props: {
    is: {
      type: String as PropType<'div' | 'span' | 'button'>,
      default: 'span'
    },
    icon: {
      type: [Object, Function] as PropType<Component | DefineComponent | FunctionalComponent | null | undefined>,
      required: false,
      default: () => undefined
    },
    theme: {
      type: Number as PropType<BadgeTheme>,
      default: BadgeTheme.BASE
    }
  },
  setup (props) {
    const classes = computed(() => {
      return BASE_BADGE_CLASSES.concat(BADGE_CLASSES[props.theme])
    })

    return {
      classes
    }
  }
})
</script>
