import sspEngineCodes from './sspEngineCodes.json'
import websites from './websites.json'

// Local enrichments allow to locally add more enrichment columns to
// an enrichment table.
// For example, this can be used to convert a country code to a country name
// without having to store this data into the database.
//
// For everything higher level than the sources, this is transparent and works just
// like regular enrichment.
//
// Caveat: needs to re-deploy front-end in case we need to support more values

export type LocalEnrichment = {
  [table: string]: {
    [column: string]: {
      [newColumn: string]: {
        oneToOne: boolean,
        values: Record<string, any>
      }
    }
  }
}

export default {
  WebsitesEnrichment: websites,
  CsmDailyConfigErrors: sspEngineCodes,
  CsmDailyConfigErrorsEnriched: sspEngineCodes
} as LocalEnrichment
