import { AxiosResponse } from 'axios'

import api from '../api'
import { Group } from '../models/group'
import { Paginate } from '../types/paginate'

export async function fetchGroups (queryParams?: Object): Promise<AxiosResponse<Paginate<Group[]>>> {
  return api.get<Paginate<Group[]>>('/groups', { params: queryParams })
}

export async function fetchGroupById (id: string): Promise<AxiosResponse<Group>> {
  return await api.get<Group>(`/groups/${id}`)
}
