import { AxiosResponse } from 'axios'

import api from '@/api'

export interface FilterResponse {
  id: number,
  value: number | string,
  name: number | string,
  disabled: boolean,
}

export async function fetchForSelectFilters (url: string, field: string, queryParams: object, requireContext: boolean = true): Promise<AxiosResponse<FilterResponse[]>> {
  const splitted = field.split('.')

  // Capitalize first letter to match join table
  splitted.forEach((element, index) => {
    if (index !== splitted.length - 1) {
      splitted[index] = element.charAt(0).toUpperCase() +
      element.slice(1)
    }
  })

  return api.get<FilterResponse[]>(`${url}?format=filter&fields=${splitted.join('.')}`, { requireContext, params: queryParams })
}
