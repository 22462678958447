import { Component, TextareaHTMLAttributes } from 'vue'

import { FormInputHints, FormInputMode } from '@/components/Form'
import FormComponent from '@/components/Form/FormTextarea.vue'

import { DefaultProps, FormRenderer } from './'

interface Props extends DefaultProps {
  attr?: TextareaHTMLAttributes,
  hints?: FormInputHints
  mode?: FormInputMode
}

export class FormTextarea implements FormRenderer<Props> {
  defaultProps: Props

  constructor (props: Props) {
    this.defaultProps = props
  }

  get wrapperClass (): string {
    return this.defaultProps.wrapperClass || ''
  }

  props (): Props {
    const attrs = { ...this.defaultProps.attr }
    const cleanProps = { ...this.defaultProps }
    delete cleanProps.attr

    return { ...cleanProps, ...attrs }
  }

  component (): Component {
    return FormComponent
  }
}
