import { CheckCircleIcon, NoSymbolIcon, StopCircleIcon } from '@heroicons/vue/20/solid'
import { Component } from 'vue'

import { BadgeTheme } from '@/components/Badge/theme'
import ActionRequired from '@/components/Icons/ActionRequired.vue'

export enum ApprovalStatusType {
  APPROVED = 'approved',
  BLOCKED = 'blocked',
  PENDING = 'pending',
  UNWHITELISTED = 'unwhitelisted'
}

export enum AggregatedApprovalStatusType {
  BLOCKED = 'blocked',
  PENDING = 'pending',
  WAITING = 'waiting',
  REQUESTED = 'requested',
  CONNECTED = 'connected'
}

export function getApprovalStatusBadgeTheme (status?: ApprovalStatusType | AggregatedApprovalStatusType | null): BadgeTheme {
  switch (status) {
    case ApprovalStatusType.APPROVED:
    case AggregatedApprovalStatusType.CONNECTED:
      return BadgeTheme.SUCCESS
    case ApprovalStatusType.BLOCKED:
    // case AggregatedApprovalStatusType.BLOCKED: // This duplicates the case above
      return BadgeTheme.BASE
    case ApprovalStatusType.PENDING:
    case AggregatedApprovalStatusType.REQUESTED:
      // case AggregatedApprovalStatusType.PENDING: // This duplicates the case above
      return BadgeTheme.WARNING
    case AggregatedApprovalStatusType.WAITING:
      return BadgeTheme.INFO
    case ApprovalStatusType.UNWHITELISTED:
      return BadgeTheme.DANGER
    default:
      return BadgeTheme.BASE
  }
}

export function getApprovalStatusIcon (status?: ApprovalStatusType | AggregatedApprovalStatusType | null): Component | null {
  switch (status) {
    case ApprovalStatusType.APPROVED:
    case AggregatedApprovalStatusType.CONNECTED:
      return CheckCircleIcon
    case ApprovalStatusType.BLOCKED:
    // case AggregatedApprovalStatusType.BLOCKED: // This duplicates the case above
      return NoSymbolIcon
    case AggregatedApprovalStatusType.WAITING:
    case AggregatedApprovalStatusType.PENDING:
    case AggregatedApprovalStatusType.REQUESTED:
      return ActionRequired
    case ApprovalStatusType.UNWHITELISTED:
      return StopCircleIcon
    default:
      return null
  }
}
