import cubejs, { CubejsApi, Filter, Query, TimeDimension, TimeDimensionRanged } from '@cubejs-client/core'
import { format } from 'date-fns'
import cloneDeep from 'lodash/cloneDeep'
import { watch } from 'vue'

import { useAuthStore } from '@/store/auth.store'

import api from '@/api'

import { Constructor, DataDefinition } from '../definition'
import { ComparisonGroupDimension, DateDimension, DateRollupDimension, GenericDimension, TimeGranularityName, deserializeDimension } from '../dimensions'

import LOCAL_ENRICHMENTS from './enrichment'
import HttpTransportWithRetry from './httpTransportRetry'

import { ComparisonGroup, DateRange, Enrichment, EnrichmentCache, FilterDimension, Filters, Preprocessor, QueryResult, SerializedSource, Source, preprocessConvertType, preprocessRemoveNull, preprocessRemoveTableName } from '.'

async function getAccessToken (): Promise<string> {
  const authStore = useAuthStore()

  if (!authStore.accessToken) {
    throw new Error('Unauthenticated')
  }
  return 'Bearer ' + authStore.accessToken
}

// To avoid querying Cube for each source instance, we only query it once per
// Cube instance (based on URL).
const TABLE_DEFINITIONS: Record<string, Record<string, DataDefinition>> = {}
const ENRICHMENT_TABLE_DEFINITIONS: Record<string, Record<string, DataDefinition>> = {}
const TABLE_DEFINITIONS_PROMISES: Record<string, Promise<void>> = {}
const ENRICHMENT_CACHE: Record<string, EnrichmentCache> = {}
const ENRICHMENT_PROMISES: Record<string, Promise<void>> = {}

export const CUBE_ENDPOINT = '/cube'

async function getExistingTables (url: string): Promise<void> {
  if (TABLE_DEFINITIONS_PROMISES[url] !== undefined) {
    await TABLE_DEFINITIONS_PROMISES[url]
    return
  }
  if (TABLE_DEFINITIONS[url] !== undefined) {
    return
  }
  const api = cubejs(getAccessToken, {
    apiUrl: url,
    transport: new HttpTransportWithRetry({
      authorization: '',
      apiUrl: url
    })
  })

  TABLE_DEFINITIONS_PROMISES[url] = new Promise<void>((resolve, reject) => {
    api.meta({ mutexKey: 'CubeSource.getExistingTables' })
      .then(meta => {
        TABLE_DEFINITIONS[url] = {}
        ENRICHMENT_TABLE_DEFINITIONS[url] = {}
        meta.cubes.filter(c => !c.name.endsWith('Filters')).forEach(cube => {
          const def: DataDefinition = {
            dimensions: cube.dimensions.map(d => {
              const name = d.name.replaceAll(`${cube.name}.`, '')
              if (d.type === 'time') {
                return new DateDimension(name)
              } else if (name === 'comparisonGroup') {
                return new ComparisonGroupDimension(name)
              }
              return new GenericDimension(name)
            }),
            metrics: {},
            hidden: cube.dimensions.filter(d => {
              const meta = (d as any).meta // Temporary fix until fix for this issue is released: https://github.com/cube-js/cube.js/issues/3682
              return typeof meta === 'object' && meta.hidden
            }).map(d => d.name.replaceAll(`${cube.name}.`, '')),
            oneToOne: []
          }
          cube.measures.forEach(m => {
            let type: Constructor
            switch (m.type) {
              case 'boolean':
                type = Boolean
                break
              case 'number':
                type = Number
                break
              case 'string':
                type = String
                break
              case 'time':
                type = String
                break
            }
            def.metrics[m.name.replaceAll(`${cube.name}.`, '')] = type
          })
          const localEnrichment = LOCAL_ENRICHMENTS[cube.name]
          if (localEnrichment !== undefined) {
            // Check if there are local enrichments for this table and inject them
            Object.entries(localEnrichment).filter(e => def.dimensions.some(d => d.name === e[0])).forEach(e => {
              Object.keys(e[1]).forEach(k => {
                def.dimensions.push(new GenericDimension(k, k, true))
                if (e[1][k].oneToOne) {
                  def.oneToOne.push(k)
                }
              })
            })
          }
          if (cube.name.endsWith('Enrichment')) {
            def.oneToOne.push(...cube.dimensions.filter(d => {
              const meta = (d as any).meta
              return typeof meta === 'object' && meta.oneToOne
            }).map(d => d.name.replaceAll(`${cube.name}.`, '')))
            ENRICHMENT_TABLE_DEFINITIONS[url][cube.name] = def
          } else {
            TABLE_DEFINITIONS[url][cube.name] = def
          }
        })
        resolve()
      })
      .catch(err => reject(err))
  })
    .finally(() => delete TABLE_DEFINITIONS_PROMISES[url])
  return TABLE_DEFINITIONS_PROMISES[url]
}

async function getTableDefinition (url: string, table: string): Promise<DataDefinition> {
  await getExistingTables(url)
  const def = TABLE_DEFINITIONS[url][table]
  if (def === undefined) {
    throw new Error(`Table ${table} doesn't exist in Cube meta`)
  }
  return {
    dimensions: [new ComparisonGroupDimension('comparisonGroup', 'comparisonGroup', false), ...def.dimensions],
    metrics: Object.assign({}, def.metrics),
    hidden: def.hidden,
    oneToOne: [...def.oneToOne]
  }
}

async function loadEnrichment (url: string, enrichment: Enrichment): Promise<void> {
  if (
    ENRICHMENT_CACHE[url] !== undefined &&
    ENRICHMENT_CACHE[url][enrichment.dimension] !== undefined &&
    ENRICHMENT_CACHE[url][enrichment.dimension][enrichment.table] !== undefined) {
    const table = ENRICHMENT_CACHE[url][enrichment.dimension][enrichment.table]
    const keys = Object.keys(table)
    if (keys.length !== 0) {
      const record = table[keys[0]]
      if (record[enrichment.foreignKey] !== undefined && enrichment.foreignDimensions.every(d => record[d.name] !== undefined)) {
        return
      }
    }
  }
  const promiseKey = `${url}|${enrichment.table}|${enrichment.dimension}|${enrichment.foreignKey}|${enrichment.foreignDimensions.map(d => d.name).join(',')}`

  await getExistingTables(url)

  if (ENRICHMENT_PROMISES[promiseKey] !== undefined) {
    return ENRICHMENT_PROMISES[promiseKey]
  }

  console.log('Cube loadEnrichment', enrichment)
  const api = cubejs(getAccessToken, {
    apiUrl: url,
    transport: new HttpTransportWithRetry({
      authorization: '',
      apiUrl: url,
      method: 'POST'
    })
  })

  const definition = ENRICHMENT_TABLE_DEFINITIONS[url][enrichment.table]
  const localEnrichment = LOCAL_ENRICHMENTS[enrichment.table]

  const dimensions: string[] = [];

  [enrichment.foreignKey, ...enrichment.foreignDimensions.map(fd => fd.name)].forEach(fd => {
    const foreignDimension = definition.dimensions.find(d => d.name === fd)
    if (foreignDimension !== undefined && !foreignDimension.enrichment) {
      dimensions.push(`${enrichment.table}.${fd}`)
    }
  })

  const query: Query = {
    dimensions,
    timezone: 'UTC',
    limit: 50000
  }
  ENRICHMENT_PROMISES[promiseKey] = new Promise<void>((resolve, reject) => {
    api.load(query, { mutexKey: 'CubeSource.loadEnrichment.' + enrichment.table })
      .then(resultSet => {
        const data = resultSet.rawData().map(record => {
          record = preprocessRemoveTableName(enrichment.table)(record)
          record = preprocessRemoveNull(definition)(record)
          record = preprocessConvertType(definition)(record)
          return record
        })
        if (ENRICHMENT_CACHE[url] === undefined) {
          ENRICHMENT_CACHE[url] = {}
        }
        if (ENRICHMENT_CACHE[url][enrichment.dimension] === undefined) {
          ENRICHMENT_CACHE[url][enrichment.dimension] = {}
        }
        if (ENRICHMENT_CACHE[url][enrichment.dimension][enrichment.table] === undefined) {
          ENRICHMENT_CACHE[url][enrichment.dimension][enrichment.table] = {}
        }
        data.forEach(r => {
          let record = ENRICHMENT_CACHE[url][enrichment.dimension][enrichment.table][r[enrichment.foreignKey]]
          if (record !== undefined) {
            Object.entries(r).forEach(e => {
              record[e[0]] = e[1]
            })
          } else {
            ENRICHMENT_CACHE[url][enrichment.dimension][enrichment.table][r[enrichment.foreignKey]] = r
          }
          if (localEnrichment !== undefined) {
            record = ENRICHMENT_CACHE[url][enrichment.dimension][enrichment.table][r[enrichment.foreignKey]]
            Object.entries(localEnrichment).filter(e => record[e[0]] !== undefined).forEach(e => {
              Object.entries(e[1]).forEach(x => {
                record[x[0]] = x[1].values[record[e[0]]] || 'unknown'
              })
            })
          }
        })
        resolve()
      })
      .catch(err => {
        reject(err)
      })
  })
    .finally(() => delete ENRICHMENT_PROMISES[promiseKey])
  return ENRICHMENT_PROMISES[promiseKey]
}

function preprocessEnrichments<T extends Record<string, any>> (url: string, enrichments: Enrichment[]): Preprocessor {
  return (record: Record<string, any>) => {
    enrichments.forEach(e => {
      if (
        ENRICHMENT_CACHE[url] !== undefined &&
        ENRICHMENT_CACHE[url][e.dimension] !== undefined &&
        ENRICHMENT_CACHE[url][e.dimension][e.table] !== undefined
      ) {
        const enrichmentRecord = ENRICHMENT_CACHE[url][e.dimension][e.table][record[e.dimension]]
        e.foreignDimensions.forEach(d => {
          record[d.alias] = enrichmentRecord !== undefined ? enrichmentRecord[d.name] : 'unknown'
        })
      }
    })
    return record as T
  }
}

function preprocessLocalEnrichments<T extends Record<string, any>> (table: string): Preprocessor {
  return (record: Record<string, any>) => {
    const localEnrichment = LOCAL_ENRICHMENTS[table]
    if (localEnrichment !== undefined) {
      Object.entries(localEnrichment).filter(e => record[e[0]] !== undefined).forEach(e => {
        Object.entries(e[1]).forEach(x => {
          record[x[0]] = x[1].values[record[e[0]]] || 'unknown'
        })
      })
    }
    return record as T
  }
}

/**
 * Source implementation for Cube.js.
 *
 * This implementation requires **two** cubes per resource: one for the data itself, and
 * one for the distinct dimension values (used for filters).
 *
 * The second cube must be named the same as the first one, with "Filters" suffix.
 * (e.g.: AdagioRevenueViewabilityAttention -> AdagioRevenueViewabilityAttentionFilters)
 * This cube must contain a measure called `order` that is always equal to 1:
 * ```
 * measures: {
 *   order: {
 *     type: "number",
 *     sql: `1`
 *   }
 * },
 * ```
 * It also needs all the dimensions that the main cube contains. All dimensions that can be used
 * for filtering (all except the time dimensions and `organization_id`) must use the following sql:
 * `ARRAY_AGG(DISTINCT dimension_name)`
 * e.g.:
 * ```
 * site: {
 *   sql: `ARRAY_AGG(DISTINCT site)`,
 *   type: `string`
 * },
 * ```
 */
export class CubeSource implements Source {
  api: CubejsApi
  uid: string
  name: string
  url: string
  table: string
  preprocessors: Preprocessor[]
  globalFilters: Record<string, string[]>
  definition?: DataDefinition

  enrichments: Enrichment[] // In cube, this is a table that ends with "Enrichment"

  constructor (uid: string, name: string, url: string, table: string, preprocessors: Preprocessor[] = [], enrichments: Enrichment[] = []) {
    this.url = url
    this.api = cubejs(getAccessToken, {
      apiUrl: url,
      transport: new HttpTransportWithRetry({
        authorization: '',
        apiUrl: url,
        method: 'POST'
      })
    })
    this.uid = uid
    this.name = name
    this.table = table
    this.enrichments = enrichments || []
    this.globalFilters = {}
    this.preprocessors = [...preprocessors]

    watch(
      () => this.enrichments.length,
      async () => {
        console.log(this.uid, 'CubeSource: enrichment changed, reloading table definition')
        this.definition = undefined
        await this.getTableDefinition()
      }
    )
  }

  async query (storeUID: string, definition: DataDefinition, timeDimensions: string[], refreshDimensions: string[], comparisonGroups: ComparisonGroup[], filters: Filters, granularity: TimeGranularityName): Promise<QueryResult> {
    await this.getTableDefinition()
    await Promise.all(this.enrichments.map(e => loadEnrichment(this.url, e)))

    if (comparisonGroups.length && definition.dimensions.some(d => d.name === 'comparisonGroup')) {
      const promises: Array<Promise<QueryResult>> = []

      comparisonGroups.forEach(comparisonGroup => {
        const consolidatedFilters = { ...filters }
        consolidatedFilters.filters = { ...comparisonGroup.filters, ...filters.filters }

        promises.push(this.buildQueryPromise(storeUID, definition, timeDimensions, refreshDimensions, consolidatedFilters, granularity))
      })

      return new Promise<QueryResult>((resolve, reject) => {
        Promise.all(promises).then((resultSets) => {
          const records:Array<Record<string, any>> = []

          resultSets.forEach((resultSet, index) => {
            records.push(...resultSet.records.map((record) => { return { comparisonGroup: comparisonGroups[index].label, ...record } }))
          })

          resolve({
            dateRange: Object.assign({}, filters.dateRange),
            records
          })
        }).catch(err => {
          reject(err)
        })
      })
    }

    return this.buildQueryPromise(storeUID, definition, timeDimensions, refreshDimensions, filters, granularity)
  }

  buildQueryPromise (storeUID: string, definition: DataDefinition, timeDimensions: string[], refreshDimensions: string[], filters: Filters, granularity: TimeGranularityName):Promise<QueryResult> {
    const query = this.buildQuery(definition, timeDimensions, refreshDimensions, filters, granularity)

    return new Promise<QueryResult>((resolve, reject) => {
      this.api.load(query, { mutexKey: storeUID + '.CubeSource.query' })
        .then(resultSet => {
          const data = resultSet.rawData().map(record => {
            record = preprocessRemoveTableName(this.table)(record)
            record = preprocessRemoveNull(definition)(record)
            record = preprocessConvertType(definition)(record)
            record = preprocessEnrichments(this.url, this.enrichments)(record)
            record = preprocessLocalEnrichments(this.table)(record)
            this.preprocessors.forEach(p => {
              record = p(record)
            })
            return record
          })
          resolve({
            dateRange: Object.assign({}, filters.dateRange),
            records: data
          })
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  buildQuery (definition: DataDefinition, timeDimensions: string[], refreshDimensions: string[], filters: Filters, granularity: TimeGranularityName): Query {
    const f: Filter[] = []

    Object.keys(this.globalFilters)
      .filter(d => this.dimensionExistsInTable(d))
      .forEach(d => {
        f.push({
          member: `${this.table}.${d}`,
          operator: 'equals',
          values: [...this.globalFilters[d]]
        })
      })

    Object.keys(filters.filters)
      .filter(d => refreshDimensions.includes(d) && this.dimensionExistsInTable(d))
      .forEach(d => {
        const values = filters.filters[d]
        f.push({
          member: `${this.table}.${d}`,
          operator: values.operator,
          values: [...values.values.map(v => String(v))]
        })
      })

    const from = format(filters.dateRange.from, "yyyy-MM-dd'T'HH:mm:ss.SSS")
    const to = format(filters.dateRange.to, "yyyy-MM-dd'T'HH:mm:ss.SSS")
    timeDimensions
      .forEach(d => {
        f.push({
          member: `${this.table}.${d}`,
          operator: 'inDateRange',
          values: [from, to]
        })
      })

    const query: Query = {
      timeDimensions: this.prepareTimeDimensions(definition, filters.dateRange, granularity),
      dimensions: definition.dimensions.filter(d => !(d instanceof DateDimension) && !(d instanceof DateRollupDimension) && !(d instanceof ComparisonGroupDimension) && !d.enrichment).map(d => `${this.table}.${d.name}`),
      measures: Object.keys(definition.metrics).map(m => `${this.table}.${m}`),
      filters: f,
      // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timezone: 'UTC',
      limit: 50000
    }
    return query
  }

  prepareTimeDimensions (definition: DataDefinition, dateRange: DateRange, granularity: TimeGranularityName): TimeDimension[] {
    return definition.dimensions.filter(d => d instanceof DateDimension || d instanceof DateRollupDimension).map(d => {
      const from = format(dateRange.from, "yyyy-MM-dd'T'HH:mm:ss.SSS")
      const to = format(dateRange.to, "yyyy-MM-dd'T'HH:mm:ss.SSS")
      return {
        dimension: `${this.table}.${d.name}`,
        dateRange: [from, to],
        granularity
      } as TimeDimensionRanged
    })
  }

  async getDimensionValues (dimensions: string[], timeDimensions: string[], filters: Filters): Promise<Record<string, FilterDimension>> {
    await this.getTableDefinition()
    await Promise.all(this.enrichments.map(e => loadEnrichment(this.url, e)))

    const filteredDimensions = dimensions.filter(d => !timeDimensions.includes(d) && !this.definition!.dimensions.find(dim => dim.name === d)?.enrichment).map(d => `${this.table}Filters.${d}Values`)
    if (filteredDimensions.length === 0) {
      return {}
    }
    const f: Filter[] = []

    Object.keys(this.globalFilters)
      .filter(d => this.dimensionExistsInTable(d))
      .forEach(d => {
        f.push({
          member: `${this.table}Filters.${d}`,
          operator: 'equals',
          values: [...this.globalFilters[d]]
        })
      })

    timeDimensions.forEach(d => {
      f.push({
        member: `${this.table}Filters.${d}`,
        operator: 'inDateRange',
        values: [
          format(filters.dateRange.from, 'yyyy-MM-dd'),
          format(filters.dateRange.to, 'yyyy-MM-dd')
        ]
      })
    })

    Object.keys(filters.filters)
      .filter(d => this.dimensionExistsInTable(d))
      .forEach(d => {
        const values = filters.filters[d]
        f.push({
          member: `${this.table}Filters.${d}`,
          operator: values.operator,
          values: [...values.values]
        })
      })

    const query: Query = {
      dimensions: filteredDimensions,
      filters: f,
      // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timezone: 'UTC',
      ungrouped: true,
      order: [
        [`${this.table}Filters.order`, 'asc']
      ],
      limit: 50000
    }
    return new Promise<Record<string, FilterDimension>>((resolve, reject) => {
      this.api.load(query, { mutexKey: this.uid + '.CubeSource.getDimensionValues' })
        .then(resultSet => {
          const data: Record<string, FilterDimension> = resultSet.rawData().map((r: Record<string, string[]>) => {
            const filterDimensions: Record<string, FilterDimension> = {}
            for (const key in r) {
              let name = key.replace(`${this.table}Filters.`, '')
              name = name.endsWith('Values') ? name.substring(0, name.length - 6) : name
              const values: Record<string, string[]> = {}
              r[key].sort().forEach(v => {
                values[v] = [v]
              })
              filterDimensions[name] = {
                name,
                label: name,
                values
              }
            }
            return filterDimensions
          })[0]
          const localEnrichment = LOCAL_ENRICHMENTS[this.table]
          if (localEnrichment !== undefined) {
            Object.entries(localEnrichment).filter(e => data[e[0]] !== undefined).forEach(originalColumn => {
              Object.entries(originalColumn[1]).forEach(enrichedColumn => {
                const values: Record<string, string[]> = {}
                Object.entries(enrichedColumn[1].values).forEach(mapping => {
                  if (values[mapping[1]] === undefined) {
                    values[mapping[1]] = []
                  }
                  values[mapping[1]].push(mapping[0])
                })
                data[enrichedColumn[0]] = {
                  name: enrichedColumn[0],
                  label: enrichedColumn[0],
                  enrichment: {
                    name: originalColumn[0],
                    oneToOne: enrichedColumn[1].oneToOne
                  },
                  values
                }
              })
            })
          }
          this.enrichments.forEach(e => {
            if (
              ENRICHMENT_CACHE[this.url] !== undefined &&
              ENRICHMENT_CACHE[this.url][e.dimension] !== undefined &&
              ENRICHMENT_CACHE[this.url][e.dimension][e.table] !== undefined
            ) {
              const def = ENRICHMENT_TABLE_DEFINITIONS[this.url][e.table]
              e.foreignDimensions.forEach(fd => {
                const values: Record<string, string[]> = {}
                Object.entries(ENRICHMENT_CACHE[this.url][e.dimension][e.table]).forEach(e => {
                  const v = e[1][fd.name]
                  if (values[v] !== undefined) {
                    values[v].push(e[0])
                  } else {
                    values[v] = [e[0]]
                  }
                })
                data[fd.alias] = {
                  name: fd.alias,
                  label: fd.alias,
                  enrichment: {
                    name: e.dimension,
                    oneToOne: def.oneToOne.includes(fd.name)
                  },
                  values
                }
              })
            }
          })
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  async getTableDefinition (): Promise<DataDefinition> {
    if (this.definition !== undefined) {
      return this.definition
    }
    this.definition = await getTableDefinition(this.url, this.table)
    this.enrichments.forEach(e => {
      const dimensions = ENRICHMENT_TABLE_DEFINITIONS[this.url][e.table].dimensions
      e.foreignDimensions.forEach(fd => {
        const dimension = dimensions.find(d => fd.name === d.name)
        if (dimension === undefined) {
          throw new Error()
        }
        const copy = dimension.serialize()
        copy.name = fd.alias
        copy.params.column = fd.alias
        copy.params.enrichment = true

        if (!this.definition!.dimensions.some(dim => dim.name === copy.name)) {
          this.definition!.dimensions.push(deserializeDimension(copy))
        }
      })
    })
    return this.definition
  }

  async getExistingTables (): Promise<Record<string, DataDefinition>> {
    await getExistingTables(this.url)
    return TABLE_DEFINITIONS[this.url]
  }

  async getExistingEnrichmentTables (): Promise<Record<string, DataDefinition>> {
    await getExistingTables(this.url)
    return ENRICHMENT_TABLE_DEFINITIONS[this.url]
  }

  dimensionExistsInTable (dimension: string): boolean {
    return this.definition?.dimensions.find(dim => dim.name === dimension && !dim.enrichment) !== undefined
  }

  // Find the dimension on which the given enriched dimension depends
  findDependency (dimension: string): string | undefined {
    if (this.definition === undefined) {
      return undefined
    }
    const localEnrichment = LOCAL_ENRICHMENTS[this.table]
    if (localEnrichment !== undefined) {
      const enrichment = Object.entries(localEnrichment).find(e => Object.keys(e[1]).includes(dimension))
      if (enrichment !== undefined) {
        return enrichment[0]
      }
    }

    return this.enrichments.find(e => e.foreignDimensions.find(fd => fd.alias === dimension))?.dimension
  }

  serialize (): SerializedSource {
    return {
      uid: this.uid,
      name: this.name,
      table: this.table,
      constructor: 'CubeSource',
      enrichment: cloneDeep(this.enrichments)
    }
  }

  static deserialize (def: SerializedSource): Source {
    if (typeof def.uid !== 'string') {
      throw new Error('Invalid SerializedSource: missing uid')
    }
    if (def.name === undefined) {
      def.name = def.uid
    }
    if (typeof def.name !== 'string') {
      throw new Error('Invalid SerializedSource: missing name')
    }
    if (typeof def.table !== 'string') {
      throw new Error('Invalid SerializedSource: missing table')
    }
    return new CubeSource(def.uid, def.name, api.contextURL(CUBE_ENDPOINT, `${import.meta.env.VITE_DATA_API_BASE_URL}/api/v1`), def.table, [], def.enrichment)
  }
}
