import i18n from '@/plugins/i18n'

import { Role } from '@/models/enum/roles'

export const userRoles = [
  {
    id: Role.ADMIN,
    name: i18n.global.t('roles.admin')
  },
  {
    id: Role.ADOPS,
    name: i18n.global.t('roles.adops')
  },
  {
    id: Role.VIEWER,
    name: i18n.global.t('roles.viewer')
  }
]

export const billingOptions = [
  {
    id: 0,
    name: i18n.global.t('labels.disabled')
  },
  {
    id: 1,
    name: i18n.global.t('labels.enabled')
  }
]

export const publisherRoles = [
  {
    id: Role.ADMIN,
    name: i18n.global.t('roles.admin'),
    description: i18n.global.t('roles.adminDescription')
  },
  {
    id: Role.ADOPS,
    name: i18n.global.t('roles.adops'),
    description: i18n.global.t('roles.adopsDescription')
  },
  {
    id: Role.VIEWER,
    name: i18n.global.t('roles.viewer'),
    description: i18n.global.t('roles.viewerDescription')
  }
]

export const bidderRoles = [
  {
    id: Role.ADMIN,
    name: i18n.global.t('roles.admin'),
    description: i18n.global.t('roles.adminDescription')
  },
  {
    id: Role.ACCOUNT_MANAGER,
    name: i18n.global.t('roles.accountManager', 2),
    description: i18n.global.t('roles.accountManagerDescription')
  },
  {
    id: Role.VIEWER,
    name: i18n.global.t('roles.viewer'),
    description: i18n.global.t('roles.viewerDescription')
  }
]

export const billingRole = {
  id: Role.BILLING,
  name: i18n.global.t('roles.billing', 2),
  description: i18n.global.t('roles.billingDescription')
}

export const rolesGroup = {
  management: [Role.ADMIN, Role.CSM],
  operation: [Role.ADMIN, Role.ADOPS, Role.CSM],
  bidderOperation: [Role.ADMIN, Role.ACCOUNT_MANAGER, Role.PSM],
  adagio: [Role.PSM, Role.CSM],
  user: [Role.ADMIN, Role.ACCOUNT_MANAGER, Role.ADOPS, Role.CSM, Role.PSM, Role.VIEWER],
  billing: [Role.ADMIN, Role.BILLING, Role.CSM],
  all: [Role.ADMIN, Role.ACCOUNT_MANAGER, Role.ADOPS, Role.CSM, Role.PSM, Role.VIEWER]
}

export const organizationRoles = [Role.ADMIN, Role.ADOPS, Role.VIEWER]
