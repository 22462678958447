import Bugsnag from '@bugsnag/js'
import { defineStore } from 'pinia'
import { ref } from 'vue'

import heapAnalytics from '@/plugins/heapAnalytics'
import { getPermission } from '@/plugins/permissions'
import segment from '@/plugins/segmentio'

import { useContextStore } from '@/store/context.store'

import { Profile } from '@/models/profile'

export const useProfileStore = defineStore('profile', () => {
  const contextStore = useContextStore()

  const profile = ref<Profile | undefined>(undefined)
  const isCustomerView = ref(false)

  const setProfile = (newProfile: Profile): void => {
    profile.value = newProfile

    Bugsnag.setUser(
      newProfile.id.toString(),
      newProfile.email,
      `${newProfile.firstname} ${newProfile.lastname}`
    )

    segment.identify(profile.value.id.toString(), {
      email: profile.value.email,
      serviceUser: profile.value.isService,
      roles: profile.value.permissions.map((p) => ({
        resourceId: p.resourceId,
        resourceType: p.resourceType,
        roles: p.roles
      }))
    })

    heapAnalytics.identify(newProfile.id.toString())

    heapAnalytics.addUserProperties({
      email: newProfile.email, // This one is a built-in property
      userId: newProfile.id.toString(),
      firstname: newProfile.firstname,
      lastname: newProfile.lastname
    })
  }

  const clearProfile = (): void => {
    profile.value = undefined
    heapAnalytics.resetIdentity()
  }

  const setCustomerView = (): void => {
    isCustomerView.value = !isCustomerView.value
  }

  const changeCustomerView = (): void => {
    setCustomerView()
    const permission = getPermission(contextStore.context)
    segment.track('viewModeChanged', {
      isCustomerView: isCustomerView.value,
      roles: permission?.roles
    })
  }

  const disableCustomerView = (): void => {
    isCustomerView.value = false
  }

  return {
    profile,
    isCustomerView,
    setProfile,
    clearProfile,
    setCustomerView,
    changeCustomerView,
    disableCustomerView
  }
})
