<template>
  <div
    :class="wrapperClass"
    class="relative"
  >
    <Tooltip
      :disabled="(fieldErrors?.$errors?.length || 0) <= 0"
      :closeable="true"
      :force-show="true"
    >
      <div class="flex flex-col">
        <label
          v-if="label"
          :for="idValue"
          class="flex w-full text-xs font-semibold text-text-primary"
          :aria-label="label"
        >
          <span
            v-if="label"
            :class="[
              mode === 'materialize' ? 'hidden' : 'mb-2',
              $attrs.required || isRequired ? `after:content-['*']` : ''
            ]"
          >
            {{ label }}
          </span>
          <span
            v-if="hints.textTop"
            class="mb-2 ml-auto text-xs font-normal text-slate-400"
          > {{ hints.textTop }}</span>
        </label>

        <div class="relative flex flex-col w-full">
          <Popover
            v-slot="{ open }"
            class="relative"
          >
            <PopoverButton
              as="div"
              :class="[
                inputClasses,
                mode === 'materialize' ? 'peer order-2 transition-all focus:pt-[1.375rem] focus:pb-1 autofill:pt-[1.375rem] autofill:pb-1' : '',
                hints.tooltip ? '!pr-8' : '',
                open && !isDisabled ? '!border-primary-500': ''
              ]"
            >
              <span
                v-if="modelValue"
                class="font-medium"
              >
                {{ format(new Date(modelValue), time ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd') }}
              </span>
              <CalendarIcon
                v-if="withLeftIcon"
                class="absolute w-5 h-5 -translate-y-1/2 top-1/2 left-3"
                aria-hidden="true"
              />
            </PopoverButton>
            <transition
              v-if="!isDisabled"
              enter-active-class="transition duration-150 ease-out"
              enter-from-class="translate-y-1 opacity-0"
              enter-to-class="translate-y-0 opacity-100"
              leave-active-class="transition duration-150 ease-in"
              leave-from-class="translate-y-0 opacity-100"
              leave-to-class="translate-y-1 opacity-0"
            >
              <PopoverPanel
                class="absolute z-30 mt-2 max-w-[440px] overflow-hidden select-none
                text-text-primary
                bg-white border border-gray-border rounded-md shadow-lg"
              >
                <div
                  class="flex flex-col items-center w-full max-w-full overflow-visible sm:flex-row sm:items-start sm:space-x-4"
                >
                  <DatePicker
                    v-model="value"
                    :value="value"
                    :mode="time? 'datetime' : 'date'"
                    :is24hr="true"
                    :is-required="isRequired"
                    :min-date="minDate"
                    :max-date="maxDate"
                    color="indigo"
                    v-bind="$attrs"
                  />
                </div>
              </PopoverPanel>
            </transition>
          </Popover>
          <span
            v-if="mode=== 'materialize'"
            class="order-1 absolute top-[50%] -mt-[11px] left-[1px] pointer-events-none transition-all block pl-5 pr-2.5 text-base leading-5.5 text-slate-500 font-semibold border-none
                peer-focus:text-xs peer-focus:top-[6px] peer-focus:mt-0 peer-focus:leading-4.5
                peer-autofill:text-xs peer-autofill:top-[6px] peer-autofill:mt-0 peer-autofill:leading-4.5
                w-full truncate"
            :class="[
              (value !== '' && value !== null) ? '!text-xs !top-[6px] !mt-0 !leading-4.5' : '',
              $attrs.required || isRequired ? `after:content-['*']` : '',
              withLeftIcon ? '!pl-10' : ''
            ]"
          >
            {{ label }}
          </span>
        </div>
      </div>

      <template
        v-if="(fieldErrors?.$errors?.length || 0) > 0"
        #title
      >
        <div class="flex flex-col gap-0.5">
          <p
            v-for="(err, i) in fieldErrors!.$errors"
            :key="i"
            class="inline-block text-xs font-normal"
          >
            {{ err }}
          </p>
        </div>
      </template>
    </Tooltip>
    <Tooltip
      v-if="hints.tooltip"
      :reference-props="{
        class: 'absolute right-2 ' + (mode === 'materialize' ? 'top-1/2 -translate-y-1/2' : 'top-0')
      }"
    >
      <InformationCircleIcon class="w-5 h-5 text-primary-400" />
      <template #content>
        <p class="text-sm">
          {{ hints.tooltip }}
        </p>
      </template>
    </Tooltip>
    <FormHelpText
      v-if="hints.textBottom"
      :help-text="hints.textBottom"
    />
  </div>
</template>

<script lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { InformationCircleIcon, CalendarIcon } from '@heroicons/vue/24/outline'
import { ErrorObject } from '@vuelidate/core'
import { format, formatRFC3339, isBefore, startOfMinute } from 'date-fns'
import { DatePicker } from 'v-calendar'
import { computed, defineComponent, PropType } from 'vue'

import { useVuelidateError } from '@/plugins/form'

import { randomString } from '@/utils/utils'

import Tooltip from '@/components/Tooltip/Tooltip.vue'

import FormHelpText from './FormHelpText.vue'

import { FormInputHints, FormInputMode } from '.'

export default defineComponent({
  components: {
    Tooltip,
    CalendarIcon,
    InformationCircleIcon,
    Popover,
    PopoverButton,
    PopoverPanel,
    DatePicker,
    FormHelpText
  },
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: String as PropType<string | null>,
      required: false,
      default: ''
    },
    errors: {
      type: Array as PropType<ErrorObject[]>,
      required: false,
      default: () => []
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    wrapperClass: {
      type: String,
      required: false,
      default: ''
    },
    mode: {
      type: String as PropType<FormInputMode>,
      required: false,
      default: 'materialize'
    },
    time: {
      type: Boolean,
      default: false
    },
    hints: {
      type: Object as PropType<FormInputHints>,
      required: false,
      default: () => ({})
    },
    minDate: {
      type: Date,
      default: () => undefined
    },
    maxDate: {
      type: Date,
      default: () => undefined
    },
    checkIsBefore: {
      type: Boolean,
      required: false,
      default: () => true
    },
    withLeftIcon: {
      type: Boolean,
      required: false,
      default: () => true
    }
  },
  emits: ['update:modelValue'],
  setup (props, { attrs, emit }) {
    const value = computed({
      get () {
        return props.modelValue
      },
      set (value: string | Date | null) {
        const input = startOfMinute(value as Date)
        const now = startOfMinute(new Date())
        const before = isBefore(input, now)
        const date = props.checkIsBefore && before ? now : input
        // FIXME the time picker is not updated automatically if this date is overridden
        // The v-calendar library seems to be abandoned
        // Alternative: https://vue3datepicker.com/
        emit('update:modelValue', value !== null ? formatRFC3339(date) : value)
      }
    })

    const idValue = `${props.name}-${randomString(12)}`

    const inputClasses = computed(() => {
      const css = [
        'relative', 'whitespace-nowrap', 'truncate',
        'block', 'w-full',
        'outline-none', 'ring-0'
      ]

      if (props.isDisabled || attrs.readonly) {
        css.push('bg-gray-200')
      } else {
        css.push('cursor-pointer')
      }

      switch (props.mode) {
        case 'default':
          css.push('rounded-lg', 'pl-5', 'pr-2.5', 'py-3',
            'font-normal', 'text-base', 'leading-[1.375rem]', 'text-text-primary',
            'border', 'border-gray-border')
          break
        case 'materialize':
          css.push('rounded-lg', 'pl-5', 'pr-2.5',
            'text-base', 'leading-[1.375rem]', 'text-text-primary',
            'border', 'border-gray-border')
          css.push('h-12.125')

          if (value.value !== '') {
            css.push('pt-[1.375rem]', 'pb-1', 'font-semibold')
          }

          if (props.withLeftIcon) {
            css.push('!pl-10')
          }
          break
      }

      if (fieldErrors.value?.$errors?.length) {
        css.push('!border-red-400')
      } else {
        css.push('')
      }

      return css.join(' ')
    })

    const fieldErrors = computed(() => useVuelidateError(props.errors))

    return {
      idValue,
      inputClasses,
      value,
      fieldErrors,
      format
    }
  }
})
</script>

<style scoped>
  .vc-container {
    @apply border-0 mx-0;
    @apply w-full;
  }

  @media  (min-width:768px) and (max-width:1023px) {
    .vc-container {
      @apply w-full;
    }
  }
</style>
<style>
  .vc-select select {
    @apply bg-none;
  }
</style>
