import { RouteRecordRaw } from 'vue-router'

import { rolesGroup } from '@/static/roles'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

export function splitRules (): RouteRecordRaw[] {
  const context = ContextType.PUBLISHERS
  return [
    {
      path: 'splitRules',
      name: `${context}.splitRules.index`,
      component: () => import('./views/Index.vue'),
      meta: {
        title: i18n.global.t('labels.splitRule', 2),
        featuresPermissions: ['splite_rules'],
        feature: 'splite_rules',
        roles: rolesGroup.user
      },
      props: route => ({ publisherId: route.params.publisherId }),
      children: [
        {
          path: 'create',
          name: `${context}.splitRules.create`,
          component: () => import('./views/Create.vue'),
          meta: {
            title: i18n.global.t('splitRules.create'),
            featuresPermissions: ['splite_rules'],
            feature: 'splite_rules',
            roles: rolesGroup.operation
          },
          props: route => ({ publisherId: route.params.publisherId })
        },
        {
          path: ':splitRuleId',
          name: `${context}.splitRules.edit`,
          component: () => import('./views/Edit.vue'),
          meta: {
            title: i18n.global.t('splitRules.edit', { name: ':splitRuleName' }),
            pattern: ':splitRuleName',
            featuresPermissions: ['splite_rules'],
            feature: 'splite_rules'
          },
          props: route => ({ splitRuleId: route.params.splitRuleId })
        }
      ]
    }
  ]
}
