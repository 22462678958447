<template>
  <Popper
    :transition-props="{
      'enterActiveClass': 'transition-opacity duration-150 ease-out',
      'enterFromClass': 'opacity-0',
      'enterToClass': 'opacity-100',
      'leaveActiveClass': 'transition-opacity duration-150 ease-in',
      'leaveFromClass': 'opacity-100',
      'leaveToClass': 'opacity-0'
    }"
    :reference-props="{
      'class': 'flex items-center p-2 text-text-primary hover:text-primary-500 gap-x-2 cursor-pointer select-none'
    }"
    :popper-props="{
      'class': 'absolute bg-white p-5 right-0 border border-gray-border rounded-md z-30 w-80 max-w-full'
    }"
    trigger="click-to-toggle"
    placement="bottom-end"
    :teleport-props="{ to: 'body' }"
  >
    <template #reference>
      <span>
        <EyeIcon class="w-4 h-4" />
      </span>
      <span
        v-if="currentView"
        class="text-sm font-semibold capitalize"
      >
        <span v-if="currentView.isDefault">{{ t('app.defaultView') }}</span>
        <span v-else>{{ currentView.name }}</span>
      </span>
      <span
        v-else
        class="text-sm font-semibold capitalize"
      >
        {{ t('app.defaultView') }}
      </span>
      <span><ChevronDownIcon class="w-3 h-3" /></span>
    </template>
    <template #default>
      <!-- Panel Header -->
      <div class="flex items-center justify-between pb-2 border-b">
        <div class="flex items-center space-x-2">
          <EyeIcon class="w-4 h-4 text-text-primary" />
          <span class="text-sm font-semibold capitalize">{{ t('app.view', 100) }}</span>
        </div>

        <!-- Actions -->

        <div v-if="currentViewType === ViewType.FORM || currentViewType === ViewType.DELETE">
          <AppButton
            type="button"
            appearance="transparent"
            size="sm"
            :left-icon="ArrowLeftIcon"
            @click.stop="onBack()"
          >
            {{ t('actions.back') }}
          </AppButton>
        </div>

        <div v-else>
          <AppButton
            v-if="canSave"
            type="button"
            appearance="transparent"
            size="sm"
            :is-loading="saveButtonLoading"
            :is-disabled="saveButtonLoading"
            @click.stop="onSave()"
          >
            {{ t('actions.save') }}
          </AppButton>

          <AppButton
            type="button"
            appearance="transparent"
            size="sm"
            @click.stop="currentViewType = ViewType.FORM"
          >
            {{ t('actions.saveAs') }}
          </AppButton>
        </div>
      </div>

      <!-- Content -->
      <div class="pt-4">
        <ViewsList
          v-if="currentViewType === ViewType.LIST"
          v-model:filters="filtersController"
          :default-view="defaultViewForCurrentTable"
          @update-edit-view="(view) => editableView = view"
          @update-view-type="(viewType) => currentViewType = viewType"
        />

        <ViewsForm
          v-if="currentViewType === ViewType.FORM"
          v-model:editable-view="editableView"
          v-model:filters="filtersController"
          @update-view-type="(viewType) => currentViewType = viewType"
          @update:view="onViewUpdated"
        />

        <ViewsDelete
          v-if="currentViewType === ViewType.DELETE"
          v-model:filters="filtersController"
          :editable-view="editableView"
          @update-edit-view="(view) => editableView = view"
          @update-view-type="(viewType) => currentViewType = viewType"
        />
      </div>
    </template>
  </Popper>
</template>

<script lang="ts">
import { ArrowLeftIcon, ChevronDownIcon, EyeIcon } from '@heroicons/vue/24/solid'
import { PropType, computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { rolesGroup } from '@/static/roles'

import { BaseFilters, ServerFilters } from '@/plugins/filters/filters'
import { hasRole, useRoutePermissions, userIsService } from '@/plugins/permissions'

import { useNotificationsStore } from '@/store/notifications.store'
import { useViewStore } from '@/store/view.store'

import { View } from '@/models/view'

import AppButton from '@/components/Buttons/AppButton.vue'
import Popper from '@/components/Tooltip/Popper.vue'

import ViewsDelete from './ViewsDelete.vue'
import ViewsForm from './ViewsForm.vue'
import ViewsList from './ViewsList.vue'

export enum ViewType {
  LIST = 'LIST',
  FORM = 'FORM',
  DELETE = 'DELETE'
}

export default defineComponent({
  components: {
    Popper,
    EyeIcon,
    ChevronDownIcon,
    ViewsList,
    ViewsForm,
    ViewsDelete,
    AppButton
  },
  props: {
    filters: {
      type: Object as PropType<BaseFilters>,
      required: true
    }
  },
  emits: ['update:filters'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const viewStore = useViewStore()
    const notificationsStore = useNotificationsStore()

    const currentRoute = useRoute()
    const router = useRouter()

    const { roles } = useRoutePermissions(currentRoute)

    const filtersController = computed({
      get () {
        return props.filters as ServerFilters
      },
      set (filters: ServerFilters) {
        emit('update:filters', filters)
      }
    })

    const defaultViewForCurrentTable = ref<View>()

    const setDefaultView = () => {
      const defaultView = viewStore.defaultViews.find((v) => v.pageContext === filtersController.value.tableName && v.isDefault)

      if (defaultView) {
        if (!currentRoute.query.view || (currentRoute.query.view && typeof currentRoute.query.view === 'string' && parseInt(currentRoute.query.view) === defaultView.id)) {
          filtersController.value.currentView.value = defaultView
          defaultViewForCurrentTable.value = defaultView
        }
      }
    }

    onMounted(() => {
      setDefaultView()
    })

    watch(
      router.currentRoute,
      () => {
        setDefaultView()
      }
    )

    const currentView = computed(() => filtersController.value.currentView.value)

    const currentViewType = ref(ViewType.LIST)

    const editableView = ref<View | undefined>()

    const saveButtonLoading = ref(false)

    const onViewUpdated = (view: View) => {
      filtersController.value.currentView.value = view
    }

    const onSave = async () => {
      if (saveButtonLoading.value) {
        return
      }

      if (currentView.value) {
        saveButtonLoading.value = true

        try {
          await viewStore.updateView({
            id: currentView.value.id,
            form: {
              name: currentView.value.name,
              userId: currentView.value.userId,
              resourceId: currentView.value.resourceId,
              resourceType: currentView.value.resourceType,
              content: filtersController.value.viewContent
            }
          })
        } catch (err) {
          notificationsStore.add({
            title: t('messages.updateError'),
            message: err as string,
            type: 'error'
          })
        } finally {
          saveButtonLoading.value = false
        }
      } else {
        if (!userIsService()) {
          return
        }

        saveButtonLoading.value = true

        if (defaultViewForCurrentTable.value) {
          updateDefaultView(defaultViewForCurrentTable.value)
        } else {
          createDefaultView()
        }
      }
    }

    const updateDefaultView = async (view: View) => {
      try {
        await viewStore.updateView({
          id: view.id,
          form: {
            content: filtersController.value.viewContent
          }
        })
      } catch (err) {
        notificationsStore.add({
          title: t('messages.updateError'),
          message: err as string,
          type: 'error'
        })
      } finally {
        saveButtonLoading.value = false
      }
    }

    const createDefaultView = async () => {
      try {
        const createdView = await viewStore.createView({
          name: t('app.defaultView'),
          content: filtersController.value.viewContent,
          pageContext: filtersController.value.tableName,
          isDefault: true
        })

        if (createdView) {
          filtersController.value.currentView.value = createdView as View
          defaultViewForCurrentTable.value = createdView as View
        }
      } catch (err) {
        notificationsStore.add({
          title: t('messages.saveError'),
          message: err as string,
          type: 'error'
        })
      } finally {
        saveButtonLoading.value = false
      }
    }

    const onBack = () => {
      currentViewType.value = ViewType.LIST
      editableView.value = undefined
    }

    const canSave = computed(() => {
      const view = currentView.value

      if (!view) {
        return userIsService()
      }

      // Private view
      if (view.userId) {
        return true
      }

      // Public view with context
      if (view.resourceType && view.resourceId) {
        return hasRole(roles, rolesGroup.operation)
      }

      // view without context (each publishers see them)
      return hasRole(roles, rolesGroup.adagio)
    })

    return {
      t,
      filtersController,
      ArrowLeftIcon,

      currentView,
      editableView,

      // View Type
      currentViewType,
      ViewType,

      // Actions
      saveButtonLoading,
      onSave,
      onBack,
      onViewUpdated,

      canSave,

      // Default View
      defaultViewForCurrentTable
    }
  }
})
</script>
