<template>
  <Teleport to="#header">
    <div class="flex flex-col p-4 mb-2 text-sm bg-white divide-y divide-gray-300 rounded">
      <div class="items-center mb-2">
        <ArrowsRightLeftIcon
          class="inline-block w-4 h-4 mb-1"
        />
        <span class="ml-1 text-base font-medium">
          {{ t('labels.compareGroups') }}
        </span>
      </div>

      <div>
        <div class="py-4">
          <span class="font-semibold text-gray-600">
            {{ t('dashboards.comparisonGroupFiltersSelection') }}
          </span>
        </div>
        <div
          v-for="(comparisonGroup, i) in modelValue"
          :key="comparisonGroup.name"
          class="flex items-center w-full mb-3"
        >
          <span
            class="p-1 px-1.5 mr-1 text-sm font-semibold rounded"
            :class="{
              [`text-${getComparisonGroupColor(comparisonGroup.name)}-600`]: true,
              [`bg-${getComparisonGroupColor(comparisonGroup.name)}-100`]: true
            }"
          >{{ comparisonGroup.name }}</span>

          <ComparisonGroupFilters
            v-model="comparisonGroup.filters"
            :dimensions="dimensions"
            :group-name="comparisonGroup.name"
            @dimension-checked="onFilterChecked"
          />

          <div
            v-if="i >= 2"
            class="flex items-center justify-end flex-1 py-1 cursor-pointer"
          >
            <XMarkIcon
              class="w-4 h-4"
              @click.stop="removeComparisonGroup(i)"
            />
          </div>
        </div>

        <div class="flex py-1">
          <div class="flex items-center text-blue-500 cursor-pointer">
            <PlusIcon class="w-5 h-5 mr-0.5" />
            <span
              class="font-semibold"
              @click="addComparisonGroup()"
            >
              {{ t('actions.addComparisonGroup') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts">
import { ArrowsRightLeftIcon, PlusIcon, XMarkIcon } from '@heroicons/vue/20/solid'
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

import { FilterDimension, ComparisonGroup } from '@/plugins/dashboard/source'

import { getComparisonGroupColor } from '@/models/comparisonGroup'

import ComparisonGroupFilters from './ComparisonGroupFilters.vue'

export default defineComponent({
  components: {
    ArrowsRightLeftIcon,
    ComparisonGroupFilters,
    PlusIcon,
    XMarkIcon
  },
  props: {
    modelValue: {
      type: Object as PropType<ComparisonGroup[]>,
      required: true
    },
    dimensions: {
      type: Object as PropType<FilterDimension[]>,
      default: () => {}
    }
  },
  emits: ['update:modelValue', 'dimension-checked', 'add-comparison-group', 'remove-comparison-group'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const onFilterChecked = (dimension: string) => {
      emit('dimension-checked', dimension)
    }

    const addComparisonGroup = () => {
      emit('add-comparison-group')
    }

    const removeComparisonGroup = (index: number) => {
      emit('remove-comparison-group', index)
    }

    return {
      onFilterChecked,
      getComparisonGroupColor,
      addComparisonGroup,
      removeComparisonGroup,

      t
    }
  }
})
</script>
