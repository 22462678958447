<template>
  <TransitionRoot
    appear
    :show="open"
    as="template"
  >
    <DialogComponent
      :open="open"
      @close="close"
    >
      <div class="fixed inset-0 z-40 overflow-y-auto">
        <div class="flex w-screen min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-50" />
          </TransitionChild>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="flex flex-col gap-2 px-6 py-4 m-auto text-left align-middle transition-all transform bg-white rounded-lg shadow-xl"
              :class="css"
            >
              <DialogTitle
                as="div"
                class="flex flex-row items-center justify-between text-lg font-medium leading-6 text-gray-900"
                :class="titleCss"
              >
                <h3 class="flex flex-row items-center">
                  <slot name="title" />
                </h3>
                <button
                  v-if="withCloseButton"
                  type="button"
                  class="inline-block p-1 ml-2 align-middle rounded-full cursor-pointer hover:bg-gray-200 focus:outline-none"
                  :title="t('actions.close')"
                  @click="$emit('update:open', false)"
                >
                  <XMarkIcon class="w-6 h-6" />
                </button>
              </DialogTitle>
              <div
                class="flex flex-col flex-grow"
                :class="containerCss"
              >
                <slot />
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </DialogComponent>
  </TransitionRoot>
</template>

<script lang="ts">
import { Dialog as DialogComponent, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { PropType, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    XMarkIcon,
    DialogComponent,
    DialogOverlay,
    DialogTitle,
    TransitionRoot,
    TransitionChild
  },
  props: {
    open: {
      type: Boolean,
      required: true
    },
    css: {
      type: String as PropType<object | string | string[] | undefined>,
      default: undefined
    },
    containerCss: {
      type: String as PropType<object | string | string[] | undefined>,
      default: undefined
    },
    titleCss: {
      type: String as PropType<object | string | string[] | undefined>,
      default: undefined
    },
    withCloseButton: {
      type: Boolean,
      default: true
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:open', 'close'],
  setup (props, { emit }) {
    const { t } = useI18n()
    return {
      close: () => {
        if (props.closeOnClickOutside) {
          emit('update:open', false)
        }
      },
      t
    }
  }
})
</script>
