import Bugsnag from '@bugsnag/js'

import { IHeapAnalytics } from './heap.interface'

/**
 * HeapAnalytics is a proxy class to interact with Heap.io
 *
 * @see https://developers.heap.io/docs/web
 * @see https://developers.heap.io/reference/api-reference-overview
 */
class HeapAnalytics implements IHeapAnalytics {
  environment!: string
  allowDevelopmentMode = false

  constructor () {
    this.environment = import.meta.env.MODE

    if (import.meta.env.VITE_HEAP_ALLOW_DEVELOP_MODE === 'true') {
      this.allowDevelopmentMode = true
    }

    if (!window.heap) {
      throw new Error('Heap script is not installed. https://developers.heap.io/docs/web')
    }
  }

  exec (fn: () => any): void {
    try {
      if (this.environment !== 'development' || (this.environment === 'development' && this.allowDevelopmentMode)) {
        fn()
      } else {
        console.warn('Heap Analytics is not configured for this environment')
      }
    } catch (e) {
      Bugsnag.notify(e as Error)
    }
  }

  load (): void {
    const appid = import.meta.env.VITE_HEAP_APP_ID
    window.heap.load(appid)
  }

  track (event: string, properties?: Object): void {
    this.exec(() => window.heap.track(event, properties))
  }

  identify (identity: string): void {
    this.exec(() => window.heap.identify(identity))
  }

  resetIdentity (): void {
    this.exec(() => window.heap.resetIdentity())
  }

  addUserProperties (properties: Record<string, string | undefined>): void {
    this.exec(() => window.heap.addUserProperties(properties))
  }

  addEventProperties (properties: Record<string, string | undefined>): void {
    this.exec(() => window.heap.addEventProperties(properties))
  }

  removeEventProperty (property: string): void {
    this.exec(() => window.heap.removeEventProperty(property))
  }

  clearEventProperties (): void {
    this.exec(() => window.heap.clearEventProperties())
  }
}

export default new HeapAnalytics()
