import uniq from 'lodash/uniq'
import without from 'lodash/without'
import { ref, unref } from 'vue'

import { FieldErrors } from '../form/serverErrors'

import { Column, Row, DatatableValidation, ValidationStatus, DatatableColumns } from './datatable.d'
import { getRowColumnValue } from './utils'

export function useValidation (columns: DatatableColumns): DatatableValidation {
  // State for the invalid row and cell
  const state = ref<Record<string, Record<string, string[]>>>({})
  const warnings = ref<Record<string, Record<string, string[]>>>({})
  const infos = ref<Record<string, Record<string, string[]>>>({})

  const isDatatableValid = (): boolean => {
    return Object.keys(state.value).length === 0
  }

  const isRowValid = (row: Row): boolean => {
    return !state.value[row.id]
  }

  const getCellErrors = (row: Row, column: Column): string[] | undefined => {
    if (!column.field) {
      return undefined
    }

    return state.value[row.id]?.[column.field]
  }

  const isCellValid = (row: Row, column: Column): boolean => {
    return getCellErrors(row, column) === undefined
  }

  const addErrors = (errs: string[] | undefined, row: Row, column: Column): void => {
    if (!errs) {
      return
    }
    if (!state.value[row.id]) {
      state.value[row.id] = {}
    }
    if (column.field) {
      if (!state.value[row.id][column.field]) {
        state.value[row.id][column.field] = []
      }
      state.value[row.id][column.field].push(...errs)
    }
  }

  const setErrors = (errs: string[] | undefined, row: Row, column?: Column): void => {
    if (!errs) {
      if (column) {
        delete state.value[row.id][column.field]
      } else {
        delete state.value[row.id]
      }
      return
    }
    if (!state.value[row.id]) {
      state.value[row.id] = {}
    }
    if (column?.field) {
      state.value[row.id][column.field] = [...errs]
    }
  }

  const flattenErrors = (errs: FieldErrors): string[] => {
    const errors: string[] = []
    if (errs.$errors) {
      errors.push(...errs.$errors)
    }
    if (errs.$elements) {
      Object.values(errs.$elements).forEach(element => {
        errors.push(...flattenErrors(element))
      })
    }
    if (errs.$fields) {
      Object.values(errs.$fields).forEach(field => {
        errors.push(...flattenErrors(field))
      })
    }
    return errors
  }

  const setDatatableErrors = (errs: Record<string, FieldErrors> | undefined, rows: Row[], columns: Column[]): void => {
    if (errs) {
      rows.forEach((r: Row) => {
        const e = errs![unref(r.index)]
        if (!e || !e.$fields) {
          return
        }
        for (const field in e.$fields) {
          const fieldError = e.$fields[field]
          const errors = flattenErrors(fieldError)
          setErrors(uniq(errors), r, columns.find((c: Column) => c.field === field)!)
        }
      })
    }
  }

  const internalValidCell = (row: Row, column: Column, value: any, revalidateRow: boolean = false): void => {
    if (column.validator) {
      const error = column.validator(value, row, column)

      if (!error) {
        if (state.value[row.id]) {
          if (column.field && state.value[row.id][column.field]) {
            delete state.value[row.id][column.field]
          }

          if (Object.keys(state.value[row.id]).length === 0) {
            delete state.value[row.id]
          }
        }
      } else {
        setErrors(error.details.map(d => d.message), row, column)
      }

      if (column.revalidateRow && revalidateRow) {
        for (const c of (columns.columns.value.filter((c: Column) => c.validator) as Column[])) {
          if (c.field === column.field) {
            continue
          }
          validCell(row, c, getRowColumnValue(row.data, c), false)
        }
      }
    }
  }

  const validCell = (row: Row, column: Column, value: any, revalidateRow: boolean = true): void => {
    internalValidCell(row, column, value, revalidateRow)
  }

  const hasInfos = (): boolean => {
    return Object.keys(infos.value).length !== 0
  }

  const rowHasInfos = (row: Row): boolean => {
    return !!infos.value[row.id]
  }

  const setInfos = (infs: typeof infos['value']): void => {
    infos.value = infs
  }

  const addInfo = (row: Row, column: Column, message: string): void => {
    if (!infos.value[row.id]) {
      infos.value[row.id] = {}
    }
    if (column.field) {
      if (!infos.value[row.id][column.field]) {
        infos.value[row.id][column.field] = []
      }
      infos.value[row.id][column.field].push(message)
    }
  }

  const clearInfos = (row: Row, column?: Column): void => {
    const rowInfos = infos.value[row.id]
    if (column && rowInfos) {
      if (column.field) {
        delete rowInfos[column.field]
      }
      if (Object.keys(rowInfos).length === 0) {
        delete infos.value[row.id]
      }
      return
    }

    delete infos.value[row.id]
  }

  const getCellInfos = (row: Row, column: Column): string[] | undefined => {
    if (!column.field) {
      return undefined
    }

    return infos.value[row.id]?.[column.field]
  }

  const cellHasInfos = (row: Row, column: Column): boolean => {
    return getCellInfos(row, column) !== undefined
  }

  const hasWarnings = (): boolean => {
    return Object.keys(warnings.value).length !== 0
  }

  const rowHasWarnings = (row: Row): boolean => {
    return !!warnings.value[row.id]
  }

  const setWarnings = (warns: typeof warnings['value']): void => {
    warnings.value = warns
  }

  const addWarning = (row: Row, column: Column, message: string): void => {
    if (!warnings.value[row.id]) {
      warnings.value[row.id] = {}
    }
    if (column.field) {
      if (!warnings.value[row.id][column.field]) {
        warnings.value[row.id][column.field] = []
      }
      warnings.value[row.id][column.field].push(message)
    }
  }

  const clearWarnings = (row: Row, column?: Column): void => {
    const rowWarnings = warnings.value[row.id]
    if (column && rowWarnings) {
      if (column.field) {
        delete rowWarnings[column.field]
      }
      if (Object.keys(rowWarnings).length === 0) {
        delete warnings.value[row.id]
      }
      return
    }

    delete warnings.value[row.id]
  }

  const getCellWarnings = (row: Row, column: Column): string[] | undefined => {
    if (!column.field) {
      return undefined
    }

    return warnings.value[row.id]?.[column.field]
  }

  const cellHasWarnings = (row: Row, column: Column): boolean => {
    return getCellWarnings(row, column) !== undefined
  }

  const getRowStatus = (row: Row): ValidationStatus => {
    if (!isRowValid(row)) {
      return ValidationStatus.ERROR
    }
    if (rowHasWarnings(row)) {
      return ValidationStatus.WARNING
    }
    if (rowHasInfos(row)) {
      return ValidationStatus.INFO
    }
    return ValidationStatus.VALID
  }

  const getCellStatus = (row: Row, column: Column): ValidationStatus => {
    if (!isCellValid(row, column)) {
      return ValidationStatus.ERROR
    }
    if (cellHasWarnings(row, column)) {
      return ValidationStatus.WARNING
    }
    if (cellHasInfos(row, column)) {
      return ValidationStatus.INFO
    }
    return ValidationStatus.VALID
  }

  const purge = (rows: Row[]): void => {
    const rowIds = rows.map(r => r.id)
    without(Object.keys(state.value), ...rowIds).forEach(r => {
      delete state.value[r]
    })
    without(Object.keys(warnings.value), ...rowIds).forEach(r => {
      delete warnings.value[r]
    })
    without(Object.keys(infos.value), ...rowIds).forEach(r => {
      delete infos.value[r]
    })
  }

  return {
    isDatatableValid,
    isRowValid,
    isCellValid,
    getCellErrors,
    setErrors,
    setDatatableErrors,
    addErrors,
    validCell,

    // Warnings
    setWarnings,
    addWarning,
    clearWarnings,
    getCellWarnings,
    cellHasWarnings,
    rowHasWarnings,
    hasWarnings,

    // Infos
    setInfos,
    addInfo,
    clearInfos,
    getCellInfos,
    cellHasInfos,
    rowHasInfos,
    hasInfos,

    getRowStatus,
    getCellStatus,
    purge
  }
}
