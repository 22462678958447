import { Component, FunctionalComponent, Ref, VNode, ref } from 'vue'

import { ButtonAppearance } from '@/components/Buttons/types'
import { IconAppearance } from '@/components/Modal/types'

export interface ConfirmOptions {
  title?: string
  contents?: () => VNode
  onClose?: () => Promise<any>
  confirmButtonText?: string
  cancelButtonText?: string
  confirmButtonAppearance?: ButtonAppearance
  icon?: Component | FunctionalComponent | null
  iconAppearance?: IconAppearance
}

interface ConfirmComposable {
  showConfirm: Ref<boolean>
  onClick: () => void
  onConfirm: () => void
  onCloseConfirm: () => Promise<void>
}

export default function useConfirm (enabled: Ref<boolean>, onAccept: Ref<() => void>, onClose?: Ref<(() => any) | undefined>): ConfirmComposable {
  const showConfirm = ref(false)

  const onClick = (): void => {
    if (enabled.value) {
      showConfirm.value = true
    } else {
      onAccept.value()
    }
  }

  const onConfirm = (): void => {
    onAccept.value()
    showConfirm.value = false
  }

  const onCloseConfirm = async (): Promise<void> => {
    if (onClose?.value) {
      await onClose?.value()
    }
    showConfirm.value = false
  }

  return {
    showConfirm,
    onClick,
    onConfirm,
    onCloseConfirm
  }
}
