import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router'

import { ContextType } from '@/plugins/context/context.enum'

import { useAlgorithmTemplatesStore } from '@/store/algorithmTemplates.store'
import { useAppStore } from '@/store/app.store'
import { useAuthStore } from '@/store/auth.store'
import { useProfileStore } from '@/store/profile.store'
import { ViewsType, useViewStore } from '@/store/view.store'

import { refreshToken } from '@/services/auth'
import { fetchProfile } from '@/services/profile'

import { router } from '@/router'

// Check if the user is connected and has a profile.
export const authGuard = async (to: RouteLocationNormalized): Promise<RouteLocationRaw | true> => {
  const viewStore = useViewStore()
  const profileStore = useProfileStore()
  const appStore = useAppStore()
  const algorithmTemplatesStore = useAlgorithmTemplatesStore()
  const authStore = useAuthStore()

  if (authStore.isLogged) {
    appStore.setLoading(true)

    try {
      const response = await fetchProfile()

      if (response && response.data) {
        profileStore.setProfile(response.data)

        // we must await fetchViews because we use the result for the routing logic
        await viewStore.fetchViews(ViewsType.NO_CONTEXT)

        algorithmTemplatesStore.retrieveAlgorithmTemplates()

        appStore.setLoading(false)

        return true
      }
    } catch (err: any) {
      if (err && err.response && err.response.status) {
        if (err.response.status === 401 && !authStore.silentRefreshRetry) {
          authStore.setSilentRefreshRetry(true)

          try {
            const response = await refreshToken()

            if (response && response.data) {
              authStore.setAccessToken(response.data.accessToken)
              authStore.setAccessTokenExpiration(response.data.expiration)

              const profileResponse = await fetchProfile()

              if (profileResponse && profileResponse.data) {
                profileStore.setProfile(profileResponse.data)

                await viewStore.fetchViews(ViewsType.NO_CONTEXT)

                appStore.setLoading(false)

                return true
              }
            }
          } catch (err) {
            authStore.logout()

            return { name: 'login' }
          }
        }
      }
    }
  }

  authStore.logout()
  return { name: 'login', query: { redirect: to.fullPath } }
}

// Check if the user is not connected.
export const guestGuard = (): RouteLocationRaw | true => {
  const authStore = useAuthStore()
  if (!authStore.isLogged) {
    return true
  }

  return { name: 'home' }
}

// Check if the user is from one or multiple publishers
export const defaultRedirection = (): boolean => {
  const profileStore = useProfileStore()

  if (profileStore.profile) {
    const userService = profileStore.profile.permissions.filter(p => p.resourceType === ContextType.GROUPS && p.isService)
    if (userService.length || profileStore.profile.isService) {
      return true
    }

    const groups = profileStore.profile.permissions.filter(p => p.resourceType === ContextType.GROUPS)
    const bidders = profileStore.profile.permissions.filter(p => p.resourceType === ContextType.BIDDERS)
    const publishers = profileStore.profile.permissions.filter(p => p.resourceType === ContextType.PUBLISHERS)

    if (!groups.length && !bidders.length && publishers.length === 1) {
      router.push({
        name: `${ContextType.PUBLISHERS}.home`,
        params: { publisherId: publishers[0].resourceId }
      })
      return false
    } else if (!groups.length && bidders.length === 1 && !publishers.length) {
      router.push({
        name: `${ContextType.BIDDERS}.home`,
        params: { bidderId: bidders[0].resourceId }
      })
      return false
    } else if (groups.length === 1 && !bidders.length && !publishers.length) {
      router.push({
        name: `${ContextType.GROUPS}.home`,
        params: { groupId: groups[0].resourceId }
      })
      return false
    }
  }

  return true
}
