export enum CalloutTheme {
  BASE,
  INFO,
  SUCCESS,
  WARNING,
  DANGER
}

export type CalloutElements = {
  button: string,
  icon: string,
  container: string,
  extraContext: string
}

export const CALLOUT_CLASSES: Record<CalloutTheme, CalloutElements> = {
  [CalloutTheme.BASE]: {
    button: 'border-slate-700',
    container: 'bg-gray-300 text-slate-700',
    extraContext: 'bg-gray-500',
    icon: 'text-slate-500'
  },
  [CalloutTheme.INFO]: {
    button: 'border-primary-600',
    container: 'bg-primary-100 text-primary-600',
    extraContext: 'bg-primary-300',
    icon: 'text-primary-500'
  },
  [CalloutTheme.SUCCESS]: {
    button: 'border-green-700',
    container: 'bg-green-100 text-green-700',
    extraContext: 'bg-green-300',
    icon: 'text-green-600'
  },
  [CalloutTheme.WARNING]: {
    button: 'border-amber-700',
    container: 'bg-amber-100 text-amber-700',
    extraContext: 'bg-amber-300',
    icon: 'text-amber-600'
  },
  [CalloutTheme.DANGER]: {
    button: 'border-red-600',
    container: 'bg-red-100 text-red-600',
    extraContext: 'bg-red-200',
    icon: 'text-red-600'
  }
}
