<template>
  <form @submit="onSubmit">
    <p class="font-medium">
      {{ t('actions.create-new', [t('labels.source')]) }}
    </p>
    <div class="flex flex-col">
      <div class="grid w-full mb-2 text-sm grid-cols-vertical-form lg:grid-cols-vertical-form-4 place-items-center-stretch">
        <div class="p-1 font-medium">
          {{ t('labels.name') }}
        </div>
        <div class="p-1">
          <input
            v-model="name"
            :placeholder="t('placeholders.nameUnique')"
            class="min-w-36 h-7 px-2.5 py-0.5 border border-gray-400 text-gray-700 focus:ring-primary-500 focus:border-primary-500 rounded focus:outline-none w-full"
          >
        </div>
        <div class="p-1 font-medium">
          {{ t('labels.table') }}
        </div>
        <div class="p-1">
          <Multiselect
            v-model="table"
            name="table"
            mode="single"
            :close-on-select="true"
            :options="Object.keys(tables)"
            :classes="multiselectTailwindClassesCompact"
            :can-clear="false"
            :can-deselect="false"
            :placeholder="t('placeholders.table')"
          />
        </div>
        <template v-if="tableDefinition !== undefined">
          <div class="col-span-2 p-1 font-medium lg:col-span-4">
            {{ t('labels.enrichment') }}
          </div>
          <div class="col-span-2 lg:col-span-4">
            <EnrichmentForm
              :definition="tableDefinition"
              @submit="enrichments.push($event)"
            />
          </div>
        </template>
      </div>
      <div
        v-if="tableDefinition !== undefined && enrichments.length > 0"
        class="mb-2"
      >
        <div class="text-sm font-medium">
          {{ t('dashboards.createdEnrichments') }}
        </div>
        <table class="w-full text-sm text-left">
          <thead class="border-b">
            <tr>
              <th class="p-2">
                {{ t('labels.baseDimension') }}
              </th>
              <th class="p-2">
                {{ t('labels.table') }}
              </th>
              <th class="p-2">
                {{ t('labels.foreignKey') }}
              </th>
              <th class="p-2">
                {{ t('labels.foreignDimension', 2) }}
              </th>
              <th />
            </tr>
          </thead>
          <tbody class="text-gray-600">
            <tr
              v-for="(enrichment, i) in enrichments"
              :key="enrichment.dimension + '|' + enrichment.table + '|' + enrichment.foreignKey"
              class="odd:bg-gray-200"
            >
              <td class="p-2">
                {{ enrichment.dimension }}
              </td>
              <td class="p-2">
                {{ enrichment.table }}
              </td>
              <td class="p-2">
                {{ enrichment.foreignKey }}
              </td>
              <td class="p-2">
                {{ enrichment.foreignDimensions.map(fd => `${fd.alias} (${fd.name})`).join(', ') }}
              </td>
              <td class="p-2">
                <button
                  type="button"
                  :title="t('actions.remove')"
                  @click="removeEnrichment(i)"
                >
                  ❌
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex flex-row">
        <button
          type="submit"
          class="px-3 py-1 text-sm text-white bg-green-500 rounded disabled:opacity-50 disabled:cursor-not-allowed"
          :disabled="!validate"
        >
          {{ t('actions.create', [t('labels.source')]) }}
        </button>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { v1 as uuidv1 } from 'uuid'
import { PropType, computed, defineComponent, onMounted, ref, unref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { DataDefinition } from '@/plugins/dashboard/definition'
import { DateDimension, DateRollupDimension } from '@/plugins/dashboard/dimensions'
import { CUBE_ENDPOINT, CubeSource, Enrichment, SOURCE_CONSTRUCTORS, SerializedSource, Source } from '@/plugins/dashboard/source'

import { useNotificationsStore } from '@/store/notifications.store'

import api from '@/api'
import Multiselect from '@/components/Form/FormMultiselect.vue'
import { multiselectTailwindClassesCompact } from '@/components/Multiselect'

import EnrichmentForm from './EnrichmentForm.vue'

export default defineComponent({
  components: {
    Multiselect,
    EnrichmentForm
  },
  props: {
    sources: {
      type: Array as PropType<Source[]>,
      required: true
    }
  },
  emits: ['submit'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const tables = ref({} as Record<string, DataDefinition>)
    const tableDefinition = ref(undefined as DataDefinition | undefined)
    const table = ref('')
    const name = ref('')
    const constructor = ref(Object.keys(SOURCE_CONSTRUCTORS)[0])
    const enrichments = ref([] as Enrichment[])

    const notificationsStore = useNotificationsStore()

    // Lifecycle
    onMounted(async () => {
      const source = new CubeSource('SourceForm', 'SourceForm', api.contextURL(CUBE_ENDPOINT, `${import.meta.env.VITE_DATA_API_BASE_URL}/api/v1`), '')
      source.getExistingTables()
        .then(t => {
          tables.value = t
        })
        .catch((e: any) => {
          notificationsStore.add({
            message: t('messages.fetchErrorWithMsg', [e.message]),
            type: 'error'
          })
        })
    })

    // Computed
    const validate = computed((): boolean => {
      return name.value.length > 0 &&
        table.value.length > 0 &&
        SOURCE_CONSTRUCTORS[constructor.value] !== undefined
    })

    // Watch
    watch(
      () => table.value,
      () => {
        tableDefinition.value = tables.value[table.value]
      }
    )

    // Methods
    const removeEnrichment = (i: number) => {
      enrichments.value.splice(i, 1)
    }

    const onSubmit = ($event: Event) => {
      $event.preventDefault()
      const event = {
        uid: uuidv1(),
        name: unref(name),
        table: unref(table),
        constructor: unref(constructor),
        enrichment: [...unref(enrichments)]
      } as SerializedSource
      table.value = ''
      name.value = ''
      enrichments.value = []
      emit('submit', event)
    }

    return {
      // Data
      name,
      table,
      tables,
      tableDefinition,
      constructor,
      enrichments,

      // Computed
      validate,

      // Methods
      removeEnrichment,
      onSubmit,

      // Misc
      SOURCE_CONSTRUCTORS,
      multiselectTailwindClassesCompact,
      DateDimension,
      DateRollupDimension,
      t
    }
  }
})
</script>
