<template>
  <div>
    <span :title="formated">{{ formated }}</span>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import { currencyFormat } from '@/utils/currency'

export default defineComponent({
  props: {
    value: {
      type: [String, Number] as PropType<string | number>,
      required: true
    },
    currency: {
      type: String,
      required: false,
      default: 'USD'
    },
    isCents: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props) {
    const valueAsNumber = () => {
      if (typeof props.value === 'string') {
        return parseFloat(props.value)
      }
      return props.value
    }

    const formated = computed(() => currencyFormat(valueAsNumber(), props.currency, props.isCents))

    return {
      formated
    }
  }
})
</script>
