import { RouteRecordRaw } from 'vue-router'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

export function optimRules (): RouteRecordRaw[] {
  const context = ContextType.GROUPS

  return [
    {
      path: 'optim-rules',
      name: `${context}.optimRules.index`,
      component: () => import('./views/Index.vue'),
      meta: {
        title: i18n.global.t('labels.optimRule', 2),
        feature: 'optim_rules',
        requireService: true
      },
      children: [
        {
          path: 'create',
          name: `${context}.optimRules.create`,
          component: () => import('./views/Create.vue'),
          meta: {
            title: i18n.global.t('optimRules.new'),
            feature: 'optim_rules'
          }
        },
        {
          path: ':optimRuleId',
          name: `${context}.optimRules.edit`,
          component: () => import('./views/Edit.vue'),
          meta: {
            title: i18n.global.t('actions.edit', [':optimRuleName']),
            pattern: ':optimRuleName',
            feature: 'optim_rules'
          },
          props: route => ({ optimRuleId: route.params.optimRuleId })
        }
      ]
    }
  ]
}
