import { AxiosResponse } from 'axios'

import api from '../api'
import { AvailableContext, Profile, UpdatePasswordForm, UpdateProfileForm } from '../models/profile'

export async function fetchProfile (): Promise<AxiosResponse<Profile>> {
  return api.get<Profile>('/profile')
}

export async function updateProfile (form: UpdateProfileForm): Promise<AxiosResponse<Profile>> {
  return api.patch<Profile>('/profile', form)
}

export async function updatePassword (form: UpdatePasswordForm): Promise<AxiosResponse<void>> {
  return api.patch('/profile/password', form)
}

export async function fetchAvailableContexts (search: string): Promise<AxiosResponse<AvailableContext[]>> {
  return api.get<AvailableContext[]>('/contexts', { params: { search } })
}
