import Joi from 'joi'

import { cleanExternalErrors, FieldErrors } from '@/plugins/form/serverErrors'

interface Touchable {
  $touch(): void
}

export function useJoi (schema: Joi.AnySchema, value: any, options?: Joi.ValidationOptions): Joi.ValidationError | undefined {
  return schema.validate(value, options).error
}

export function useDatatableJoi (schema: Joi.AnySchema, value: any, validation: Touchable | null | undefined, externalResults: FieldErrors | Record<string, FieldErrors>, path: string[]): Joi.ValidationError | undefined {
  const error = useJoi(schema, value)

  const fieldErrors = completePath(externalResults, path)
  if (error !== undefined) {
    // Add errors to externalResults
    fieldErrors.$errors = error?.details.map(d => d.message)
  } else {
    // Remove errors from externalResults
    fieldErrors.$errors = undefined
    fieldErrors.$elements = undefined
    fieldErrors.$fields = undefined
  }
  const empty = cleanExternalErrors(path[0].startsWith('$') ? externalResults : (externalResults as Record<string, FieldErrors>)[path[0]])
  if (!path[0].startsWith('$') && empty) {
    delete (externalResults as Record<string, FieldErrors>)[path[0]]
  }
  if (validation) {
    validation.$touch()
  }
  return error
}

const completePath = (errors: FieldErrors, path: string[]): FieldErrors => {
  let errs: Record<string, any> = errors
  path.forEach(step => {
    if (!errs[step]) {
      errs[step] = {}
    }
    errs = errs[step]
  })
  return errs as FieldErrors
}
