import { RouteRecordRaw } from 'vue-router'

import i18n from '@/plugins/i18n'

export const settings: RouteRecordRaw = {
  path: 'settings',
  name: 'settings',
  component: () => import('./views/Settings.vue'),
  meta: {
    title: i18n.global.t('labels.setting', 100),
    layout: 'base'
  }
}
