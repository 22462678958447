<template>
  <svg
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.25 8.25C0.25 3.83154 3.83154 0.25 8.25 0.25C12.6685 0.25 16.25 3.83154 16.25 8.25C16.25 12.6685 12.6685 16.25 8.25 16.25C3.83154 16.25 0.25 12.6685 0.25 8.25ZM9.5858 5.03605C8.85554 4.39851 7.64446 4.39851 6.91503 5.03605C6.79218 5.14355 6.63167 5.19785 6.46879 5.187C6.30591 5.17616 6.15401 5.10105 6.04651 4.97821C5.93901 4.85536 5.88471 4.69485 5.89556 4.53197C5.90641 4.36909 5.98152 4.21719 6.10436 4.10969C7.29738 3.066 9.20262 3.066 10.3956 4.10969C11.6371 5.19605 11.6371 6.99626 10.3956 8.08261C10.188 8.26365 9.95704 8.41599 9.70887 8.53554C9.15421 8.80467 8.86539 9.17062 8.86539 9.48077V10.0962C8.86539 10.2594 8.80055 10.4159 8.68514 10.5313C8.56974 10.6467 8.41321 10.7115 8.25 10.7115C8.08679 10.7115 7.93026 10.6467 7.81486 10.5313C7.69945 10.4159 7.63462 10.2594 7.63462 10.0962V9.48077C7.63462 8.43133 8.50436 7.75195 9.17308 7.42785C9.32241 7.35564 9.46108 7.26456 9.5858 7.15626C10.2668 6.55974 10.2668 5.63256 9.5858 5.03605ZM8.25 13.1731C8.41321 13.1731 8.56974 13.1082 8.68514 12.9928C8.80055 12.8774 8.86539 12.7209 8.86539 12.5577C8.86539 12.3945 8.80055 12.238 8.68514 12.1225C8.56974 12.0071 8.41321 11.9423 8.25 11.9423C8.08679 11.9423 7.93026 12.0071 7.81486 12.1225C7.69945 12.238 7.63462 12.3945 7.63462 12.5577C7.63462 12.7209 7.69945 12.8774 7.81486 12.9928C7.93026 13.1082 8.08679 13.1731 8.25 13.1731Z"
      fill="currentColor"
    />
  </svg>
</template>
