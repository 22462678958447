<template>
  <Popper
    ref="popper"
    :transition-props="{
      'enterActiveClass': 'transition-opacity duration-150 ease-out',
      'enterFromClass': 'opacity-0',
      'enterToClass': 'opacity-100',
      'leaveActiveClass': 'transition-opacity duration-150 ease-in',
      'leaveFromClass': 'opacity-100',
      'leaveToClass': 'opacity-0'
    }"
    :trigger="trigger"
    :placement="placement"
    :strategy="strategy"
    :modifiers="computedModifiers"
    :force-show="actualForceShow"
    :popper-is="popperIs"
    :popper-props="computedPopperProps"
    :reference-is="referenceIs"
    :reference-props="referenceProps"
    :disabled="actualDisabled"
    :teleport-props="teleportProps"
  >
    <template #reference="{ visible, setVisible }">
      <slot
        :close="close"
        :visible="visible"
        :set-visible="setVisible"
      />
    </template>
    <div
      class="flex flex-col gap-2 text-base leading-normal text-white rounded-md bg-primary-900"
      :class="{
        'hidden': !($slots.content || $slots.footer || $slots.title || title),
        'p-3': $slots.content || $slots.footer,
        'px-2 py-1': !($slots.content || $slots.footer)
      }"
    >
      <div
        v-if="closeable || title || $slots.title"
        class="flex flex-row items-center gap-2"
      >
        <XCircleIcon
          v-if="closeable"
          class="w-6 h-6 cursor-pointer shrink-0"
          @click.stop="close()"
        />
        <slot
          name="title"
          :close="close"
        />
        <span
          v-if="title && !$slots.title"
          :class="$slots.content || $slots.footer ? 'font-semibold text-lg' : ''"
        >
          {{ title }}
        </span>
      </div>
      <slot
        name="content"
        :close="close"
      />
      <slot
        name="footer"
        :close="close"
      />
    </div>
    <div
      class="tooltip-arrow"
      :class="{'hidden': !($slots.content || $slots.footer || $slots.title || title)}"
      data-popper-arrow
    />
  </Popper>
</template>

<script lang="ts">
import { XCircleIcon } from '@heroicons/vue/24/solid'
import { Placement, PositioningStrategy } from '@popperjs/core'
import { computed, defineComponent, PropType, TeleportProps, ref, watch, FunctionalComponent } from 'vue'
import { Trigger, usePopperjs } from 'vue-use-popperjs'

import Popper from './Popper.vue'

export default defineComponent({
  components: {
    Popper,
    XCircleIcon
  },
  inheritAttrs: false,
  props: {
    trigger: {
      type: String as PropType<Exclude<Trigger, 'manual'>>,
      default: 'hover'
    },
    placement: {
      type: String as PropType<Placement>,
      default: 'auto'
    },
    strategy: {
      type: String as PropType<PositioningStrategy>,
      default: 'absolute'
    },
    popperIs: {
      type: String,
      default: 'div'
    },
    popperProps: {
      type: Object,
      default: () => undefined
    },
    referenceIs: {
      type: [String, Object, Function] as PropType<string | FunctionalComponent>,
      default: 'div'
    },
    referenceProps: {
      type: Object,
      default: () => undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    forceShow: {
      type: Boolean,
      default: false
    },
    teleportProps: {
      type: Object as PropType<TeleportProps>,
      default: () => undefined
    },
    closeable: {
      type: Boolean,
      default: false
    },
    disableOnClose: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: () => undefined
    },
    modifiers: {
      type: Array as PropType<Required<Parameters<typeof usePopperjs>>['2']['modifiers']>,
      default: () => undefined
    },
    disableEventListeners: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const popper = ref(null as any)
    const computedPopperProps = computed(() => {
      const p = Object.assign({}, props.popperProps)
      if (p.class) {
        if (typeof p.class === 'string') {
          p.class += ' tooltip z-30 w-max max-w-[40ch]'
        } else if (typeof p.class === 'object') {
          p.class.tooltip = true
          p.class['z-30'] = true
          p.class['w-max'] = true
          p.class['max-w-[40ch]'] = true
        } else if (Array.isArray(p.class)) {
          p.class.push('tooltip')
          p.class.push('z-30')
          p.class.push('w-max')
          p.class.push('max-w-[40ch]')
        }
      } else {
        p.class = 'z-30 tooltip w-max max-w-[40ch]'
      }
      p.title = ''
      return p
    })

    const computedModifiers = computed(() => {
      const modifiers = [...props.modifiers || []].concat(
        {
          name: 'arrow',
          options: {
            padding: ({ popper, placement }: {popper: {width: number, height: number}, placement: Placement}) => {
              let sideLength = 0
              if (placement.includes('top') || placement.includes('bottom')) {
                sideLength = popper.width
              } else if (placement.includes('left') || placement.includes('right')) {
                sideLength = popper.height
              }
              const pos = sideLength / 2 - 4
              return pos > 15 ? 15 : pos
            }
          }
        },
        {
          name: 'offset',
          options: {
            offset: [0, 15]
          }
        }
      )

      if (props.disableEventListeners) {
        modifiers.push({
          name: 'eventListeners',
          options: {
            scroll: false
            // resize: false
            // We can keep the resize listener enabled as its performance impact isn't noticeable.
          }
        })
      }

      return modifiers
    })

    const closed = ref(false)
    const actualForceShow = computed(() => props.forceShow && !closed.value)
    const close = () => {
      closed.value = true
      if (popper.value) {
        popper.value.setVisible(false)
      }
    }

    const actualDisabled = computed(() => props.disabled || (props.disableOnClose && closed.value))
    watch(() => actualDisabled.value, () => { closed.value = false })

    return {
      popper,
      computedPopperProps,
      computedModifiers,
      actualForceShow,
      actualDisabled,
      close
    }
  }
})
</script>
<style scoped>
@tailwind base;

.vue-use-popperjs-none {
  @apply hidden;
}

.tooltip-arrow,
.tooltip-arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  @apply bg-primary-900;
}

.tooltip-arrow {
  visibility: hidden;
  z-index: -1;
}

.tooltip-arrow::before {
  visibility: visible;
  content: '';
}

.tooltip[data-popper-placement^='top'] > .tooltip-arrow {
  bottom: -4px;
}

.tooltip[data-popper-placement^='bottom'] > .tooltip-arrow {
  top: -4px;
}

.tooltip[data-popper-placement^='top'] .tooltip-arrow::before, .tooltip[data-popper-placement^='bottom'] .tooltip-arrow::before {
  transform: scaleY(1.5) rotate(45deg);
}

.tooltip[data-popper-placement^='left'] > .tooltip-arrow {
  right: -4px;
}

.tooltip[data-popper-placement^='right'] > .tooltip-arrow {
  left: -4px;
}

.tooltip[data-popper-placement^='left'] .tooltip-arrow::before, .tooltip[data-popper-placement^='right'] .tooltip-arrow::before {
  transform: scaleX(1.5) rotate(45deg);
}
</style>
