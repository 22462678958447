<template>
  <div class="flex flex-row items-center gap-2">
    <FormToggle
      v-model="value"
      v-bind="$attrs"
      :name="name"
      size="sm"
      :is-disabled="isDisabled || isLoading"
      @click.stop="onClick"
    />

    <LoadingIcon
      v-if="isLoading"
      class="inline-block w-4 h-4"
    />
  </div>
</template>

<script lang="ts">
import Bugsnag from '@bugsnag/js'
import { computed, defineComponent, ref, PropType } from 'vue'

import FormToggle from '@/components/Form/FormToggle.vue'
import LoadingIcon from '@/components/Loading/LoadingIcon.vue'

import { Column, Row } from '../datatable.d'

export default defineComponent({
  components: {
    FormToggle,
    LoadingIcon
  },
  props: {
    modelValue: {
      type: [String, Number, Boolean] as PropType<any>,
      required: true
    },
    row: {
      type: Object as PropType<Row>,
      default: undefined
    },
    column: {
      type: Object as PropType<Column>,
      default: undefined
    },
    name: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    withLoader: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const isLoading = ref(false)

    const value = computed({
      get: () => props.modelValue,
      set: (value) => {
        if (!props.row || !props.column) {
          const eMsg = 'Must provide a row and column props'
          if (import.meta.env.DEV) {
            console.error(eMsg)
          } else {
            Bugsnag.notify(eMsg)
          }

          return
        }

        try {
          emit('update:modelValue', value, props.row, props.column, isLoading)
        } catch (e) {
          isLoading.value = false
        }
      }
    })

    const onClick = () => {
      if (props.isDisabled || !props.withLoader || isLoading.value === true) {
        return
      }
      isLoading.value = true
    }

    return {
      value,
      isLoading,

      onClick
    }
  }
})

</script>
