import { Component } from 'vue'

import { Role } from '@/models/enum/roles'

import FormComponent from '@/components/Form/FormRoles.vue'

import { DefaultProps, FormRenderer } from './'

interface Props extends Pick<DefaultProps, 'errors' | 'wrapperClass'> {
  modelValue?: Role[]
  edit?: boolean
  enableBilling?: boolean
}

export class FormRoles implements FormRenderer<Props> {
  defaultProps: Props

  constructor (props: Props) {
    this.defaultProps = props
  }

  get wrapperClass (): string {
    return this.defaultProps.wrapperClass || ''
  }

  props (): Props {
    return { ...this.defaultProps }
  }

  component (): Component {
    return FormComponent
  }
}
