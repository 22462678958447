<template>
  <button
    :class="buttonClass"
    :title="t('actions.exportAs', ['CSV'])"
    @click="open = true"
  >
    <ArrowDownOnSquareIcon class="h-4 w-4" />
  </button>
  <Modal v-model:open="open">
    <template #title>
      {{ t('actions.exportAs', ['CSV']) }}
    </template>

    <div class="grid grid-cols-1 md:grid-cols-2 pt-2">
      <div
        v-if="showOnlySelectedRowsInput"
        class="p-3 md:col-span-2"
      >
        <span class="leading-6 font-medium">
          <input
            type="checkbox"
            :checked="onlySelectedRows"
            class="cursor-pointer rounded h-4 w-4 mr-1 text-primary-600 focus:ring-primary-500 border-gray-500"
            @input.prevent="onlySelectedRows = !onlySelectedRows"
          >
          <span
            class="align-middle cursor-pointer"
            @click="onlySelectedRows = !onlySelectedRows"
          >
            {{ t('dashboards.onlySelectedRows') }}
          </span>
        </span>
      </div>
      <div class="p-3">
        <p class="leading-6 font-medium">
          {{ t('labels.separator') }}
        </p>
        <div
          v-for="f in formats"
          :key="f.toString()"
          class="flex flex-row items-center group"
        >
          <span class="p-1">
            <input
              type="radio"
              :checked="selectedFormat === f.value"
              class="cursor-pointer rounded-full h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-500"
              @input.prevent="selectedFormat = f.value"
            >
          </span>
          <span
            class="p-1 flex-grow cursor-pointer"
            @click="selectedFormat = f.value"
          >
            {{ f.name }}
          </span>
        </div>
        <div class="overflow-auto py-2">
          <p class="leading-6 font-medium">
            {{ t('labels.preview') }}
          </p>
          <p class="whitespace-nowrap font-mono text-sm">
            {{ example }}
          </p>
        </div>
      </div>
      <div class="p-3">
        <p class="leading-6 font-medium">
          {{ t('labels.metric', 2) }}
        </p>
        <div
          v-for="m in definition.metrics"
          :key="m.name"
          class="flex flex-row items-center group"
        >
          <span class="p-1">
            <input
              type="checkbox"
              :checked="selectedMetrics[m.name] === true"
              class="cursor-pointer rounded h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-400"
              @input.prevent="onMetricCheck(m.name)"
            >
          </span>
          <span
            class="p-1 flex-grow cursor-pointer"
            @click="onMetricCheck(m.name)"
          >
            {{ translateDBName(m.name) }}
          </span>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-end p-3">
      <button
        class="bg-green-500 rounded-md py-1 px-3 text-white whitespace-nowrap"
        @click="submit()"
      >
        <ArrowDownOnSquareIcon class="h-4 w-4 inline-block" />&nbsp;{{ t('actions.export') }}
      </button>
    </div>
  </Modal>
</template>

<script lang="ts">
import { ArrowDownOnSquareIcon } from '@heroicons/vue/24/outline'
import { defineComponent, ref, PropType, watch, nextTick, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { CSV_FORMATS, ExportForm, translateDBName, WidgetDefinition } from '@/plugins/dashboard'

import Modal from '@/components/Modal/Modal.vue'

export default defineComponent({
  components: {
    Modal,
    ArrowDownOnSquareIcon
  },
  props: {
    definition: {
      type: Object as PropType<WidgetDefinition>,
      required: true
    },
    buttonClass: {
      type: String as PropType<object | string | string[] | undefined>,
      default: undefined
    },
    showOnlySelectedRowsInput: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['submit'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const open = ref(false)
    const onlySelectedRows = ref(false)
    const selectedFormat = ref('CSV_US')
    const selectedMetrics = ref<Record<string, boolean>>({})
    const formats = [
      { name: 'CSV US', value: 'CSV_US' },
      { name: 'CSV EU', value: 'CSV_EU' },
      { name: 'TSV', value: 'TSV' }
    ]

    const updateSelectedMetrics = () => {
      const metrics: Record<string, boolean> = {}
      props.definition.metrics.forEach(m => {
        metrics[m.name] = true
      })
      selectedMetrics.value = metrics
    }

    const example = computed(() => {
      const format = CSV_FORMATS[selectedFormat.value]
      const data = [
        format.numberFormat(123.45),
        'newyork',
        format.numberFormat(67.8)
      ]
      return data.join(format.separator)
    })

    watch(
      () => props.definition,
      () => {
        updateSelectedMetrics()
      },
      { immediate: true }
    )

    watch(
      () => open.value,
      () => {
        if (open.value) {
          updateSelectedMetrics()
        }
      }
    )

    const onMetricCheck = (m: string) => {
      selectedMetrics.value[m] = !selectedMetrics.value[m]
    }

    const submit = () => {
      emit('submit', {
        format: CSV_FORMATS[selectedFormat.value],
        metrics: Object.entries(selectedMetrics.value).filter(e => e[1] === true).map(e => e[0]),
        onlySelected: onlySelectedRows.value
      } as ExportForm)
      nextTick(() => {
        open.value = false
      })
    }

    return {
      open,
      selectedFormat,
      selectedMetrics,
      formats,
      example,
      onlySelectedRows,

      onMetricCheck,
      submit,
      translateDBName,
      t
    }
  }
})
</script>
