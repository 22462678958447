<template>
  <div class="w-full">
    <div
      v-if="!formCollapsed"
      class="relative z-20 p-4 mb-4 bg-white rounded-md"
    >
      <div>
        <h4 class="inline-block mb-2 text-lg font-medium leading-10 text-gray-900">
          {{ t('dashboards.editorTitle') }}
        </h4>
      </div>
      <DashboardEditor
        v-model:name="actualName"
        v-model:max-days-range="maxDaysRange"
        v-model:default-period="defaultPeriod"
        v-model:default-comparison-period="defaultComparisonPeriod"
        v-model:granularity="granularity"
        :dashboard-id="dashboardId"
        :sources="sources"
        :stores="stores"
        :widgets="widgets"
        @source-submit="onSourceSubmit"
        @store-submit="onStoreSubmit"
        @store-duplicate="onStoreDuplicate"
        @store-remove="removeStore"
        @widget-submit="onWidgetSubmit"
        @widget-update="onWidgetDefinitionChanged"
        @widget-remove="removeWidget($event.uid)"
        @widget-duplicate="duplicateWidget($event)"
        @source-remove="removeSource"
        @delete="deleteClicked"
      />
      <div class="flex flex-row flex-wrap max-w-full gap-1 m-auto mt-4 2xl:w-xl-px">
        <button
          type="button"
          class="px-3 py-1 text-white bg-green-500 rounded-md"
          @click="save"
        >
          💾&nbsp;{{ t('actions.save') }}
        </button>
        <button
          type="button"
          class="px-3 py-1 text-white bg-blue-400 rounded-md"
          @click="edit = !edit"
        >
          ✏️&nbsp;{{ edit ? t('actions.stopEdit') : t('actions.edit') }}
        </button>
        <button
          type="button"
          class="px-3 py-1 text-white bg-blue-400 rounded-md"
          @click="editMobile = !editMobile"
        >
          {{ editMobile ? '🖥️' : '📱' }}&nbsp;{{ editMobile ? t('labels.desktopMode') : t('labels.mobileMode') }}
        </button>
        <button
          type="button"
          class="px-3 py-1 text-white bg-blue-400 rounded-md"
          @click="showJSON = true"
        >
          <span class="font-bold text-gray-700">{&nbsp;}</span>
          &nbsp;JSON
        </button>
        <button
          type="button"
          class="px-3 py-1 text-white bg-blue-400 rounded-md"
          @click="refresh"
        >
          🔄&nbsp;{{ t('actions.refresh') }}
        </button>
        <button
          type="button"
          class="px-3 py-1 text-white rounded-md bg-amber-500"
          @click="load(lastDefinition)"
        >
          🚫&nbsp;{{ t('actions.cancel') }}
        </button>
        <Modal
          v-model:open="showJSON"
          css="h-90-screen w-full md:w-md lg:min-w-136"
          container-css="overflow-hidden"
        >
          <template #title>
            {{ t('dashboards.json') }}
          </template>
          <DashboardJSONEditor
            class="flex-grow"
            :json="serialize()"
            @cancel="showJSON = false"
            @submit="onJSONSubmit"
          />
        </Modal>
      </div>
    </div>
    <ComparisonGroups
      v-if="sources.length && isComparisonGroups"
      v-model="comparisonGroups"
      :dimensions="dimensionValues"
      @add-comparison-group="addComparisonGroup"
      @remove-comparison-group="removeComparisonGroup"
      @dimension-checked="onFilterChecked"
    />
    <FiltersComponent
      v-if="sources.length"
      v-model="filters"
      v-model:formCollapsed="formCollapsed"
      :no-comparison="isComparisonGroups"
      :editable="editable"
      :max-days-range="maxDaysRange"
      :granularity="granularity"
      :dimensions="dimensionValues"
      @dimension-checked="onFilterChecked"
    />
    <!-- FIXME if a widget is added at the bottom and the height of the page increases, there is overflow (size of the grid is not updated) -->
    <grid-layout
      ref="grid"
      :layout="currentLayout"
      :responsive-layouts="layout"
      :col-num="8"
      :row-height="50"
      :responsive="true"
      :is-resizable="edit"
      :is-draggable="edit"
      :vertical-compact="false"
      :margin="[0,0]"
      :cols="{sm: 6, md: 12}"
      :breakpoints="{ md: 996, sm: 0 }"
      class="-ml-2 -mr-2"
      :class="{'select-none': edit, '!mx-auto': editMobile, 'mb-32': edit}"
      :style="{ 'max-width': editMobile ? '996px' : undefined }"
      @breakpoint-changed="onBreakpointChanged"
    >
      <!-- TODO auto-compact layout on drag -->
      <grid-item
        v-for="item in layoutWidgets"
        :key="item.position.i"
        :x="item.position.x"
        :y="item.position.y"
        :w="item.position.w"
        :h="item.position.h"
        :i="item.position.i"
        :min-w="2"
        :min-h="2"
        :max-w="12"
        drag-ignore-from=".no-drag"
      >
        <TableWidget
          v-if="item.widget.vizType === 'table'"
          :id="item.widget.uid"
          class="transition-colors rounded"
          :definition="item.widget"
          :query-builder="queryBuilder"
          :filters="filters"
          :edit-mode="edit"
          :dimension-values="dimensionValues"
          @segment-clicked="onSegmentClicked"
          @remove-clicked="removeWidget(item.widget.uid)"
          @duplicate-clicked="duplicateWidget(item.widget)"
          @definition-changed="onWidgetDefinitionChanged"
        />
        <SingleMetricWidget
          v-else-if="item.widget.vizType === 'singleMetric'"
          :id="item.widget.uid"
          class="transition-colors rounded"
          :definition="item.widget"
          :query-builder="queryBuilder"
          :default-metric="item.widget.metrics[0].name"
          :filters="filters"
          :edit-mode="edit"
          @remove-clicked="removeWidget(item.widget.uid)"
          @duplicate-clicked="duplicateWidget(item.widget)"
          @definition-changed="onWidgetDefinitionChanged"
        />
        <LineChart
          v-else-if="item.widget.vizType === 'line'"
          :id="item.widget.uid"
          class="transition-colors rounded"
          :definition="item.widget"
          :query-builder="queryBuilder"
          :filters="filters"
          :edit-mode="edit"
          @remove-clicked="removeWidget(item.widget.uid)"
          @duplicate-clicked="duplicateWidget(item.widget)"
          @definition-changed="onWidgetDefinitionChanged"
        />
        <PieChart
          v-else-if="item.widget.vizType === 'pie'"
          :id="item.widget.uid"
          class="transition-colors rounded"
          :definition="item.widget"
          :query-builder="queryBuilder"
          :filters="filters"
          :edit-mode="edit"
          @remove-clicked="removeWidget(item.widget.uid)"
          @duplicate-clicked="duplicateWidget(item.widget)"
          @definition-changed="onWidgetDefinitionChanged"
        />
        <BarChart
          v-else-if="item.widget.vizType === 'bar'"
          :id="item.widget.uid"
          class="transition-colors"
          :definition="item.widget"
          :query-builder="queryBuilder"
          :filters="filters"
          :edit-mode="edit"
          @remove-clicked="removeWidget(item.widget.uid)"
          @duplicate-clicked="duplicateWidget(item.widget)"
          @definition-changed="onWidgetDefinitionChanged"
        />
        <MapChart
          v-else-if="item.widget.vizType === 'map'"
          :id="item.widget.uid"
          class="transition-colors rounded"
          :definition="item.widget"
          :query-builder="queryBuilder"
          :filters="filters"
          :edit-mode="edit"
          @remove-clicked="removeWidget(item.widget.uid)"
          @duplicate-clicked="duplicateWidget(item.widget)"
          @definition-changed="onWidgetDefinitionChanged"
        />
        <TextWidget
          v-else-if="item.widget.vizType === 'text'"
          :id="item.widget.uid"
          class="transition-colors rounded"
          :definition="item.widget"
          :query-builder="queryBuilder"
          :filters="filters"
          :edit-mode="edit"
          @remove-clicked="removeWidget(item.widget.uid)"
          @duplicate-clicked="duplicateWidget(item.widget)"
          @definition-changed="onWidgetDefinitionChanged"
        />
        <ComparativeTableWidget
          v-else-if="item.widget.vizType === 'comparativeTable'"
          :id="item.widget.uid"
          class="transition-colors rounded"
          :definition="item.widget"
          :query-builder="queryBuilder"
          :filters="filters"
          :edit-mode="edit"
          :dimension-values="dimensionValues"
          @segment-clicked="onSegmentClicked"
          @remove-clicked="removeWidget(item.widget.uid)"
          @duplicate-clicked="duplicateWidget(item.widget)"
          @definition-changed="onWidgetDefinitionChanged"
        />
        <SeatsBoosters
          v-else-if="item.widget.vizType === 'seatsBoosters'"
          :id="item.widget.uid"
          class="transition-colors rounded"
          :definition="item.widget"
          :query-builder="queryBuilder"
          :filters="filters"
          :edit-mode="edit"
          @remove-clicked="removeWidget(item.widget.uid)"
          @duplicate-clicked="duplicateWidget(item.widget)"
          @definition-changed="onWidgetDefinitionChanged"
        />
        <!--
          I can't use a generic component that returns the correct component definition
          based on the vizType because for some reason the events stop binding correctly
          after the second dynamic vizType change.
        -->
      </grid-item>
    </grid-layout>
  </div>
</template>

<script lang="ts">
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import xor from 'lodash/xor'
import { v1 as uuidv1 } from 'uuid'
import { computed, defineComponent, onMounted, onUnmounted, PropType, provide, reactive, Ref, ref, unref, watch } from 'vue'
import { GridLayout } from 'vue-grid-layout'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { useContext } from '@/plugins/context'
import { DashboardSerializer, DashboardSerializerOptions, DEFAULT_MAX_DAYS_RANGE, deserializeWidgetDefinition, LayoutItem, PositionedWidget, QueryBuilder, ResponsiveLayout, SegmentClickedEvent, SerializedDashboard, SerializedStore, serializeWidgetDefinition, Store, StoreFormSubmitEvent, StoresInjectKey, StoreState, WidgetDefinition, WidgetFilter } from '@/plugins/dashboard'
import { DateDimension, DateRollupDimension, TimeGranularityName } from '@/plugins/dashboard/dimensions'
import { deserializeSource, FilterDimension, Filters, FilterValues, SerializedSource, Source } from '@/plugins/dashboard/source'
import { translateDBName } from '@/plugins/dashboard/translations'

import { useContextStore } from '@/store/context.store'
import { useNotificationsStore } from '@/store/notifications.store'

import { createComparisonGroup } from '@/models/comparisonGroup'
import { UpdateDashboardForm } from '@/models/dashboard'

import { deleteDashboard, updateDashboard } from '@/services/dashboards'

import { ComparisonRangePreset, DateRange, DateRangePreset } from '@/components/DateRangePicker/dateRange'

import Modal from '../Modal/Modal.vue'

import ComparisonGroups from './ComparisonGroups.vue'
import defaultSize from './defaultSize'
import FiltersComponent from './Filters.vue'
import DashboardEditor from './forms/DashboardEditor.vue'
import DashboardJSONEditor from './forms/DashboardJSONEditor.vue'
import BarChart from './widgets/BarChart.vue'
import ComparativeTableWidget from './widgets/ComparativeTableWidget.vue'
import LineChart from './widgets/LineChart.vue'
import MapChart from './widgets/MapChart.vue'
import PieChart from './widgets/PieChart.vue'
import SeatsBoosters from './widgets/SeatsBoosters.vue'
import SingleMetricWidget from './widgets/SingleMetricWidget.vue'
import TableWidget from './widgets/TableWidget.vue'
import TextWidget from './widgets/TextWidget.vue'
import { highlightWidget } from './widgets/Widget'

export default defineComponent({
  components: {
    TableWidget,
    SingleMetricWidget,
    ComparisonGroups,
    FiltersComponent,
    DashboardEditor,
    PieChart,
    BarChart,
    LineChart,
    MapChart,
    Modal,
    TextWidget,
    ComparativeTableWidget,
    DashboardJSONEditor,
    SeatsBoosters
  },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    definition: {
      type: Object as PropType<SerializedDashboard>,
      required: true
    },
    dashboardId: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const router = useRouter()
    const route = useRoute()

    const notificationsStore = useNotificationsStore()
    const contextStore = useContextStore()

    const { t } = useI18n()

    // Refs
    const grid = ref(null as GridLayout | null)

    // Data
    const lastDefinition = ref(props.definition)
    const stores: Ref<Store[]> = ref([])
    provide(StoresInjectKey, stores)
    const defaultPeriod = ref('last_7_days' as DateRange | DateRangePreset)
    const defaultComparisonPeriod = ref(undefined as ComparisonRangePreset | undefined)
    const filters = reactive({
      filters: {},
      datePreset: '' as DateRangePreset,
      dateRange: {
        from: new Date(),
        to: new Date()
      },
      comparisonDateRange: {
        from: undefined,
        to: undefined
      }
    } as Filters)
    const comparisonGroups = reactive([
      createComparisonGroup('A'),
      createComparisonGroup('B')
    ])
    const maxDaysRange = ref(DEFAULT_MAX_DAYS_RANGE)
    const formCollapsed = ref(true)
    const edit = ref(false)
    const editMobile = ref(false)
    const dimensionValues = ref([] as FilterDimension[])
    const sources = ref([] as Source[])
    const widgets = ref([] as WidgetDefinition[])
    const layout = ref({ sm: [], md: [] } as ResponsiveLayout)
    const currentLayout = ref([] as LayoutItem[])
    const actualName = ref('')
    const showJSON = ref(false)
    const granularity = ref('day' as TimeGranularityName)
    let lastName = props.name.toString()

    const queryBuilder = new QueryBuilder(filters, router)

    const tooManyRequestsNotification = throttle(() => {
      notificationsStore.add({
        type: 'error',
        title: t('messages.tooManyRequests'),
        message: t('messages.tooManyRequestsMessage')
      })
    }, 10000, { trailing: false })

    const dataLimitNotification = throttle(() => {
      notificationsStore.add({
        title: t('labels.warning'),
        message: t('dashboards.dataLimit'),
        type: 'warning'
      })
    }, 10000, { trailing: false })

    const refreshErrorNotification = throttle(() => {
      notificationsStore.add({
        message: t('dashboards.refreshError'),
        type: 'error'
      })
    }, 10000, { trailing: false })

    const refreshFiltersErrorNotification = throttle(() => {
      notificationsStore.add({
        message: t('dashboards.refreshFiltersError'),
        type: 'error'
      })
    }, 10000, { trailing: false })

    // Computed
    const layoutWidgets = computed((): PositionedWidget[] => {
      return widgets.value
        .filter(w => currentLayout.value.findIndex(l => l.i === w.uid) !== -1)
        .map(w => {
          return {
            widget: w,
            position: currentLayout.value.find(l => l.i === w.uid)!
          }
        })
    })

    // Methods
    const getDimensionValues = () => {
      if (sources.value.length === 0) {
        return
      }
      const promises: Array<Promise<Record<string, FilterDimension>>> = []
      stores.value
        .filter(store => store.requiredFiltersFulfilled())
        .forEach(store => {
          const dimensions = new Set<string>()
          store.definition.dimensions.filter(d => !store.definition.hidden.includes(d.name) && d.name !== 'comparisonGroup').forEach(d => dimensions.add(d.name))
          store.refreshDimensions.filter(d => !store.definition.hidden.includes(d)).forEach(d => dimensions.add(d))
          const dims = [...dimensions]

          const localFilters: Record<string, FilterValues> = {}
          store.requiredFilters
            .filter(f => filters.filters[f.dimension] !== undefined)
            .forEach(f => {
              const values = filters.filters[f.dimension]
              localFilters[f.dimension] = {
                operator: values.operator,
                values: [...values.values]
              }
            })
          promises.push(new Promise((resolve, reject) => {
            store.source.getDimensionValues(dims, store.timeDimensions, { dateRange: filters.dateRange, filters: localFilters } as Filters)
              .then(dimensions => {
                resolve(dimensions)
              })
              .catch(err => {
                reject(err)
              })
          }))
        })

      Promise.all(promises)
        .then(results => {
          const newValues: Record<string, { dimension: FilterDimension, values: Record<string, string[]>}> = {}
          // console.log('Possible dimension values (filters) refreshed')

          results.forEach(dimensions => {
            for (const key in dimensions) {
              if (newValues[key] === undefined) {
                // Possible side-effect of having two sources enriching the same dimension but with different table and/or key:
                // the table and key of the first one will be kept.
                // It is important to use globally unique aliases for enriched dimensions!
                newValues[key] = { dimension: dimensions[key], values: {} }
              }
              Object.entries(dimensions[key].values).forEach(e => { newValues[key].values[e[0]] = e[1] })
            }
          })

          // Remove the dimensions that are not available anymore from filters
          const newDimensions = Object.keys(newValues)
          const removedDimensions = dimensionValues.value.filter(d => d.enrichment === undefined && !newDimensions.includes(d.name)).map(d => d.name)
          removedDimensions.forEach(d => delete filters.filters[d])

          dimensionValues.value = []
          for (const key in newValues) {
            dimensionValues.value.push({
              name: newValues[key].dimension.name,
              label: translateDBName(newValues[key].dimension.name),
              enrichment: newValues[key].dimension.enrichment,
              values: newValues[key].values
            })
          }

          if (removedDimensions.length > 0) {
            stores.value.forEach(s => s.onFilterClicked(removedDimensions, filters.filters))
          }
        })
        .catch(err => {
          if (err.status === 429) {
            tooManyRequestsNotification()
            return
          }
          refreshFiltersErrorNotification()
          console.error(err)
        })
    }

    const refresh = () => {
      queryBuilder.parseQuery(route, filters, defaultPeriod.value, maxDaysRange.value, defaultComparisonPeriod.value)

      stores.value.forEach(s => {
        s.clearCache()
        if (s.state.state > StoreState.WORKER_READY) {
          s.state.state = StoreState.WORKER_READY
        }
        s.setDateRange(filters.dateRange)
        s.refreshFunction()
        if (s.comparisonStore) {
          s.comparisonStore.clearCache()
          if (s.comparisonStore.state.state > StoreState.WORKER_READY) {
            s.comparisonStore.state.state = StoreState.WORKER_READY
          }
          s.comparisonStore.setDateRange(filters.comparisonDateRange)
          s.comparisonStore.refreshFunction()
        }
      })
    }

    const isComparisonGroups = computed((): boolean => {
      return stores.value.some(s => s.definition.dimensions.some(d => d.name === 'comparisonGroup'))
    })

    const addComparisonGroup = () => {
      const name = String.fromCharCode(comparisonGroups.length + 65)
      comparisonGroups.push(createComparisonGroup(name))

      refresh()
    }

    const removeComparisonGroup = (index: number) => {
      comparisonGroups.splice(index, 1)
      comparisonGroups.forEach((comparisonGroup, i) => {
        if (i >= index) {
          const name = String.fromCharCode(i + 65)
          const tmp = createComparisonGroup(name)

          comparisonGroup.name = tmp.name
          comparisonGroup.label = tmp.label
        }
      })

      refresh()
    }

    const onFilterChecked = (dims: string | string[], originStore?: string) => {
      // First, remove filters that are dependent on that dimension (required filters)
      // If at least one store has a required filter on that dimension, clear filters for its data dimensions
      const dimensions = Array.isArray(dims) ? dims : [dims]
      const clearedDimensions = new Set<string>()
      let prevLength = -1
      while (prevLength !== clearedDimensions.size) {
        // Using while to handle cascading required filter dependencies
        prevLength = clearedDimensions.size
        dimensions.forEach(d => {
          stores.value
            .filter(store => store.requiredFilters.some(rf => rf.dimension === d))
            .forEach(store => {
              store.definition.dimensions
                .filter(dim => dim.name !== d && !dim.enrichment && !(dim instanceof DateDimension) && !(dim instanceof DateRollupDimension) && !dims.includes(dim.name))
                .forEach(dim => clearedDimensions.add(dim.name))
            })
        })
      }
      clearedDimensions.forEach(d => {
        delete filters.filters[d]
      })
      clearedDimensions.forEach(d => dimensions.push(d))

      stores.value.filter(s => s.uid !== originStore).forEach(s => s.onFilterClicked(dimensions, filters.filters))
    }

    const onSegmentClicked = ($event: SegmentClickedEvent) => {
      const filtersBefore: Record<string, string[]> = {}
      const originStore = stores.value.find(s => s.uid === $event.originStore)!

      // Transform dimensions and value if one-to-one enrichment (and save the current state of filters before change)
      $event.dimensions.forEach((segmentDimensions, i) => {
        const newDimensions: WidgetFilter[] = [];

        [...segmentDimensions].forEach(d => {
          const refDimension = dimensionValues.value.find(dim => dim.name === d.name)
          if (refDimension !== undefined && refDimension.enrichment !== undefined && refDimension.enrichment.oneToOne) {
            const originalValue = d.value
            if (refDimension.values[originalValue] !== undefined) {
              refDimension.values[originalValue].forEach(v => {
                if (!newDimensions.some(nd => nd.name === refDimension.enrichment?.name && nd.value === v) && !originStore.requiredFilters.some(rf => rf.dimension === refDimension.enrichment?.name)) {
                  newDimensions.push({
                    name: refDimension.enrichment!.name,
                    value: v
                  })
                }
              })
              filtersBefore[refDimension.enrichment.name] = [...(filters.filters[refDimension.enrichment.name]?.values || [])]
            }
            // Note: if there is no original value to map (unknown or NULL), then it's excluded and not added to filters
          } else {
            if (!originStore.requiredFilters.some(rf => rf.dimension === d.name)) {
              newDimensions.push(d)
            }
            filtersBefore[d.name] = [...(filters.filters[d.name]?.values || [])]
          }
        })

        $event.dimensions[i] = newDimensions
      })

      if (!$event.ctrl) {
        // clear the filters for this dimension
        $event.dimensions.forEach(segmentDimensions => {
          segmentDimensions.forEach(f => {
            delete filters.filters[f.name]
          })
        })
      }

      // Toggle the filter
      $event.dimensions.forEach(wf => wf.forEach(d => {
        if (filters.filters[d.name] === undefined) {
          filters.filters[d.name] = {
            operator: 'equals',
            values: []
          }
        }

        const i = filters.filters[d.name].values.indexOf(d.value)
        if ($event.ctrl && i !== -1) {
          filters.filters[d.name].values.splice(i, 1)
          if (filters.filters[d.name].values.length === 0) {
            delete filters.filters[d.name]
          }
        } else {
          filters.filters[d.name].values.push(d.value)
        }
      }))

      const changed = new Set<string>()
      Object.entries(filtersBefore).forEach(e => {
        if (xor(e[1], filters.filters[e[0]]?.values).length) {
          changed.add(e[0])
        }
      })
      onFilterChecked([...changed], $event.originStore)
    }

    const onBreakpointChanged = (newBreakpoint: string) => {
      switch (newBreakpoint) {
        case 'sm':
          currentLayout.value = layout.value.sm
          break
        case 'md':
          currentLayout.value = layout.value.md
          break
      }
    }

    const updateCurrentLayout = () => {
      if (grid.value) {
        onBreakpointChanged(grid.value.lastBreakpoint)
      }
    }

    const update = (body: UpdateDashboardForm): Promise<void> => {
      return new Promise<void>((resolve, reject) => {
        updateDashboard(props.dashboardId, body)
          .then(() => {
            console.log('Dashboard saved')
            notificationsStore.add({
              message: t('dashboards.saveSuccess'),
              type: 'success',
              duration: 4000
            })
            resolve()
          })
          .catch((e: any) => {
            notificationsStore.add({
              message: t('dashboards.saveError', [e.message]),
              type: 'error'
            })
            reject(e)
          })
      })
    }

    const onStoreError = (err: any) => {
      if (err.status === 429) {
        tooManyRequestsNotification()
        return
      }
      refreshErrorNotification()
    }

    const onDataLimit = () => {
      dataLimitNotification()
    }

    const serialize = (): SerializedDashboard => {
      const options: DashboardSerializerOptions = {
        maxDaysRange: maxDaysRange.value,
        granularity: granularity.value,
        defaultPeriod: defaultPeriod.value,
        defaultComparisonPeriod: defaultComparisonPeriod.value,
        sources: sources.value,
        stores: stores.value,
        widgets: widgets.value,
        layout: layout.value
      }
      return new DashboardSerializer().serialize(options)
    }

    const save = () => {
      const saved = serialize()
      const request: Record<string, any> = {
        config: saved
      }
      const currentName = unref(actualName.value)
      if (lastName !== currentName) {
        request.name = currentName
      }
      update(request).then(() => {
        lastName = currentName
        lastDefinition.value = saved
      })
    }

    const load = (def: SerializedDashboard) => {
      try {
        const dashboard = new DashboardSerializer().deserialize(def, comparisonGroups, filters, onStoreError, onDataLimit)
        maxDaysRange.value = dashboard.maxDaysRange
        sources.value = dashboard.sources
        stores.value.forEach(s => s.dispose())
        stores.value = dashboard.stores
        widgets.value = dashboard.widgets
        layout.value = dashboard.layout
        granularity.value = dashboard.granularity
        defaultPeriod.value = dashboard.defaultPeriod
        defaultComparisonPeriod.value = dashboard.defaultComparisonPeriod
        updateCurrentLayout()
        console.log('Dashboard loaded')
        refresh()
        if (props.editable && sources.value.length === 0) {
          formCollapsed.value = false
        }
        showJSON.value = false
      } catch (e: any) {
        console.error(e)
        notificationsStore.add({
          message: t('dashboards.loadError', [e.message]),
          type: 'error'
        })
      }
    }

    const onSourceSubmit = (event: SerializedSource) => {
      const source = deserializeSource(event)
      source.getTableDefinition().then(() => {
        sources.value.push(source)
        console.log('New Source created')
      })
        .catch((e: any) => {
          console.error(e)
          notificationsStore.add({
            message: t('dashboards.createSourceError', [e.message]),
            type: 'error'
          })
        })
    }

    const onStoreSubmit = (event: StoreFormSubmitEvent) => {
      const store = new Store(event.uid, event.name, event.source, event.definition, event.timeDimensions, event.requiredFilters, event.refreshDimensions, comparisonGroups, filters, event.granularity, false, event.disableComparison, event.periodOverride, event.comparisonPeriodOverride, onStoreError, onDataLimit)
      stores.value.push(store)
      console.log('New Store created')
      refresh()
    }

    const onStoreDuplicate = (event: SerializedStore) => {
      try {
        const store = Store.deserialize(event, sources.value, comparisonGroups, filters, onStoreError, onDataLimit)
        stores.value.push(store)
        console.log('Store duplicated')
        refresh()
      } catch (e: any) {
        console.error(e)
        notificationsStore.add({
          title: t('labels.error'),
          message: t('dashboards.duplicateError', [e.message]),
          type: 'error'
        })
      }
    }

    const onJSONSubmit = (def: SerializedDashboard) => {
      load(def)
    }

    const newWidgetPosition = (uid: string, vizType: string, sm: boolean, y: number, sizeOverride?: LayoutItem): LayoutItem => {
      const size = sizeOverride ? { width: sizeOverride.w, height: sizeOverride.h } : defaultSize[sm ? 'sm' : 'md'][vizType]
      return {
        x: 0,
        y,
        w: size.width,
        h: size.height,
        i: uid
      }
    }

    const onWidgetSubmit = (widget: WidgetDefinition, smPos?: LayoutItem, mdPos?: LayoutItem) => {
      widgets.value.push(widget)
      const mdY = Math.max(...layout.value.md.map(l => l.y + l.h))
      const smY = Math.max(...layout.value.sm.map(l => l.y + l.h))
      layout.value.md.push(newWidgetPosition(widget.uid, widget.vizType, false, mdY === -Infinity ? 0 : mdY, mdPos))
      layout.value.sm.push(newWidgetPosition(widget.uid, widget.vizType, true, smY === -Infinity ? 0 : smY, smPos))
      console.log(`New Widget created with id ${widget.uid}`)
      edit.value = true
      setTimeout(() => {
        notificationsStore.add({
          title: t('labels.success'),
          message: t('dashboards.widgetCreated'),
          type: 'success',
          duration: 2500
        })
        highlightWidget(widget.uid)
      }, 250)
    }

    const onWidgetDefinitionChanged = (newDefinition: WidgetDefinition) => {
      const i = widgets.value.findIndex(w => w.uid === newDefinition.uid)
      if (i !== -1) {
        widgets.value[i] = newDefinition
      }
    }

    const duplicateWidget = (item: WidgetDefinition) => {
      const cpy: WidgetDefinition = deserializeWidgetDefinition(serializeWidgetDefinition(item), stores.value)
      cpy.uid = uuidv1()
      const sm = layout.value.sm.find(l => l.i === item.uid)
      const md = layout.value.md.find(l => l.i === item.uid)
      onWidgetSubmit(cpy, sm, md)
    }

    const removeWidget = (uid: string) => {
      const i = widgets.value.findIndex(w => w.uid === uid)
      if (i !== -1) {
        widgets.value.splice(i, 1)
        const j = layout.value.md.findIndex(l => l.i === uid)
        if (j !== -1) {
          layout.value.md.splice(j, 1)
        }
        const k = layout.value.sm.findIndex(l => l.i === uid)
        if (k !== -1) {
          layout.value.sm.splice(k, 1)
        }
        console.log(`Widget ${uid} removed`)
      }
    }

    const removeStore = (uid: string) => {
      const i = stores.value.findIndex(w => w.uid === uid)
      if (i !== -1) {
        stores.value[i].dispose()
        stores.value.splice(i, 1)
        console.log(`Store ${uid} removed`)
      }
      widgets.value.filter(w => w.store.uid === uid).forEach(w => {
        removeWidget(w.uid)
      })
    }

    const removeSource = (uid: string) => {
      const i = sources.value.findIndex(w => w.uid === uid)
      if (i !== -1) {
        sources.value.splice(i, 1)
        console.log(`Source ${uid} removed`)
      }
      stores.value.filter(s => s.source.uid === uid).forEach(s => {
        removeStore(s.uid)
      })
    }

    const onKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && props.editable) {
        switch (event.key) {
          case 'e': // FIXME doesn't work on mac with "cmd"
            event.preventDefault()
            edit.value = !edit.value
            break
          case 's':
            event.preventDefault()
            save()
            break
        }
      }
    }

    const onFilterUpdated = debounce(() => {
      getDimensionValues()
    }, 1000)

    const deleteClicked = () => {
      deleteDashboard(props.dashboardId)
        .then(() => {
          notificationsStore.add({
            message: t('messages.deleteSuccess'),
            type: 'success',
            duration: 4000
          })

          if (contextStore.hasContext) {
            router.push(useContext('dashboards.index', {}).value)
          }
        })
        .catch((e: any) => {
          console.error(e)
          notificationsStore.add({
            message: t('messages.deleteErrorWithMsg', [e.message]),
            type: 'error'
          })
        })
    }

    // Watch
    watch(
      () => props.definition,
      () => {
        lastDefinition.value = props.definition
        load(lastDefinition.value)
      }
    )

    watch(
      () => props.name,
      () => {
        lastName = props.name
        actualName.value = props.name
      }
    )

    watch(
      () => [filters.filters, filters.dateRange, filters.datePreset],
      () => onFilterUpdated(),
      { deep: true }
    )

    watch(
      () => granularity.value,
      () => {
        stores.value.forEach(s => {
          s.granularity = granularity.value
        })
      }
    )

    // Lifecycle
    onMounted(() => {
      load(lastDefinition.value)
      actualName.value = props.name
      if (props.editable) {
        window.addEventListener('keydown', onKeyDown)
      }
    })

    onUnmounted(() => {
      stores.value.forEach(s => s.dispose())
      queryBuilder.dispose()
      window.removeEventListener('keydown', onKeyDown)
    })

    return {
      // Refs
      grid,

      // Data
      stores,
      comparisonGroups,
      filters,
      formCollapsed,
      edit,
      editMobile,
      dimensionValues,
      sources,
      layout,
      currentLayout,
      actualName,
      lastDefinition,
      maxDaysRange,
      showJSON,
      defaultComparisonPeriod,
      defaultPeriod,
      widgets,
      granularity,

      // Computed
      layoutWidgets,

      // Methods
      refresh,
      isComparisonGroups,
      addComparisonGroup,
      removeComparisonGroup,
      onFilterChecked,
      onSegmentClicked,
      onBreakpointChanged,
      serialize,
      save,
      load,
      onSourceSubmit,
      onStoreSubmit,
      onStoreDuplicate,
      onWidgetSubmit,
      onWidgetDefinitionChanged,
      duplicateWidget,
      removeWidget,
      removeStore,
      removeSource,
      deleteClicked,
      onJSONSubmit,

      // Misc
      translateDBName,
      queryBuilder,
      t
    }
  }
})
</script>
