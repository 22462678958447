import { RouteRecordRaw } from 'vue-router'

import { rolesGroup } from '@/static/roles'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

import Create from './views/Create.vue'
import Edit from './views/Edit.vue'
import Index from './views/Index.vue'
import InstantReport from './views/InstantReport.vue'

export function reports (context: ContextType): RouteRecordRaw[] {
  const routes: RouteRecordRaw[] = [
    {
      path: 'reports',
      name: `${context}.reports.index`,
      component: Index,
      meta: {
        title: i18n.global.t('labels.scheduledReport', 100),
        roles: context === ContextType.BIDDERS ? rolesGroup.adagio : rolesGroup.user,
        requireService: context === ContextType.BIDDERS,
        feature: 'reports'
      },
      children: [
        {
          path: 'create',
          name: `${context}.reports.create`,
          component: Create,
          meta: {
            title: i18n.global.t('reports.create'),
            feature: 'reports',
            roles: rolesGroup.user
          }
        },
        {
          path: ':reportId/edit',
          name: `${context}.reports.edit`,
          component: Edit,
          meta: {
            title: i18n.global.t('reports.edit', { name: ':reportName' }),
            pattern: ':reportName',
            feature: 'reports',
            roles: rolesGroup.user
          },
          props: route => ({ reportId: route.params.reportId })
        }
      ]
    }
  ]

  if (context === ContextType.PUBLISHERS) {
    routes.push({
      path: 'instant-report',
      name: `${context}.instant-report`,
      component: InstantReport,
      meta: {
        layout: 'base',
        title: i18n.global.t('labels.instantReport'),
        roles: rolesGroup.user
      }
    })
  }
  return routes
}
