import { RouteRecordRaw } from 'vue-router'

import { rolesGroup } from '@/static/roles'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

export function publisherWhitelists (context: ContextType): RouteRecordRaw[] {
  return [
    {
      path: 'whitelists',
      name: `${context}.whitelists.index`,
      component: () => import('./views/Index.vue'),
      meta: {
        title: i18n.global.t('labels.whitelist', 2),
        feature: 'whitelists',
        roles: rolesGroup.user
      },
      children: [
        {
          path: ':websiteId/seats/:seatId/edit',
          name: `${context}.whitelists.edit`,
          component: () => import('./views/Edit.vue'),
          meta: {
            title: i18n.global.t('whitelists.edit', { name: ':websiteName' }),
            pattern: ':websiteName',
            feature: 'whitelists',
            roles: rolesGroup.all
          },
          props: route => ({ websiteId: route.params.websiteId, seatId: route.params.seatId })
        },
        {
          path: 'bulk',
          name: `${context}.whitelists.bulk`,
          component: () => import('./views/Bulk.vue'),
          meta: {
            // layout: 'base',
            title: i18n.global.t('whitelists.bulk'),
            feature: 'whitelists',
            roles: rolesGroup.operation
          }
        }
      ]
    }
  ]
}
