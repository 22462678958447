import { Grouping } from 'crossfilter2'
import { format } from 'date-fns'
import { ComputedRef, ExtractPropTypes, PropType, Ref, WatchStopHandle, computed, getCurrentInstance, onBeforeUnmount, onMounted, ref, toRaw, watch } from 'vue'
import VChart from 'vue-echarts'
import { useI18n } from 'vue-i18n'

import { ExportForm, Metric, QueryBuilder, StoreState, WidgetDefinition, download, toCSVDataURL, translateDBName } from '@/plugins/dashboard'
import { CompositeDimension, DateDimension, DateRollupDimension, Dimension } from '@/plugins/dashboard/dimensions'
import { StringReducer, UniqueReducerConstructor } from '@/plugins/dashboard/reducers'
import { Filters } from '@/plugins/dashboard/source'

import { multiselectTailwindClassesCompact } from '@/components/Multiselect'

/**
 * Properties that all widgets have in common.
 */
export const WIDGET_PROPS = {
  definition: {
    type: Object as PropType<WidgetDefinition>,
    required: true as true
  },
  queryBuilder: {
    type: Object as PropType<QueryBuilder>,
    required: true as true
  },
  filters: {
    type: Object as PropType<Filters>,
    default: () => {}
  },
  editMode: {
    type: Boolean,
    default: false
  }
}

export type WidgetSettings = {
  props: ExtractPropTypes<typeof WIDGET_PROPS>, // The props received by the `setup()` function
  dimension: Ref<Dimension>, // A ref to a dynamically created dimension, or a ref to the first dimension in `props.dimensions`

  // The callback function called every time the store updates the cache for the given dimension
  updateFunc: (v: ReadonlyArray<Grouping<any, Record<string, any>>> | undefined) => void

  // The callback function called every time the store updates the comparison cache for the given dimension.
  // Only requests and processes comparison data if this callback function is not undefined.
  comparisonUpdateFunc?: (v: ReadonlyArray<Grouping<any, Record<string, any>>> | undefined) => void

  // The callback function called every time the store updates the cache for one of the metrics in the widget definition.
  // Only requests and processes metrics if the callback function is not undefined.
  // All non-string metrics in the widget definition are processed as single metrics.
  singleMetricUpdateFunc?: (name: string, value: any) => void

  // The callback function called every time the store updates the comparison cache for one of the metrics in the widget definition.
  // Only requests and processes comparison data if this callback function is not undefined.
  singleMetricComparisonUpdateFunc?: (name: string, value: any) => void
}

interface SetupEnlarged {
  enlarged: Ref<boolean>
  onExpandClicked: () => void
}

interface SetupWidget extends SetupEnlarged {
  title: ComputedRef<string>
  loading: Ref<boolean>
  isLoading: ComputedRef<boolean>
  hasData: ComputedRef<boolean>
  hasComparisonData: ComputedRef<boolean>
  isWaitingForBothData: ComputedRef<boolean>
  onExport: (title: string, form: ExportForm) => void
}

/**
 * Setup function shared for all widgets. Handles dimension registering and disposal, as well as cache update watching.
 * If the value of the given dimension Ref is changed, the old value will be disposed and the new one will be used instead automatically.
 * Widgets using this setup function can make use of the returned "onExportClicked(string)" function to add a button to export the widgets data as CSV.
 * @param settings see WidgetSettings for more information
 * @returns
 *  - a "title" computed ref
 *  - a "loading" ref indicating if the widget is waiting for its data
 *  - "isLoading" computed ref for use with the `Loading` component
 *  - "onExport" function for CSV export, an "onExpandClicked" function for fullscreen widget
 *  - an "enlarged" boolean ref to know if fullscreen mode is activated
 *  - a computed boolean "hasData" to know if the widget has data to display
 *  - a computed boolean "hasComparisonData" to know if the widget has comparison data to display
 */
export function setupWidget (settings: WidgetSettings): SetupWidget {
  let stopWatch: WatchStopHandle
  let stopWatchStoreState: WatchStopHandle
  let stopWatchDimension: WatchStopHandle
  let stopWatchComparison: WatchStopHandle
  let stopWatchComparisonStoreState: WatchStopHandle
  let stopWatchComparisonDimension: WatchStopHandle

  let stopWatchSingleMetrics: Record<string, WatchStopHandle> = {}
  let stopWatchComparisonSingleMetrics: Record<string, WatchStopHandle> = {}

  const records = ref(undefined as ReadonlyArray<Grouping<any, Record<string, any>>> | undefined)
  const comparisonRecords = ref(undefined as ReadonlyArray<Grouping<any, Record<string, any>>> | undefined)

  const { t } = useI18n()

  const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1)
  const dimensionName = (): string => {
    const name = settings.dimension.value instanceof CompositeDimension
      ? settings.dimension.value.dimensions
        .filter(d => !(d instanceof DateDimension) && !(d instanceof DateRollupDimension))
        .map(d => d.name)
        .join(', ')
      : settings.dimension.value.name
    return translateDBName(name)
  }
  const title = computed(() =>
    settings.props.definition.title || t('dashboards.defaultWidgetTitle', {
      vizType: capitalize(settings.props.definition.vizType),
      metrics: settings.props.definition.metrics.map(m => translateDBName(m.name)).join(', '),
      dimensionName: dimensionName()
    })
  )

  const isWaitingForBothData = computed(() => {
    if (settings.props.definition.store.comparisonStore === undefined || settings.props.definition.disableComparison || !settings.props.definition.store.comparisonStore.requiredFiltersFulfilled() || !settings.props.definition.store.comparisonStore.getDateRange() || settings.comparisonUpdateFunc === undefined) {
      return false
    }
    const comparisonLoading = !comparisonRecords.value && settings.props.definition.store.comparisonStore.state.state !== StoreState.WORKER_READY && settings.props.definition.store.comparisonStore.state.state !== StoreState.READY
    const mainDataLoading = !records.value && settings.props.definition.store.state.state !== StoreState.WORKER_READY && settings.props.definition.store.state.state !== StoreState.READY
    return mainDataLoading || comparisonLoading
  })

  const loading = ref(false)
  const isLoading = computed(() => {
    return settings.props.definition.store.state.state === StoreState.PREPARING || settings.props.definition.store.state.state === StoreState.LOADING || settings.props.definition.store.shouldRefresh || loading.value || isWaitingForBothData.value
  })

  const hasData = computed(() => records.value !== undefined)
  const hasComparisonData = computed(() => comparisonRecords.value !== undefined)

  const getMetrics = (def: WidgetDefinition, dimension: Dimension): Metric[] => {
    const metrics = def.metrics.map(m => {
      if (Object.getPrototypeOf(m.ReducerConstructor).name === StringReducer.name) {
        const reducer = new m.ReducerConstructor() as StringReducer
        return new Metric(m.name, UniqueReducerConstructor(reducer.metric || m.name), 'INTEGER')
      }

      return m
    })
    const dimensions = dimension instanceof CompositeDimension
      ? dimension.dimensions.map(d => new Metric(d.name, UniqueReducerConstructor(d.column), 'INTEGER'))
      : [new Metric(settings.dimension.value.name, UniqueReducerConstructor(dimension.column), 'INTEGER')]
    return [...dimensions, ...metrics]
  }

  const mounted = (): void => {
    const dim = toRaw(settings.dimension.value)
    if (settings.props.definition.store.state.state === StoreState.READY) {
      settings.props.definition.store.addDimension(dim, settings.props.definition.metrics, settings.props.definition.uid)

      if (settings.singleMetricUpdateFunc !== undefined) {
        getMetrics(settings.props.definition, dim).forEach(m => {
          settings.props.definition.store.addMetric(m, settings.props.definition.uid)
        })
      }
    }
    if (!settings.props.definition.disableComparison && settings.comparisonUpdateFunc !== undefined && settings.props.definition.store.comparisonStore && settings.props.definition.store.comparisonStore.state.state === StoreState.READY) {
      settings.props.definition.store.comparisonStore.addDimension(dim, settings.props.definition.metrics, settings.props.definition.uid)

      if (settings.singleMetricComparisonUpdateFunc !== undefined) {
        getMetrics(settings.props.definition, dim).forEach(m => {
          settings.props.definition.store.comparisonStore!.addMetric(m, settings.props.definition.uid)
        })
      }
    }
  }

  const beforeUnmount = (definition: WidgetDefinition): void => {
    definition.store.disposeDimension(settings.dimension.value.name, definition.uid)
    if (settings.singleMetricUpdateFunc !== undefined) {
      Object.keys(stopWatchSingleMetrics).forEach(m => {
        settings.props.definition.store.disposeMetric(m, settings.props.definition.uid)
      })
    }
    if (!definition.disableComparison && definition.store.comparisonStore && settings.comparisonUpdateFunc !== undefined) {
      definition.store.comparisonStore.disposeDimension(settings.dimension.value.name, definition.uid)

      if (settings.singleMetricComparisonUpdateFunc !== undefined) {
        Object.keys(stopWatchComparisonSingleMetrics).forEach(m => {
          settings.props.definition.store.comparisonStore!.disposeMetric(m, settings.props.definition.uid)
        })
      }
    }

    if (settings.props.queryBuilder.enlargedWidgetID.value === settings.props.definition.uid) {
      settings.props.queryBuilder.setEnlarged(undefined)
    }
  }

  const init = (): void => {
    const onCacheUpdated = (v: Record<string, ReadonlyArray<Grouping<any, Record<string, any>>>> | undefined): void => {
      if (v === undefined) {
        if (records.value === undefined) {
          return
        }
        records.value = undefined
        settings.updateFunc(undefined)
        if (settings.props.definition.store.state.state === StoreState.READY) {
          loading.value = true
          settings.props.definition.store.addDimension(toRaw(settings.dimension.value), settings.props.definition.metrics, settings.props.definition.uid)
        }
      } else {
        if (records.value !== undefined && records.value === v[settings.props.definition.uid]) {
          return
        }
        records.value = v[settings.props.definition.uid]
        settings.updateFunc(records.value)
        loading.value = false
      }
    }

    const onSingleMetricCacheUpdated = (m: Metric, v: Record<string, any>): void => {
      if (v === undefined || v[settings.props.definition.uid] === undefined) {
        settings.singleMetricUpdateFunc!(m.name, undefined)
        if (settings.props.definition.store.state.state === StoreState.READY) {
          settings.props.definition.store.addMetric(m, settings.props.definition.uid)
        }
        return
      }
      settings.singleMetricUpdateFunc!(m.name, v[settings.props.definition.uid])
    }

    const watchSingleMetrics = (): void => {
      if (settings.singleMetricUpdateFunc !== undefined) {
        stopWatchSingleMetrics = {}
        getMetrics(settings.props.definition, settings.dimension.value).forEach(m => {
          stopWatchSingleMetrics[m.name] = watch(
            () => settings.props.definition.store.metricsCache[m.name],
            (v: Record<string, any>) => onSingleMetricCacheUpdated(m, v),
            { deep: true }
          )
        })
      }
    }
    watchSingleMetrics()

    stopWatchStoreState = watch(
      () => settings.props.definition.store.state.state,
      state => {
        if (state === StoreState.READY) {
          loading.value = true
          const dimension = toRaw(settings.dimension.value)
          settings.props.definition.store.addDimension(dimension, settings.props.definition.metrics, settings.props.definition.uid)
          if (settings.singleMetricUpdateFunc !== undefined) {
            getMetrics(settings.props.definition, dimension).forEach(m => {
              settings.props.definition.store.addMetric(m, settings.props.definition.uid)
            })
          }
        } else if (state === StoreState.LOADING) {
          onCacheUpdated(undefined)
        }
      }
    )

    stopWatch = watch(
      () => settings.props.definition.store.cache[settings.dimension.value.name],
      onCacheUpdated,
      { deep: true }
    )

    stopWatchDimension = watch(
      () => settings.dimension.value,
      (newDimension, prevDimension) => {
        if (settings.props.definition.store.state.state === StoreState.READY) {
          loading.value = true
          settings.props.definition.store.disposeDimension(prevDimension.name, settings.props.definition.uid.toString())
          stopWatch()
          stopWatch = watch(
            () => settings.props.definition.store.cache[newDimension.name],
            onCacheUpdated,
            { deep: true }
          )
          settings.props.definition.store.addDimension(toRaw(newDimension), settings.props.definition.metrics, settings.props.definition.uid.toString())

          if (settings.singleMetricUpdateFunc !== undefined) {
            Object.entries(stopWatchSingleMetrics).forEach(m => {
              m[1]()
              settings.props.definition.store.disposeMetric(m[0], settings.props.definition.uid)
            })
            stopWatchSingleMetrics = {}
            watchSingleMetrics()
          }
        }
      }
    )

    if (!settings.props.definition.disableComparison && settings.props.definition.store.comparisonStore && settings.comparisonUpdateFunc !== undefined) {
      const onComparisonCacheUpdated = (v: Record<string, ReadonlyArray<Grouping<any, Record<string, any>>>> | undefined): void => {
        if (v === undefined) {
          if (comparisonRecords.value === undefined) {
            return
          }
          comparisonRecords.value = undefined
          settings.comparisonUpdateFunc!(undefined)
          if (settings.props.definition.store.comparisonStore!.state.state === StoreState.READY) {
            settings.props.definition.store.comparisonStore!.addDimension(toRaw(settings.dimension.value), settings.props.definition.metrics, settings.props.definition.uid)
          }
        } else {
          if (comparisonRecords.value !== undefined && comparisonRecords.value === v[settings.props.definition.uid]) {
            return
          }
          comparisonRecords.value = v[settings.props.definition.uid]
          settings.comparisonUpdateFunc!(comparisonRecords.value)
        }
      }

      const onSingleMetricComparisonCacheUpdated = (m: Metric, v: Record<string, any>): void => {
        if (v === undefined || v[settings.props.definition.uid] === undefined) {
          settings.singleMetricComparisonUpdateFunc!(m.name, undefined)
          if (settings.props.definition.store.comparisonStore!.state.state === StoreState.READY) {
            settings.props.definition.store.comparisonStore!.addMetric(m, settings.props.definition.uid)
          }
          return
        }
        settings.singleMetricComparisonUpdateFunc!(m.name, v[settings.props.definition.uid])
      }

      stopWatchComparison = watch(
        () => settings.props.definition.store.comparisonStore!.cache[settings.dimension.value.name],
        onComparisonCacheUpdated,
        { deep: true }
      )

      stopWatchComparisonStoreState = watch(
        () => settings.props.definition.store.comparisonStore!.state.state,
        state => {
          if (state === StoreState.READY) {
            const dimension = toRaw(settings.dimension.value)
            settings.props.definition.store.comparisonStore!.addDimension(dimension, settings.props.definition.metrics, settings.props.definition.uid)
            if (settings.singleMetricComparisonUpdateFunc !== undefined) {
              getMetrics(settings.props.definition, dimension).forEach(m => {
                settings.props.definition.store.comparisonStore!.addMetric(m, settings.props.definition.uid)
              })
            }
          } else if (state === StoreState.LOADING) {
            onComparisonCacheUpdated(undefined)
          }
        }
      )

      const watchComparisonSingleMetrics = (): void => {
        if (settings.singleMetricComparisonUpdateFunc !== undefined) {
          Object.values(stopWatchComparisonSingleMetrics).forEach(f => f())
          stopWatchComparisonSingleMetrics = {}
          getMetrics(settings.props.definition, settings.dimension.value).forEach(m => {
            stopWatchComparisonSingleMetrics[m.name] = watch(
              () => settings.props.definition.store.comparisonStore!.metricsCache[m.name],
              (v: Record<string, any>) => onSingleMetricComparisonCacheUpdated(m, v),
              { deep: true }
            )
          })
        }
      }
      watchComparisonSingleMetrics()

      stopWatchComparisonDimension = watch(
        () => settings.dimension.value,
        (newDimension, prevDimension) => {
          if (settings.props.definition.store.comparisonStore!.state.state === StoreState.READY) {
            settings.props.definition.store.comparisonStore!.disposeDimension(prevDimension.name, settings.props.definition.uid.toString())
            stopWatchComparison()
            stopWatchComparison = watch(
              () => settings.props.definition.store.comparisonStore!.cache[settings.dimension.value.name],
              onComparisonCacheUpdated,
              { deep: true }
            )
            settings.props.definition.store.comparisonStore!.addDimension(toRaw(newDimension), settings.props.definition.metrics, settings.props.definition.uid.toString())

            if (settings.singleMetricComparisonUpdateFunc !== undefined) {
              Object.entries(stopWatchComparisonSingleMetrics).forEach(m => {
                m[1]()
                settings.props.definition.store.comparisonStore!.disposeMetric(m[0], settings.props.definition.uid)
              })
              stopWatchComparisonSingleMetrics = {}
              watchComparisonSingleMetrics()
            }
          }
        }
      )
    }
  }

  const clearCachedRecords = (def: WidgetDefinition): void => {
    records.value = undefined
    comparisonRecords.value = undefined
    settings.updateFunc(undefined)
    if (settings.singleMetricUpdateFunc !== undefined) {
      Object.keys(stopWatchSingleMetrics).forEach(m => settings.singleMetricUpdateFunc!(m, undefined))
    }
    if (!def.disableComparison) {
      if (settings.comparisonUpdateFunc !== undefined) {
        settings.comparisonUpdateFunc(undefined)
      }
      if (settings.singleMetricComparisonUpdateFunc !== undefined) {
        Object.keys(stopWatchComparisonSingleMetrics).forEach(m => settings.singleMetricComparisonUpdateFunc!(m, undefined))
      }
    }

    stopWatchSingleMetrics = {}
    stopWatchComparisonSingleMetrics = {}
  }

  onMounted(mounted)
  onBeforeUnmount(() => beforeUnmount(settings.props.definition))
  init()

  watch(
    () => settings.props.definition,
    (newDef, prevDef) => {
      stopWatch()
      stopWatchStoreState()
      stopWatchDimension()
      if (settings.singleMetricUpdateFunc !== undefined) {
        Object.values(stopWatchSingleMetrics).forEach(f => f())
      }
      if (!prevDef.disableComparison) {
        if (settings.singleMetricComparisonUpdateFunc !== undefined) {
          Object.values(stopWatchComparisonSingleMetrics).forEach(f => f())
        }
        if (prevDef.store.comparisonStore && settings.comparisonUpdateFunc !== undefined) {
          stopWatchComparison()
          stopWatchComparisonStoreState()
          stopWatchComparisonDimension()
        }
      }
      beforeUnmount(prevDef)
      clearCachedRecords(prevDef)
      init()
      mounted()
    }
  )

  const onExport = (title: string, form: ExportForm): void => {
    if (records.value) {
      const columns = []
      if (settings.dimension.value instanceof CompositeDimension) {
        columns.push(...settings.dimension.value.dimensions.map(d => d.name))
      } else {
        columns.push(settings.dimension.value.name)
      }
      columns.push(...form.metrics)
      const csvDataURL = toCSVDataURL(records.value, columns, form.format)
      const titleWithPeriods = title + '_' + format(settings.props.definition.store.lastDateRange!.from, 'yyyy-MM-dd') + ' - ' + format(settings.props.definition.store.lastDateRange!.to, 'yyyy-MM-dd')
      download(titleWithPeriods, csvDataURL)
    }
  }

  return { title, loading, isLoading, onExport, hasData, hasComparisonData, isWaitingForBothData, ...useEnlarged(settings.props) }
}

export function useEnlarged (props: ExtractPropTypes<typeof WIDGET_PROPS>): SetupEnlarged {
  const enlarged = ref(props.queryBuilder.enlargedWidgetID.value === props.definition.uid)
  const instance = getCurrentInstance()
  const onExpandClicked = (): void => {
    if (!instance) {
      return
    }
    enlarged.value = !enlarged.value
    if (instance.refs.chart) {
      const chart = instance.refs.chart as typeof VChart
      chart.resize()
    }
  }

  watch(
    () => enlarged.value,
    () => {
      props.queryBuilder.setEnlarged(enlarged.value ? props.definition.uid : undefined)
    }
  )

  watch(
    () => props.editMode,
    () => {
      enlarged.value = false
    }
  )

  return {
    enlarged, onExpandClicked
  }
}

// selectMetric returns the given metric name if it exists in the widget's definition.
// Otherwise returns the first metric in the definition.
export function selectMetric (metric: string | undefined, definition: WidgetDefinition): string {
  if (definition.metrics.some(m => m.name === metric)) {
    return metric!
  }

  return definition.metrics[0].name
}

export function canSortOn (metric: string, definition: WidgetDefinition): boolean {
  return definition.dimensions.some(m => m.name === metric) || definition.metrics.some(m => m.name === metric)
}

export function highlightWidget (uid: string): void {
  const element = document.getElementById(uid)
  if (element) {
    element.classList.add('bg-primary-500')
    element.scrollIntoView()
    setTimeout(() => {
      element.classList.remove('bg-primary-500')
    }, 2000)
  }
}

export const multiselectTailwindClasses = Object.assign({}, multiselectTailwindClassesCompact)
multiselectTailwindClasses.container = multiselectTailwindClasses.container + ' !w-36 max-w-full self-start'
