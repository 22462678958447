import { Component } from 'vue'

import { FormInputHints } from '@/components/Form'
import FormDateRangeComponent from '@/components/Form/FormDateRange.vue'

import { DefaultProps, FormRenderer } from '.'

interface Props extends DefaultProps {
  attr?: any // These will be bound on Multiselect component
  withLeftIcon?: boolean
  hints?: FormInputHints
}

export class FormDateRange implements FormRenderer<Props> {
  defaultProps: Props

  constructor (props: Props) {
    this.defaultProps = props
  }

  get wrapperClass (): string {
    return this.defaultProps.wrapperClass || ''
  }

  props (): Props {
    return { ...this.defaultProps, ...this.defaultProps.attr }
  }

  component (): Component {
    return FormDateRangeComponent
  }
}
