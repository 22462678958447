<template>
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="g9"
      transform="matrix(1.4090909,0,0,1.4090909,2.25,2.25)"
    >
      <path
        d="m 10.5,5.5 c 0,0.65661 -0.1293,1.3068 -0.3806,1.9134 C 9.8681,8.02 9.4998,8.5712 9.0355,9.0355 8.5712,9.4998 8.02,9.8681 7.41342,10.1194 6.80679,10.3707 6.15661,10.5 5.5,10.5 4.84339,10.5 4.19321,10.3707 3.58658,10.1194 2.97996,9.8681 2.42876,9.4998 1.96447,9.0355 1.50017,8.5712 1.13188,8.02 0.8806,7.4134 0.62933,6.8068 0.5,6.15661 0.5,5.5 0.5,4.17392 1.02678,2.90215 1.96447,1.96447 2.90215,1.02678 4.17392,0.5 5.5,0.5 6.82608,0.5 8.0979,1.02678 9.0355,1.96447 9.9732,2.90215 10.5,4.17392 10.5,5.5 Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M 2.67157,8.3284 C 3.42172,9.0786 4.43913,9.5 5.5,9.5 6.56087,9.5 7.5783,9.0786 8.3284,8.3284 9.0786,7.5783 9.5,6.5609 9.5,5.5 h -4 -4 c 0,1.0609 0.42143,2.0783 1.17157,2.8284 z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
