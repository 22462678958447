import { Component } from 'vue'

import SectionComponent from '@/components/FormBuilder/FormSection.vue'

import { FormRenderer } from '../'

interface Props {
  title?: string
  subTitle?: string
  wrapperClass?: string
  mode?: 'base' | 'disclosure'
  isDefaultOpen?: boolean
}

export class FormSection implements FormRenderer<Props> {
  defaultProps: Props

  constructor (props: Props) {
    this.defaultProps = props
  }

  get wrapperClass (): string {
    return this.defaultProps.wrapperClass || ''
  }

  props (): Props {
    return this.defaultProps
  }

  component (): Component {
    return SectionComponent
  }
}
