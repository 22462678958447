import { AuctionStep } from './enum/auctionStep'

export const optimizations = {
  dynamicFloorPrice: 'dynamicfloorprice',
  dynamicFee: 'dynamicfee',
  shaping: 'shaping',
  bidability: 'bidability',
  bidadjustment: 'bidadjustment',
  usersync: 'usersync'
}

export const algorithms = {
  noop: 'no-op',
  manualInference: 'manual-inference',
  queryIntelligence: 'query-intelligence'
}

export interface Property {
  type: string,
  minimum?: number,
  maximum?: number,
  multipleOf?: number,
  items?: Property
  minItems?: number
  maxItems?: number
  enum?: string[]
  [custom: string]: any
}

export interface Properties {
  [name: string]: Property
}

export interface ParamsTemplate {
  properties: Properties
  title: string,
  type: string,
  required?: string[]
  additionalProperties?: boolean
}

export interface AlgorithmTemplate {
  id: number
  optimization: string
  algorithm: string
  auctionStep: AuctionStep
  seatPredictParamsTemplate?: ParamsTemplate
  $key?: string
  $validate?: (data: object) => boolean
}

export function computeAlgorithmKey (optimization: string, algorithm: string): string {
  return `${optimization}_${algorithm}`
}
