<template>
  <div class="w-full">
    <span
      v-if="v"
      class="px-1.5 py-0.5 rounded text-sm leading-snug text-text-primary"
      :class="bgClass"
      :title="v"
    >
      {{ v }}
    </span>
  </div>
</template>

<!--
  MEDIATYPE
  <span class="bg-gray-100 px-1.5 py-0.5 rounded">{{ value }}</span>
-->

<!--
  SELLER TYPE
  <span class="inline-flex rounded px-2 py-1 text-xs font-semibold bg-primary-100 text-primary-500">{{ value.toUpperCase() }}</span>
-->

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'

import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter'

export default defineComponent({
  props: {
    value: {
      type: null as unknown as PropType<string | null>,
      default: ''
    },
    bgClass: {
      type: String,
      default: 'bg-primary-100'
    },
    toLower: {
      type: Boolean,
      required: false,
      default: () => true
    }
  },
  setup (props) {
    const v = computed(() => capitalizeFirstLetter(props.value ? props.toLower ? props.value.toLocaleLowerCase() : props.value : ''))

    return {
      v
    }
  }
})
</script>
