<template>
  <Panel
    :title="t('reports.create')"
    :after-close="afterClose"
    :is-loading="false"
  >
    <PanelForm
      :on-submit="onSubmit"
      :is-submitting="state.isSubmitting"
      :is-disabled="!state.isOkToSubmit"
      :on-cancel="reset"
      :is-form-type-create="true"
      :with-cancel-confirm="validation.$anyDirty"
    >
      <FormReport
        v-model="form"
        :errors="errors"
        :is-reaonly-from-v1="false"
        :action="FormType.CREATE"
      />
    </PanelForm>
  </Panel>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { FormType } from '@/types/form'

import { useForm } from '@/plugins/form'

import { useAppStore } from '@/store/app.store'
import { useNotificationsStore } from '@/store/notifications.store'

import { ReportForm, createReportForm, reportFormRules, transformToReport } from '@/models/report'

import { createReport } from '@/services/reports'

import Panel from '@/components/Panel/Panel.vue'
import PanelForm from '@/components/Panel/PanelForm.vue'

import FormReport from '../components/FormReport.vue'

export default defineComponent({
  components: {
    Panel,
    PanelForm,
    FormReport
  },
  props: {
    updateDatatable: {
      type: Function,
      required: true
    },
    afterClose: {
      type: Function,
      required: true
    }
  },
  setup (props) {
    const { t } = useI18n()

    const notificationsStore = useNotificationsStore()
    const appStore = useAppStore()

    const currentRoute = useRoute()

    const queryParams = currentRoute.query

    const { form, onSubmit, reset, state, errors, validation } = useForm<ReportForm>({
      defaultForm: computed(() => createReportForm()),
      formType: FormType.CREATE,
      submitHandler: (form) => {
        return createReport(transformToReport(form))
      },
      rules: reportFormRules,
      onSuccess: async (response) => {
        if (response && response.data) {
          const createdId = response.data.id
          const result = await props.updateDatatable(queryParams)

          if (result?.data?.records) {
            const arrayIds = result.data.records.map((item: any) => {
              return item.id
            })
            if (arrayIds.includes(createdId)) {
              notificationsStore.add({
                title: t('reports.create'),
                message: t('reports.createSuccess'),
                type: 'success'
              })

              return appStore.closePanel()
            }
          }
        }

        notificationsStore.add({
          title: t('reports.create'),
          message: t('notifications.filteredWarning', { action: t('reports.createSuccess') }),
          type: 'success'
        })

        appStore.closePanel()
      },
      onError: () => {
        notificationsStore.add({
          title: t('messages.createError'),
          message: t('messages.validationErrors'),
          type: 'error'
        })
      }
    })

    return {
      FormType,
      form,
      validation,
      state,
      errors,

      onSubmit,
      reset,
      t
    }
  }
})
</script>
