import Ajv from 'ajv'
import { groupBy } from 'lodash'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { AlgorithmTemplate, computeAlgorithmKey } from '@/models/algorithmTemplate.base'
import { AuctionStep } from '@/models/enum/auctionStep'

import { fetchAlgorithmTemplates } from '@/services/algorithmTemplates'

export const useAlgorithmTemplatesStore = defineStore('algorithmTemplates', () => {
  const algorithmTemplates = ref<AlgorithmTemplate[]>([])

  const algorithmsByOptimization = computed(() => groupBy(algorithmTemplates.value, 'optimization'))

  const adUnitAlgorithmsByOptimization = computed(() => {
    return groupBy(
      algorithmTemplates.value.filter((at) => at.auctionStep === AuctionStep.ADUNIT),
      'optimization'
    )
  })

  const algorithmByKey = (key: string): AlgorithmTemplate | undefined => {
    return algorithmTemplates.value.find(
      (at) => computeAlgorithmKey(at.optimization, at.algorithm) === key
    )
  }

  const setAlgorithmTemplates = (value: AlgorithmTemplate[]): void => {
    algorithmTemplates.value = value.map((at) => {
      if (at.seatPredictParamsTemplate && Object.keys(at.seatPredictParamsTemplate).length > 0) {
        at.$validate = new Ajv().addKeyword('bidderCodes').compile(at.seatPredictParamsTemplate)
      }
      return at
    })
  }

  const retrieveAlgorithmTemplates = async (): Promise<void> => {
    try {
      const { data } = await fetchAlgorithmTemplates()

      if (data && data.records) {
        setAlgorithmTemplates(data.records)
      }
    } catch (err: any) {
      return Promise.reject(err)
    }
  }

  return {
    algorithmTemplates,
    algorithmsByOptimization,
    adUnitAlgorithmsByOptimization,
    algorithmByKey,
    retrieveAlgorithmTemplates,
    setAlgorithmTemplates
  }
})
