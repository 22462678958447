<template>
  <form
    class="mb-2"
    @submit="onSubmit"
  >
    <div
      class="grid w-full mb-2 text-sm grid-cols-vertical-form place-items-center-stretch"
      :class="{'lg:grid-cols-vertical-form-4': !vertical}"
    >
      <div class="p-1 font-medium">
        {{ t('labels.store') }}
      </div>
      <div class="p-1">
        <Multiselect
          v-model="selectedStoreUID"
          name="selectedStoreUID"
          mode="single"
          :close-on-select="true"
          :options="stores.map(s => {
            return {
              name: s.name,
              id: s.uid
            }
          })"
          :classes="multiselectTailwindClassesCompact"
          :can-clear="false"
          :can-deselect="false"
        />
      </div>
      <div class="p-1 font-medium">
        {{ t('labels.title') }}
      </div>
      <div class="p-1">
        <input
          v-model="title"
          :placeholder="t('placeholders.titleAutoFilled')"
          class="min-w-36 h-7 px-2.5 py-0.5 border border-gray-400 text-gray-700 focus:ring-primary-500 focus:border-primary-500 rounded focus:outline-none w-full"
        >
      </div>
      <div class="p-1 font-medium">
        {{ t('labels.visualizationType') }}
      </div>
      <div class="p-1">
        <Multiselect
          v-model="vizType"
          name="vizType"
          mode="single"
          :close-on-select="true"
          :options="['table', 'singleMetric', 'line', 'pie', 'bar', 'map', 'text', 'comparativeTable', 'seatsBoosters']"
          :classes="multiselectTailwindClassesCompact"
          :can-clear="false"
          :can-deselect="false"
        />
      </div>
      <template v-if="vizType !== 'text' && vizType !== 'seatsBoosters'">
        <div class="p-1 font-medium">
          {{ t('dashboards.disableComparison') }}
        </div>
        <div class="flex items-center p-1">
          <input
            v-model="disableComparison"
            type="checkbox"
            class="w-4 h-4 mr-1 border-gray-400 rounded cursor-pointer text-primary-600 focus:ring-primary-500"
          >
          <span
            class="cursor-pointer select-none"
            @click="disableComparison = !disableComparison"
          >
            {{ disableComparison ? t('labels.yes') : t('labels.no') }}
          </span>
        </div>
        <template v-if="selectedStore !== undefined">
          <div class="p-1 font-medium">
            {{ t('labels.dimension', 2) }}
          </div>
          <div class="flex flex-row items-center w-full gap-1 p-1">
            <div class="flex-grow">
              <Multiselect
                v-model="selectedDimensions"
                mode="tags"
                name="selectedDimensions"
                :options="multiselectDimensions"
                compact
              />
            </div>
            <button
              type="button"
              class="bg-green-500 whitespace-nowrap rounded-md py-0.5 px-2 text-white disabled:opacity-50 disabled:cursor-not-allowed"
              :disabled="selectedDimensions.length === 0 || (createdDimensions.length === 0 && (vizType === 'line' || vizType === 'singleMetric') && (!(selectedStore.definition.dimensions.find(d => d.name === selectedDimensions[0]) instanceof DateDimension) || selectedDimensions.length > 1))"
              @click="addDimension"
            >
              {{ t('actions.add', [t('app.dimension')]) }}
            </button>
          </div>
          <div class="col-span-2 p-1">
            <draggable
              :list="createdDimensions"
              item-key="name"
            >
              <template #item="{ element }">
                <span class="py-0.5 px-1 bg-gray-300 m-0.5 rounded text-sm break-words cursor-grab select-none inline-block">
                  {{ element.name }}
                  <button
                    type="button"
                    title="Remove"
                    class="ml-1"
                    @click="removeDimension(element)"
                  >❌</button>
                </span>
              </template>
            </draggable>
          </div>
          <div
            class="col-span-2 p-1 my-2 font-medium"
            :class="{'lg:col-span-4': !vertical}"
          >
            <hr>
          </div>
          <div class="p-1 font-medium">
            {{ t('labels.metric', 2) }}
          </div>
          <div
            class="p-1 text-right"
            :class="{'lg:col-span-3': !vertical}"
          >
            <PresetPicker
              button-class="py-0.5 px-1 bg-gray-300 m-0.5 rounded text-sm cursor-pointer"
              :definition="selectedStore.definition"
              @submit="usePreset($event)"
            />
          </div>
          <div class="p-1 font-medium">
            {{ t('labels.name') }}
          </div>
          <div class="p-1">
            <input
              v-model="metric.name"
              :placeholder="t('labels.name')"
              class="h-7 px-2.5 py-0.5 border border-gray-400 text-gray-700 focus:ring-primary-500 focus:border-primary-500 rounded focus:outline-none w-full"
            >
          </div>
          <div class="p-1 font-medium">
            {{ t('labels.reducer') }}
          </div>
          <div class="p-1">
            <Multiselect
              v-model="metric.reducer.constructor"
              name="reducerConstructor"
              mode="single"
              :close-on-select="true"
              :options="Object.keys(REDUCER_CONSTRUCTORS)"
              :classes="multiselectTailwindClassesCompact"
              :can-clear="false"
              :can-deselect="false"
            />
          </div>
          <template v-if="metric.reducer.constructor === 'WeightedAverageReducer' || metric.reducer.constructor === 'RateReducer'">
            <div
              class="p-1 font-medium"
              :class="{'lg:col-span-2': !vertical}"
            >
              {{ t('label.parameter', 2) }}
            </div>
            <div
              class="p-1 text-xs text-right text-gray-600 whitespace-nowrap"
              :class="{'lg:col-span-2': !vertical}"
            >
              {{ metric.reducer.constructor === 'WeightedAverageReducer' ? '(sum(metric1) * factor) / sum(metric2)' : '(metric1 / sum(metric2)) * factor' }}
            </div>
            <div class="p-1 font-medium">
              {{ t('labels.metric') }} 1
            </div>
            <div class="p-1">
              <Multiselect

                v-model="metric.reducer.params.metric1"
                name="reducerMetric1"
                mode="single"
                :close-on-select="true"
                :options="metrics"
                :classes="multiselectTailwindClassesCompact"
                :can-clear="false"
                :can-deselect="false"
              />
            </div>
            <div class="p-1 font-medium">
              {{ t('labels.metric') }} 2
            </div>
            <div class="p-1">
              <Multiselect
                v-model="metric.reducer.params.metric2"
                name="reducerMetric2"
                mode="single"
                :close-on-select="true"
                :options="metrics"
                :classes="multiselectTailwindClassesCompact"
                :can-clear="false"
                :can-deselect="false"
              />
            </div>
            <div class="p-1 font-medium">
              {{ t('labels.factor') }}
            </div>
            <div class="p-1">
              <input
                v-model="metric.reducer.params.factor"
                type="number"
                class="h-7 px-2.5 py-0.5 border border-gray-400 text-gray-700 focus:ring-primary-500 focus:border-primary-500 rounded focus:outline-none w-full"
              >
            </div>
            <template v-if="metric.reducer.constructor === 'RateReducer'">
              <div class="p-1 font-medium">
                {{ t('dashboards.globalSumDimensions') }}
              </div>
              <div class="p-1">
                <Multiselect
                  v-model="metric.reducer.params.globalSumDimensions"
                  mode="multiple"
                  name="globalSumDimensions"
                  class="flex-grow w-full"
                  :options="multiselectDimensions"
                  compact
                />
              </div>
              <div class="p-1 font-medium">
                {{ t('dashboards.conditionDimension') }}
              </div>
              <div class="p-1">
                <Multiselect
                  v-model="metric.reducer.params.conditionDimension"
                  name="conditionDimension"
                  mode="single"
                  :close-on-select="true"
                  :options="multiselectDimensions"
                  :classes="multiselectTailwindClassesCompact"
                  :can-clear="false"
                  :can-deselect="false"
                />
              </div>
              <div class="p-1 font-medium">
                {{ t('dashboards.conditionValueDimension') }}
              </div>
              <div class="p-1">
                <Multiselect
                  v-model="metric.reducer.params.conditionValueDimension"
                  name="conditionValueDimension"
                  mode="single"
                  :close-on-select="true"
                  :options="multiselectDimensions"
                  :classes="multiselectTailwindClassesCompact"
                  :can-clear="false"
                  :can-deselect="false"
                />
              </div>
              <div class="p-1 font-medium">
                {{ t('dashboards.conditionDimensionSeparator') }}
              </div>
              <div class="p-1">
                <input
                  v-model="metric.reducer.params.conditionDimensionSeparator"
                  class="h-7 px-2.5 py-0.5 border border-gray-400 text-gray-700 focus:ring-primary-500 focus:border-primary-500 rounded focus:outline-none w-full"
                >
              </div>
            </template>
          </template>
          <template v-if="metric.reducer.constructor === 'FormulaReducer'">
            <div class="p-1 font-medium">
              {{ t('labels.formula') }}
            </div>
            <div class="p-1">
              <input
                v-model="metric.reducer.params.formula"
                :placeholder="t('labels.formula')"
                class="h-7 px-2.5 py-0.5 border border-gray-400 text-gray-700 focus:ring-primary-500 focus:border-primary-500 rounded focus:outline-none w-full"
              >
            </div>
          </template>
          <template v-else>
            <div class="p-1 font-medium">
              {{ t('labels.metric') }}
            </div>
            <div class="p-1">
              <Multiselect
                v-model="metric.reducer.params.metric"
                name="metricReducer"
                mode="single"
                :close-on-select="true"
                :options="metrics"
                :classes="multiselectTailwindClassesCompact"
                :can-clear="false"
                :can-deselect="false"
              />
            </div>
          </template>
          <template v-if="metric.reducer.constructor === 'SumReducer'">
            <div class="p-1 font-medium">
              {{ t('dashboards.overrideSumDimensions') }}
            </div>
            <div class="flex items-center p-1">
              <input
                v-model="overrideSumDimensions"
                type="checkbox"
                class="w-4 h-4 mr-1 border-gray-400 rounded cursor-pointer text-primary-600 focus:ring-primary-500"
              >
              <span
                class="cursor-pointer select-none"
                @click="overrideSumDimensions = !overrideSumDimensions"
              >
                {{ overrideSumDimensions ? t('labels.yes') : t('labels.no') }}
              </span>
            </div>
            <div class="p-1 font-medium">
              {{ t('dashboards.globalSumDimensions') }}
            </div>
            <div class="p-1">
              <Multiselect
                v-model="metric.reducer.params.globalSumDimensions"
                mode="multiple"
                name="globalSumDimensions"
                class="flex-grow"
                :options="multiselectDimensions"
                compact
                :disabled="!overrideSumDimensions"
              />
            </div>
          </template>
          <div class="p-1 font-medium">
            {{ t('labels.formatter') }}
          </div>
          <div class="p-1">
            <Multiselect
              v-model="metric.formatter"
              name="metricFormatter"
              mode="single"
              :close-on-select="true"
              :options="metricFormatterOptions"
              :classes="multiselectTailwindClassesCompact"
              :can-clear="false"
              :can-deselect="false"
            >
              <template #option="{ option }">
                <span>{{ option.name }}</span>
                <span
                  class="ml-1 text-xs"
                  :class="{ 'text-gray-600': metric.formatter !== option.id }"
                >({{ METRIC_FORMATTERS[option.id](option.exampleValue) }})</span>
              </template>
            </Multiselect>
          </div>
          <div class="p-1">
            <button
              type="button"
              class="bg-green-500 rounded-md py-0.5 my-0.5 px-2 text-white disabled:opacity-50 disabled:cursor-not-allowed"
              :disabled="metric.name.length === 0 || createdMetrics.some(m => m.name === metric.name)"
              @click="addMetric"
            >
              <!-- TODO proper metric validation -->
              {{ t('actions.add', [t('app.metric')]) }}
            </button>
          </div>
          <div
            class="p-1"
            :class="{'lg:col-span-3': !vertical}"
          >
            <draggable
              v-if="createdMetrics.length > 0"
              :list="createdMetrics"
              item-key="name"
            >
              <template #item="{ element }">
                <span class="py-0.5 px-1 bg-gray-300 m-0.5 rounded text-sm break-words cursor-grab select-none inline-block">
                  {{ element.name }}
                  <button
                    type="button"
                    :title="t('actions.remove')"
                    class="ml-1"
                    @click="removeMetric(element)"
                  >❌</button>
                </span>
              </template>
            </draggable>
          </div>
          <template v-if="vizType === 'table'">
            <div class="p-1 font-medium">
              {{ t('dashboards.sortDefault') }}
            </div>
            <div class="p-1">
              <Multiselect
                :model-value="sortByDefaultOn || sortByDefaultNoneOption"
                name="sortByDefaultOn"
                mode="single"
                :close-on-select="true"
                :options="sortByDefaultOptions"
                :classes="multiselectTailwindClassesCompact"
                :can-clear="false"
                :can-deselect="false"
                @input="sortByDefaultOn = $event === sortByDefaultNoneOption ? undefined : $event"
              />
            </div>
            <div class="p-1 font-medium">
              {{ t('dashboards.sortDefaultOrder') }}
            </div>
            <div class="p-1">
              <Multiselect
                v-model="defaultSortOrder"
                name="defaultSortOrder"
                mode="single"
                :close-on-select="true"
                :options="sortOrderOptions"
                :classes="multiselectTailwindClassesCompact"
                :can-clear="false"
                :can-deselect="false"
              />
            </div>
          </template>
        </template>
      </template>
    </div>
    <div class="flex flex-row w-full">
      <button
        type="submit"
        class="px-3 py-1 text-sm text-white bg-green-500 rounded disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
        :disabled="!validate"
      >
        <span v-if="definition === undefined">
          {{ t('actions.create', [t('labels.widget')]) }}
        </span>
        <span v-else>
          {{ t('actions.update', [t('labels.widget')]) }}
        </span>
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import { v1 as uuidv1 } from 'uuid'
import { computed, defineComponent, nextTick, onMounted, PropType, ref, unref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import draggable from 'vuedraggable'

import { Metric, METRIC_FORMATTERS, SerializedMetric, Store, VisualizationType, WidgetDefinition } from '@/plugins/dashboard'
import { CompositeDimension, DateDimension, DateRollupDimension, Dimension } from '@/plugins/dashboard/dimensions'
import { REDUCER_CONSTRUCTORS } from '@/plugins/dashboard/reducers'

import { useNotificationsStore } from '@/store/notifications.store'

import Multiselect from '@/components/Form/FormMultiselect.vue'

import { multiselectTailwindClassesCompact } from '../../Multiselect'

import PresetPicker from './PresetPicker.vue'

export default defineComponent({
  components: {
    Multiselect,
    draggable,
    PresetPicker
  },
  props: {
    stores: {
      type: Array as PropType<Store[]>,
      required: true
    },
    definition: {
      type: Object as PropType<WidgetDefinition | undefined>,
      default: undefined
    },
    vertical: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['submit'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const notificationsStore = useNotificationsStore()

    // Data
    const title = ref('')
    const selectedStoreUID = ref(undefined as string | undefined)
    const selectedStore = ref(undefined as Store | undefined)
    const selectedDimensions = ref([] as string[])
    const createdDimensions = ref([] as Dimension[])
    const createdMetrics = ref([] as Metric[])
    const metric = ref({
      name: '',
      formatter: 'INTEGER',
      reducer: {
        constructor: Object.keys(REDUCER_CONSTRUCTORS)[0],
        params: {}
      }
    } as SerializedMetric)
    const vizType = ref('table' as VisualizationType)
    const disableComparison = ref(false)
    const overrideSumDimensions = ref(false)
    const sortByDefaultOn = ref(undefined as string | undefined)
    const sortByDefaultNoneOption = '~~null~~'
    const defaultSortOrder = ref<'ASC' | 'DESC'>('DESC')

    const reset = () => {
      title.value = ''
      sortByDefaultOn.value = undefined
      createdMetrics.value = []
      createdDimensions.value = []
      overrideSumDimensions.value = false
    }

    const loadDefinition = () => {
      if (props.definition !== undefined) {
        selectedStoreUID.value = props.definition.store.uid
        nextTick(() => {
          title.value = props.definition!.title || ''
          vizType.value = props.definition!.vizType
          createdMetrics.value = [...props.definition!.metrics]
          createdDimensions.value = [...props.definition!.dimensions]
          disableComparison.value = props.definition!.disableComparison === true
          sortByDefaultOn.value = props.definition!.defaultSort?.column
          defaultSortOrder.value = props.definition!.defaultSort?.order === 'ASC' ? 'ASC' : 'DESC'
        })
      } else {
        reset()
      }
    }

    onMounted(() => {
      loadDefinition()
    })

    // Computed
    const validate = computed((): boolean => {
      if (createdDimensions.value.length === 0 && vizType.value !== 'singleMetric' && vizType.value !== 'text' && vizType.value !== 'seatsBoosters') {
        return false
      }
      if (vizType.value === 'text' && title.value.length <= 0) {
        return false
      }
      if (vizType.value !== 'comparativeTable' && vizType.value !== 'table' && vizType.value !== 'text' && vizType.value !== 'seatsBoosters' && createdMetrics.value.length === 0) {
        return false
      }
      if (vizType.value === 'line' && !(createdDimensions.value[0] instanceof DateDimension) && !(createdDimensions.value[0] instanceof DateRollupDimension)) {
        return false
      }
      if (vizType.value === 'singleMetric' && createdDimensions.value.length > 0 && !(createdDimensions.value[0] instanceof DateDimension)) {
        return false
      }
      return selectedStore.value !== undefined
    })
    // TODO use validation system

    const visibleDimensions = computed(() => selectedStore.value !== undefined ? selectedStore.value.definition.dimensions.filter(d => !selectedStore.value!.definition.hidden.includes(d.name)) : [])
    const multiselectDimensions = computed(() => visibleDimensions.value.map(d => d.name))
    const metrics = computed(() => selectedStore.value !== undefined ? [...Object.keys(selectedStore.value.definition.metrics), ...visibleDimensions.value.map(d => d.name)] : [])

    const availableDimensions = computed(() => {
      const dimensions = new Set<string>()
      createdDimensions.value.forEach(d => {
        if (d instanceof CompositeDimension) {
          d.dimensions.forEach(d2 => dimensions.add(d2.name))
        } else {
          dimensions.add(d.name)
        }
      })
      return [...dimensions]
    })

    const sortByDefaultOptions = computed(() => {
      return [
        { name: t('dashboards.dontSort'), id: sortByDefaultNoneOption },
        ...availableDimensions.value.map(d => { return { id: d, name: d } }),
        ...createdMetrics.value.map(m => { return { id: m.name, name: m.name } })
      ]
    })

    const sortOrderOptions = computed(() => [
      { name: t('labels.desc'), id: 'DESC' },
      { name: t('labels.asc'), id: 'ASC' }
    ])

    const metricFormatterOptions = computed(() => {
      return Object.entries(METRIC_FORMATTERS).map(e => {
        let exampleValue: any = 123.456
        if (e[0].includes('COMPACT')) {
          exampleValue = 123456.78
        } else if (e[0] === 'DATE') {
          exampleValue = new Date()
        } else if (e[0].includes('PERCENT')) {
          exampleValue = 0.123
        } else if (e[0].includes('WEBSITE_STATUS')) {
          exampleValue = '200_warning'
        }
        return {
          name: e[0],
          id: e[0],
          exampleValue
        }
      })
    })

    // Watch
    watch(
      () => selectedStoreUID.value,
      () => {
        if (selectedStoreUID.value) {
          selectedStore.value = props.stores.find(s => s.uid === selectedStoreUID.value)
        }
      }
    )

    watch(
      () => selectedStore.value,
      () => {
        if (selectedStore.value) {
          sortByDefaultOn.value = undefined
          createdMetrics.value = []
          createdDimensions.value = []
        }
      }
    )

    watch(
      () => props.stores.length,
      () => {
        if (selectedStore.value !== undefined && !props.stores.some(s => s.uid === selectedStore.value!.uid)) {
          selectedStore.value = undefined
        }
      }
    )

    watch(
      () => metric.value.reducer.params.metric,
      () => {
        if (metric.value.name === '' && metric.value.reducer.params.metric !== undefined) {
          metric.value.name = metric.value.reducer.params.metric
        }
      }
    )

    watch(
      () => metric.value.reducer.constructor,
      () => {
        for (const key in metric.value.reducer.params) {
          delete metric.value.reducer.params[key]
        }
        if (metric.value.reducer.constructor === 'WeightedAverageReducer' && metric.value.reducer.params.factor === undefined) {
          metric.value.reducer.params.factor = 1
        } else if (metric.value.reducer.constructor === 'RateReducer') {
          if (metric.value.reducer.params.factor === undefined) {
            metric.value.reducer.params.factor = 1
          }
          if (metric.value.reducer.params.conditionDimensionSeparator === undefined) {
            metric.value.reducer.params.conditionDimensionSeparator = ','
          }
          if (metric.value.reducer.params.globalSumDimensions === undefined) {
            metric.value.reducer.params.globalSumDimensions = []
          }
        }
      }
    )

    watch(
      () => props.definition,
      () => {
        loadDefinition()
      }
    )

    watch(
      () => vizType.value,
      () => {
        if (vizType.value === 'line' && props.definition === undefined) {
          disableComparison.value = true
        }
        if (vizType.value !== 'table') {
          sortByDefaultOn.value = undefined
        }
      }
    )

    watch(
      () => multiselectDimensions.value,
      () => {
        createdDimensions.value = createdDimensions.value.filter(d => {
          if (d instanceof CompositeDimension) {
            d.dimensions.every(d2 => multiselectDimensions.value.includes(d2.name))
          }
          return multiselectDimensions.value.includes(d.name)
        })
      }
    )

    watch(
      () => overrideSumDimensions.value,
      () => {
        if (!overrideSumDimensions.value) {
          delete metric.value.reducer.params.globalSumDimensions
        } else {
          metric.value.reducer.params.globalSumDimensions = []
        }
      }
    )

    // Methods
    const addDimension = () => {
      if (selectedStore.value === undefined) {
        return
      }
      const dims = selectedDimensions.value.map(d => selectedStore.value!.definition.dimensions.find(dim => dim.name === d)!).filter(d => d !== undefined)
      if (selectedDimensions.value.length > 1) {
        createdDimensions.value.push(new CompositeDimension(...dims))
      } else if (selectedDimensions.value.length === 1) {
        createdDimensions.value.push(dims[0])
      }
      selectedDimensions.value = []
    }

    const removeDimension = (dimension: Dimension) => {
      const i = createdDimensions.value.indexOf(dimension)
      if (i !== -1) {
        createdDimensions.value.splice(i, 1)
      }
      if (sortByDefaultOn.value !== undefined && !availableDimensions.value.includes(sortByDefaultOn.value)) {
        sortByDefaultOn.value = undefined
      }
    }

    const addMetric = () => {
      try {
        createdMetrics.value.push(Metric.deserialize(metric.value))
        metric.value = {
          name: '',
          formatter: Object.keys(METRIC_FORMATTERS)[0],
          reducer: {
            constructor: Object.keys(REDUCER_CONSTRUCTORS)[0],
            params: {}
          }
        }
        overrideSumDimensions.value = false
      } catch (e: any) {
        notificationsStore.add({
          message: t('dashboards.addMetricError', [e.message]),
          type: 'error'
        })
      }
    }

    const removeMetric = (m: Metric) => {
      const i = createdMetrics.value.indexOf(m)
      if (i !== -1) {
        createdMetrics.value.splice(i, 1)
      }
      if (sortByDefaultOn.value === m.name) {
        sortByDefaultOn.value = undefined
      }
    }

    const usePreset = (p: SerializedMetric) => {
      metric.value = {
        name: p.name,
        formatter: p.formatter,
        reducer: {
          constructor: p.reducer.constructor,
          params: {}
        }
      }
      if (p.reducer.params.globalSumDimensions !== undefined && p.reducer.constructor === 'SumReducer') {
        overrideSumDimensions.value = true
      }
      nextTick(() => {
        for (const key in p.reducer.params) {
          metric.value.reducer.params[key] = p.reducer.params[key]
        }
      })
    }

    const onSubmit = ($event: Event) => {
      $event.preventDefault()

      const def: WidgetDefinition = {
        uid: props.definition !== undefined ? props.definition.uid : uuidv1().slice(0, 8),
        store: selectedStore.value!,
        dimensions: [...createdDimensions.value],
        metrics: [...createdMetrics.value],
        vizType: unref(vizType.value),
        title: title.value === '' ? undefined : unref(title),
        disableComparison: unref(disableComparison),
        defaultSort: sortByDefaultOn.value !== undefined
          ? {
              column: sortByDefaultOn.value,
              order: defaultSortOrder.value
            }
          : undefined
      }

      reset()

      emit('submit', def)
    }

    return {
      // Data
      metrics,
      title,
      selectedStore,
      selectedStoreUID,
      selectedDimensions,
      createdDimensions,
      createdMetrics,
      metric,
      vizType,
      disableComparison,
      sortByDefaultOn,
      defaultSortOrder,
      sortByDefaultNoneOption,
      overrideSumDimensions,

      // Computed
      validate,
      multiselectDimensions,
      metricFormatterOptions,
      sortByDefaultOptions,
      sortOrderOptions,

      // Methods
      addDimension,
      removeDimension,
      addMetric,
      removeMetric,
      usePreset,
      onSubmit,

      // Misc
      multiselectTailwindClassesCompact,
      REDUCER_CONSTRUCTORS,
      METRIC_FORMATTERS,
      DateDimension,
      t
    }
  }
})
</script>
