import difference from 'lodash/difference'
import { RouteLocation, RouteParams } from 'vue-router'

import { Context, contextFromRoute } from '@/plugins/context'
import { ContextType } from '@/plugins/context/context.enum'

import { useAuthStore } from '@/store/auth.store'
import { useContextStore } from '@/store/context.store'
import { useProfileStore } from '@/store/profile.store'

import { Role } from '@/models/enum/roles'
import { Permission } from '@/models/permission'

/**
 * Retrieve a the first permission that match the current context
 * or a group "is_service" from permissions array. A "is_service" group acts like a wildcard that match all context.
 */
export function getPermission (context?: Context): (Permission | undefined) {
  const profileStore = useProfileStore()
  const authStore = useAuthStore()

  if (!authStore.isLogged || profileStore.profile === undefined) {
    return undefined
  }

  if (profileStore.isCustomerView && context) {
    return {
      resourceId: context.resourceId,
      resourceType: context.resourceType,
      roles: [Role.ADMIN],
      isService: false
    }
  }

  const groupServicePermission = profileStore.profile.permissions.find(p => p.group?.isService === true)
  if (groupServicePermission) {
    return groupServicePermission
  }

  return profileStore.profile.permissions.find(p => p.resourceType === context?.resourceType && p.resourceId === context?.resourceId)
}

export function userIsLogged (): boolean {
  const profileStore = useProfileStore()
  const authStore = useAuthStore()

  return !!(authStore.isLogged && profileStore.profile !== undefined)
}

export function userIsService (withoutCustomerViewRewrite: boolean = false): boolean {
  const profileStore = useProfileStore()

  if (profileStore.isCustomerView && !withoutCustomerViewRewrite) {
    return false
  }

  return !!(userIsLogged() && profileStore?.profile?.isService)
}

/**
 * @param roles the "user" roles array
 * @param expected the role or an array of roles that must be found in the `roles` array
 * @param options.includeAll if true, all `expected` values must be found in the `roles` array
 * @param options.includeService if true (default), the function will check first if the user is Service and returns true as a service has all roles
 */
export function hasRole (roles: Role[], expected: Role | Role[], options?: {includeAll?: boolean, includeService?: boolean}): boolean {
  if (!userIsLogged()) {
    return false
  }

  if (options?.includeService !== false && userIsService()) {
    return true
  }

  if (Array.isArray(expected)) {
    if (options?.includeAll) {
      return expected.every(r => roles.includes(r))
    } else {
      let found = false
      for (const expectedRole of expected) {
        found = roles.some(r => expectedRole === r)
        if (found) break
      }
      return found
    }
  }

  return roles.some(r => r === expected)
}

/**
 * Get permission information for the currently authenticated user on the requested route.
 * @param to the target route
 * @returns "hasAccess" if the user have access to that route,
 * "roles" the roles of the users on that route (or empty array),
 * "isService" if the user is part of a service group and has service access on that group.
 */
export function useRoutePermissions (to: RouteLocation): {hasAccess: boolean, roles: Role[], isService: boolean} {
  const roles: Role[] = []
  const isService = userIsService()
  let disabled = false
  const contextStore = useContextStore()

  const routeFeaturesPermissions = to.meta.featuresPermissions as string[] | undefined
  const routeRoles = to.meta.roles !== undefined ? (to.meta.roles as Role[]) : []
  const context = contextFromRoute(to)
  if (context) {
    const permission = getPermission(context)
    const contextPermission = hasContextPermission(to.name?.toString(), to.params)
    if (permission) {
      roles.push(...permission.roles)
    }
    const hasRole = permission ? (routeRoles.length === 0 || permission.roles.some(p => routeRoles.includes(p))) : false

    const featuresEnabled = routeFeaturesPermissions === undefined || (contextStore.permissions && difference(routeFeaturesPermissions, contextStore.permissions.features).length === 0)
    const hasRequirements = routeRoles.length > 0 || to.meta.requireService
    disabled =
      ((!contextPermission && !isService) || (!featuresEnabled && !isService) ||
      (hasRequirements &&
        (to.meta.requireService
          ? !isService
          : (!isService && (!hasRole || permission === undefined))
        )
      )) as boolean
  }

  return {
    hasAccess: !disabled,
    roles,
    isService
  }
}

/**
 * Get feature permission information for the currently authenticated user on the requested route.
 * @param featuresPermissions the "featuresPermissions" array expected
 * @returns boolean if the user has at least one feature enabled in its context
 */
export function hasOneFeature (featuresPermissions: string[] | undefined, options?: {includeService?: boolean}): boolean {
  const contextStore = useContextStore()

  if (!userIsLogged()) {
    return false
  }

  if (options?.includeService !== false && userIsService()) {
    return true
  }
  const featuresEnabled = (featuresPermissions === undefined || (contextStore.permissions && difference(featuresPermissions, contextStore.permissions.features).length < featuresPermissions.length))

  return !!featuresEnabled
}

/**
 * Get context permission information for the currently authenticated user on the requested route.
 * @param context the context to check
 * @returns boolean if the user has the context permission
 */
export function hasContextPermission (to?: string, params?: RouteParams): boolean {
  const profileStore = useProfileStore()

  if (!to) {
    return true
  }

  const userProfile = profileStore.profile

  if (!userProfile) {
    return true
  }

  const userService = userProfile.permissions.filter(p => p.resourceType === ContextType.GROUPS && p.isService)

  if (userService.length || userProfile.isService) {
    return true
  }

  const publishers = userProfile.permissions.filter(p => p.resourceType === ContextType.PUBLISHERS)

  if (to.startsWith(ContextType.PUBLISHERS)) {
    return findPermission(publishers, params?.publisherId?.toString())
  }

  const bidders = userProfile.permissions.filter(p => p.resourceType === ContextType.BIDDERS)

  if (to.startsWith(ContextType.BIDDERS)) {
    return findPermission(bidders, params?.bidderId?.toString())
  }

  const groups = userProfile.permissions.filter(p => p.resourceType === ContextType.GROUPS)

  if (to.startsWith(ContextType.GROUPS)) {
    return findPermission(groups, params?.groupId?.toString())
  }

  return false
}

function findPermission (permissions: Permission[], id?: string): boolean {
  let result = !!permissions.length
  if (id) {
    result = result && !!permissions.find(p => p.resourceId.toString() === id)
  }
  return result
}
