import { RouteRecordRaw } from 'vue-router'

import i18n from '@/plugins/i18n'

import { logoutGuard } from '@/router/guards/logout'

export const login: RouteRecordRaw = {
  path: '/login',
  name: 'login',
  component: () => import('./views/Login.vue'),
  props: route => ({ redirect: route.query.redirect }),
  meta: {
    title: i18n.global.t('auth.logIn')
  }
}

export const logout: RouteRecordRaw = {
  beforeEnter: logoutGuard,
  path: '/logout',
  name: 'logout',
  component: () => import('./views/Logout.vue')
}
