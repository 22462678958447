import i18n from '@/plugins/i18n'

export const PRESET_TRANSLATIONS: Record<string, string> = {
  today: i18n.global.t('dates.today'),
  yesterday: i18n.global.t('dates.yesterday'),
  last_7_days: i18n.global.t('dates.last-days', [7]),
  last_14_days: i18n.global.t('dates.last-days', [14]),
  last_30_days: i18n.global.t('dates.last-days', [30]),
  past_week: i18n.global.t('dates.past-week'),
  past_4_weeks: i18n.global.t('dates.past-weeks', [4]),
  past_12_weeks: i18n.global.t('dates.past-weeks', [12]),
  past_month: i18n.global.t('dates.past-month'),
  past_quarter: i18n.global.t('dates.past-quarter'),
  current_week: i18n.global.t('dates.current-week'),
  current_month: i18n.global.t('dates.current-month'),
  current_quarter: i18n.global.t('dates.current-quarter'),
  none: i18n.global.t('dates.noComparison'),
  auto: i18n.global.t('dates.same-before'),
  day_before: i18n.global.t('dates.day-before'),
  week_before: i18n.global.t('dates.week-before'),
  month_before: i18n.global.t('dates.month-before'),
  quarter_before: i18n.global.t('dates.quarter-before'),
  year_before: i18n.global.t('dates.year-before')
}
