import { RouteRecordRaw } from 'vue-router'

import { rolesGroup } from '@/static/roles'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

export function refreshRules (): RouteRecordRaw[] {
  const context = ContextType.PUBLISHERS
  return [
    {
      path: 'refresh-rules',
      name: `${context}.refreshRules.index`,
      component: () => import('./views/Index.vue'),
      meta: {
        title: i18n.global.t('labels.refresh'),
        feature: 'refreshRules',
        roles: rolesGroup.user
      },
      children: [
        {
          path: 'create',
          name: `${context}.refreshRules.create`,
          component: () => import('./views/Create.vue'),
          meta: {
            title: i18n.global.t('refreshRules.create'),
            roles: rolesGroup.operation,
            feature: 'refreshRules'
          }
        },
        {
          path: ':refreshRuleId/edit',
          name: `${context}.refreshRules.edit`,
          component: () => import('./views/Edit.vue'),
          meta: {
            title: i18n.global.t('refreshRules.edit', { name: ':refreshRuleName' }),
            pattern: ':refreshRuleName',
            feature: 'refreshRules'
          },
          props: route => ({ refreshRuleId: route.params.refreshRuleId })
        }
      ]
    }
  ]
}
