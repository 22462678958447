import { RouteRecordRaw } from 'vue-router'

import { rolesGroup } from '@/static/roles'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

export function bidderSeats (context: ContextType): RouteRecordRaw[] {
  return [
    {
      path: 'seats',
      name: `${context}.seats.index`,
      component: () => import('./views/Index.vue'),
      meta: {
        title: i18n.global.t('labels.seat', 2),
        featuresPermissions: ['seats'],
        feature: 'seats',
        roles: rolesGroup.all
      },
      children: [
        {
          path: ':seatId/edit',
          name: `${context}.seats.edit`,
          component: () => import('./views/Edit.vue'),
          meta: {
            title: i18n.global.t('seats.edit', { name: ':seatName' }),
            pattern: ':seatName',
            featuresPermissions: ['seats'],
            feature: 'seats',
            roles: rolesGroup.bidderOperation
          },
          props: route => ({ seatId: route.params.seatId })
        }
      ]
    }
  ]
}
