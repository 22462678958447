import { AxiosResponse } from 'axios'

import { Paginate } from '@/types/paginate'
import { ObjectLike } from '@/types/utils'

import { Context } from '@/plugins/context'

import { VariableType } from '@/models/enum/variableType'
import { SelectResponse } from '@/models/select'

import api from '@/api'

import { Variable } from '../models/variable'

export async function fetchVariablesByType (variableType: VariableType, queryParams: Record<string, string | number> = {}): Promise<AxiosResponse<Paginate<Variable[]>>> {
  const params = { sort: 'value,ASC', ...queryParams }
  return api.get<Paginate<Variable[]>>(`/variables/${variableType}`, { requireContext: true, params })
}

export async function fetchVariablesForSelect (variableType: VariableType, queryParams?: ObjectLike): Promise<AxiosResponse<SelectResponse[]>> {
  const params = { sort: 'value,ASC', format: 'select', ...queryParams }
  return api.get<SelectResponse[]>(`/variables/${variableType}`, { requireContext: true, params })
}

export async function fetchVariablesForSelectContext (ctx: Context, variableType: VariableType, queryParams?: ObjectLike): Promise<AxiosResponse<SelectResponse[]>> {
  const params = { sort: 'value,ASC', format: 'select', ...queryParams }
  return api.get<SelectResponse[]>(`/${ctx.resourceType}/${ctx.resourceId}/variables/${variableType}`, { params })
}
