<template>
  <div class="flex space-x-1 max-w-[80px] m-1 px-1.5 rounded py-0.5">
    <div
      class="inline-block text-xs truncate select-none"
    >
      <slot />
    </div>

    <button
      v-if="withRemove"
      type="button"
      @click="onRemove()"
    >
      <XMarkIcon class="w-4 h-4" />
    </button>
  </div>
</template>

<script lang="ts">
import { XMarkIcon } from '@heroicons/vue/20/solid'

export default {
  components: {
    XMarkIcon
  },
  props: {
    withRemove: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['remove'],
  setup (props, { emit }) {
    const onRemove = () => {
      emit('remove')
    }

    return {
      onRemove
    }
  }
}
</script>
