import * as querystring from 'querystring'

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import i18n from '@/plugins/i18n'

import { useAuthStore } from '@/store/auth.store'
import { useContextStore } from '@/store/context.store'
import { useSettingsStore } from '@/store/settings.store'

import { router } from '@/router'

import { useNotificationsStore } from './../store/notifications.store'

export interface ApiConfig extends AxiosRequestConfig {
  requireContext?: boolean
  task?: boolean
}

const axiosInstance = axios.create()

axiosInstance.interceptors.response.use(undefined, (error) => {
  const notificationsStore = useNotificationsStore()
  if (error.response?.status === 429) {
    notificationsStore.throttledAdd({
      type: 'error',
      title: i18n.global.t('messages.tooManyRequests'),
      message: i18n.global.t('messages.tooManyRequestsMessage')
    })
  }
  if (error.response?.status === 401) {
    const authStore = useAuthStore()
    if (authStore.isLogged) {
      notificationsStore.throttledAdd({
        type: 'error',
        title: i18n.global.t('labels.disconnected'),
        message: i18n.global.t('messages.disconnected')
      })
    }
    authStore.logout()
    router.push({ name: 'login', query: { redirect: router.currentRoute.value.fullPath } })
  }
  return Promise.reject(error)
})

function handleApiConfig (apiConfig?: ApiConfig): AxiosRequestConfig {
  const authStore = useAuthStore()
  const settingsStore = useSettingsStore()
  const contextStore = useContextStore()

  const config: AxiosRequestConfig & { taskURL?: string } = {
    baseURL: `${import.meta.env.VITE_API_BASE_URL}/api/v1`,
    taskURL: `${import.meta.env.VITE_TASK_API_BASE_URL}/api/v1`,
    ...apiConfig,
    paramsSerializer: (params) => {
      return querystring.encode(params)
    }
  }

  if (!config.headers) {
    config.headers = {}
  }

  if (authStore.accessToken) {
    config.headers.Authorization = `Bearer ${authStore.accessToken}`
  }

  if (settingsStore.settings.locale) {
    config.headers['Accept-Language'] = settingsStore.settings.locale
  }

  if (apiConfig && apiConfig.requireContext) {
    if (!contextStore.hasContext) {
      throw Error('Route require context, but context not defined in the store.')
    }

    if (contextStore.selectedPublisher) {
      config.baseURL = `${config.baseURL}/publishers/${contextStore.selectedPublisher.id}`
    }

    if (contextStore.selectedBidder) {
      config.baseURL = `${config.baseURL}/bidders/${contextStore.selectedBidder.id}`
    }

    if (contextStore.selectedGroup) {
      config.baseURL = `${config.baseURL}/groups/${contextStore.selectedGroup.id}`
    }
  } else if (apiConfig && apiConfig.task) {
    config.baseURL = `${config.taskURL}/jobs`
  }

  return config
}

function contextURL (uri: string, baseURL: string = `${import.meta.env.VITE_API_BASE_URL}/api/v1`): string {
  const contextStore = useContextStore()

  if (!contextStore.hasContext) {
    throw Error('Route require context, but context not defined in the store.')
  }

  let url
  if (contextStore.selectedPublisher) {
    url = `${baseURL}/publishers/${contextStore.selectedPublisher.id}`
  } else if (contextStore.selectedBidder) {
    url = `${baseURL}/bidders/${contextStore.selectedBidder.id}`
  } else if (contextStore.selectedGroup) {
    url = `${baseURL}/groups/${contextStore.selectedGroup.id}`
  }

  return `${url}${uri}`
}

export default {
  get<T> (url: string, config?: ApiConfig): Promise<AxiosResponse<T>> {
    return axiosInstance.get(url, handleApiConfig(config))
  },
  post<T> (url: string, data: unknown, config?: ApiConfig): Promise<AxiosResponse<T>> {
    return axiosInstance.post(url, data, handleApiConfig(config))
  },
  put<T> (url: string, data: unknown, config?: ApiConfig): Promise<AxiosResponse<T>> {
    return axiosInstance.put(url, data, handleApiConfig(config))
  },
  patch<T> (url: string, data: unknown, config?: ApiConfig): Promise<AxiosResponse<T>> {
    return axiosInstance.patch(url, data, handleApiConfig(config))
  },
  delete (url: string, config?: ApiConfig) {
    return axiosInstance.delete(url, handleApiConfig(config))
  },
  contextURL
}
