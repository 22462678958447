<template>
  <div class="flex flex-col items-center">
    <div class="flex flex-row justify-end w-full max-w-full gap-1 mb-2 2xl:w-xl-px">
      <app-link :to="duplicateRoute">
        📝&nbsp;{{ t('actions.duplicate') }}
      </app-link>
      <button
        type="button"
        class="px-3 py-1 text-white bg-red-700 rounded-md"
        @click="deleteOpen = true"
      >
        🗑️&nbsp;{{ t('actions.delete') }}
      </button>
    </div>
    <div class="grid w-full max-w-full mb-3 text-sm grid-cols-vertical-form lg:grid-cols-vertical-form-4 place-items-center-stretch 2xl:w-xl-px">
      <div class="p-1 font-medium">
        {{ t('labels.name') }}
      </div>
      <div class="p-1">
        <input
          :value="name"
          class="h-7 px-2.5 py-0.5 border border-gray-400 text-gray-700 focus:ring-primary-500 focus:border-primary-500 rounded focus:outline-none w-full"
          @input="$emit('update:name', ($event.target as HTMLInputElement).value)"
        >
      </div>
      <div class="p-1 font-medium">
        {{ t('labels.maxDaysRange') }}
      </div>
      <div class="p-1">
        <input
          :value="maxDaysRange"
          type="number"
          min="1"
          max="365"
          class="h-7 px-2.5 py-0.5 text-sm border border-gray-400 text-gray-700 focus:ring-primary-500 focus:border-primary-500 rounded focus:outline-none w-full"
          @input="($event.target as HTMLInputElement).value !== '' ? $emit('update:maxDaysRange', parseInt(($event.target as HTMLInputElement).value)) : {}"
        >
      </div>
      <div class="p-1 font-medium">
        {{ t('dates.defaultPeriod') }}
      </div>
      <div class="p-1">
        <DefaultPeriodPicker
          :model-value="defaultPeriod"
          :required="true"
          button-classes="!h-7 mt-0"
          @update:model-value="$emit('update:defaultPeriod', $event)"
        />
      </div>
      <div class="p-1 font-medium">
        {{ t('dates.defaultComparisonPeriod') }}
      </div>
      <div class="p-1">
        <VueFormMultiSelect
          name="defaultComparisonPeriod"
          :model-value="defaultComparisonPeriod || 'none'"
          mode="single"
          :close-on-select="true"
          :options="comparisonPresetOptions"
          :classes="multiselectTailwindClassesCompact"
          :can-clear="false"
          :can-deselect="false"
          @input="$emit('update:defaultComparisonPeriod', $event === 'none' ? undefined : $event)"
        />
      </div>
      <div class="p-1 font-medium">
        {{ t('labels.granularity') }}
      </div>
      <div class="p-1">
        <VueFormMultiSelect
          name="granularity"
          :model-value="granularity"
          mode="single"
          :close-on-select="true"
          :options="granularityMultiselectOptions"
          :classes="multiselectTailwindClassesCompact"
          :can-clear="false"
          :can-deselect="false"
          @input="$emit('update:granularity', $event)"
        />
      </div>
    </div>
    <Modal v-model:open="deleteOpen">
      <template #title>
        {{ t('actions.delete', [t('app.dashboard')]) }}
      </template>

      <p class="p-3">
        <ExclamationTriangleIcon class="inline-block w-8 h-8 m-2 text-amber-500" />
        {{ t('messages.deleteConfirm', [t('app.dashboard')]) }}
      </p>
      <div class="flex flex-row justify-end gap-1 p-3">
        <button
          type="button"
          class="px-3 py-1 text-white rounded-md bg-primary-400"
          @click="deleteOpen = false"
        >
          {{ t('actions.cancel') }}
        </button>
        <button
          type="button"
          class="px-3 py-1 text-white bg-red-700 rounded-md"
          @click="$emit('delete'); deleteOpen = false"
        >
          🗑️&nbsp;{{ t('actions.delete') }}
        </button>
      </div>
    </Modal>
    <SourcesView
      :sources="sources"
      :stores="stores"
      class="w-full max-w-full 2xl:w-xl-px"
      @submit="$emit('source-submit', $event)"
      @delete="$emit('source-remove', $event.uid)"
    />
    <StoresView
      v-if="sources.length > 0"
      :stores="stores"
      :sources="sources"
      class="w-full max-w-full 2xl:w-xl-px"
      @submit="$emit('store-submit', $event)"
      @delete="$emit('store-remove', $event.uid)"
      @duplicate="$emit('store-duplicate', $event)"
    />
    <WidgetsView
      v-if="stores.length > 0"
      :widgets="widgets"
      :stores="stores"
      class="w-full max-w-full 2xl:w-xl-px"
      @submit="$emit('widget-submit', $event)"
      @update="$emit('widget-update', $event)"
      @remove="$emit('widget-remove', $event)"
      @duplicate="$emit('widget-duplicate', $event)"
    />
  </div>
</template>

<script lang="ts">
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid'
import { defineComponent, PropType, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useContext } from '@/plugins/context'
import { DEFAULT_MAX_DAYS_RANGE, WidgetDefinition, Store, granularityMultiselectOptions } from '@/plugins/dashboard'
import { TimeGranularityName } from '@/plugins/dashboard/dimensions'
import { Source } from '@/plugins/dashboard/source'

import { COMPARISON_DATE_RANGE_PRESETS, DateRange, DateRangePreset } from '@/components/DateRangePicker/dateRange'
import { PRESET_TRANSLATIONS } from '@/components/DateRangePicker/translations'
import VueFormMultiSelect from '@/components/Form/FormMultiselect.vue'
import Modal from '@/components/Modal/Modal.vue'
import { multiselectTailwindClassesCompact } from '@/components/Multiselect'

import DefaultPeriodPicker from './DefaultPeriodPicker.vue'
import SourcesView from './SourcesView.vue'
import StoresView from './StoresView.vue'
import WidgetsView from './WidgetsView.vue'

// TODO idea: put the dashboard editor into the panel

export default defineComponent({
  components: {
    VueFormMultiSelect,
    Modal,
    DefaultPeriodPicker,
    ExclamationTriangleIcon,
    StoresView,
    SourcesView,
    WidgetsView
  },
  props: {
    dashboardId: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    stores: {
      type: Array as PropType<Store[]>,
      required: true
    },
    sources: {
      type: Array as PropType<Source[]>,
      required: true
    },
    widgets: {
      type: Array as PropType<WidgetDefinition[]>,
      required: true
    },
    maxDaysRange: {
      type: Number,
      default: DEFAULT_MAX_DAYS_RANGE
    },
    defaultPeriod: {
      type: [String, Object] as PropType<DateRangePreset | DateRange>,
      required: true
    },
    defaultComparisonPeriod: {
      type: String,
      default: () => undefined
    },
    granularity: {
      type: String as PropType<TimeGranularityName>,
      default: 'day'
    }
  },
  emits: [
    'source-submit', 'store-submit', 'store-duplicate',
    'widget-submit', 'widget-update', 'widget-remove', 'widget-duplicate',
    'source-remove', 'store-remove',
    'update:name', 'update:maxDaysRange', 'update:defaultPeriod', 'update:defaultComparisonPeriod', 'update:granularity',
    'delete', 'duplicate'
  ],
  setup (props) {
    const { t } = useI18n()

    // Data
    const deleteOpen = ref(false)

    // Computed
    const makeComparisonPresetOptions = (noneLabel: string) => {
      const options = Object.keys(COMPARISON_DATE_RANGE_PRESETS).map(p => {
        return {
          id: p,
          name: PRESET_TRANSLATIONS[p],
          disabled: false
        }
      })
      return [
        {
          id: 'none',
          name: noneLabel,
          disabled: false
        },
        ...options
      ]
    }

    const comparisonPresetOptions = computed(() => makeComparisonPresetOptions(t('dates.noComparison')))

    const duplicateRoute = computed(() => {
      return useContext('dashboards.duplicate', { dashboardId: props.dashboardId })
    })

    return {
      // Data
      deleteOpen,
      duplicateRoute,

      // Computed,
      comparisonPresetOptions,

      // Misc
      multiselectTailwindClassesCompact,
      granularityMultiselectOptions,
      t
    }
  }
})
</script>
