import { AxiosResponse } from 'axios'

import api from '../api'
import { Export, ExportResponse } from '../models/export'

export async function createExport (data: Export): Promise<AxiosResponse<Export>> {
  return api.post<Export>('/exports', data, { requireContext: true, baseURL: `${import.meta.env.VITE_DATA_API_BASE_URL}/api/v1` })
}

export async function fetchExport (token: string): Promise<AxiosResponse<ExportResponse>> {
  return api.get<ExportResponse>(`/exports/${token}`)
}
