<template>
  <Loading
    :is-loading="loading"
    class="min-h-25-screen"
    text="dashboards.loading"
  >
    <Dashboard
      v-if="definition"
      :definition="definition"
      :dashboard-id="parseInt(dashboardId)"
      :name="name"
      :editable="userIsService()"
    />
  </Loading>
  <router-view
    :after-close="afterClose"
    :dashboard-id="dashboardId"
  />
</template>

<script lang="ts">
import debounce from 'lodash/debounce'
import { defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { useContext } from '@/plugins/context'
import { addMeta, removeMeta } from '@/plugins/meta'
import { userIsService } from '@/plugins/permissions'

import { useContextStore } from '@/store/context.store'
import { useNotificationsStore } from '@/store/notifications.store'

import Dashboard from '../../../components/Dashboard/Dashboard.vue'
import Loading from '../../../components/Loading/Loading.vue'
import { SerializedDashboard } from '../../../plugins/dashboard'
import { fetchDashboardById } from '../../../services/dashboards'

export default defineComponent({
  components: {
    Dashboard,
    Loading
  },
  inheritAttrs: false,
  props: {
    dashboardId: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const { t } = useI18n()
    const router = useRouter()
    const loading = ref(true)
    const definition = ref(null as SerializedDashboard | null)
    const name = ref('')

    const contextStore = useContextStore()
    const notificationsStore = useNotificationsStore()

    const fetch = () => {
      loading.value = true
      name.value = ''
      definition.value = null
      removeMeta(':dashboardName')
      fetchDashboardById(props.dashboardId)
        .then(response => {
          if (response.data === undefined || response.data.config === undefined) {
            notificationsStore.add({
              title: t('labels.error'),
              message: t('dashboards.loadError', [t('messages.emptyResponse')]),
              type: 'error'
            })
            return
          }

          try {
            definition.value = response.data.config
            name.value = response.data.name
            addMeta(':dashboardName', response.data.name)
          } catch (e: any) {
            notificationsStore.add({
              title: t('labels.error'),
              message: t('dashboards.loadError', [e.message]),
              type: 'error'
            })
          }
        })
        .catch((e: any) => {
          if (e.response?.status === 429) {
            return
          }
          notificationsStore.add({
            title: t('labels.error'),
            message: t('dashboards.loadError', [e.message]),
            type: 'error'
          })
        })
        .finally(() => {
          loading.value = false
        })
    }

    const fetchDebounced = debounce(fetch, 500)

    watch(
      () => props.dashboardId,
      () => fetchDebounced(),
      { immediate: true }
    )

    watch(
      () => contextStore.context,
      v => { if (v) { fetchDebounced() } },
      { flush: 'post' }
    )

    const afterClose = () => {
      const selfRoute = useContext('dashboards.show', { dashboardId: props.dashboardId })
      router.push(selfRoute.value)
    }

    return { loading, definition, name, userIsService, afterClose }
  }
})
</script>
