import { ReducerConstructor, deserializeReducer } from './reducers'

import { MetricFormatter, METRIC_FORMATTERS, Serializable, SerializedMetric } from '.'

export class Metric implements Serializable<SerializedMetric> {
  name: string
  ReducerConstructor: ReducerConstructor
  formatter: MetricFormatter
  formatterName: string

  constructor (name: string, reducerConstructor: ReducerConstructor, formatter: string) {
    this.name = name
    this.ReducerConstructor = reducerConstructor
    this.formatterName = formatter
    this.formatter = METRIC_FORMATTERS[this.formatterName]
    if (this.formatter === undefined) {
      throw new Error(`Metric formatter "${formatter}" doesn't exist`)
    }
  }

  serialize (): SerializedMetric {
    return {
      name: this.name,
      formatter: this.formatterName,
      reducer: this.ReducerConstructor.serialize()
    }
  }

  static retroCompatFormatter (formatter: string): (string | undefined) {
    switch (formatter) {
      case 'NORMAL': return 'RAW'
      case 'NUMBER': return 'INTEGER'
      case 'RELATIVE_PERCENT': return 'SIGNED_PERCENT'
      case 'DOLLAR': return 'CURRENCY_DOLLAR'
    }

    return undefined
  }

  static deserialize (m: SerializedMetric): Metric {
    if (m.reducer === undefined || m.formatter === undefined || m.name === undefined) {
      throw new Error('Invalid SerializedMetric: missing parameters')
    }
    const formatter = METRIC_FORMATTERS[m.formatter] ? m.formatter : Metric.retroCompatFormatter(m.formatter)
    if (formatter === undefined) {
      throw new Error(`Invalid SerializedMetric: formatter "${m.formatter} doesn't exist"`)
    }
    return new Metric(m.name, deserializeReducer(m.reducer), formatter)
  }
}
