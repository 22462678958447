<template>
  <component
    :is="referenceIs"
    v-bind="referenceProps"
    ref="reference"
    :aria-describedby="role"
  >
    <slot
      name="reference"
      :visible="visible"
      :set-visible="setVisible"
    />
  </component>

  <MaybeTeleport :teleport-props="teleportProps">
    <MaybeTransition
      :transition-props="transitionProps"
      @before-enter="handle('before-enter')"
      @enter="handle('enter')"
      @after-enter="handle('after-enter')"
      @enter-cancelled="handle('enter-cancelled')"
      @before-leave="handle('before-leave')"
      @leave="handle('leave')"
      @after-leave="handle('after-leave')"
      @leave-cancelled="handle('leave-cancelled')"
    >
      <component
        :is="popperIs"
        v-show="visible"
        v-bind="popperProps"
        ref="popper"
        :role="role"
      >
        <slot
          :visible="visible"
          :set-visible="setVisible"
        />
      </component>
    </MaybeTransition>
  </MaybeTeleport>
</template>

<script lang="ts">
// Fork of the Popper component
// - adds setVisible functions
// - fixes reactivity of the "forceShow" prop
import {
  defineComponent,
  ref,
  toRef,
  computed,
  watch,
  UnwrapRef,
  PropType,
  TeleportProps,
  TransitionProps,
  onBeforeUnmount,
  onMounted,
  FunctionalComponent
} from 'vue'
import { usePopperjs } from 'vue-use-popperjs'
import MaybeTeleport from 'vue-use-popperjs/src/MaybeTeleport.vue'
import MaybeTransition from 'vue-use-popperjs/src/MaybeTransition.vue'

import { popperEmitter, POPPER_UPDATE_EVENT } from './emitter'

let popperUid = 0

export default defineComponent({
  components: {
    MaybeTeleport,
    MaybeTransition
  },

  props: {
    // hook options
    delayOnMouseout: {
      type: Number,
      default: () => undefined
    },
    delayOnMouseover: {
      type: Number,
      default: () => undefined
    },
    trigger: {
      type: String as PropType<
      Exclude<UnwrapRef<Required<Parameters<typeof usePopperjs>>['2']['trigger']>, 'manual'>>,
      default: () => undefined
    },
    forceShow: Boolean,
    modifiers: {
      type: Array as PropType<Required<Parameters<typeof usePopperjs>>['2']['modifiers']>,
      default: () => undefined
    },
    onFirstUpdate: {
      type: Function as PropType<Required<Parameters<typeof usePopperjs>>['2']['onFirstUpdate']>,
      default: () => undefined
    },
    placement: {
      type: String as PropType<Required<Parameters<typeof usePopperjs>>['2']['placement']>,
      default: () => undefined
    },
    strategy: {
      type: String as PropType<Required<Parameters<typeof usePopperjs>>['2']['strategy']>,
      default: () => undefined
    },

    // component props
    popperIs: {
      default: 'div',
      type: String
    },
    popperProps: {
      type: Object,
      default: () => undefined
    },
    referenceIs: {
      type: [String, Object, Function] as PropType<string | FunctionalComponent>,
      default: 'div'
    },
    referenceProps: {
      type: Object,
      default: () => undefined
    },
    disabled: Boolean,
    teleportProps: {
      type: Object as PropType<TeleportProps>,
      default: () => undefined
    },
    transitionProps: {
      type: Object as PropType<TransitionProps>,
      default: () => undefined
    }
  },

  emits: [
    'show',
    'hide',

    'before-enter',
    'enter',
    'after-enter',
    'enter-cancelled',
    'before-leave',
    'leave',
    'after-leave',
    'leave-cancelled'
  ],

  setup (props, { emit }) {
    const reference = ref()
    const popper = ref()
    const { instance, visible } = usePopperjs(reference, popper, {
      ...props,
      trigger: toRef(props, 'trigger'),
      forceShow: toRef(props, 'forceShow'),
      delayOnMouseover: toRef(props, 'delayOnMouseover'),
      delayOnMouseout: toRef(props, 'delayOnMouseout'),
      onShow: () => emit('show'),
      onHide: () => emit('hide'),
      modifiers: props.modifiers
    })

    watch(
      () => [visible.value, props.disabled],
      () => {
        if (props.disabled && visible.value) {
          visible.value = false
        }
        if (!props.disabled && props.forceShow) {
          visible.value = true
        }
      }
    )

    const role = computed(() =>
      process.env.NODE_ENV === 'test' && visible.value
        ? 'vue-use-popperjs-' + popperUid++
        : undefined
    )

    const handle =
      (event: Parameters<typeof emit>[0]) =>
        (...args: any[]) => {
          return emit(event, ...args)
        }

    const setVisible = (v: boolean) => {
      visible.value = v
    }

    const onUpdate = () => {
      instance.value?.update()
    }

    let observer: MutationObserver | null

    const disconnectObserver = () => {
      if (observer) {
        observer.disconnect()
        observer = null
      }
    }

    watch(
      () => popper.value,
      () => {
        disconnectObserver()
        observer = new MutationObserver(onUpdate)
        observer.observe(popper.value, {
          childList: true,
          subtree: true
        })
      },
      { flush: 'post' }
    )

    onMounted(() => {
      popperEmitter.on(POPPER_UPDATE_EVENT, onUpdate)
    })

    onBeforeUnmount(() => {
      popperEmitter.off(POPPER_UPDATE_EVENT, onUpdate)
      disconnectObserver()
    })

    return {
      visible,
      reference,
      popper,
      role,
      setVisible,
      handle,
      onSlotUpdate: onUpdate
    }
  }
})
</script>
