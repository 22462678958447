<template>
  <WidgetShell
    :loading="isLoading"
    :title="title"
    :definition="definition"
    :edit-mode="editMode"
    :compact="compact"
    :show-expand="!compact"
    :enlarged="enlarged"
    :has-data="hasData"
    @json-editor-input="onJSONEditorInput"
    @remove-clicked="$emit('remove-clicked')"
    @export="onExport(title, $event)"
    @save-image="onSaveAsImageClicked(title)"
    @expand-clicked="onExpandClicked"
  >
    <template
      v-if="!compact"
      #header
    >
      <MultiSelect
        v-model="metric"
        name="metric"
        mode="single"
        :close-on-select="true"
        :options="definition.metrics.map(m => ({ id: m.name, name: translateDBName(m.name.toString()) }))"
        :classes="multiselectTailwindClasses"
        :can-clear="false"
        :can-deselect="false"
      />
    </template>

    <template #controls>
      <button
        v-if="title !== '' && hasComparisonData"
        type="button"
        class="inline-block mx-0.5 cursor-pointer align-middle invisible group-hover:visible"
        :title="hideComparison ? t('dashboards.showComparison') : t('dashboards.hideComparison') "
        @click="hideComparison = !hideComparison"
      >
        <EyeIcon
          v-if="hideComparison"
          class="w-4 h-4"
        />
        <EyeSlashIcon
          v-else
          class="w-4 h-4"
        />
      </button>
    </template>

    <template v-if="!isLoading">
      <v-chart
        v-if="!empty"
        ref="chart"
        class="flex-grow min-h-px"
        :init-options="initOptions"
        :option="chartOptions"
        :theme="ECHARTS_THEME_NAME"
        :update-options="updateOptions"
        autoresize
      />
      <template v-else>
        <div class="flex h-full">
          <p class="m-auto leading-relaxed">
            {{ t('dashboards.nothingToShow') }}
          </p>
        </div>
      </template>
    </template>
  </WidgetShell>
</template>

<script lang="ts">
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/outline'
import { Grouping } from 'crossfilter2'
import { EChartsOption, LegendComponentOption, LineSeriesOption, XAXisComponentOption } from 'echarts'
import { LineChart } from 'echarts/charts'
import { use } from 'echarts/core'
import { defineComponent, ref, watch, computed, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'

import { METRIC_FORMATTERS, translateDBName, WidgetDefinition, echartsTooltipFormatter, TemporalFormatter, TemporalFormatterOptions } from '@/plugins/dashboard'
import { CompositeDimension, DateRollupDimension, TIME_GRANULARITIES } from '@/plugins/dashboard/dimensions'

import { localToUTC } from '@/utils/dates'

import MultiSelect from '@/components/Form/FormMultiselect.vue'

import { ECHARTS_THEME } from '../theme'

import ChartWidget from './ChartWidgetMixin'
import { setupWidget, WidgetSettings, multiselectTailwindClasses, selectMetric } from './Widget'
import WidgetShell from './WidgetShell.vue'

use(LineChart)

// TODO stackbar et stacked area chart
// TODO on click on line, filter
// TODO event overlay (cf datadog) to highlight one day in the chart in transparency

// TODO on click on legend, select ONLY this series (same behavior as TableWidget, click, ctrl click)

export default defineComponent({
  components: {
    WidgetShell,
    MultiSelect,
    EyeIcon,
    EyeSlashIcon
  },
  mixins: [ChartWidget],
  // Note: for multiline charts, need first dimension to be time dimension
  props: {
    compact: {
      type: Boolean,
      default: false
    }
  },
  emits: ['remove-clicked', 'definition-changed'],
  setup (props, { emit }) {
    const { t } = useI18n()
    let large = false
    let timeDimensionName: string
    let cachedGroup: ReadonlyArray<Grouping<any, Record<string, any>>> | undefined
    let cachedComparisonGroup: ReadonlyArray<Grouping<any, Record<string, any>>> | undefined

    // Data
    const dimension = ref(props.definition.dimensions[0])
    const metric = ref(selectMetric(props.queryBuilder.widgetMetrics[props.definition.uid], props.definition))
    const chartOptions = ref<EChartsOption>({})
    const hideComparison = ref(false)

    // Computed
    const updateOptions = computed(() => {
      // See: https://echarts.apache.org/en/api.html#echartsInstance.setOption
      // By default, options are merged, meaning that if a series is removed, it will
      // still show on the chart. Using replace solves this issue albeit being slightly more expensive.
      return { notMerge: true }
    })

    const createDimension = () => {
      timeDimensionName = props.definition.dimensions[0].name + '_by_' + props.definition.store.granularity
      const dateRollup = new DateRollupDimension(timeDimensionName, TIME_GRANULARITIES[props.definition.store.granularity], props.definition.dimensions[0].column)
      if (props.definition.dimensions.length > 1) {
        dimension.value = new CompositeDimension(dateRollup, ...props.definition.dimensions.slice(1))
      } else {
        dimension.value = dateRollup
      }
    }
    createDimension()

    const updateChart = () => {
      if (!cachedGroup || commonWidget.isWaitingForBothData.value) {
        return
      }
      const currentMetric = props.definition.metrics.find(m => m.name === metric.value)!
      const options: TemporalFormatterOptions = {
        from: localToUTC(props.definition.store.lastDateRange!.from!),
        to: localToUTC(props.definition.store.lastDateRange!.to!),
        granularity: TIME_GRANULARITIES[props.definition.store.granularity],
        metric: metric.value,
        timeDimension: timeDimensionName
      }
      const formatter = new TemporalFormatter()
      const data = formatter.format(cachedGroup, options)
      let legendSeries = Object.keys(data).length > 0 ? Object.keys(Object.entries(data)[0][1]) : ['No results']
      if (!(dimension.value instanceof CompositeDimension)) {
        legendSeries = legendSeries.map(s => translateDBName(s))
      }
      const xAxis = Object.keys(data)
      const series = legendSeries.map((s, index) => {
        const color = ECHARTS_THEME.color[index % (ECHARTS_THEME.color.length - 1)]
        return {
          data: [] as any[],
          type: props.compact ? 'bar' : 'line',
          barCategoryGap: props.compact ? 2 : undefined,
          smooth: !props.compact,
          name: s,
          lineStyle: {
            color
          },
          itemStyle: {
            color
          },
          symbol: props.compact ? 'none' : 'emptyCircle',
          areaStyle: props.compact ? {} : undefined
        } as LineSeriesOption
      })

      const newOptions: EChartsOption = {
        xAxis: {
          type: 'category',
          data: xAxis,
          axisTick: {
            show: !props.compact,
            alignWithLabel: true,
            interval: xAxis.length > 2 && props.compact ? xAxis.length - 2 : 'auto'
          },
          axisLabel: {
            show: !props.compact,
            interval: xAxis.length > 2 && props.compact ? xAxis.length - 2 : 'auto'
          },
          boundaryGap: !props.compact
        },
        yAxis: {
          type: 'value',
          show: !props.compact,
          axisLabel: {
            show: !props.compact,
            formatter: (value: string | number) => {
              if (currentMetric.formatterName === 'INTEGER' ||
              currentMetric.formatterName.startsWith('CURRENCY')) {
                return METRIC_FORMATTERS[`COMPACT_${currentMetric.formatterName}`](value)
              }
              return currentMetric.formatter(value)
            }
          }
        },
        grid: {
          containLabel: true,
          left: props.compact ? 0 : 'left',
          right: props.compact ? 0 : (large ? 220 : undefined),
          top: props.compact ? 0 : 20,
          bottom: 0
        },
        tooltip: {
          trigger: dimension.value instanceof CompositeDimension ? 'item' : 'axis',
          appendToBody: true,
          formatter: echartsTooltipFormatter(currentMetric.formatter)
        },
        legend: props.compact || !large
          ? undefined
          : {
              data: legendSeries,
              right: 0,
              top: 20,
              bottom: 20,
              type: 'scroll',
              orient: 'vertical',
              textStyle: {
                overflow: 'truncate',
                width: 180
              }
            }
      }
      for (const date in data) {
        Object.values(data[date]).forEach((e, index) => {
          series[index].data!.push(e === Infinity ? '-' : e)
        })
      }

      if (cachedComparisonGroup && !hideComparison.value) {
        const comparisonOptions: TemporalFormatterOptions = {
          from: new Date(props.definition.store.comparisonStore!.lastDateRange!.from!),
          to: new Date(props.definition.store.comparisonStore!.lastDateRange!.to!),
          granularity: TIME_GRANULARITIES[props.definition.store.granularity],
          metric: metric.value,
          timeDimension: timeDimensionName
        }
        const comparisonData = formatter.format(cachedComparisonGroup, comparisonOptions)
        const comparisonXAxis = Object.keys(comparisonData)
        let comparisonSeries = Object.keys(comparisonData).length > 0 ? Object.keys(Object.entries(comparisonData)[0][1]) : []
        if (!(dimension.value instanceof CompositeDimension)) {
          comparisonSeries = comparisonSeries.map(s => translateDBName(s))
        }

        newOptions.xAxis = [
          (newOptions.xAxis as XAXisComponentOption),
          {
            type: 'category',
            data: comparisonXAxis,
            axisTick: {
              alignWithLabel: true,
              interval: comparisonXAxis.length > 2 ? comparisonXAxis.length - 2 : 'auto'
            },
            axisLine: {
              show: !props.compact,
              lineStyle: {
                type: 'dashed'
              }
            },
            axisLabel: {
              show: !props.compact
            },
            boundaryGap: !props.compact
          }
        ]
        const originalDataLength = series.length
        series.push(...comparisonSeries.map((s, index) => {
          const color = props.compact || series.length === 1
            ? ECHARTS_THEME.color[(index + 1) % (ECHARTS_THEME.color.length - 1)]
            : series.find(s2 => s2.name === s)?.lineStyle?.color
          return {
            data: [] as any[],
            type: 'line',
            smooth: !props.compact,
            name: s,
            xAxisIndex: 1,
            lineStyle: {
              type: 'dashed',
              width: props.compact ? 3 : 2,
              color
            },
            itemStyle: {
              color
            },
            symbol: props.compact ? 'none' : 'emptyCircle'
          } as LineSeriesOption
        }))
        for (const date in comparisonData) {
          Object.values(comparisonData[date]).forEach((e, index) => {
            series[index + originalDataLength].data!.push(e === Infinity ? '-' : e)
          })
        }
        if (newOptions.legend) {
          (newOptions.legend as LegendComponentOption).data!.push(...comparisonSeries)
        }
      }

      newOptions.series = series
      chartOptions.value = newOptions
    }

    const empty = computed(() => !(chartOptions.value.series as LineSeriesOption[])?.some(s => s.data!.length > 0))

    const updateFunc = (v: ReadonlyArray<Grouping<any, Record<string, any>>> | undefined) => {
      cachedGroup = v
      updateChart()
    }
    const comparisonUpdateFunc = (v: ReadonlyArray<Grouping<any, Record<string, any>>> | undefined) => {
      cachedComparisonGroup = v
      updateChart()
    }

    // Watch
    watch(
      () => props.definition,
      () => {
        cachedGroup = undefined
        cachedComparisonGroup = undefined
        createDimension()
      }
    )
    watch(
      () => [metric.value, hideComparison.value],
      () => updateChart()
    )

    watch(
      () => metric.value,
      () => props.queryBuilder.setMetric(props.definition.uid, metric.value !== props.definition.metrics[0].name ? metric.value : undefined),
      { immediate: true }
    )

    // Methods
    const setMetric = (m: string) => {
      metric.value = m
    }

    const onJSONEditorInput = (newDefinition: WidgetDefinition) => {
      emit('definition-changed', newDefinition)
    }

    const settings: WidgetSettings = {
      props,
      dimension,
      updateFunc,
      comparisonUpdateFunc
    }
    const commonWidget = setupWidget(settings)
    watch(
      () => commonWidget.enlarged.value,
      () => {
        large = commonWidget.enlarged.value
        nextTick(() => setTimeout(() => {
          updateChart()
        }, 0))
      }
    )

    return {
      // Data
      chartOptions,
      metric,
      hideComparison,

      // Computed
      updateOptions,
      empty,

      // Methods
      setMetric,
      onJSONEditorInput,

      // Misc
      TIME_GRANULARITIES,
      multiselectTailwindClasses,
      translateDBName,
      t,

      ...commonWidget
    }
  }
})
</script>
