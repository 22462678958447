<template>
  <svg
    viewBox="0 0 15 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8182 13.5L11.0114 11.7273H3.75C2.70455 11.7273 1.81818 11.3636 1.09091 10.6364C0.363636 9.90909 0 9.02273 0 7.97727C0 7.10455 0.270455 6.32727 0.811364 5.64545C1.35227 4.95909 2.04773 4.52045 2.89773 4.32955C2.93409 4.23864 2.96818 4.15 3 4.06364C3.04545 3.97727 3.07955 3.88409 3.10227 3.78409L0.272727 0.954545L1.22727 0L13.7727 12.5455M14.0455 10.875L4.80682 1.65682C5.20682 1.38409 5.62955 1.175 6.075 1.02955C6.525 0.888636 7 0.818182 7.5 0.818182C8.83182 0.818182 9.95909 1.28182 10.8818 2.20909C11.8091 3.13182 12.2727 4.25909 12.2727 5.59091C13.0591 5.68182 13.7091 6.02273 14.2227 6.61364C14.7409 7.19546 15 7.87727 15 8.65909C15 9.11364 14.9159 9.525 14.7477 9.89318C14.575 10.275 14.3409 10.6023 14.0455 10.875Z"
    />
  </svg>
</template>
