<template>
  <div :class="wrapperClass">
    <Tooltip
      :disabled="(fieldErrors?.$errors?.length || 0) <= 0"
      :closeable="true"
      :force-show="true"
    >
      <div class="flex flex-col">
        <label
          :for="idValue"
          class="flex w-full text-xs font-semibold text-text-primary"
          :aria-label="label"
        >
          <span
            :class="[
              mode === 'materialize' ? 'hidden' : 'mb-2',
              $attrs.required || isRequired ? `after:content-['*']` : ''
            ]"
          >
            {{ label }}
          </span>
          <span
            v-if="hints.textTop"
            class="mb-2 ml-auto text-xs font-normal text-slate-400"
          > {{ hints.textTop }}</span>
        </label>
      </div>

      <div class="relative flex flex-col overflow-hidden">
        <textarea
          :id="idValue"
          v-model="value"
          v-bind="$attrs"
          :class="[
            inputClasses,
            mode === 'materialize' ? 'peer order-2 transition-all focus:pt-8 focus:pb-1 focus:font-semibold' : ''
          ]"
          :aria-invalid="errors.length ? true : undefined"
          :aria-describedby="errors.length ? `${name}-error` : undefined"
          :aria-required="isRequired && !$attrs.required ? true : undefined"
        />
        <span
          v-if="mode=== 'materialize'"
          :class="[
            'absolute top-0 left-0 flex order-1 items-center',
            'w-[calc(100%_-_1px)] pl-5 p-2.5 pb-1.5',
            'pointer-events-none transition-all',
            'text-base leading-none text-slate-500 primary font-semibold',
            'border-t border-l  rounded-t-lg',
            'bg-white shadow-lg shadow-white/50',
            'peer-focus:text-xs',
            'peer-disabled:bg-gray-200 peer-read-only:bg-gray-200',
            'group-:border-red-100',
            (errors.length) ? '!border-red-400' : 'border-gray-border',
            (value !== '') ? '!text-xs' : '',
            (!$attrs.readonly) ? 'peer-focus:border-primary-500' : '',
            $attrs.required || isRequired ? `after:content-['*']` : ''
          ]"
        >
          {{ label }}
        </span>
      </div>

      <template
        v-if="(fieldErrors?.$errors?.length || 0) > 0"
        #title
      >
        <div class="flex flex-col gap-0.5">
          <p
            v-for="(err, i) in fieldErrors!.$errors"
            :key="i"
            class="inline-block text-xs font-normal"
          >
            {{ err }}
          </p>
        </div>
      </template>
    </Tooltip>
    <FormHelpText
      v-if="hints.textBottom"
      :help-text="hints.textBottom"
    />
  </div>
</template>

<script lang="ts">
import { ErrorObject } from '@vuelidate/core'
import { PropType, computed, defineComponent, onBeforeUnmount, ref } from 'vue'
import useClipboard from 'vue-clipboard3'
import { useI18n } from 'vue-i18n'

import { useVuelidateError } from '@/plugins/form'

import { useNotificationsStore } from '@/store/notifications.store'

import { randomString } from '@/utils/utils'

import Tooltip from '@/components/Tooltip/Tooltip.vue'

import FormHelpText from './FormHelpText.vue'

import { FormInputHints, FormInputMode } from '.'

export default defineComponent({
  components: {
    Tooltip,
    FormHelpText
  },
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: [String, Object],
      required: false,
      default: ''
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    errors: {
      type: [Array] as PropType<ErrorObject[]>,
      required: false,
      default: () => []
    },
    wrapperClass: {
      type: String,
      required: false,
      default: ''
    },
    fieldClass: {
      type: String,
      required: false,
      default: ''
    },
    mode: {
      type: String as PropType<FormInputMode>,
      required: false,
      default: 'materialize'
    },
    hints: {
      type: Object as PropType<FormInputHints>,
      required: false,
      default: () => ({})
    }
  },
  emits: ['update:modelValue'],
  setup (props, { attrs, emit }) {
    const { t } = useI18n()

    const notificationsStore = useNotificationsStore()

    const input = ref<HTMLTextAreaElement | null>(null)
    const idValue = `${props.name}-${randomString(12)}`

    const { toClipboard } = useClipboard()

    const value = computed({
      get () {
        return typeof props.modelValue === 'object' ? JSON.stringify(props.modelValue, null, '\t') : props.modelValue
      },
      set (value: string) {
        emit('update:modelValue', value)
      }
    })

    const onClipboardClick = async () => {
      try {
        await toClipboard(props.modelValue.toString())
        notificationsStore.add({
          title: t('labels.success'),
          message: t('actions.clipboardSuccess'),
          type: 'success',
          duration: 4000
        })
      } catch (e: any) {
        notificationsStore.add({
          title: t('labels.error'),
          message: t('actions.clipboardFailure', [e.message]),
          type: 'error'
        })
      }
    }

    const inputClasses = computed(() => {
      const css = [
        'block', 'w-full', 'rounded-lg', 'pl-5', 'pr-2.5',
        'text-base', 'leading-[1.375rem]', 'text-text-primary',
        'border', 'border-gray-border',
        'outline-none', 'focus:outline-none',
        'ring-0', 'focus:ring-0',
        'placeholder:text-slate-400', 'placeholder:font-normal',
        'disabled:bg-gray-200',
        'read-only:bg-gray-200'
      ]

      switch (props.mode) {
        case 'default':
          break
        case 'materialize':
          // css.push('h-12.125')
          break
      }

      if (!attrs.readonly) {
        css.push('focus:border-primary-500')
      }

      if (value.value !== '' && props.mode === 'materialize') {
        css.push('pt-8', 'pb-1', 'font-semibold')
      } else {
        css.push('font-normal')
      }

      if (fieldErrors.value?.$errors?.length) {
        css.push('!border-red-400')
      }

      if (props.fieldClass) {
        css.push(props.fieldClass)
      }

      return css.join(' ')
    })

    const fieldErrors = computed(() => useVuelidateError(props.errors))

    onBeforeUnmount(() => {
      if (input.value) {
        input.value.blur()
      }
    })

    return {
      t,
      input,
      idValue,
      inputClasses,
      value,
      onClipboardClick,
      fieldErrors
    }
  }
})
</script>
