<template>
  <div
    v-if="show"
    ref="calloutElm"
    class="group"
    :class="{'is-compact': isCompact}"
  >
    <div :class="['relative flex items-center gap-2.5 group-[.is-compact]:flex-col group-[.is-compact]:items-start group-[.is-compact]:gap-4 justify-between p-4 rounded-lg', cTheme.container]">
      <div
        class="flex flex-col flex-1"
        :class="{
          'gap-4': Array.isArray(content) || slots.extraContext,
          'min-w-[calc(100%-(16px+10px))]': isDismissable,
        }"
      >
        <!-- title -->
        <div class="flex items-start gap-1">
          <span class="flex items-center h-5">
            <component
              :is="calloutIcon"
              :class="['w-3 h-3', cTheme.icon]"
              aria-hidden="true"
            />
          </span>
          <div
            class="inline-flex flex-wrap items-center"
          >
            <h4
              class="text-sm font-bold"
              :class="{
                'mr-1': slots.head
              }"
            >
              {{ title }}
            </h4>
            <div
              v-if="slots.head"
              class="text-xs font-normal"
            >
              <slot name="head" />
            </div>
          </div>
        </div>

        <!-- body -->
        <div
          v-if="content || slots.default"
          class="ml-4 text-sm font-medium text-slate-500"
        >
          <slot>
            <p v-if="typeof content === 'string'">
              {{ content }}
            </p>

            <ul
              v-if="Array.isArray(content) && content.length"
            >
              <li
                v-for="(text, index) in content"
                :key="index"
              >
                {{ text }}
              </li>
            </ul>
          </slot>

          <div
            v-if="slots.extraContext"
            :class="['inline-block mt-1.5 px-1.5 py-0.5 text-xs text-normal leading-snug text-slate-900 rounded-sm', cTheme.extraContext]"
          >
            <slot
              name="extraContext"
            />
          </div>
        </div>
      </div>

      <div
        v-if="slots.actions"
        :class="['flex flex-row items-strech gap-1 group-[.is-compact]:ml-4', cTheme.button]"
      >
        <slot
          name="actions"
        />
      </div>

      <AppButton
        v-if="isDismissable"
        appearance="none"
        size="sm"
        class="p-1 group-[.is-compact]:absolute group-[.is-compact]:top-1 group-[.is-compact]:right-1 group-[.is-compact]:p-2.5"
        :aria-label="t('actions.dismiss')"
        :unique-icon="XMarkIcon"
        @click="onDismiss"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { XCircleIcon, InformationCircleIcon, CheckCircleIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/20/solid'
import { useElementSize } from '@vueuse/core'
import { Component, defineComponent, PropType, ref, computed, useSlots } from 'vue'
import { useI18n } from 'vue-i18n'

import AppButton from '../Buttons/AppButton.vue'

import { CalloutTheme, CALLOUT_CLASSES } from './theme'

export default defineComponent({
  components: {
    AppButton,
    CheckCircleIcon,
    XCircleIcon,
    ExclamationTriangleIcon,
    InformationCircleIcon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    theme: {
      type: Number as PropType<CalloutTheme>,
      default: CalloutTheme.BASE
    },
    content: {
      type: [String, Array] as PropType<string | string[]>,
      default: ''
    },
    isDismissable: {
      type: Boolean,
      default: () => true
    },
    icon: {
      type: [Object, Function] as PropType<Component>,
      default: () => undefined
    }
  },
  emits: ['dismiss'],
  setup (props, { emit }) {
    const COMPONENT_BREAKPOINT = 620

    const { t } = useI18n()

    const slots = useSlots()

    const calloutElm = ref<HTMLDivElement>()

    const show = ref(true)

    const { width: calloutElmWidth } = useElementSize(calloutElm)

    const isCompact = computed(() => calloutElmWidth.value < COMPONENT_BREAKPOINT)

    const calloutIcon = computed(() => {
      if (props.icon) {
        return props.icon
      }
      switch (props.theme) {
        case CalloutTheme.DANGER:
          return XCircleIcon
        case CalloutTheme.WARNING:
          return ExclamationTriangleIcon
        case CalloutTheme.INFO:
          return InformationCircleIcon
        default:
          return CheckCircleIcon
      }
    })

    const cTheme = computed(() => {
      return CALLOUT_CLASSES[props.theme]
    })

    const onDismiss = () => {
      show.value = false
      emit('dismiss')
    }

    return {
      t,
      show,
      onDismiss,
      calloutIcon,
      cTheme,
      XMarkIcon,

      calloutElm,
      isCompact,

      slots
    }
  }
})
</script>
