<template>
  <Panel
    :title="t('reports.edit', { name: form.name })"
    :is-loading="loading"
    :after-close="afterClose"
  >
    <PanelForm
      v-if="!loading"
      :on-submit="onSubmit"
      :is-submitting="state.isSubmitting"
      :on-cancel="reset"
      :view-only="isViewOnly"
      :with-confirm="true"
      :with-cancel-confirm="validation.$anyDirty"
    >
      <FormReport
        v-model="form"
        :errors="errors"
        :view-only="isViewOnly"
      />

      <template #actions="{isSubmitting, isDisabled, isLoading, isViewOnly: isTemplateViewOnly}">
        <PanelFormActions
          :is-submitting="isSubmitting"
          :is-disabled="isDisabled"
          :is-loading="isLoading"
          :is-view-only="isTemplateViewOnly"
        >
          <template #afterButtons>
            <AppButton
              :hide-if-missing-permission="false"
              @click.prevent="downloadReport"
            >
              {{ t('actions.download') }}
            </AppButton>
          </template>
        </PanelFormActions>
      </template>
    </PanelForm>
  </Panel>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRequest } from 'vue-request'
import { useRoute } from 'vue-router'

import { rolesGroup } from '@/static/roles'

import { useForm } from '@/plugins/form'
import { addMeta } from '@/plugins/meta'
import { hasRole, useRoutePermissions } from '@/plugins/permissions'

import { useAppStore } from '@/store/app.store'
import { useNotificationsStore } from '@/store/notifications.store'

import { ReportForm, createReportForm, reportFormRules, transformToReport } from '@/models/report'

import { fetchExport } from '@/services/exports'
import { fetchReport, updateReport } from '@/services/reports'

import AppButton from '@/components/Buttons/AppButton.vue'
import Panel from '@/components/Panel/Panel.vue'
import PanelForm from '@/components/Panel/PanelForm.vue'
import PanelFormActions from '@/components/Panel/PanelFormActions.vue'

import FormReport from '../components/FormReport.vue'

export default defineComponent({
  components: {
    Panel,
    PanelForm,
    FormReport,
    AppButton,
    PanelFormActions
  },
  props: {
    reportId: {
      type: String,
      required: true
    },
    afterClose: {
      type: Function,
      required: true
    },
    updateDatatable: {
      type: Function,
      required: true
    }
  },
  setup (props) {
    const { t } = useI18n()

    const notificationsStore = useNotificationsStore()
    const appStore = useAppStore()

    const currentRoute = useRoute()

    const { roles } = useRoutePermissions(currentRoute)

    const isViewOnly = !hasRole(roles, rolesGroup.user)

    const reportForm = ref<ReportForm>(createReportForm())

    const queryParams = currentRoute.query

    const { run, loading } = useRequest(fetchReport, {
      defaultParams: [parseInt(props.reportId, 10)],
      manual: true,
      onSuccess: (response) => {
        if (response && response.data) {
          reportForm.value = createReportForm(response.data)
          addMeta(':reportName', response.data.name)
        }
      },
      onError: (e: any) => {
        if (e.response?.status === 429) {
          return
        }
        notificationsStore.add({
          title: t('reports.edit'),
          message: t('messages.fetchError'),
          type: 'error'
        })

        props.updateDatatable(queryParams)

        appStore.closePanel()
      }
    })

    run(parseInt(props.reportId, 10))

    watch(
      () => props.reportId,
      () => run(parseInt(props.reportId, 10))
    )

    const { form, onSubmit, reset, state, validation, errors } = useForm<ReportForm>({

      defaultForm: computed(() => reportForm.value),

      rules: reportFormRules,

      submitHandler: (form) => {
        return updateReport(parseInt(props.reportId, 10), transformToReport(form))
      },

      onSuccess: async (response) => {
        if (response && response.data) {
          const createdId = response.data.id
          const result = await props.updateDatatable(queryParams)

          if (result?.data?.records) {
            const arrayIds = result.data.records.map((item: any) => {
              return item.id
            })
            if (arrayIds.includes(createdId)) {
              notificationsStore.add({
                title: t('reports.edit'),
                message: t('reports.editSuccess'),
                type: 'success'
              })

              return appStore.closePanel()
            }
          }
        }

        notificationsStore.add({
          title: t('reports.edit'),
          message: t('notifications.filteredWarning', { action: t('reports.editSuccess') }),
          type: 'success'
        })

        appStore.closePanel()
      }
    })

    const rawReportUrl = `${reportForm.value.token}`
    const { run: runFetchExport } = useRequest(fetchExport, {
      defaultParams: [rawReportUrl],
      manual: true,
      onSuccess: async (response) => {
        if (response && response.data) {
          const a = window.document.createElement('a')
          a.href = response.data.url
          a.target = '_blank'

          const evt = new MouseEvent('click', {
            view: document.defaultView,
            bubbles: true,
            cancelable: false
          })

          a.dispatchEvent(evt)
        }
      },
      onError: () => {
        notificationsStore.add({
          title: t('reports.download'),
          message: t('reports.createError'),
          type: 'error'
        })
      }
    })

    const downloadReport = () => {
      const rawReportUrl = `${reportForm.value.token}`
      if (rawReportUrl) {
        runFetchExport(rawReportUrl)
      }
    }

    return {
      form,
      isViewOnly,
      validation,
      errors,
      state,
      loading,
      onSubmit,
      reset,
      downloadReport,
      t
    }
  }
})
</script>
