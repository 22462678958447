<template>
  <template v-if="permissions && (disable || permissions.hasAccess)">
    <slot
      :is-service="permissions.isService"
      :roles="permissions.roles"
      :has-access="permissions.hasAccess"
    />
  </template>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { RouteLocationRaw, useRouter } from 'vue-router'

import { useRoutePermissions } from '.'

export default defineComponent({
  props: {
    to: {
      type: [String, Object] as PropType<RouteLocationRaw>,
      required: true
    },
    disable: {
      type: Boolean,
      default: () => false
    }
  },
  setup (props) {
    const router = useRouter()
    const route = computed(() => {
      try {
        return router.resolve(props.to)
      } catch (e) {
        return undefined
      }
    })

    const permissions = computed(() => route.value ? useRoutePermissions(route.value) : undefined)

    return {
      permissions
    }
  }
})
</script>
