import { RouteRecordRaw } from 'vue-router'

import { rolesGroup } from '@/static/roles'

import i18n from '@/plugins/i18n'

export function winningAdvertisers (): RouteRecordRaw[] {
  return [
    {
      path: 'winning-advertisers',
      name: 'publishers.winningAdvertisers.index',
      component: () => import('./views/Index.vue'),
      meta: {
        title: i18n.global.t('labels.winningAdvertiser', 100),
        feature: 'winning-advertisers',
        roles: rolesGroup.user
      }
    }
  ]
}
