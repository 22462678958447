import { InformationCircleIcon, CheckCircleIcon, ExclamationTriangleIcon, ExclamationCircleIcon, NoSymbolIcon } from '@heroicons/vue/20/solid'

import i18n from '@/plugins/i18n'

/**
 * BidderLinesStatus is the status name used to define the aggregated status of ads.txt lines for a bidder on a website
 */
export enum BidderLinesStatus {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  OK = 'ok',
  NOT_CRAWLED = 'not_crawled',
  NOT_APPLICABLE = 'not_applicable',
}

export const bidderLinesStatus = [
  { id: BidderLinesStatus.OK, name: i18n.global.t('labels.valid') },
  { id: BidderLinesStatus.WARNING, name: i18n.global.t('labels.warning') },
  { id: BidderLinesStatus.INFO, name: i18n.global.t('labels.info') },
  { id: BidderLinesStatus.ERROR, name: i18n.global.t('labels.error') },
  { id: BidderLinesStatus.NOT_CRAWLED, name: i18n.global.t('labels.notCrawled') },
  { id: BidderLinesStatus.NOT_APPLICABLE, name: i18n.global.t('labels.notApplicable') }
]

export function getStatusLabel (value: string | null): string {
  switch (value) {
    case BidderLinesStatus.OK: return i18n.global.t('labels.valid')
    case BidderLinesStatus.WARNING: return i18n.global.t('labels.warning')
    case BidderLinesStatus.INFO: return i18n.global.t('labels.info')
    case BidderLinesStatus.ERROR: return i18n.global.t('labels.error')
    case BidderLinesStatus.NOT_CRAWLED: return i18n.global.t('labels.notCrawled')
    case BidderLinesStatus.NOT_APPLICABLE: return i18n.global.t('labels.notApplicable')
    default: return ''
  }
}

export function getStatusIcon (value: string | null): any {
  switch (value) {
    case BidderLinesStatus.OK: return CheckCircleIcon
    case BidderLinesStatus.WARNING: return ExclamationTriangleIcon
    case BidderLinesStatus.INFO: return InformationCircleIcon
    case BidderLinesStatus.ERROR: return ExclamationCircleIcon
    case BidderLinesStatus.NOT_APPLICABLE: return NoSymbolIcon
    default: return undefined
  }
}
