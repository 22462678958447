import NotificationError from '../components/Notifications/NotificationError.vue'
import NotificationInfo from '../components/Notifications/NotificationInfo.vue'
import NotificationSuccess from '../components/Notifications/NotificationSuccess.vue'
import NotificationWarning from '../components/Notifications/NotificationWarning.vue'

export const notificationTypes = {
  success: NotificationSuccess,
  error: NotificationError,
  warning: NotificationWarning,
  info: NotificationInfo
}

export interface NotificationAction {
  label: string
  fn(): void
}
export interface Notification {
  title?: string
  message?: string
  type: keyof typeof notificationTypes
  duration?: number
  action?: NotificationAction
}
