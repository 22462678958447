<template>
  <div
    v-if="validation.name.$errors.length"
    class="bg-red-100 text-red-600 text-sm mb-2 px-2 py-0.5 rounded"
  >
    {{ t('views.validationName') }}
  </div>
  <form
    method="POST"
    class="flex flex-col space-y-2"
    @submit.prevent="onSubmit()"
  >
    <input
      v-model="viewForm.name"
      class="py-0.5 px-2 rounded text-sm border-none focus:ring-0 bg-gray-200 w-full"
      type="text"
      :placeholder="t('labels.name')"
    >
    <select
      v-model="viewForm.scope"
      class="py-0.5 px-2 rounded text-sm border-none focus:ring-0 bg-gray-200"
    >
      <option :value="ScopeType.PUBLIC">
        {{ t('views.public') }}
      </option>
      <option :value="ScopeType.PRIVATE">
        {{ t('views.private') }}
      </option>
    </select>

    <select
      v-if="userIsService()"
      v-model="viewForm.context"
      class="py-0.5 px-2 rounded text-sm border-none focus:ring-0 bg-gray-200"
    >
      <option :value="ContextType.WITH">
        {{ t('views.withContext') }}
      </option>
      <option :value="ContextType.WITHOUT">
        {{ t('views.noContext') }}
      </option>
    </select>

    <AppButton
      type="submit"
      appearance="primary"
      size="sm"
      :is-fullwidth="true"
      :is-loading="viewForm.isLoading"
    >
      <span v-if="editableView">{{ t('actions.edit') }}</span>
      <span v-else>{{ t('actions.create') }}</span>
    </AppButton>
  </form>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core'
import { maxLength, minLength, required } from '@vuelidate/validators'
import { PropType, computed, defineComponent, onMounted, reactive } from 'vue'
import { useI18n } from 'vue-i18n'

import { ServerFilters } from '@/plugins/filters/filters'
import { userIsService } from '@/plugins/permissions'

import { useContextStore } from '@/store/context.store'
import { useProfileStore } from '@/store/profile.store'
import { useViewStore } from '@/store/view.store'

import { CreateView, UpdateView, View } from '@/models/view'

import AppButton from '@/components/Buttons/AppButton.vue'

enum ScopeType {
  PUBLIC = 'public',
  PRIVATE = 'private'
}

enum ContextType {
  WITH = 'with',
  WITHOUT = 'without'
}

export default defineComponent({
  components: {
    AppButton
  },
  props: {
    editableView: {
      type: Object as PropType<View>,
      required: false,
      default: null
    },
    filters: {
      type: Object as PropType<ServerFilters>,
      required: true
    }
  },
  emits: ['update:filters', 'update:editableView', 'updateViewType', 'update:view'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const contextStore = useContextStore()
    const viewStore = useViewStore()
    const profileStore = useProfileStore()

    const filtersController = computed({
      get () {
        return props.filters
      },
      set (filters: ServerFilters) {
        emit('update:filters', filters)
      }
    })

    // Context
    const hasContext = computed(() => {
      return contextStore.hasContext
    })

    const getContextType = computed(() => {
      return contextStore.contextType
    })

    const getContextId = computed(() => {
      return contextStore.contextId
    })

    // Form
    const viewForm = reactive({
      id: 0,
      name: '',
      scope: ScopeType.PRIVATE,
      context: ContextType.WITH,
      isLoading: false
    })

    const rules = computed(() => ({
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100)
      }
    }))

    const validation = useVuelidate(rules, viewForm)

    onMounted(() => {
      if (props.editableView) {
        viewForm.id = props.editableView.id
        viewForm.name = props.editableView.name

        if (props.editableView.userId) {
          viewForm.scope = ScopeType.PRIVATE
        } else {
          viewForm.scope = ScopeType.PUBLIC
        }

        if (props.editableView.resourceId) {
          viewForm.context = ContextType.WITH
        } else {
          viewForm.context = ContextType.WITHOUT
        }
      } else {
        const currentViewName = filtersController.value.currentView.value?.name
        if (currentViewName) {
          viewForm.name = `(Copy) ${currentViewName}`
        }
      }
    })

    const onSubmit = () => {
      if (props.editableView) {
        onEditView()
      } else {
        onCreateView()
      }
    }

    const onEditView = async () => {
      if (!await validation.value.$validate()) {
        return
      }

      viewForm.isLoading = true

      const form: UpdateView = {
        name: viewForm.name,
        content: filtersController.value.viewContent
      }

      if (viewForm.scope === ScopeType.PRIVATE) {
        if (profileStore.profile) {
          form.userId = profileStore.profile.id
        }
      }

      if (viewForm.context === ContextType.WITH) {
        if (hasContext.value) {
          form.resourceType = getContextType.value
          form.resourceId = getContextId.value
        }
      }

      const view = await viewStore.updateView({
        id: viewForm.id,
        form
      })

      viewForm.isLoading = false

      emit('updateViewType', 'LIST')
      emit('update:editableView', null)
      emit('update:view', view as View)
    }

    const onCreateView = async () => {
      if (!await validation.value.$validate()) {
        return
      }

      viewForm.isLoading = true

      const form: CreateView = {
        name: viewForm.name,
        content: filtersController.value.viewContent,
        pageContext: filtersController.value.tableName
      }

      if (viewForm.scope === ScopeType.PRIVATE) {
        if (profileStore.profile) {
          form.userId = profileStore.profile.id
        }
      }

      if (viewForm.context === ContextType.WITH) {
        if (hasContext.value) {
          form.resourceType = getContextType.value
          form.resourceId = getContextId.value
        }
      }

      await viewStore.createView(form)

      viewForm.isLoading = false

      emit('updateViewType', 'LIST')
    }

    return {
      t,

      viewForm,
      ContextType,
      ScopeType,

      onSubmit,

      userIsService,
      validation
    }
  }
})
</script>
