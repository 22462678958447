import { AxiosResponse } from 'axios'

import { Paginate } from '@/types/paginate'

import api from '../api'
import { Dashboard, DashboardNavigation, CreateDashboardForm, UpdateDashboardForm } from '../models/dashboard'

export async function fetchDashboards (queryParams?: Object): Promise<AxiosResponse<Paginate<DashboardNavigation[]>>> {
  return api.get<Paginate<DashboardNavigation[]>>('/dashboards', { requireContext: true, params: queryParams })
}

export async function fetchDashboardById (id: string): Promise<AxiosResponse<Dashboard>> {
  return await api.get<Dashboard>(`/dashboards/${id}`, { requireContext: true })
}

export async function createDashboard (form: CreateDashboardForm): Promise<AxiosResponse<Dashboard>> {
  return await api.post<Dashboard>('/dashboards', form, { requireContext: true })
}

export async function updateDashboard (id: number, form: UpdateDashboardForm): Promise<AxiosResponse<void>> {
  return await api.patch(`/dashboards/${id}`, form)
}

export async function deleteDashboard (id: number): Promise<AxiosResponse<void>> {
  return await api.delete(`/dashboards/${id}`)
}
