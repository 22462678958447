<template>
  <NotificationWrapper>
    <div class="flex items-start p-4 bg-blue-50">
      <div>
        <InformationCircleIcon
          class="w-6 h-6 text-blue-600"
          aria-hidden="true"
        />
      </div>
      <div class="flex items-start flex-col ml-3 w-0 flex-1 pt-0.5 gap-2.5">
        <p class="text-sm font-medium text-gray-900">
          {{ notification.title || t('notifications.infoDefaultTitle') }}
        </p>
        <p class="text-sm text-gray-600">
          {{ notification.message }}
        </p>
        <button
          v-if="notification.action"
          class="bg-transparent border border-blue-600 text-blue-600 hover:text-blue-800 hover:border-blue-800 active:text-blue-900 active:border-blue-900 rounded-lg py-1.5 px-2.5 text-sm leading-4"
          @click.stop.prevent="notification.action?.fn"
        >
          <span class="group-hover:underline group-focus:underline">{{ notification.action?.label }}</span>
        </button>
      </div>
      <div class="ml-4 shrink-0">
        <NotificationClose
          class="bg-blue-50"
          :notification="notification"
        />
      </div>
    </div>
    <NotificationLoading
      loading-class="bg-blue-600"
      :notification="notification"
    />
  </NotificationWrapper>
</template>

<script lang="ts">
import { InformationCircleIcon } from '@heroicons/vue/24/solid'
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

import { Notification } from '@/types/notifications'

import NotificationWrapper from './Notification.vue'
import NotificationClose from './NotificationClose.vue'
import NotificationLoading from './NotificationLoading.vue'

export default defineComponent({
  components: {
    NotificationWrapper,
    InformationCircleIcon,
    NotificationClose,
    NotificationLoading
  },
  props: {
    notification: {
      type: Object as PropType<Notification>,
      required: true
    }
  },
  setup () {
    const { t } = useI18n()

    return { t }
  }
})
</script>
