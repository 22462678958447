<template>
  <svg
    viewBox="0 0 15 13"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 9.75H9.16667V11.375H12.5V13L15 10.5625L12.5 8.125V9.75ZM6.66667 0C4.83333 0 3.33333 1.4625 3.33333 3.25C3.33333 5.0375 4.83333 6.5 6.66667 6.5C8.5 6.5 10 5.0375 10 3.25C10 1.4625 8.5 0 6.66667 0ZM6.66667 8.125C3 8.125 0 9.5875 0 11.375V13H7.91667C7.66667 12.35 7.5 11.7 7.5 10.9688C7.5 9.99375 7.75 9.1 8.25 8.20625C7.75 8.20625 7.25 8.125 6.66667 8.125Z"
    />
  </svg>
</template>
