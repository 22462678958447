import i18n from '@/plugins/i18n'

import { useAuthStore } from '@/store/auth.store'
import { useNotificationsStore } from '@/store/notifications.store'

import { logout } from '@/services/auth'

/**
 * logoutGuard
 * Make a request to initate the logout process
 * @success Redirect to login
 * @error Redirect to home with a Notification Error
 * @returns RouteName
 */
export async function logoutGuard (): Promise<{name: string}> {
  const notificationsStore = useNotificationsStore()
  const authStore = useAuthStore()

  if (authStore.isLogged) {
    try {
      await logout()
    } catch (err) {
      notificationsStore.add({
        title: i18n.global.t('labels.error'),
        message: i18n.global.t('auth.logoutError'),
        type: 'error'
      })
    }
  }
  await authStore.logout()
  return { name: 'login' }
}
