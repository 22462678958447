<template>
  <Panel
    :after-close="afterClose"
    :title="dashboardId ? t('dashboards.duplicateDashboard') : t('dashboards.createDashboard')"
    :is-loading="dashboardId !== undefined && loading"
  >
    <PanelForm
      :on-submit="onSubmit"
      :on-cancel="reset"
      :is-submitting="state.isSubmitting"
      :is-disabled="!state.isOkToSubmit"
      :is-form-type-create="true"
      :with-cancel-confirm="validation.$anyDirty"
    >
      <FormDashboard
        v-model="form"
        :errors="errors"
      />
      <div class="p-5">
        <p class="py-2 pl-3 pr-2 text-base border-l-4 border-primary-500 bg-primary-100 text-primary-600">
          {{ accessibilityNotice }}
        </p>
      </div>
    </PanelForm>
  </Panel>
</template>

<script lang="ts">
import { required } from '@vuelidate/validators'
import { PropType, computed, defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRequest } from 'vue-request'
import { useRouter } from 'vue-router'

import { FormType } from '@/types/form'

import { useContext } from '@/plugins/context'
import { ContextType } from '@/plugins/context/context.enum'
import { DEFAULT_MAX_DAYS_RANGE } from '@/plugins/dashboard'
import { useForm } from '@/plugins/form'

import { useAppStore } from '@/store/app.store'
import { useContextStore } from '@/store/context.store'
import { useNotificationsStore } from '@/store/notifications.store'

import { CreateDashboardForm, Dashboard, DashboardForm } from '@/models/dashboard'
import { DashboardType } from '@/models/enum/dashboard'

import { createDashboard, fetchDashboardById } from '@/services/dashboards'

import Panel from '@/components/Panel/Panel.vue'
import PanelForm from '@/components/Panel/PanelForm.vue'

import FormDashboard from '../components/FormDashboard.vue'

export default defineComponent({
  components: {
    Panel,
    PanelForm,
    FormDashboard
  },
  props: {
    dashboardId: { // If this prop is provided, the form will be in "duplicate" mode
      type: String as PropType<string | undefined>,
      default: () => undefined
    },
    afterClose: {
      type: Function,
      required: true
    }
  },
  setup (props) {
    const { t } = useI18n()
    const router = useRouter()

    const contextStore = useContextStore()
    const appStore = useAppStore()
    const notificationsStore = useNotificationsStore()

    const dashboard = ref<Dashboard | undefined>(undefined)

    const { run, loading } = useRequest(fetchDashboardById, {
      defaultParams: [props.dashboardId || ''],
      manual: true,
      onSuccess: (response) => {
        if (response && response.data) {
          dashboard.value = response.data
        }
      },
      onError: (e: any) => {
        if (e.response?.status === 429) {
          return
        }
        notificationsStore.add({
          message: t('messages.fetchError'),
          type: 'error'
        })

        appStore.closePanel()
      }
    })

    watch(
      () => props.dashboardId,
      () => {
        if (props.dashboardId) {
          run(props.dashboardId)
        }
      },
      { immediate: true }
    )

    const { form, state, validation, errors, onSubmit, reset } = useForm<DashboardForm>({
      defaultForm: computed(() => {
        const f: DashboardForm = {
          name: dashboard.value ? t('dashboards.copy', [dashboard.value.name]) : '',
          isPublic: false,
          dashboardType: contextStore.contextType as string as DashboardType
        }
        return f
      }),
      formType: FormType.CREATE,
      submitHandler: (form) => {
        const f: CreateDashboardForm = {
          name: form.name!,
          dashboardType: form.dashboardType || (contextStore.contextType as string as DashboardType),
          isPublic: form.isPublic,
          isActive: true,
          config: dashboard.value
            ? dashboard.value.config
            : {
                maxDaysRange: DEFAULT_MAX_DAYS_RANGE,
                sources: [],
                stores: [],
                widgets: [],
                layout: { sm: [], md: [] }
              }
        }
        return createDashboard(f)
      },
      rules: () => computed(() => ({
        name: {
          required
        }
      })),
      onSuccess: (response) => {
        notificationsStore.add({
          title: t('labels.success'),
          message: t('dashboards.created'),
          type: 'success',
          duration: 4000
        })

        // Redirect
        if (contextStore.hasContext && response?.data?.id !== undefined) {
          router.push(useContext('dashboards.show', { dashboardId: response.data.id.toString() }).value)
        }
      },
      onError: (e) => {
        console.error(e)
        notificationsStore.add({
          title: t('labels.error'),
          message: t('dashboards.dashboardCreationError', [e.message]),
          type: 'error'
        })
      }
    })

    const accessibilityNotice = computed(() => {
      if (form.isPublic) {
        let contextType = ''
        switch (form.dashboardType) {
          case DashboardType.PUBLISHERS:
            contextType = 'app.publisher'
            break
          case DashboardType.BIDDERS:
            contextType = 'app.bidder'
            break
          case DashboardType.GROUPS:
            contextType = 'app.serviceGroup'
            break
          case DashboardType.GROUP_BIDDERS:
            contextType = 'app.bidderGroup'
            break
          case DashboardType.GROUP_PUBLISHERS:
            contextType = 'app.publisherGroup'
            break
        }
        return t('dashboards.accessibilityNoticePublic', [t(contextType, 2)])
      }

      let contextType = ''
      let contextName = ''
      switch (contextStore.contextType) {
        case ContextType.PUBLISHERS:
          contextType = 'app.publisher'
          contextName = contextStore.selectedPublisher!.name
          break
        case ContextType.BIDDERS:
          contextType = 'app.bidder'
          contextName = contextStore.selectedBidder!.name
          break
        case ContextType.GROUPS:
          contextType = 'app.group'
          contextName = contextStore.selectedGroup!.name
          break
      }
      return t('dashboards.accessibilityNotice', [t(contextType), contextName])
    })

    return {
      FormType,
      form,
      validation,
      errors,
      state,
      loading,
      accessibilityNotice,
      onSubmit,
      reset,
      t
    }
  }
})
</script>
