/**
 * This list has been copied from SSPEngine.
 */
const sizesWhitelist = [
  '1800x1000',

  '970x250',
  '970x90',

  '930x180',

  '800x250',

  '728x90',

  '336x280',

  '320x480',
  '320x320',
  '320x160',
  '320x100',
  '320x50',

  '300x600',
  '300x300',
  '300x250',
  '300x100',
  '300x50',

  '250x250',

  '160x600',

  '120x600'
]

const formated = sizesWhitelist.map(s => {
  return {
    id: s,
    name: s
  }
})

export default formated
