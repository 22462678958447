import { ISegment } from './segmentio.interface'

class Segment implements ISegment {
  constructor () {
    if (!window.analytics) {
      throw new Error('Segment script is not installed.')
    }
  }

  load (): void {
    const writeKey = import.meta.env.VITE_SEGMENT_WRITE_KEY
    window.analytics._writeKey = writeKey
    window.analytics.load(writeKey)
  }

  identify (userId: string, traits?: Object): void {
    window.analytics.identify(userId, traits)
  }

  page (category?: string, name?: string, properties?: Object): void {
    window.analytics.page(category, name, properties)
  }

  group (groupId?: string, properties?: Object): void {
    window.analytics.group(groupId, properties)
  }

  track (event: string, properties?: Record<string, any>): void {
    if (properties) {
      const groupId = window.analytics._group?.id()
      if (groupId) {
        properties.groupId = groupId
      }
    }
    window.analytics.track(event, properties)
  }
}
export default new Segment()
