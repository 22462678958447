<template>
  <div
    class="flex flex-row w-full gap-0.5 flex-nowrap"
    v-bind="$attrs"
  >
    <a
      v-for="(segment, i) in segments"
      :key="segment.id"
      :href="`#${segment.id}`"
      :disabled="unref(segment.disabled) || disabled"
      class="px-4 py-1.5 text-sm text-gray-600 bg-white cursor-pointer outline outline-2 outline-offset-0 outline-gray-400 first:rounded-l-lg last:rounded-r-lg hover:text-text-primary hover:bg-primary-50 focus:bg-primary-50"
      :class="{
        '!bg-primary-100 !outline-primary-300 !text-text-primary': selectedSegment === segment.id && !(unref(segment.disabled) || disabled),
        '!bg-primary-50 ': selectedSegment === segment.id && (unref(segment.disabled) || disabled),
        '!text-gray-400 !cursor-not-allowed hover:bg-white': unref(segment.disabled) || disabled
      }"
      :style="{
        'z-index': selectedSegment === segment.id ? segments.length : segments.length-i
      }"
      @click.stop.prevent="setSelectedSegment(segment.id)"
    >
      {{ segment.label }}
    </a>
  </div>
  <slot
    v-if="selectedSegment"
    :name="selectedSegment"
  />
</template>

<script lang="ts">
import { PropType, defineComponent, onMounted, ref, unref, watch } from 'vue'
import { useRoute } from 'vue-router'

import { SegmentTemplate } from './types'

// Example usage:
/*
<SegmentControl
  :segments="[
    {id: 'segment-1', label: t('...'), disabled: computed(() => false)},
    {id: 'segment-2', label: t('...'), disabled: true},
    {id: 'segment-3', label: t('...'), disabled: computed(() => variable)}
  ]"
>
  <template #segment-1>Hello world</template>
  <template #segment-2>Hello there</template>
</SegmentControl>
*/

export default defineComponent({
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String as PropType<string | null>,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    disableFragment: {
      type: Boolean,
      required: false,
      default: false
    },
    segments: {
      type: Array as PropType<SegmentTemplate[]>,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const currentRoute = useRoute()

    const selectedSegment = ref<string | null>(props.segments.length ? props.segments[0].id : null)
    watch(
      () => selectedSegment.value,
      () => emit('update:modelValue', selectedSegment.value)
    )

    const updateFragment = () => {
      if (props.disabled || props.disableFragment) {
        return
      }
      history.replaceState(history.state, '', selectedSegment.value ? `#${selectedSegment.value}` : '')
    }

    const setSelectedSegment = (id: string | null) => {
      if (props.disabled) {
        return
      }
      const segment = props.segments.find(s => s.id === id)
      selectedSegment.value = segment && !unref(segment.disabled) ? id : null
      updateFragment()
    }

    onMounted(() => {
      if (props.disabled || props.disableFragment) { return }
      if (currentRoute.hash && props.segments.map(t => t.id).includes(currentRoute.hash.slice(1))) {
        setSelectedSegment(currentRoute.hash.slice(1))
      }
    })

    return {
      selectedSegment,
      setSelectedSegment,
      unref
    }
  }
})
</script>
