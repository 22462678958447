import { RouteLocationNormalized } from 'vue-router'

import i18n from '@/plugins/i18n'

import { useAuthStore } from '@/store/auth.store'
import { useInviteStore } from '@/store/invite.store'
import { useNotificationsStore } from '@/store/notifications.store'
import { useProfileStore } from '@/store/profile.store'

import { fetchInviteByToken } from '@/services/invites'
import { fetchProfile } from '@/services/profile'

export const inviteGuard = async (to: RouteLocationNormalized): Promise<boolean | {name: string}> => {
  const profileStore = useProfileStore()
  const notificationsStore = useNotificationsStore()
  const authStore = useAuthStore()

  const inviteStore = useInviteStore()
  const token = to.query.token as string

  if (token) {
    try {
      const response = await fetchInviteByToken(token)

      if (response && response.data) {
        inviteStore.setCurrentInvite(response.data)
      }

      if (inviteStore.currentInvite) {
        if (authStore.isLogged) {
          const response = await fetchProfile()

          if (response && response.data) {
            profileStore.setProfile(response.data)
          }
        }
      }

      return true
    } catch (err: any) {
      if (err.response) {
        if (err.response.status) {
          // Token is expired...
          if (err.response.status === 410) {
            notificationsStore.add({
              title: i18n.global.t('labels.invitation'),
              message: i18n.global.t('invitations.errorExpired'),
              type: 'error'
            })

            return { name: 'login' }
          }

          // Token is not found
          if (err.response.status === 404) {
            notificationsStore.add({
              title: i18n.global.t('labels.invitation'),
              message: i18n.global.t('invitations.errorNotFound'),
              type: 'error'
            })

            return { name: 'login' }
          }

          // Token is lock
          if (err.response.status === 423) {
            notificationsStore.add({
              title: i18n.global.t('labels.invitation'),
              message: i18n.global.t('invitations.errorUsed'),
              type: 'error'
            })

            return { name: 'login' }
          }
        }
      }
    }
  }

  return { name: 'login' }
}

export const authInviteGuard = async (to: RouteLocationNormalized): Promise<boolean> => {
  const token = to.query.token as string
  const inviteStore = useInviteStore()

  if (token) {
    try {
      const response = await fetchInviteByToken(token)

      if (response && response.data) {
        inviteStore.setCurrentInvite(response.data)
      }
    } catch (e) {
      return true
    }
  }

  return true
}
