<template>
  <div>
    <p class="px-4 mb-2 text-sm font-medium text-center text-text-primary">
      {{ t('views.delete', { name: editableView.name }) }}
    </p>
    <AppButton
      appearance="danger"
      type="button"
      size="sm"
      :is-fullwidth="true"
      @click="onDelete(editableView)"
    >
      {{ t('actions.delete') }}
    </AppButton>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { ServerFilters } from '@/plugins/filters/filters'

import { useViewStore } from '@/store/view.store'

import { View } from '@/models/view'

import AppButton from '@/components/Buttons/AppButton.vue'

export default defineComponent({
  components: {
    AppButton
  },
  props: {
    editableView: {
      type: Object as PropType<View>,
      required: false,
      default: null
    },
    filters: {
      type: Object as PropType<ServerFilters>,
      required: true
    }
  },
  emits: ['update:filters', 'updateViewType', 'updateEditView'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const viewStore = useViewStore()

    const filtersController = computed({
      get () {
        return props.filters
      },
      set (filters: ServerFilters) {
        emit('update:filters', filters)
      }
    })

    const currentView = computed(() => filtersController.value.currentView.value)

    const resetView = () => {
      filtersController.value.currentView.value = null
    }

    const isLoading = ref(false)

    const onDelete = async (view: View) => {
      isLoading.value = true

      await viewStore.deleteView(view.id)

      if (currentView.value) {
        if (currentView.value.id === view.id) {
          resetView()
        }
      }

      isLoading.value = false

      emit('updateEditView', null)
      emit('updateViewType', 'LIST')
    }

    return {
      t,
      isLoading,
      onDelete
    }
  }
})
</script>
