import { ComparableObject } from 'crossfilter2'

import { toUTC } from '@/utils/dates'

import { deserializeDimensions, Dimension, SerializedDimension } from '.'

export const COMPOSITE_DIMENSION_SEPARATOR = ' - '

/**
 * An object that can be used as NaturallyOrderedValue for crossfilter grouping key.
 */
export class CompositeDimensionKey implements ComparableObject {
  key: Record<string, any>

  constructor (key: Record<string, any>) {
    this.key = key
  }

  valueOf (): string {
    return Object.values(this.key)
      .map(v => {
        if (v instanceof Date) {
          return toUTC(v, 'yyyy-MM-dd HH:mm')
        }
        return v
      })
      .join(COMPOSITE_DIMENSION_SEPARATOR)
  }
}

export class CompositeDimension implements Dimension {
  dimensions: Dimension[]
  name: string
  column: string
  enrichment: boolean

  constructor (...dimensions: Dimension[]) {
    this.dimensions = dimensions
    this.enrichment = this.dimensions.some(d => d.enrichment)
    this.name = this.dimensions.map(d => d.name).join(COMPOSITE_DIMENSION_SEPARATOR)
    this.column = this.dimensions.map(d => d.column).join(COMPOSITE_DIMENSION_SEPARATOR)
  }

  selector (d: Record<string, any>): CompositeDimensionKey {
    const key: Record<string, any> = {}
    this.dimensions.forEach(dim => {
      key[dim.name] = dim.selector(d)
    })
    return new CompositeDimensionKey(key)
  }

  initCompositeDimension (r: Record<string, any>): void {
    this.dimensions.forEach(d => {
      r[d.name] = null
    })
  }

  serialize (): SerializedDimension {
    return {
      name: this.name,
      params: {
        dimensions: this.dimensions.map(d => d.serialize())
      },
      constructor: 'CompositeDimension'
    }
  }

  static deserialize (d: SerializedDimension): CompositeDimension {
    if (d.params === undefined || !Array.isArray(d.params.dimensions)) {
      throw new Error('Invalid SerializedDimension (CompositeDimension): missing parameters')
    }
    return new CompositeDimension(...(deserializeDimensions(d.params.dimensions)))
  }
}
