<template>
  <div :class="wrapperClass">
    <Tooltip
      :disabled="(fieldErrors?.$errors?.length || 0) <= 0"
      :closeable="true"
      :force-show="true"
    >
      <div
        v-if="mode === 'default'"
        class="flex flex-col"
      >
        <label
          :for="idValue"
          class="flex w-full text-xs font-semibold text-text-primary"
          :aria-label="label"
        >
          <span
            v-if="label"
            :class="[
              'mb-2',
              $attrs.required || isRequired ? `after:content-['*']` : ''
            ]"
          >
            {{ label }}
          </span>
          <span
            v-if="hints.textTop"
            class="ml-auto mb-2 text-xs font-normal text-slate-400"
          > {{ hints.textTop }}</span>
        </label>
      </div>

      <div class="relative flex flex-col">
        <select
          :id="idValue"
          v-model="value"
          :name="name"
          v-bind="$attrs"
          :disabled="!!($attrs.disabled) || !!($attrs.readonly)"

          :class="[
            inputClasses,
            mode === 'materialize' ? 'peer order-2 transition-all focus:pt-[1.375rem] focus:pb-1 focus:font-semibold' : '',
            withIcon ? 'pl-10' : '',
          //value ? 'invisible' : 'visible'
          ]"

          :aria-invalid="errors.length ? true : undefined"
          :aria-describedby="errors.length ? `${name}-error` : undefined"
          :aria-required="isRequired && !$attrs.required ? true : undefined"
        >
          <option
            v-for="option in localOptions"
            :key="option.id"
            :value="option.id"
            :selected="value === option.id"
          >
            {{ option.name }}
          </option>
        </select>
        <div
          v-if="mode=== 'materialize'"
          class="order-1 absolute z-20 top-px left-px pointer-events-none transition-all flex items-center h-[calc(100%_-_2px)] pl-5 p-2.5 text-base leading-none text-text-primary font-semibold rounded-l-lg peer-focus:text-xs peer-focus:-top-2 peer-focus:font-normal"
          :class="(value !== '') ? '!text-xs !-top-2 !font-normal' : ' bg-white'"
        >
          <label
            :for="idValue"
            :class="[
              $attrs.required || isRequired ? `after:content-['*']` : ''
            ]"
          >{{ label }}</label>
          <span
            v-if="hints.textTop"
            class="ml-auto mb-2 text-xs font-normal text-slate-400"
          > {{ hints.textTop }}</span>
        </div>
        <span v-if="withIcon">
          <slot
            name="icon"
            :classes="'absolute top-1/2 -mt-2 left-2 h-4 w-4 text-gray-500'"
          />
        </span>
      </div>

      <template
        v-if="(fieldErrors?.$errors?.length || 0) > 0"
        #title
      >
        <div class="flex flex-col gap-0.5">
          <p
            v-for="(err, i) in fieldErrors!.$errors"
            :key="i"
            class="text-xs font-normal inline-block"
          >
            {{ err }}
          </p>
        </div>
      </template>
    </Tooltip>
    <FormHelpText
      v-if="hints.textBottom"
      :help-text="hints.textBottom"
    />
  </div>
</template>

<script lang="ts">
import { ErrorObject } from '@vuelidate/core'
import { defineComponent, computed, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useVuelidateError } from '@/plugins/form'

import { randomString } from '@/utils/utils'

import Tooltip from '@/components/Tooltip/Tooltip.vue'

import FormHelpText from './FormHelpText.vue'

import { FormInputHints, FormInputMode } from '.'

export default defineComponent({
  components: {
    Tooltip,
    FormHelpText
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: [String, Number],
      required: false,
      default: ''
    },
    options: {
      type: Array as PropType<Array<{ id: number | string, name: string }>>,
      required: true
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    errors: {
      type: Array as PropType<ErrorObject[]>,
      required: false,
      default: () => []
    },
    wrapperClass: {
      type: String,
      required: false,
      default: ''
    },
    onSelect: {
      type: Function,
      required: false,
      default: () => {}
    },
    emptyValue: {
      type: [Number, String, Boolean] as PropType<string | number | boolean>,
      required: false,
      default: () => true
    },
    mode: {
      type: String as PropType<FormInputMode>,
      required: false,
      default: 'materialize'
    },
    hints: {
      type: Object as PropType<FormInputHints>,
      required: false,
      default: () => ({})
    },
    withIcon: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit, attrs }) {
    const { t } = useI18n()
    const idValue = `${props.name}-${randomString(12)}`

    const localOptions = ref<Array<{ id: number | string, name: string }>>([])

    if (typeof props.emptyValue === 'boolean' && props.emptyValue) {
      localOptions.value.push({ id: '', name: t('labels.selectEmptyValue') })
    }

    localOptions.value = localOptions.value.concat(props.options)

    const value = computed({
      get () {
        return props.modelValue
      },
      /*
        @TODO Not the best way to perform this
      */
      set (value: any) {
        props.onSelect(value)
        emit('update:modelValue', value)
      }
    })

    const inputClasses = computed(() => {
      const css = [
        'block', 'w-full', 'rounded-lg', 'pl-5 p-2.5',
        'text-sm', 'text-text-primary',
        'border', 'border-gray-border',
        'outline-none', 'focus:outline-none',
        'ring-0', 'focus:ring-0',
        'placeholder:text-slate-400', 'placeholder:font-normal',
        'disabled:bg-gray-200'
      ]

      switch (props.mode) {
        case 'default':
          break
        case 'materialize':
          css.push('h-12.125')
          break
      }

      if (!attrs.readonly) {
        css.push('focus:border-primary-500')
      }

      if (value.value !== '' && props.mode === 'materialize') {
        css.push('pt-[1.375rem]', 'pb-1', 'font-semibold')
      } else {
        css.push('font-normal')
      }

      if (fieldErrors.value?.$errors?.length) {
        css.push('!border-red-400')
      }

      return css.join(' ')
    })

    const fieldErrors = computed(() => useVuelidateError(props.errors))

    return {
      idValue,
      inputClasses,
      localOptions,
      value,
      fieldErrors
    }
  }
})
</script>
