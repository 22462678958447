<template>
  <div class="flex-1 flex items-center gap-2.5">
    <Badge
      is="div"
      v-if="status"
      :icon="getStatusIcon(status)"
      :theme="theme"
    >
      <span
        class="flex-1 truncate"
        :title="getStatusLabel(status)"
      >
        {{ getStatusLabel(status) }}
      </span>
    </Badge>
    <Badge
      is="div"
      v-if="!!(status11)"
      :icon="getStatusIcon(status11)"
      :theme="theme11"
    >
      <span
        class="flex-1 truncate"
        :title="getStatusLabel(status11)"
      >
        {{ getStatusLabel(status11) }} 1.1
      </span>
    </Badge>
    <slot />
  </div>
</template>

<script lang="ts">

import { computed, defineComponent, PropType } from 'vue'

import { getStatusLabel, getStatusIcon, WebsiteStatus } from '@/models/enum/websiteStatus'

import Badge from '@/components/Badge/Badge.vue'
import { websiteStatusBadgeTheme, websiteStatusBadgeTheme11 } from '@/components/SupplyChain/supplyChain'

export default defineComponent({
  components: { Badge },
  props: {
    status: {
      type: String as PropType<WebsiteStatus | null>,
      default: null
    },
    status11: {
      type: String as PropType<WebsiteStatus | null>,
      required: false,
      default: null
    }
  },
  setup (props) {
    const theme = computed(() => websiteStatusBadgeTheme(props.status))
    const theme11 = computed(() => websiteStatusBadgeTheme11(props.status11))

    return {
      theme,
      theme11,
      getStatusLabel,
      getStatusIcon
    }
  }
})
</script>
