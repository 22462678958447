import { AxiosResponse } from 'axios'

import { BulkUpdateForm } from '@/models/bulk'

import api from '../api'
import { Report } from '../models/report'
import { Paginate } from '../types/paginate'

import { fetchForSelectFilters, FilterResponse } from './datatableFilters'

export async function fetchReports (queryParams?: Object): Promise<AxiosResponse<Paginate<Report[]>>> {
  return api.get<Paginate<Report[]>>('/reports', { requireContext: true, params: queryParams })
}

export function fetchReport (id: number): Promise<AxiosResponse<Report>> {
  return api.get<Report>(`/reports/${id}`, { requireContext: true })
}

export async function createReport (data: Report): Promise<AxiosResponse<Report>> {
  return api.post<Report>('/reports', data, { requireContext: true })
}

export function updateReport (id: number, data: Report): Promise<AxiosResponse<Report>> {
  return api.patch<Report>(`/reports/${id}`, data, { requireContext: true })
}

export function updateReportBulk (data: BulkUpdateForm): Promise<AxiosResponse<void>> {
  return api.patch('/reports/bulk', data, { requireContext: true })
}

export async function fetchReportsForSelectFilters (field: string, query: object): Promise<AxiosResponse<FilterResponse[]>> {
  return fetchForSelectFilters('/reports', field, query)
}
