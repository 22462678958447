import { reactive, watch, WatchCallback } from 'vue'

interface MetaPattern {
  [key: string]: string
}

const state: MetaPattern = reactive({})

export const useMeta = (pattern: string): string => {
  if (state[pattern]) {
    return state[pattern]
  }

  return ''
}

export const addMeta = (pattern: string, value: string): void => {
  state[pattern] = value
}

export const removeMeta = (pattern: string): void => {
  delete state[pattern]
}

export function watchMeta (callback: WatchCallback<MetaPattern, MetaPattern>): void {
  watch(state, callback)
}
