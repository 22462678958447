<template>
  <div>
    <div class="grid w-full text-sm grid-cols-vertical-form lg:grid-cols-vertical-form-4 place-items-center-stretch">
      <div class="p-1 font-medium">
        {{ t('labels.baseDimension') }}
      </div>
      <div class="p-1">
        <Multiselect
          v-model="enrichmentDimension"
          name="enrichmentDimension"
          mode="single"
          :close-on-select="true"
          :options="definition.dimensions.filter(d => !definition.hidden.includes(d.name)).map(d => d.name)"
          :classes="multiselectTailwindClassesCompact"
          :can-clear="false"
          :can-deselect="false"
        />
      </div>
      <div class="p-1 font-medium">
        {{ t('labels.enrichmentTable') }}
      </div>
      <div class="p-1">
        <Multiselect
          v-model="enrichmentTable"
          name="enrichmentTable"
          mode="single"
          :close-on-select="true"
          :options="Object.keys(enrichmentTables)"
          :classes="multiselectTailwindClassesCompact"
          :can-clear="false"
          :can-deselect="false"
        />
      </div>
      <div class="p-1 font-medium">
        {{ t('labels.foreignKey') }}
      </div>
      <div class="p-1">
        <Multiselect
          v-model="enrichmentForeignKey"
          name="enrichmentForeignKey"
          mode="single"
          :disabled="enrichmentTableDefinition === undefined"
          :close-on-select="true"
          :options="enrichmentTableDefinition !== undefined ? enrichmentTableDefinition.dimensions.filter(d => !(d instanceof DateDimension) && !(d instanceof DateRollupDimension)).map(d => d.name) : []"
          :classes="multiselectTailwindClassesCompact"
          :can-clear="false"
          :can-deselect="false"
        />
      </div>
      <div class="p-1 font-medium">
        {{ t('labels.foreignDimension') }}
      </div>
      <div class="p-1">
        <Multiselect
          v-model="enrichmentForeignDimension"
          name="enrichmentForeignDimension"
          :disabled="enrichmentTableDefinition === undefined"
          mode="single"
          :close-on-select="true"
          :options="availableForeignDimensions"
          :classes="multiselectTailwindClassesCompact"
          :can-clear="false"
          :can-deselect="false"
        />
      </div>
      <div class="p-1 font-medium">
        {{ t('labels.alias') }}
      </div>
      <div class="p-1">
        <input
          v-model="enrichmentAlias"
          :disabled="enrichmentTableDefinition === undefined"
          :placeholder="t('labels.alias')"
          class="min-w-36 h-7 px-2.5 py-0.5 border border-gray-400 text-gray-700 focus:ring-primary-500 focus:border-primary-500 rounded focus:outline-none w-full"
        >
      </div>
      <div class="col-span-2 p-1">
        <button
          type="button"
          class="bg-green-500 rounded-md py-0.5 px-2 text-white disabled:opacity-50 disabled:cursor-not-allowed"
          :disabled="!validateForeignDimension"
          @click="submitForeignDimension"
        >
          {{ t('dashboards.addForeignDimension') }}
        </button>
      </div>
      <div
        v-if="foreignDimensions.length > 0"
        class="flex flex-row flex-wrap col-span-2 gap-1 p-1 lg:col-span-4"
      >
        <span
          v-for="(s, i) in foreignDimensions"
          :key="s.name"
          class="py-0.5 px-1 bg-gray-200 rounded text-sm break-words cursor-grab select-none inline-block"
        >
          {{ s.alias }} ({{ s.name }})
          <button
            type="button"
            :title="t('actions.remove')"
            class="ml-1"
            @click="removeForeignDimension(i)"
          >❌</button>
        </span>
      </div>
    </div>
    <div>
      <div class="my-1">
        <button
          type="button"
          class="bg-green-500 rounded-md py-0.5 px-2 text-white disabled:opacity-50 disabled:cursor-not-allowed"
          :disabled="!validate"
          @click="onSubmit"
        >
          {{ t('dashboards.addEnrichment') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent, onMounted, ref, unref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { DataDefinition } from '@/plugins/dashboard/definition'
import { DateDimension, DateRollupDimension } from '@/plugins/dashboard/dimensions'
import { CUBE_ENDPOINT, CubeSource, Enrichment, ForeignDimension } from '@/plugins/dashboard/source'

import { useNotificationsStore } from '@/store/notifications.store'

import api from '@/api'
import Multiselect from '@/components/Form/FormMultiselect.vue'
import { multiselectTailwindClassesCompact } from '@/components/Multiselect'

export default defineComponent({
  components: {
    Multiselect
  },
  props: {
    definition: {
      type: Object as PropType<DataDefinition>,
      required: true
    }
  },
  emits: ['submit'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const notificationsStore = useNotificationsStore()

    // Data
    const enrichmentTables = ref({} as Record<string, DataDefinition>)
    const enrichmentTableDefinition = ref(undefined as DataDefinition | undefined)
    const enrichmentTable = ref('')
    const enrichmentDimension = ref('')
    const enrichmentForeignKey = ref('')
    const enrichmentForeignDimension = ref('')
    const enrichmentAlias = ref('')
    const foreignDimensions = ref([] as ForeignDimension[])

    // Lifecycle
    onMounted(async () => {
      const source = new CubeSource('SourceForm', 'SourceForm', api.contextURL(CUBE_ENDPOINT, `${import.meta.env.VITE_DATA_API_BASE_URL}/api/v1`), '')
      source.getExistingEnrichmentTables()
        .then(t => {
          enrichmentTables.value = t
        })
        .catch((e: any) => {
          notificationsStore.add({
            message: t('messages.fetchErrorWithMsg', [e.message]),
            type: 'error'
          })
        })
    })

    // Computed
    const availableForeignDimensions = computed(() => {
      return enrichmentTableDefinition.value !== undefined
        ? enrichmentTableDefinition.value.dimensions.filter(d => !(d instanceof DateDimension) && !(d instanceof DateRollupDimension) && !foreignDimensions.value.some(dim => dim.name === d.name)).map(d => d.name)
        : []
    })

    const validate = computed((): boolean => {
      return enrichmentDimension.value !== '' && enrichmentTable.value !== '' && enrichmentForeignKey.value !== '' && foreignDimensions.value.length > 0
    })

    const validateForeignDimension = computed((): boolean => {
      return enrichmentForeignDimension.value !== '' &&
        enrichmentAlias.value !== '' &&
        !props.definition.dimensions.some(d => d.name === enrichmentAlias.value) &&
        !foreignDimensions.value.some(fd => fd.alias === enrichmentAlias.value)
    })

    // Methods
    const removeForeignDimension = (i: number) => {
      foreignDimensions.value.splice(i, 1)
    }

    const submitForeignDimension = () => {
      foreignDimensions.value.push({
        name: enrichmentForeignDimension.value,
        alias: enrichmentAlias.value
      })
      enrichmentForeignDimension.value = ''
      enrichmentAlias.value = ''
    }

    const onSubmit = () => {
      emit('submit', {
        dimension: unref(enrichmentDimension),
        table: unref(enrichmentTable),
        foreignKey: unref(enrichmentForeignKey),
        foreignDimensions: [...unref(foreignDimensions)]
      } as Enrichment)
      enrichmentDimension.value = ''
      enrichmentTable.value = ''
      enrichmentForeignKey.value = ''
      foreignDimensions.value = []
    }

    // Watch
    watch(
      () => props.definition,
      () => {
        enrichmentDimension.value = ''
      }
    )

    watch(
      () => enrichmentTable.value,
      () => {
        enrichmentTableDefinition.value = enrichmentTables.value[enrichmentTable.value]
        enrichmentForeignKey.value = ''
        enrichmentForeignDimension.value = ''
      }
    )

    watch(
      () => enrichmentForeignDimension.value,
      () => {
        if (enrichmentAlias.value === '') {
          enrichmentAlias.value = enrichmentForeignDimension.value
        }
      }
    )

    return {
      // Data
      enrichmentTables,
      enrichmentTableDefinition,
      enrichmentTable,
      enrichmentDimension,
      enrichmentForeignKey,
      enrichmentForeignDimension,
      enrichmentAlias,
      foreignDimensions,

      // Computed
      availableForeignDimensions,
      validate,
      validateForeignDimension,

      // Methods
      submitForeignDimension,
      removeForeignDimension,
      onSubmit,

      // Misc
      multiselectTailwindClassesCompact,
      DateDimension,
      DateRollupDimension,
      t
    }
  }
})
</script>
