import { ValidationArgs } from '@vuelidate/core'
import { required, requiredIf, minLength, maxLength } from '@vuelidate/validators'
import { startOfToday, endOfToday } from 'date-fns'
import trim from 'lodash/trim'
import { computed, ComputedRef } from 'vue'

import { PUBLISHER_REPORTS_SCHEMA } from '@/static/reports'

import { alwaysValid } from '@/plugins/validation'

export interface Report {
  id?: number
  name: string
  source: string
  dimensions: string[]
  metrics: string[]
  timezone: string
  isScheduled: boolean
  token?: string
  period?: string
  dayOfExport?: number
  emails?: string[]
  isLocked: boolean
  isActive: boolean
}

export interface ReportForm {
  name: string
  source: string
  dimensions: string[]
  metrics: string[]
  timezone: string
  type: string
  isScheduled: boolean
  tokenUrl: string
  period?: string
  dayOfExport: number | null
  emails?: string
  isActive: boolean
  directLink: boolean
  token?: string
}

export interface InstantReportForm {
  source: string
  dimensions: string[]
  metrics: string[]
  timezone: string
  dateRange: {
    start: Date,
    end: Date
  }
}

export const reportFormRules = (form: ReportForm): ComputedRef<ValidationArgs> => computed(() => ({
  name: {
    required,
    max: maxLength(255)
  },
  dimensions: {
    required
  },
  metrics: {
    required
  },
  timezone: {
    required
  },
  type: {
    required
  },
  isScheduled: {
    required
  },
  isActive: {
    required
  },
  period: {
    required
  },
  dayOfExport: {
    requiredIfPeriod: requiredIf(form.period !== undefined && !['yesterday', 'last_7_days'].includes(form.period))
  },
  emails: {
    alwaysValid
  }
}))

export const instantReportFormRules = (): ComputedRef<ValidationArgs> => computed(() => ({
  source: {
    required
  },
  dimensions: {
    required,
    minLength: minLength(1)
  },
  metrics: {
    required,
    minLength: minLength(1)
  },
  timezone: {
    required
  },
  dateRange: {
    required
  }
}))

export const createReportForm = (report?: Report): ReportForm => {
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return {
    name: report ? report.name : '',
    source: report ? report.source : '',
    dimensions: report ? report.dimensions : [],
    metrics: report ? report.metrics : [],
    timezone: report ? report.timezone : localTimezone,
    isScheduled: report ? report.isScheduled : true,
    period: report ? report.period : '',
    dayOfExport: report?.dayOfExport ? report.dayOfExport : null,
    emails: report ? report.emails?.join(',') : '',
    tokenUrl: import.meta.env.VITE_API_BASE_URL + '/api/v1/exports/' + (report ? report.token : '<future-token>'),
    directLink: false,
    token: report ? report.token : undefined,
    type: 'template',
    isActive: report ? report.isActive : true
  }
}

export const createInstantReportForm = (report?: Report): InstantReportForm => {
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return {
    source: report ? report.source : PUBLISHER_REPORTS_SCHEMA,
    dimensions: report ? report.dimensions : [],
    metrics: report ? report.metrics : [],
    timezone: report ? report.timezone : localTimezone,
    dateRange: {
      start: startOfToday(),
      end: endOfToday()
    }
  }
}

export const transformToReport = (form: ReportForm): Report => {
  return {
    name: form.name,
    source: form.source,
    dimensions: form.dimensions,
    metrics: form.metrics,
    timezone: form.timezone,
    isScheduled: form.isScheduled,
    period: form.period !== '' ? form.period : undefined,
    dayOfExport: form?.dayOfExport || undefined,
    emails: form.emails?.split(',').map(e => trim(e)).filter(e => e !== ''),
    isActive: form ? form.isActive : true,
    isLocked: false
  }
}
