import { addDays, differenceInDays, endOfDay, endOfMonth, endOfQuarter, endOfToday, endOfWeek, endOfYesterday, startOfDay, startOfMonth, startOfQuarter, startOfToday, startOfWeek, startOfYesterday, subDays, subMonths, subQuarters, subWeeks, subYears } from 'date-fns'

import i18n from '@/plugins/i18n'

export type DateRange = {
  start: Date
  end: Date
}

export const PRESET_TRANSLATIONS: Record<string, string> = {
  today: i18n.global.t('dates.today'),
  yesterday: i18n.global.t('dates.yesterday'),
  past_day: i18n.global.t('dates.past-day'),
  last_7_days: i18n.global.t('dates.last-days', [7]),
  last_14_days: i18n.global.t('dates.last-days', [14]),
  last_30_days: i18n.global.t('dates.last-days', [30]),
  past_week: i18n.global.t('dates.past-week'),
  past_4_weeks: i18n.global.t('dates.past-weeks', [4]),
  past_12_weeks: i18n.global.t('dates.past-weeks', [12]),
  past_month: i18n.global.t('dates.past-month'),
  past_quarter: i18n.global.t('dates.past-quarter'),
  current_week: i18n.global.t('dates.current-week'),
  current_month: i18n.global.t('dates.current-month'),
  current_quarter: i18n.global.t('dates.current-quarter'),
  none: i18n.global.t('dates.noComparison'),
  auto: i18n.global.t('dates.same-before'),
  day_before: i18n.global.t('dates.day-before'),
  week_before: i18n.global.t('dates.week-before'),
  month_before: i18n.global.t('dates.month-before'),
  quarter_before: i18n.global.t('dates.quarter-before'),
  year_before: i18n.global.t('dates.year-before'),
  custom: i18n.global.t('labels.custom')
}

export function dateRangeUndefined (): DateRange {
  return {
    start: new Date(0),
    end: new Date(0)
  }
}

export function dateRangeToday (): DateRange {
  return {
    start: startOfToday(),
    end: endOfToday()
  }
}

export function dateRangeYesterday (): DateRange {
  return {
    start: startOfYesterday(),
    end: endOfYesterday()
  }
}

export function dateRangeLastDays (n: number): DateRange {
  const endYesterday = endOfYesterday()
  return {
    start: startOfDay(subDays(endYesterday, n - 1)),
    end: endYesterday
  }
}

export function dateRangePastWeeks (n: number): DateRange {
  const thisWeek = startOfWeek(startOfToday(), { weekStartsOn: 1 })
  const lastWeek = subWeeks(thisWeek, 1)
  const endLastWeek = endOfWeek(lastWeek, { weekStartsOn: 1 })
  return {
    start: subWeeks(lastWeek, n - 1),
    end: endLastWeek
  }
}

export function dateRangeCurrentWeek (): DateRange {
  return {
    start: startOfWeek(startOfToday(), { weekStartsOn: 1 }),
    end: endOfToday()
  }
}

export function dateRangePastMonth (): DateRange {
  const lastMonth = subMonths(startOfMonth(startOfToday()), 1)
  return {
    start: lastMonth,
    end: endOfMonth(lastMonth)
  }
}

export function dateRangeCurrentMonth (): DateRange {
  return {
    start: startOfMonth(startOfToday()),
    end: endOfToday()
  }
}

export function dateRangePastQuarter (): DateRange {
  const lastQuarter = subQuarters(startOfQuarter(startOfToday()), 1)
  return {
    start: lastQuarter,
    end: endOfQuarter(lastQuarter)
  }
}

export function dateRangeCurrentQuarter (): DateRange {
  return {
    start: startOfQuarter(startOfToday()),
    end: endOfToday()
  }
}

export type DateRangePreset = 'today' | 'yesterday' | 'last_7_days' | 'last_14_days' | 'last_30_days' | 'past_week' | 'past_4_weeks' | 'past_12_weeks' | 'past_month' | 'past_quarter' | 'current_week' | 'current_month' | 'current_quarter' | 'custom'

export const DATE_RANGE_PRESETS: Record<string, () => DateRange> = {
  today: dateRangeToday,
  yesterday: dateRangeYesterday,
  last_7_days: () => dateRangeLastDays(7),
  last_14_days: () => dateRangeLastDays(14),
  last_30_days: () => dateRangeLastDays(30),

  past_week: () => dateRangePastWeeks(1),
  past_4_weeks: () => dateRangePastWeeks(4),
  past_12_weeks: () => dateRangePastWeeks(12),

  past_month: dateRangePastMonth,
  past_quarter: dateRangePastQuarter,

  current_week: dateRangeCurrentWeek,
  current_month: dateRangeCurrentMonth,
  current_quarter: dateRangeCurrentQuarter
}

export const customOptionId = 'custom'

export const DATE_RANGE_PRESETS_OPTIONS: Record<string, {id: DateRangePreset, label: string, value:() => DateRange}> = {
  today: { id: 'today', label: PRESET_TRANSLATIONS.today, value: dateRangeToday },
  yesterday: { id: 'yesterday', label: PRESET_TRANSLATIONS.past_day, value: dateRangeYesterday },
  last_7_days: { id: 'last_7_days', label: PRESET_TRANSLATIONS.last_7_days, value: () => dateRangeLastDays(7) },
  last_14_days: { id: 'last_14_days', label: PRESET_TRANSLATIONS.last_14_days, value: () => dateRangeLastDays(14) },
  last_30_days: { id: 'last_30_days', label: PRESET_TRANSLATIONS.last_30_days, value: () => dateRangeLastDays(30) },
  past_week: { id: 'past_week', label: PRESET_TRANSLATIONS.past_week, value: () => dateRangePastWeeks(1) },
  past_4_weeks: { id: 'past_4_weeks', label: PRESET_TRANSLATIONS.past_4_weeks, value: () => dateRangePastWeeks(4) },
  past_12_weeks: { id: 'past_12_weeks', label: PRESET_TRANSLATIONS.past_12_weeks, value: () => dateRangePastWeeks(12) },
  past_month: { id: 'past_month', label: PRESET_TRANSLATIONS.past_month, value: dateRangePastMonth },
  past_quarter: { id: 'past_quarter', label: PRESET_TRANSLATIONS.past_quarter, value: dateRangePastQuarter },
  current_week: { id: 'current_week', label: PRESET_TRANSLATIONS.current_week, value: dateRangeCurrentWeek },
  current_month: { id: 'current_month', label: PRESET_TRANSLATIONS.current_month, value: dateRangeCurrentMonth },
  current_quarter: { id: 'current_quarter', label: PRESET_TRANSLATIONS.current_quarter, value: dateRangeCurrentQuarter },
  custom: { id: 'custom', label: PRESET_TRANSLATIONS.custom, value: dateRangeUndefined }
}

export function equalDateRanges (a: DateRange, b: DateRange):Boolean {
  return equalDates(a.start, b.start) && equalDates(a.end, b.end)
}

export function equalDates (a: Date, b: Date):Boolean {
  return startOfDay(a).getTime() === startOfDay(b).getTime()
}

export type ComparisonRange = (refDate: DateRange) => DateRange

export function compareLastSamePeriod (refDate: DateRange): DateRange {
  const diff = differenceInDays(refDate.end, refDate.start)
  const start = startOfDay(subDays(refDate.start, diff + 1))
  return {
    start,
    end: endOfDay(addDays(start, diff))
  }
}

export function compareDayBefore (refDate: DateRange): DateRange {
  return {
    start: startOfDay(subDays(refDate.start, 1)),
    end: endOfDay(subDays(refDate.end, 1))
  }
}

export function compareWeekBefore (refDate: DateRange): DateRange {
  const diff = differenceInDays(refDate.end, refDate.start)
  const start = startOfDay(subWeeks(refDate.start, 1))
  return {
    start,
    end: endOfDay(addDays(start, diff))
  }
}

export function compareMonthBefore (refDate: DateRange): DateRange {
  const diff = differenceInDays(refDate.end, refDate.start)
  const start = startOfDay(subMonths(refDate.start, 1))
  return {
    start,
    end: endOfDay(addDays(start, diff))
  }
}

export function compareQuarterBefore (refDate: DateRange): DateRange {
  const diff = differenceInDays(refDate.end, refDate.start)
  const start = startOfDay(subQuarters(refDate.start, 1))
  return {
    start,
    end: endOfDay(addDays(start, diff))
  }
}

export function compareYearBefore (refDate: DateRange): DateRange {
  const diff = differenceInDays(refDate.end, refDate.start)
  const start = startOfDay(subYears(refDate.start, 1))
  return {
    start,
    end: endOfDay(addDays(start, diff))
  }
}

export type ComparisonRangePreset = 'auto' | 'day_before' | 'week_before' | 'month_before' | 'quarter_before' | 'year_before'

export const COMPARISON_DATE_RANGE_PRESETS: Record<string, ComparisonRange> = {
  auto: compareLastSamePeriod,
  day_before: compareDayBefore,
  week_before: compareWeekBefore,
  month_before: compareMonthBefore,
  quarter_before: compareQuarterBefore,
  year_before: compareYearBefore
}
