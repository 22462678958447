import { RouteRecordRaw } from 'vue-router'

import { rolesGroup } from '@/static/roles'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

export function bidderMappingRules (): RouteRecordRaw[] {
  const context = ContextType.BIDDERS

  return [
    {
      path: 'mapping-rules',
      name: `${context}.mappingRules.index`,
      component: () => import('./views/Index.vue'),
      meta: {
        title: i18n.global.t('labels.mappingRule', 2),
        featuresPermissions: ['mapping_rules'],
        feature: 'mapping_rules',
        roles: rolesGroup.adagio,
        requireService: true
      },
      children: [
        {
          path: 'create',
          name: `${context}.mappingRules.create`,
          component: () => import('./views/Create.vue'),
          meta: {
            title: i18n.global.t('mappingRules.create'),
            featuresPermissions: ['mapping_rules'],
            feature: 'mapping_rules',
            roles: rolesGroup.adagio,
            requireService: true
          },
          props: route => ({ bidderId: route.params.bidderId })
        },
        {
          path: ':mappingRuleId',
          name: `${context}.mappingRules.edit`,
          component: () => import('./views/Edit.vue'),
          meta: {
            title: i18n.global.t('mappingRules.edit', { name: ':mappingRuleName' }),
            pattern: ':mappingRuleName',
            featuresPermissions: ['mapping_rules'],
            feature: 'mapping_rules',
            roles: rolesGroup.adagio,
            requireService: true
          },
          props: route => ({ mappingRuleId: route.params.mappingRuleId })
        }
      ]
    }
  ]
}
