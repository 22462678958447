import { RouteLocationNormalized } from 'vue-router'

import i18n from '@/plugins/i18n'

import { useNotificationsStore } from '@/store/notifications.store'

import { fetchResetPasswordByToken } from '@/services/passwords'

export const resetPasswordGuard = async (to: RouteLocationNormalized): Promise<boolean | {name: string}> => {
  const notificationsStore = useNotificationsStore()

  const token = to.params.token as string

  if (token) {
    try {
      const response = await fetchResetPasswordByToken(token)

      if (response && response.status === 204) {
        return true
      }
    } catch {
      notificationsStore.add({
        title: i18n.global.t('passwords.resetPasswordTitle'),
        message: i18n.global.t('passwords.resetPasswordLinkInvalid'),
        type: 'error'
      })

      return { name: 'login' }
    }
  }

  notificationsStore.add({
    title: i18n.global.t('passwords.resetPasswordTitle'),
    message: i18n.global.t('passwords.resetPasswordLinkInvalid'),
    type: 'error'
  })

  return { name: 'login' }
}
