import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { Context } from '@/plugins/context'
import { ContextType } from '@/plugins/context/context.enum'
import heapAnalytics from '@/plugins/heapAnalytics'
import { addMeta } from '@/plugins/meta'
import { getPermission } from '@/plugins/permissions'
import segment from '@/plugins/segmentio'

import { ViewsType, useViewStore } from '@/store/view.store'

import { Bidder } from '@/models/bidder'
import { ContextPermission } from '@/models/contextPermission'
import { Group } from '@/models/group'
import { Publisher } from '@/models/publisher'

export const useContextStore = defineStore('context', () => {
  const viewStore = useViewStore()

  const selectedPublisher = ref<Publisher | null>(null)
  const selectedBidder = ref<Bidder | null>(null)
  const selectedGroup = ref<Group | null>(null)

  const hasContext = computed(
    () => !!selectedGroup.value || !!selectedPublisher.value || !!selectedBidder.value
  )

  const contextName = computed((): string => {
    if (hasContext.value) {
      if (selectedPublisher.value) return selectedPublisher.value.name
      if (selectedBidder.value) return selectedBidder.value.name
      if (selectedGroup.value) return selectedGroup.value.name
    }

    return ''
  })

  const contextRouteParam = computed((): { [key: string]: number } => {
    if (hasContext.value) {
      if (selectedPublisher.value) return { publisherId: selectedPublisher.value.id }
      if (selectedBidder.value) return { bidderId: selectedBidder.value.id }
      if (selectedGroup.value) return { groupId: selectedGroup.value.id }
    }

    return {}
  })

  const contextType = computed((): ContextType => {
    if (hasContext.value) {
      if (selectedPublisher.value) return ContextType.PUBLISHERS
      if (selectedBidder.value) return ContextType.BIDDERS
      if (selectedGroup.value) return ContextType.GROUPS
    }

    return ContextType.PUBLISHERS
  })

  const contextId = computed((): number => {
    if (hasContext.value) {
      if (selectedPublisher.value) return selectedPublisher.value.id
      if (selectedBidder.value) return selectedBidder.value.id
      if (selectedGroup.value) return selectedGroup.value.id
    }

    return 0
  })

  const context = computed((): Context | undefined => {
    if (!hasContext.value) {
      return undefined
    }

    const context: Context = {
      resourceType: contextType.value,
      resourceId: 0
    }

    if (selectedPublisher.value) context.resourceId = selectedPublisher.value.id
    else if (selectedBidder.value) context.resourceId = selectedBidder.value.id
    else if (selectedGroup.value) context.resourceId = selectedGroup.value.id

    return context
  })

  const permissions = computed((): ContextPermission | null | undefined => {
    if (!hasContext.value) return undefined

    if (selectedPublisher.value) return selectedPublisher.value.contextPermission
    else if (selectedBidder.value) return selectedBidder.value.contextPermission
    else if (selectedGroup.value) return selectedGroup.value.contextPermission

    return null
  })

  const contextSelectedPublisher = computed((): Publisher | undefined => {
    if (hasContext.value && selectedPublisher.value) return selectedPublisher.value

    return undefined
  })

  const contextSelectedBidder = computed((): Bidder | undefined => {
    if (hasContext.value && selectedBidder.value) return selectedBidder.value

    return undefined
  })

  const contextSelectedGroup = computed((): Group | undefined => {
    if (hasContext.value && selectedGroup.value) return selectedGroup.value

    return undefined
  })

  const setHeapAnalyticsEventProperties = (
    properties?: Record<string, string | undefined>
  ): void => {
    heapAnalytics.clearEventProperties()
    if (properties) {
      const permission = getPermission(context.value)
      properties.currentOrgRole = permission?.isService ? 'service' : permission?.roles.join(',')
      heapAnalytics.addEventProperties(properties)
    }
  }

  const setSegmentGroupProperties = (): void => {
    if (!hasContext.value) return

    const properties = {
      context: contextType.value.toString(),
      name: contextName.value,
      sellerType: selectedPublisher.value?.sellerType.toString()
    }
    const groupID = `${contextType.value.toString()}_${contextId.value}`
    segment.group(groupID, properties)
  }

  const clearContext = (): void => {
    selectedPublisher.value = null
    selectedBidder.value = null
    selectedGroup.value = null

    setHeapAnalyticsEventProperties()
  }

  const setPublisher = (publisher: Publisher): void => {
    addMeta(':publisherName', publisher.name)

    selectedPublisher.value = publisher
    selectedBidder.value = null
    selectedGroup.value = null
  }

  const setBidder = (bidder: Bidder): void => {
    addMeta(':bidderName', bidder.name)

    selectedPublisher.value = null
    selectedBidder.value = bidder
    selectedGroup.value = null
  }

  const setGroup = (group: Group): void => {
    addMeta(':groupName', group.name)

    selectedPublisher.value = null
    selectedBidder.value = null
    selectedGroup.value = group
  }

  const selectPublisher = async (publisher: Publisher): Promise<void> => {
    setPublisher(publisher)

    await viewStore.fetchViews(ViewsType.WITH_CONTEXT)

    // useRouter().push({ name: 'publishers.home', params: { publisherId: publisher.id } })
    setSegmentGroupProperties()
  }

  const selectBidder = async (bidder: Bidder): Promise<void> => {
    setBidder(bidder)

    await viewStore.fetchViews(ViewsType.WITH_CONTEXT)

    // useRouter().push({ name: 'bidders.home', params: { bidderId: bidder.id } })
    setSegmentGroupProperties()
  }

  const selectGroup = async (group: Group): Promise<void> => {
    setGroup(group)

    await viewStore.fetchViews(ViewsType.WITH_CONTEXT)

    // useRouter().push({ name: 'groups.home', params: { groupId: group.id } })
    setSegmentGroupProperties()
  }

  return {
    selectedPublisher,
    selectedBidder,
    selectedGroup,
    hasContext,
    contextName,
    contextRouteParam,
    contextType,
    contextId,
    context,
    permissions,
    contextSelectedPublisher,
    contextSelectedBidder,
    contextSelectedGroup,

    setBidder,
    setPublisher,
    setGroup,

    setHeapAnalyticsEventProperties,
    setSegmentGroupProperties,
    clearContext,

    selectPublisher,
    selectBidder,
    selectGroup
  }
})
