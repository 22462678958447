import i18n from '@/plugins/i18n'

import { TIME_GRANULARITIES } from './dimensions'

export const TRANSLATIONS: Record<string, string> = {
  organizationId: i18n.global.t('metrics.organizationId'),
  publisher: i18n.global.t('metrics.publisher'),
  reseller: i18n.global.t('metrics.reseller'),
  resellerRevenueShare: i18n.global.t('metrics.resellerRevenueShare'),
  orgaLiveAt: i18n.global.t('metrics.orgaLiveAt'),
  websiteLiveAt: i18n.global.t('metrics.websiteLiveAt'),
  site: i18n.global.t('metrics.site'),
  placement: i18n.global.t('metrics.placement'),
  pagetype: i18n.global.t('metrics.pagetype'),
  environment: i18n.global.t('metrics.environment'),
  category: i18n.global.t('metrics.category'),
  subcategory: i18n.global.t('metrics.subcategory'),
  mediatypes: i18n.global.t('metrics.mediatypes'),
  bidMediatype: i18n.global.t('metrics.bidMediatype'),
  bidder: i18n.global.t('metrics.bidder'),
  meta: i18n.global.t('metrics.meta'),
  adCalls: i18n.global.t('metrics.adCalls'),
  validAdCalls: i18n.global.t('metrics.validAdCalls'),
  ruleMatched: i18n.global.t('metrics.ruleMatched'),
  predictionPassed: i18n.global.t('metrics.predictionPassed'),
  auctions: i18n.global.t('metrics.auctions'),
  adResponses: i18n.global.t('metrics.adResponses'),
  wins: i18n.global.t('metrics.wins'),
  impressions: i18n.global.t('metrics.impressions'),
  netRevenue: i18n.global.t('metrics.netRevenue'),
  grossRevenue: i18n.global.t('metrics.grossRevenue'),
  bidNetCPM: i18n.global.t('metrics.bidNetCPM'),
  bidGrossCPM: i18n.global.t('metrics.bidGrossCPM'),
  netCPM: i18n.global.t('metrics.netCPM'),
  grossCPM: i18n.global.t('metrics.grossCPM'),
  inventoryPublisherId: i18n.global.t('metrics.inventoryPublisherId'),
  inventoryPublisherName: i18n.global.t('metrics.inventoryPublisherName'),
  salesUserId: i18n.global.t('metrics.salesUserId'),
  salesUserName: i18n.global.t('metrics.salesUserName'),
  successManagerUserId: i18n.global.t('metrics.successManagerUserId'),
  successManagerUserName: i18n.global.t('metrics.successManagerUserName'),
  windowStart: i18n.global.t('metrics.windowStart'),
  integratedAdrequests: i18n.global.t('metrics.integratedAdrequests'),
  integratedNetRevenue: i18n.global.t('metrics.integratedNetRevenue'),
  declaredAdrequests: i18n.global.t('metrics.declaredAdrequests'),
  declaredRevenueTarget: i18n.global.t('metrics.declaredRevenueTarget'),
  expectedNetRevenue: i18n.global.t('metrics.expectedNetRevenue'),
  declaredWebsites: i18n.global.t('metrics.declaredWebsites'),
  createdWebsites: i18n.global.t('metrics.createdWebsites'),
  liveWebsites: i18n.global.t('metrics.liveWebsites'),
  integrationRate: i18n.global.t('metrics.integrationRate'),
  potentialNetRevenue: i18n.global.t('metrics.potentialNetRevenue'),
  missingNetRevenue: i18n.global.t('metrics.missingNetRevenue'),
  moneytizationRate: i18n.global.t('metrics.moneytizationRate'),
  rpb: i18n.global.t('metrics.rpb'),
  expectedRpb: i18n.global.t('metrics.expectedRpb'),
  asleepWebsitesRate: i18n.global.t('metrics.asleepWebsitesRate'),
  potentialWebsitesRate: i18n.global.t('metrics.potentialWebsitesRate'),
  csmError: i18n.global.t('metrics.csmError'),
  inventoryWebsiteName: i18n.global.t('metrics.inventoryWebsiteName'),
  inventoryWebsiteStatus: i18n.global.t('metrics.inventoryWebsiteStatus'),
  inventoryWebsiteAdsTxtReason: i18n.global.t('metrics.inventoryWebsiteAdsTxtReason'),
  inventoryPublisherSellerType: i18n.global.t('metrics.inventoryPublisherSellerType'),
  inventoryPublisherSellersJsonReason: i18n.global.t('metrics.inventoryPublisherSellersJsonReason'),
  adcalls: i18n.global.t('metrics.adcalls'),
  inventoryMediatype: i18n.global.t('metrics.inventoryMediatype'),
  adresponseMediatype: i18n.global.t('metrics.adresponseMediatype'),
  comparisonGroup: i18n.global.t('metrics.comparisonGroup'),
  userDevice: i18n.global.t('metrics.userDevice'),
  contentPagetype: i18n.global.t('metrics.contentPagetype'),
  inventoryPlacement: i18n.global.t('metrics.inventoryPlacement'),
  mediatypeAdcalls: i18n.global.t('metrics.mediatypeAdcalls'),
  adresponses: i18n.global.t('metrics.adresponses'),
  adresponsesNetCpmSum: i18n.global.t('metrics.adresponsesNetCpmSum'),
  measured: i18n.global.t('metrics.measured'),
  viewed: i18n.global.t('metrics.viewed'),
  timeInViewSum: i18n.global.t('metrics.timeInViewSum'),
  userEnvironment: i18n.global.t('metrics.userEnvironment'),
  adresponSize: i18n.global.t('metrics.adresponSize'),
  pbjsVersion: i18n.global.t('metrics.pbjsVersion'),
  prebidAnalytics: i18n.global.t('metrics.prebidAnalytics'),
  inventoryWebsiteDomain: i18n.global.t('metrics.inventoryWebsiteDomain'),
  inventoryWebsiteConfiguredDomain: i18n.global.t('metrics.inventoryWebsiteConfiguredDomain'),
  adgBids: i18n.global.t('metrics.adgBids'),
  adgImpressions: i18n.global.t('metrics.adgImpressions'),
  adgNetRevenue: i18n.global.t('metrics.adgNetRevenue'),
  measurables: i18n.global.t('metrics.measurables'),
  viewables: i18n.global.t('metrics.viewables'),
  adgBidNetCpmSum: i18n.global.t('metrics.adgBidNetCpmSum'),
  exposureSum: i18n.global.t('metrics.exposureSum'),
  adgExposureSum: i18n.global.t('metrics.adgExposureSum'),
  reportingReqFound: i18n.global.t('metrics.reportingReqFound'),
  reportingRespFound: i18n.global.t('metrics.reportingRespFound'),
  bidrequestimpFound: i18n.global.t('metrics.bidrequestimpFound'),
  seatType: i18n.global.t('metrics.seatType'),
  seatId: i18n.global.t('metrics.seatId'),
  mediatype: i18n.global.t('metrics.mediatype'),
  device: i18n.global.t('metrics.device'),
  country: i18n.global.t('metrics.country'),
  pubReq: i18n.global.t('metrics.pubReq'),
  pubReq_Eligible: i18n.global.t('metrics.pubReq_Eligible'),
  sspReq: i18n.global.t('metrics.sspReq'),
  sspReq_FloorCpmSum: i18n.global.t('metrics.sspReq_FloorCpmSum'),
  sspReq_FloorCpmCount: i18n.global.t('metrics.sspReq_FloorCpmCount'),
  sspResp: i18n.global.t('metrics.sspResp'),
  sspResp_CpmSum: i18n.global.t('metrics.sspResp_CpmSum'),
  sspResp_CpmCount: i18n.global.t('metrics.sspResp_CpmCount'),
  sspResp_Compliant: i18n.global.t('metrics.sspResp_Compliant'),
  sspResp_CompliantCpmSum: i18n.global.t('metrics.sspResp_CompliantCpmSum'),
  sspResp_CompliantCpmCount: i18n.global.t('metrics.sspResp_CompliantCpmCount'),
  pubResp: i18n.global.t('metrics.pubResp'),
  pubResp_CpmSum: i18n.global.t('metrics.pubResp_CpmSum'),
  pubResp_CpmCount: i18n.global.t('metrics.pubResp_CpmCount'),
  adgPub_Imp: i18n.global.t('metrics.adgPub_Imp'),
  adgPub_ImpCpmSum: i18n.global.t('metrics.adgPub_ImpCpmSum'),
  adgPub_ImpCpmCount: i18n.global.t('metrics.adgPub_ImpCpmCount'),
  adgPub_FeesCpmSum: i18n.global.t('metrics.adgPub_FeesCpmSum'),
  adgPub_FeesCpmCount: i18n.global.t('metrics.adgPub_FeesCpmCount'),
  adgPub_SspFeesCpmSum: i18n.global.t('metrics.adgPub_SspFeesCpmSum'),
  adgPub_SspFeesCpmCount: i18n.global.t('metrics.adgPub_SspFeesCpmCount'),
  adgAdsrv_Imp: i18n.global.t('metrics.adgAdsrv_Imp'),
  adgAdsrv_ImpCpmSum: i18n.global.t('metrics.adgAdsrv_ImpCpmSum'),
  adgAdsrv_ImpCpmCount: i18n.global.t('metrics.adgAdsrv_ImpCpmCount'),
  adgAdsrv_FeesCpmSum: i18n.global.t('metrics.adgAdsrv_FeesCpmSum'),
  adgAdsrv_FeesCpmCount: i18n.global.t('metrics.adgAdsrv_FeesCpmCount'),
  adgAdsrv_SspFeesCpmSum: i18n.global.t('metrics.adgAdsrv_SspFeesCpmSum'),
  adgAdsrv_SspFeesCpmCount: i18n.global.t('metrics.adgAdsrv_SspFeesCpmCount'),
  sspReqDfp: i18n.global.t('metrics.sspReqDfp'),
  sspReqDfp_FloorCpmSum: i18n.global.t('metrics.sspReqDfp_FloorCpmSum'),
  sspReqDfp_FloorCpmCount: i18n.global.t('metrics.sspReqDfp_FloorCpmCount'),
  sspRespDfp: i18n.global.t('metrics.sspRespDfp'),
  sspRespDfp_CpmSum: i18n.global.t('metrics.sspRespDfp_CpmSum'),
  sspRespDfp_CpmCount: i18n.global.t('metrics.sspRespDfp_CpmCount'),
  notViewed: i18n.global.t('metrics.notViewed'),
  bidRate: i18n.global.t('metrics.bidRate'),
  winRate: i18n.global.t('metrics.winRate'),
  fillRate: i18n.global.t('metrics.fillRate'),
  vRate: i18n.global.t('metrics.vRate'),
  timeInView: i18n.global.t('metrics.timeInView'),
  adresponsesCPM: i18n.global.t('metrics.adresponsesCPM'),
  cpm: i18n.global.t('metrics.cpm'),
  timeBound: i18n.global.t('metrics.timeBound'),
  incrementalRevenue: i18n.global.t('metrics.incrementalRevenue'),
  potentialRevenueForTimeBound: i18n.global.t('metrics.potentialRevenueForTimeBound'),
  bidsBelowTimeBound: i18n.global.t('metrics.bidsBelowTimeBound'),
  bidsAboveTimeBound: i18n.global.t('metrics.bidsAboveTimeBound'),
  incrementality: i18n.global.t('metrics.incrementality'),
  timeoutRate: i18n.global.t('metrics.timeoutRate'),
  splitRuleId: i18n.global.t('metrics.splitRuleId'),
  splitRuleCaseId: i18n.global.t('metrics.splitRuleCaseId'),

  // Time granularities
  day: i18n.global.t('dates.day'),
  week: i18n.global.t('dates.week'),
  month: i18n.global.t('dates.month'),
  quarter: i18n.global.t('dates.quarter'),
  hour: i18n.global.t('dates.hour')
}

export function translateDBName (name: string): string {
  return TRANSLATIONS[name] || name
}

export const granularityMultiselectOptions = Object.keys(TIME_GRANULARITIES).map(g => {
  return {
    id: g,
    name: TRANSLATIONS[g]
  }
})
