<template>
  <div
    ref="container"
    class="flex w-full space-x-1 overflow-hidden flex-nowrap"
    :title="title"
  >
    <span
      v-for="(val, i) in displayedValues"
      :key="getKey(val)"
      class="px-1.5 py-0.5 rounded text-sm leading-snug bg-primary-100 text-text-primary truncate"
    >
      <span v-if="showPosition">{{ i+1 }}&nbsp;-&nbsp;</span>
      {{ getValue(val) }}
    </span>
    <span
      v-if="maxVisible > 0 && value.length > maxVisible"
      class="px-1.5 py-0.5 rounded text-sm leading-snug bg-primary-100 text-text-primary whitespace-nowrap"
    >
      + {{ value.length - maxVisible }}
    </span>
  </div>
</template>

<script lang="ts">
import get from 'lodash/get'
import { computed, defineComponent, PropType, ref } from 'vue'

import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter'

export default defineComponent({
  props: {
    value: {
      type: Array as PropType<string[] | number[] | object[]>,
      required: true
    },
    valueKey: {
      type: String,
      default: null
    },
    truncate: { // If true, display a "+1" tag if the container cannot display all elements
      type: Boolean,
      default: false
    },
    elementWidth: { // The average element width used to calculate the maximum number of displayed elements when truncate is true
      type: Number,
      default: 80
    },
    showPosition: { // If true, shows the position of the element in the array in the element tag
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const getKey = (val: any) => {
      const v = (props.valueKey) ? props.valueKey : val
      return capitalizeFirstLetter(v)
    }

    const getValue = (val: any) => {
      const v = (props.valueKey) ? get(val, props.valueKey) : val
      return capitalizeFirstLetter(v)
    }

    const title = computed(() => props.value.map(getValue).join(', '))

    // Truncate handling
    const container = ref<HTMLElement | null>(null)
    const maxVisible = computed(() => {
      if (container.value === null || !props.truncate) {
        return -1
      }

      return Math.floor(container.value.clientWidth / props.elementWidth)
    })

    const displayedValues = computed(() => maxVisible.value > 0 ? props.value.slice(0, maxVisible.value) : props.value)

    return {
      container,
      getKey,
      getValue,
      displayedValues,
      maxVisible,
      title
    }
  }
})
</script>
