import { Component, InputHTMLAttributes } from 'vue'

import { FormInputHints, FormInputMode } from '@/components/Form'
import FormComponent from '@/components/Form/FormDate.vue'

import { DefaultProps, FormRenderer } from './'

interface Props extends DefaultProps {
  hints?: FormInputHints
  minDate?: Date
  mode?: FormInputMode
  time?: boolean
  withLeftIcon?: boolean
  attr?: InputHTMLAttributes
  checkIsBefore?: boolean,
}

export class FormDate implements FormRenderer<Props> {
  defaultProps: Props

  constructor (props: Props) {
    this.defaultProps = props
  }

  get wrapperClass (): string {
    return this.defaultProps.wrapperClass || ''
  }

  props (): Props {
    const attrs = { ...this.defaultProps.attr }
    const cleanProps = { ...this.defaultProps }
    delete cleanProps.attr

    return { ...cleanProps, ...attrs }
  }

  component (): Component {
    return FormComponent
  }
}
