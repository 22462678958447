import { Adapter } from './adapter.base'
import { Adstxt as AdstxtBase } from './adstxt.base'
import { AdstxtCheck } from './adstxtCheck.base'
import { AdstxtLine, computeManagerAdstxtLine, computeManagerdomainAdstxtLine, computeOwnerAdstxtLine, computeOwnerdomainAdstxtLine } from './adstxtLine'
import { Bidder } from './bidder.base'
import { AdstxtLineStatus, AdstxtLineType, SellersjsonEntryStatus } from './enum/adstxt'
import { Publisher } from './publisher.base'
import { Seat } from './seat.base'
import { ManagerLine, OwnerLine, Website } from './website.base'

export interface Adstxt extends AdstxtBase {
  publisher?: Publisher
  website?: Website
  bidder?: Bidder
  seat?: Seat
  adapter?: Adapter
  adstxtLine: AdstxtLine
  adstxtCheck?: AdstxtCheck
  adstxtSellersjsonCheck?: AdstxtCheck
}

export enum AdstxtStatus {
  UNKNOWN = 0,
  OK = 1,
  WARNING = 2,
  ERROR = 3,
  PENDING = 4,
  INFO = 5,
}
export interface AdstxtWithStatus extends Adstxt {
  status: AdstxtStatus
}

export enum AdstxtItemType {
  PARAMETER = 1,
  OWNER = 2,
  ADAGIO = 3,
  MANAGER = 4,
  SEAT = 5,
  // When multiple seats share the same secondary lines,
  // the seat titles of the primary lines are displayed as subsections (e.g. `## Adagio - Onetag`).
  SUBSECTION = 6,
  BIDDER = 7,
  ADSTXT = 8,
}

export interface AdstxtItem {
  type: AdstxtItemType
  adstxt?: AdstxtWithStatus
}

const createAdstxt = (adstxtLine: AdstxtLine): Adstxt => {
  return {
    publisherId: 0,
    websiteId: 0,
    adapterId: 0,
    bidderId: 0,
    seatId: 0,
    adstxtLineId: 0,
    adstxtStatusCheck: AdstxtLineStatus.NOT_CRAWLED,
    sellersjsonStatusCheck: SellersjsonEntryStatus.NOT_CRAWLED,
    adstxtLine
  }
}

export const createOwnerAdstxt = (ownerLine: OwnerLine, adstxts: Adstxt[]): Adstxt[] => {
  const ownerAdstxts: Adstxt[] = []

  let ownerdomainAdstxt = adstxts.find(a => a.adstxtLine.adstxtLineType === AdstxtLineType.OWNERDOMAIN &&
     a.adstxtLine.domainName === ownerLine.sellersjsonDomain)

  if (!ownerdomainAdstxt) {
    ownerdomainAdstxt = createAdstxt(computeOwnerdomainAdstxtLine(ownerLine.sellersjsonDomain))
  }

  ownerAdstxts.push(ownerdomainAdstxt)

  if (ownerLine.publisherAccountId.length) {
    let ownerAdstxt = adstxts.find(a => a.adstxtLine.adstxtLineType === AdstxtLineType.OWNER &&
      a.adstxtLine.domainName === ownerLine.sellersjsonDomain &&
      a.adstxtLine.publisherAccountId === ownerLine.publisherAccountId &&
      a.adstxtLine.certAuthId === ownerLine.certAuthId)

    if (!ownerAdstxt) {
      ownerAdstxt = createAdstxt(computeOwnerAdstxtLine(ownerLine.sellersjsonDomain, ownerLine.publisherAccountId, ownerLine.certAuthId))
    }

    ownerAdstxts.push(ownerAdstxt)
  }

  return ownerAdstxts
}

export const createManagerAdstxts = (managerDomain: string, managerLines: ManagerLine[], adstxts: Adstxt[]): Adstxt[] => {
  const managerAdstxts: Adstxt[] = []

  let managerdomainAdstxt = adstxts.find(a => a.adstxtLine.adstxtLineType === AdstxtLineType.MANAGERDOMAIN &&
    a.adstxtLine.domainName === managerDomain)

  if (!managerdomainAdstxt) {
    managerdomainAdstxt = createAdstxt(computeManagerdomainAdstxtLine(managerDomain))
  }

  managerAdstxts.push(managerdomainAdstxt)

  managerLines.forEach((managerLine) => {
    let managerAdstxt = adstxts.find(a => a.adstxtLine.adstxtLineType === AdstxtLineType.MANAGER &&
      a.adstxtLine.domainName === managerDomain &&
      a.adstxtLine.publisherAccountId === managerLine.publisherAccountId &&
      a.adstxtLine.certAuthId === managerLine.certAuthId)

    if (!managerAdstxt) {
      managerAdstxt = createAdstxt(computeManagerAdstxtLine(managerDomain, managerLine.publisherAccountId, managerLine.certAuthId))
    }

    managerAdstxts.push(managerAdstxt)
  })

  return managerAdstxts
}
