import { ValidationStatus } from '@/plugins/datatable/datatable.d'
import i18n from '@/plugins/i18n'

import { BidderLinesStatus } from '@/models/enum/bidderLinesStatus'

import { Adapter } from './adapter'
import { Bidder } from './bidder'
import { ApprovalStatusType } from './enum/approvalStatus'
import { Optin } from './optin'
import { Publisher } from './publisher'
import { Seat } from './seat'
import { Website } from './website'

/* ----------------------------------------------------------------------------- WEBSITES  */

export interface Whitelist {
  id: number
  bidderId: number
  adapterId: number
  seatId: number
  publisherId: number
  websiteId: number
  bidderWhitelistStatus: ApprovalStatusType
  submissionDate: string

  // Reationship
  bidder: Bidder
  adapter: Adapter
  seat: Seat
  publisher: Publisher
  website: Website
  optin?: Optin
}

export interface WhitelistsWithBidderLinesStatus {
  bidderWhitelistStatus: ApprovalStatusType
  createdAt: string
  updatedAt: string
  submissionDate: string
  bidderLinesStatus: BidderLinesStatus
  autoWhitelistedAt: string
  firstBidrequestAt: string
}

export interface WhitelistsWithBidderLinesStatusSeat extends WhitelistsWithBidderLinesStatus {
  seatId: number
  seatName: string
  seatType: string
}

export interface WhitelistsWithBidderLinesStatusWebsite extends WhitelistsWithBidderLinesStatus {
  websiteId: number
  websiteDomain: string
}

export interface BidderWhitelistForm {
  adapterId: number
  seatId: number
  publisherId: number
  websiteId: number
  bidderWhitelistStatus: ApprovalStatusType
  submissionDate?: string

  adapter?: Adapter
  seat?: Seat
  publisher?: Publisher
  website?: Website
}

export function createBidderWhitelistForm (bidderWhitelist?: Whitelist): BidderWhitelistForm {
  return {
    adapterId: bidderWhitelist?.adapterId || 0,
    seatId: bidderWhitelist?.seatId || 0,
    publisherId: bidderWhitelist?.publisherId || 0,
    websiteId: bidderWhitelist?.websiteId || 0,
    bidderWhitelistStatus: bidderWhitelist?.bidderWhitelistStatus || ApprovalStatusType.PENDING,
    submissionDate: bidderWhitelist?.submissionDate || '',
    adapter: bidderWhitelist?.adapter,
    seat: bidderWhitelist?.seat,
    publisher: bidderWhitelist?.publisher,
    website: bidderWhitelist?.website
  }
}
export interface BulkDomainForm {
  status: ApprovalStatusType
  websiteDomain: string // Currently not website domain but tld+1 domain
}

// WhitelistForm the actual form sent to the API when updating or bulk whitelists
export interface BulkBidderWhitelistForm {
  validation: ValidationStatus
  bidderWhitelistStatus: ApprovalStatusType
  oldBidderWhitelistStatus: ApprovalStatusType
  websiteId: number
  websiteDomain: string //
  publisherId: number
  publisherName: string
  seatId: number
  seatName: string
}
export interface BulkBidderWhitelist {
  seatId: number
  websiteId: number
  status: ApprovalStatusType
  submissionDate?: string
}

export function createBulkBidderWhitelist (form: BidderWhitelistForm | BulkBidderWhitelistForm): BulkBidderWhitelist {
  const newForm: BulkBidderWhitelist = {
    websiteId: form.websiteId,
    seatId: form.seatId,
    status: form.bidderWhitelistStatus
  }
  return newForm
}

// Publishers Whitelists
export interface PublisherWhitelistForm {
  adapterId: number
  seatId: number
  publisherId: number
  websiteId: number
  bidderWhitelistStatus: ApprovalStatusType
  submissionDate?: string

  adapter?: Adapter
  seat?: Seat
  publisher?: Publisher
  website?: Website
}

export function createPublisherWhitelistForm (bidderWhitelist?: Whitelist): PublisherWhitelistForm {
  return {
    adapterId: bidderWhitelist?.adapterId || 0,
    seatId: bidderWhitelist?.seatId || 0,
    publisherId: bidderWhitelist?.publisherId || 0,
    websiteId: bidderWhitelist?.websiteId || 0,
    bidderWhitelistStatus: bidderWhitelist?.bidderWhitelistStatus || ApprovalStatusType.PENDING,
    submissionDate: bidderWhitelist?.submissionDate || '',
    adapter: bidderWhitelist?.adapter,
    seat: bidderWhitelist?.seat,
    publisher: bidderWhitelist?.publisher,
    website: bidderWhitelist?.website
  }
}

export interface BulkPublisherWhitelistForm {
  bidderWhitelistStatus: ApprovalStatusType
  websiteId: number
  websiteDomain: string
  seatId: number
  seatName: string
}

export interface BulkPublisherWhitelist {
  status: ApprovalStatusType
  websiteId: number
  seatId: number
}

export function createBulkPublisherWhitelist (form: PublisherWhitelistForm | BulkPublisherWhitelistForm): BulkPublisherWhitelist {
  const newForm: BulkPublisherWhitelist = {
    status: form.bidderWhitelistStatus,
    websiteId: form.websiteId,
    seatId: form.seatId
  }

  return newForm
}

export const whitelistStatus = [
  { id: ApprovalStatusType.APPROVED, name: i18n.global.t('labels.whitelisted') },
  { id: ApprovalStatusType.PENDING, name: i18n.global.t('labels.actionRequired') },
  { id: ApprovalStatusType.BLOCKED, name: i18n.global.t('labels.blocked') },
  { id: ApprovalStatusType.UNWHITELISTED, name: i18n.global.t('labels.unwhitelisted') }
]

export function retrieveWhitelistStatus (label: string): ApprovalStatusType | string {
  switch (label) {
    case i18n.global.t('labels.whitelisted').toLocaleLowerCase():
      return ApprovalStatusType.APPROVED
    case i18n.global.t('labels.actionRequired').toLocaleLowerCase():
      return ApprovalStatusType.PENDING
    case i18n.global.t('labels.blocked').toLocaleLowerCase():
      return ApprovalStatusType.BLOCKED
    case i18n.global.t('labels.unwhitelisted').toLocaleLowerCase():
      return ApprovalStatusType.UNWHITELISTED
    default:
      return label
  }
}

export const whitelistStatusOptions = whitelistStatus.map(s => {
  if ([ApprovalStatusType.UNWHITELISTED, ApprovalStatusType.PENDING].includes(s.id)) {
    return { ...s, disabled: true }
  }
  return s
})

export const publisherWhitelistStatus = [
  { id: ApprovalStatusType.APPROVED, name: i18n.global.t('labels.whitelisted') },
  { id: ApprovalStatusType.PENDING, name: i18n.global.t('labels.pending') },
  { id: ApprovalStatusType.BLOCKED, name: i18n.global.t('labels.blocked') },
  { id: ApprovalStatusType.UNWHITELISTED, name: i18n.global.t('labels.unwhitelisted') }
]

export const publisherWhitelistStatusOptions = publisherWhitelistStatus.map(s => {
  if (s.id === ApprovalStatusType.UNWHITELISTED) {
    return { ...s, disabled: true }
  }
  return s
})
