<template>
  <Popper
    ref="popper"
    :transition-props="{
      'enterActiveClass': 'transition-opacity duration-150 ease-out',
      'enterFromClass': 'opacity-0',
      'enterToClass': 'opacity-100',
      'leaveActiveClass': 'transition-opacity duration-150 ease-in',
      'leaveFromClass': 'opacity-100',
      'leaveToClass': 'opacity-0'
    }"
    :reference-props="{
      'class': referenceClass
    }"
    trigger="click-to-toggle"
    placement="bottom"
  >
    <template #reference="{ visible }">
      <DateRangeInput
        :open="visible"
        :value="range"
        :label="inputLabel"
        :comparison-label="comparisonInputLabel"
        :time="time"
        :timezone="timezone"
        :class="buttonClass"
      />
    </template>

    <div class="flex flex-col max-w-full overflow-visible bg-white border border-gray-100 rounded shadow-lg md:flex-row w-80 md:w-auto ring-1 ring-black ring-opacity-5">
      <DatePicker
        v-model.range="range"
        :mode="time ? 'dateTime' : 'date'"
        :min-date="minDragDate"
        :max-date="maxDragDate"
        color="indigo"
        @drag="onDatePickerDrag"
        @vue:unmounted="onPickerUnmounted"
        @dayclick="selectedPreset = 'custom'"
      />
      <div class="w-full px-2 py-1 bg-white md:p-0">
        <div class="hidden w-full px-2 py-1 font-medium text-center border-b border-gray-200 md:block">
          {{ t('labels.period') }}
        </div>
        <div
          class="flex flex-col px-2 pt-2 pb-1 overflow-auto text-sm md:block"
          :class="time ? 'max-h-96' : 'max-h-60'"
        >
          <div
            v-for="option in presetOptions"
            :key="option.id"
            :class="selectedPreset === option.id ? '!bg-primary-500 text-white' : ''"
            class="w-full px-2 py-1 rounded cursor-pointer hover:bg-gray-100 min-w-28"
            @click="selectPreset(option.id)"
          >
            {{ option.name }}
          </div>
        </div>
        <Multiselect
          v-model="selectedPreset"
          name="selectedPreset"
          mode="single"
          :close-on-select="true"
          :options="presetOptions"
          :classes="multiselectClasses"
          :can-clear="false"
          :can-deselect="false"
          :show-options="true"
        >
          <template #singlelabel="{ value }">
            <div :class="multiselectClasses.singleLabel">
              {{ t('labels.period') }}:&nbsp;<span class="font-medium">{{ value.label }}</span>
            </div>
          </template>
        </Multiselect>
      </div>
      <div
        v-if="!noComparison"
        class="w-full px-2 py-1 bg-white md:p-0"
      >
        <div class="hidden w-full px-2 py-1 font-medium text-center border-b border-gray-200 md:block">
          {{ t('labels.comparison') }}
        </div>
        <div
          class="flex-col hidden px-2 pt-2 pb-1 overflow-auto text-sm md:block"
          :class="time ? 'max-h-96' : 'max-h-60'"
        >
          <div
            v-for="option in comparisonPresetOptions"
            :key="option.id"
            :class="selectedComparison === option.id ? '!bg-primary-500 text-white' : ''"
            class="w-full px-2 py-1 rounded cursor-pointer hover:bg-gray-100 min-w-28"
            @click="selectedComparison = option.id"
          >
            {{ option.name }}
          </div>
        </div>
        <Multiselect
          v-model="selectedComparison"
          name="selectedComparison"
          mode="single"
          :close-on-select="true"
          :options="comparisonPresetOptions"
          :classes="multiselectClasses"
          :can-clear="false"
          :can-deselect="false"
          :placeholder="t('labels.comparison')"
        >
          <template #singlelabel="{ value }">
            <div :class="multiselectClasses.singleLabel">
              {{ t('labels.comparison') }}:&nbsp;<span class="font-medium">{{ value.label }}</span>
            </div>
          </template>
        </Multiselect>
      </div>
    </div>
  </Popper>
</template>

<script lang="ts">
import { addDays, addMilliseconds, differenceInDays, endOfDay, startOfDay, subDays } from 'date-fns'
import { DatePicker } from 'v-calendar'
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { DateRangePreset } from '@/utils/dateRange'

import Multiselect from '@/components/Form/FormMultiselect.vue'
import { multiselectTailwindClassesCompact } from '@/components/Multiselect'
import Popper from '@/components/Tooltip/Popper.vue'

import { COMPARISON_DATE_RANGE_PRESETS, DATE_RANGE_PRESETS, DateRange } from './dateRange'
import DateRangeInput from './DateRangeInput.vue'
import { PRESET_TRANSLATIONS } from './translations'

export default defineComponent({
  components: {
    DatePicker,
    Popper,
    DateRangeInput,
    Multiselect
  },
  props: {
    modelValue: {
      type: Object as PropType<{ datePreset : DateRangePreset, dateRange: any, comparisonDateRange?: any }>,
      required: true
    },
    startProp: {
      type: String,
      default: 'start'
    },
    endProp: {
      type: String,
      default: 'end'
    },
    maxDaysRange: {
      type: Number,
      default: () => undefined
    },
    maxDate: {
      type: Date,
      default: () => undefined
    },
    time: {
      type: Boolean,
      default: false
    },
    timezone: {
      type: String,
      default: () => undefined
    },
    buttonClass: {
      type: String as PropType<string | undefined>,
      default: () => undefined
    },
    referenceClass: {
      type: String as PropType<string | undefined>,
      default: () => undefined
    },
    noComparison: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const customOptionValue = 'custom' as DateRangePreset
    const customOption = {
      id: customOptionValue,
      name: t('labels.custom'),
      disabled: false
    }
    const noComparisonOptionValue = 'none'
    const noComparisonOption = {
      id: noComparisonOptionValue,
      name: t('dates.noComparison'),
      disabled: false
    }

    // Data
    const selectedPreset = ref(customOption.id)
    const selectedComparison = ref(noComparisonOption.id)

    const range = ref({
      start: new Date(0),
      end: new Date(0)
    })

    const minDragDate = ref(undefined as Date | undefined)
    const maxDragDate = ref(props.maxDate as Date | undefined)

    // Computed
    const presetOptions = computed(() => {
      const options = []
      Object.entries(DATE_RANGE_PRESETS)
        .forEach(e => {
          const date = e[1]()
          if (props.maxDaysRange !== undefined && differenceInDays(addMilliseconds(date.end, 1), date.start) > props.maxDaysRange) {
            return
          }
          options.push({
            id: e[0],
            name: PRESET_TRANSLATIONS[e[0]],
            disabled: false
          })
        })
      options.push(customOption)
      return options
    })

    const comparisonPresetOptions = computed(() => {
      const options = Object.keys(COMPARISON_DATE_RANGE_PRESETS).map(p => {
        return {
          id: p,
          name: PRESET_TRANSLATIONS[p],
          disabled: false
        }
      })
      return [noComparisonOption, ...options]
    })

    const inputLabel = computed(() => {
      return selectedPreset.value === customOptionValue ? undefined : PRESET_TRANSLATIONS[selectedPreset.value]
    })

    const comparisonInputLabel = computed(() => {
      return selectedComparison.value !== noComparisonOptionValue ? PRESET_TRANSLATIONS[selectedComparison.value] : undefined
    })

    const multiselectClasses = computed(() => {
      const classes = Object.assign({}, multiselectTailwindClassesCompact)
      classes.option = classes.option.replaceAll('py-2', 'py-0.5')
      classes.container += ' md:hidden'
      return classes
    })

    // Methods
    const useSelectedPreset = () => {
      const preset = DATE_RANGE_PRESETS[selectedPreset.value]
      if (preset !== undefined) {
        range.value = preset()
      }
    }

    const selectPreset = (id: DateRangePreset) => {
      selectedPreset.value = id
      useSelectedPreset()
    }

    const updateSelectedComparisonPreset = () => {
      if (props.modelValue.comparisonDateRange !== undefined) {
        const comparisonDateRange = props.modelValue.comparisonDateRange
        const comparisonPreset = Object.entries(COMPARISON_DATE_RANGE_PRESETS).find(e => {
          const r = e[1]({
            start: props.modelValue.dateRange[props.startProp],
            end: props.modelValue.dateRange[props.endProp]
          })
          return r.start.getTime() === comparisonDateRange[props.startProp].getTime() && r.end.getTime() === comparisonDateRange[props.endProp].getTime()
        })
        if (comparisonPreset !== undefined) {
          selectedComparison.value = comparisonPreset[0]
          return
        }
      }

      selectedComparison.value = noComparisonOptionValue
    }

    const updateModelValue = () => {
      const newValue = props.modelValue
      if (props.modelValue.dateRange[props.startProp]?.getTime() !== range.value.start.getTime() || props.modelValue.dateRange[props.endProp]?.getTime() !== range.value.end.getTime()) {
        const newDateRange: Record<string, Date> = {}
        newDateRange[props.startProp] = range.value.start
        newDateRange[props.endProp] = range.value.end
        newValue.dateRange = newDateRange
      }
      if (selectedComparison.value !== noComparisonOptionValue) {
        const comparison = COMPARISON_DATE_RANGE_PRESETS[selectedComparison.value](range.value)
        if (props.modelValue.comparisonDateRange === undefined || props.modelValue.comparisonDateRange[props.startProp]?.getTime() !== comparison.start.getTime() || props.modelValue.comparisonDateRange[props.endProp]?.getTime() !== comparison.end.getTime()) {
          const newComparisonValue: Record<string, Date> = {}
          newComparisonValue[props.startProp] = comparison.start
          newComparisonValue[props.endProp] = comparison.end
          newValue.comparisonDateRange = newComparisonValue
        }
      } else {
        newValue.comparisonDateRange = undefined
      }

      if (selectedPreset.value !== props.modelValue.datePreset) {
        newValue.datePreset = selectedPreset.value
      }

      emit('update:modelValue', newValue)
    }

    const onDatePickerDrag = (dragRange: DateRange) => {
      if (props.maxDaysRange !== undefined && startOfDay(dragRange.start).getTime() === startOfDay(dragRange.end).getTime()) {
        minDragDate.value = startOfDay(subDays(dragRange.start, props.maxDaysRange - 1))
        const max = endOfDay(addDays(dragRange.start, props.maxDaysRange - 1))
        if (props.maxDate && props.maxDate < max) {
          maxDragDate.value = props.maxDate
        } else {
          maxDragDate.value = max
        }
      }
    }

    const onPickerUnmounted = () => {
      minDragDate.value = undefined
      maxDragDate.value = props.maxDate
    }

    // Watch
    watch(
      () => selectedPreset.value,
      () => useSelectedPreset(),
      { immediate: true }
    )

    watch(
      () => range.value,
      () => {
        minDragDate.value = undefined
        maxDragDate.value = props.maxDate
        updateModelValue()
      }
    )

    watch(
      () => selectedComparison.value,
      () => {
        updateModelValue()
      }
    )

    watch(
      () => props.modelValue,
      () => {
        const start: Date = props.modelValue.dateRange[props.startProp]
        const end: Date = props.modelValue.dateRange[props.endProp]
        if (start && end) {
          range.value.start = start
          range.value.end = end
          selectedPreset.value = props.modelValue.datePreset
          updateSelectedComparisonPreset()
        } else {
          selectedPreset.value = props.modelValue.datePreset
          useSelectedPreset()
        }
      },
      { deep: true, immediate: true }
    )

    return {
      // Data
      range,
      selectedPreset,
      selectedComparison,
      noComparisonOptionValue,
      minDragDate,
      maxDragDate,

      // Computed
      presetOptions,
      comparisonPresetOptions,
      inputLabel,
      comparisonInputLabel,

      // Methods
      onDatePickerDrag,
      onPickerUnmounted,
      selectPreset,

      // Misc
      multiselectClasses,
      t
    }
  }
})
</script>

<style scoped>
@tailwind base;

.vc-container {
  @apply border-0 mx-0;
  @apply w-full;
}

@media  (min-width:768px) and (max-width:1023px) {
  .vc-container {
    @apply w-full;
  }
}
</style>
<style>
.vc-select select {
  @apply bg-none;
}
</style>
