<template>
  <div class="flex items-start p-2">
    <InformationCircleIcon class="h-4 w-4 mt-0.5 text-slate-300" />
    <span class="flex-1 text-sm text-slate-300 ml-1">{{ helpText }}</span>
  </div>
</template>

<script lang="ts">
import { InformationCircleIcon } from '@heroicons/vue/24/outline'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    InformationCircleIcon
  },
  props: {
    helpText: {
      type: String,
      required: false,
      default: () => undefined
    }
  }
})
</script>
