import { DataDefinition } from '../definition'
import { DateDimension, DateRollupDimension } from '../dimensions'

/**
 * Data preprocessor taking records from raw data to alter them, for example
 * in order to add a new calculated metric. All records in the raw data are passed
 * one by one to all preprocessors.
 * The preprocessor must return the modifed version of the record.
 */
export type Preprocessor = (record: Record<string, any>) => Record<string, any>

export function preprocessRemoveTableName (table: string): Preprocessor {
  return (record: Record<string, any>) => {
    for (const metric in record) {
      if (table.length + 1 >= metric.length) {
        continue
      }
      const name = metric.slice(table.length + 1)
      record[name] = record[metric]
      delete record[metric]
    }
    return record
  }
}

export function preprocessConvertType<T extends Record<string, any>> (definition: DataDefinition): Preprocessor {
  return (record: Record<string, any>) => {
    for (const metric in record) {
      if (definition.metrics[metric] !== undefined) {
        record[metric] = new definition.metrics[metric](record[metric]).valueOf()
      }
    }
    return record as T
  }
}

// FIXME hopefully we won't need that in the finished product
export function preprocessRemoveNull (definition: DataDefinition): Preprocessor {
  return (record: Record<string, any>) => {
    definition.dimensions.forEach(dim => {
      if (record[dim.column] === null) {
        record[dim.column] = dim instanceof DateDimension || dim instanceof DateRollupDimension ? new Date(0) : 'null'
      }
    })
    return record
  }
}
