import { ITransport, ITransportResponse, ResultSet, TransportOptions } from '@cubejs-client/core'
import fetch from 'cross-fetch'
import 'url-search-params-polyfill'

// Fork of cubejs's HttpTransport adding retry

class HttpTransportWithRetry implements ITransport<ResultSet> {
  private authorization: string
  private apiUrl: string
  private method: TransportOptions['method']
  private headers: Record<string, string>
  private credentials: TransportOptions['credentials']

  constructor ({ authorization, apiUrl, method, headers = {}, credentials }: TransportOptions) {
    this.authorization = authorization
    this.apiUrl = apiUrl
    this.method = method
    this.headers = headers
    this.credentials = credentials
  }

  public request (method: 'GET' | 'PUT' | 'POST' | 'PATCH', { baseRequestId, ...params }: Record<string, unknown>): ITransportResponse<ResultSet> {
    let spanCounter = 1
    const searchParams = new URLSearchParams(
      params && Object.keys(params)
        .map(k => ({ [k]: typeof params[k] === 'object' ? JSON.stringify(params[k]) : params[k] } as Record<string, string>))
        .reduce((a, b) => ({ ...a, ...b }), {})
    )

    let url = `${this.apiUrl}/${method}${searchParams.toString().length ? `?${searchParams}` : ''}`

    const requestMethod = this.method || (url.length < 2000 ? 'GET' : 'POST')
    if (requestMethod === 'POST') {
      url = `${this.apiUrl}/${method}`
      this.headers['Content-Type'] = 'application/json'
    }

    // Currently, all methods make GET requests. If a method makes a request with a body payload,
    // remember to add {'Content-Type': 'application/json'} to the header.

    const run = (): Promise<Response> => fetch(url, {
      method: requestMethod,
      headers: {
        Authorization: this.authorization,
        'x-request-id': baseRequestId && `${baseRequestId}-span-${spanCounter++}`,
        ...this.headers
      } as HeadersInit,
      credentials: this.credentials,
      body: requestMethod === 'POST' ? JSON.stringify(params) : null
    })

    const runRequest = async (): Promise<Response> => {
      const retryFunc = (): Promise<Response> => new Promise((resolve, reject) => {
        setTimeout(() => {
          spanCounter-- // Reverting the spanCounter so the request ID stays the same for the retry query
          try {
            resolve(run())
          } catch (e) {
            reject(e)
          }
        }, 10000)
      })

      try {
        const r = await run()
        if (r.status >= 500) {
          return await retryFunc()
        }
        return r
      } catch (e) {
        return await retryFunc()
      }
    }

    return {
      /* eslint no-unsafe-finally: off */
      async subscribe (callback) {
        let result: any = {
          error: 'network Error' // add default error message
        }
        try {
          result = await runRequest()
        } finally {
          return callback(result, () => this.subscribe(callback))
        }
      }
    }
  }
}

export default HttpTransportWithRetry
