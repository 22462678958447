import { NumberReducer, Reducer, ReducerConstructor, SerializedReducer } from '.'

/**
 * Number reducer calculating average value.
 *
 * If a metric name is given in the constructor, this field will be used to calculate the sum.
 * This can be used if you want the metric to be calculated off another metric (aka dynamic metric).
 */
export class AverageReducer extends NumberReducer implements Reducer {
  metric?: string
  count: Map<Record<string, any>, number>
  sum: Map<Record<string, any>, number>

  constructor (metric?: string) {
    super()
    this.metric = metric
    this.count = new Map()
    this.sum = new Map()
  }

  add (p: Record<string, any>, key: string, v: Record<string, any>): void {
    const m = this.metric || key
    const count = this.count.get(p)! + 1
    const sum = this.sum.get(p)! + v[m]
    this.sum.set(p, sum)
    this.count.set(p, count)
    p[key] = count ? sum / count : 0
  }

  remove (p: Record<string, any>, key: string, v: Record<string, any>): void {
    const m = this.metric || key
    const count = this.count.get(p)! - 1
    const sum = this.sum.get(p)! - v[m]
    this.sum.set(p, sum)
    this.count.set(p, count)
    p[key] = count ? sum / count : 0
  }

  init (p: Record<string, any>, key: string): void {
    super.init(p, key)
    this.count.set(p, 0)
    this.sum.set(p, 0)
  }

  static deserialize (m: SerializedReducer): ReducerConstructor {
    if (m.params === undefined) {
      throw new Error('Invalid SerializedReducer (AverageReducer): missing parameters')
    }
    return AverageReducerConstructor(m.params.metric)
  }
}

/**
 * @param metric the name of the metric to calculate the average off
 * @returns AverageReducer constructor using this metric
 */
export function AverageReducerConstructor (metric: string): ReducerConstructor {
  return class extends AverageReducer {
    constructor () { super(metric) }

    static serialize (): SerializedReducer {
      return {
        constructor: 'AverageReducer',
        params: {
          metric
        }
      }
    }
  }
}
