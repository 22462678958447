import { Grouping } from 'crossfilter2'
import { LabelFormatterCallback, TooltipComponentFormatterCallbackParams } from 'echarts'
import { CallbackDataParams, TooltipFormatterCallback, ZRColor } from 'echarts/types/dist/shared'

import i18n from '@/plugins/i18n'

import { toUTC } from '@/utils/dates'

import { ECHARTS_BAR_THEME } from '@/components/Dashboard/theme'

import { CompositeDimension, CompositeDimensionKey, COMPOSITE_DIMENSION_SEPARATOR, Dimension, TimeGranularity, TimeGranularityName } from './dimensions'

import { MetricFormatter } from '.'

/**
 * A Formatter takes a raw array of records and transforms it into a format that can be easily used by
 * charts. The format of the output (`TOutput`) varies depending on the type of chart that needs rendering.
 */
export interface Formatter<TOutput> {
  format(records: ReadonlyArray<Grouping<any, Record<string, any>>>, options: any): TOutput
}

export type TemporalFormatterOptions = {
  from: Date
  to: Date
  granularity: TimeGranularity
  timeDimension: string
  metric: string
}

/**
 * Formatter for line charts with a time axis.
 * The key for the first `Indexable` dimension is a date.
 * The key for the second `Indexable` dimension depends on the type of dimension:
 *  - A simple time dimension: one key for the metric set in options.
 *  - A composite dimension: one key for each non-temporal dimension value
 *
 * The returned data will always contain an entry for all possible values defined by the granularity set in options,
 * with 0 as a default value if no record match this date.
 */
export class TemporalFormatter implements Formatter<Record<string, Record<string, any>>> {
  format (records: ReadonlyArray<Grouping<any, Record<string, any>>>, options: TemporalFormatterOptions): Record<string, Record<string, any>> {
    const result: Record<string, Record<string, any>> = {}

    if (records.length === 0) {
      return result
    }

    const values = new Set<string>()

    const key = records[0].key
    const isComposite = typeof key === 'object' && Object.keys(key).length > 0
    if (isComposite) {
      records.forEach(r => values.add(this.getNonTemporalDimensions(r, options)))
    } else {
      values.add(options.metric)
    }

    let step = options.granularity.rollup(options.from)
    while (step <= options.to) {
      const entry: Record<string, any> = {}
      values.forEach(v => { entry[v] = 0 })
      result[options.granularity.format(step)] = entry
      step = options.granularity.add(step, 1)
    }

    if (isComposite) {
      records.forEach(r => {
        const date = options.granularity.format(r.key[options.timeDimension])
        if (result[date] !== undefined) {
          result[date][this.getNonTemporalDimensions(r, options)] = r.value[options.metric]
        }
      })
    } else {
      records.forEach(r => {
        const record = result[options.granularity.format(r.key)]
        if (record) {
          record[options.metric] = r.value[options.metric]
        }
      })
    }

    return result
  }

  private getNonTemporalDimensions (record: Grouping<any, Record<string, any>>, options: TemporalFormatterOptions): string {
    return Object.entries(record.key)
      .filter(e => e[0] !== options.timeDimension)
      .map(e => e[1] as string).join(COMPOSITE_DIMENSION_SEPARATOR)
  }
}

export type CategoryFormatterOptions = {
  dimension: Dimension,
  metrics: string[]
  granularity: TimeGranularityName
}

/**
 * Formatter for bar charts.
 * Each element in the returned array is a category.
 * Each category contains the record's dimension value and all metrics set in options.
 */
export class CategoryFormatter implements Formatter<Array<Record<string, any>>> {
  format (records: ReadonlyArray<Grouping<any, Record<string, any>>>, options: CategoryFormatterOptions): any[][] {
    const result: any[][] = []

    records.forEach(r => {
      const s: any[] = []
      s.push(options.dimension instanceof CompositeDimension
        ? new CompositeDimensionKey(r.key).valueOf()
        : r.key instanceof Date ? toUTC(r.key, options.granularity === 'hour' ? 'yyyy-MM-dd Ka' : 'yyyy-MM-dd') : r.key)
      options.metrics.forEach(m => {
        s.push(r.value[m])
      })
      result.push(s)
    })

    return result
  }
}

/**
 * Entry for value-based charts (with only one metric), such as pie charts
 */
export type ValueEntry = {
  name: string,
  value: any,
  itemStyle?: {
    color?: ZRColor
    opacity?: number
    borderRadius?: Array<number | string> | number | string
  }
}

export type ValueFormatterOptions = {
  metric: string,
  dimensionName: string,
  maxSegments?: number
}

/**
 * Formatter for pie charts.
 * Returns a sorted array of `ValueEntry`.
 * If options `maxSegments` is set, only the `maxSegments - 1` first records are selected, and the rest is summed
 * up in a "Other" entry.
 */
export class ValueFormatter implements Formatter<ValueEntry[]> {
  format (records: ReadonlyArray<Grouping<any, Record<string, any>>>, options: ValueFormatterOptions): ValueEntry[] {
    const result: ValueEntry[] = []

    const others = {
      name: i18n.global.t('labels.other'),
      value: 0
    }
    records.slice().sort((a, b) => {
      if (a.value[options.metric] < b.value[options.metric]) return 1
      if (a.value[options.metric] > b.value[options.metric]) return -1
      return 0
    })
      .forEach(r => {
        if (options.maxSegments !== undefined && result.length >= options.maxSegments - 1) {
          others.value += r.value[options.metric]
        } else {
          result.push({
            name: typeof r.key === 'object' && !(r.key instanceof Date) ? (new CompositeDimensionKey(r.key as Record<string, any>)).valueOf() : r.key,
            value: r.value[options.metric]
          })
        }
      })

    if (options.maxSegments !== undefined && result.length >= options.maxSegments - 1) {
      result.push(others)
    }

    return result
  }
}

/**
 * Calculates the relative difference between a value and the value to compare it with.
 * Returns a result that should be passed to METRIC_FORMATTERS.SIGNED_PERCENT for display.
 */
export function relativeComparison (value?: number, comparisonValue?: number): number | undefined {
  if (comparisonValue === undefined || value === undefined) {
    return undefined
  }
  return ((value - comparisonValue) / comparisonValue)
}

/**
 * Adapter for the ability to use MetricFormatter in ECharts label formatters.
 * @param f formatter
 * @param metric the name of the metric
 * @returns ECharts format string
 */
export function echartsLabelFormatter (f: MetricFormatter): LabelFormatterCallback<CallbackDataParams> {
  return (params: CallbackDataParams) => {
    if (Array.isArray(params.value)) {
      const i = params.dimensionNames?.indexOf(params.seriesName!) || 0
      return f(params.value[i])
    }
    return f(params.value)
  }
}

function makeTooltipEntry (series: any, f: MetricFormatter, labelTransform?: (v: string, idx?: number) => string): HTMLElement {
  // Must use "any" for "series" type because "axisValueLabel" is not present in the TS Type but does exist in the received object.
  const seriesNameText = series.axisValueLabel || series.name
  const entry = document.createElement('div')
  entry.classList.add('flex', 'items-center')
  const colorDot = document.createElement('span')
  colorDot.classList.add('inline-block', 'align-middle', 'rounded-full', 'w-3', 'h-3', 'mr-1')
  colorDot.style.backgroundColor = series.seriesName === 'Previous' ? ECHARTS_BAR_THEME.previousSerieColor : ((series.color as string) || 'transparent')
  const seriesName = document.createElement('span')
  seriesName.textContent = labelTransform !== undefined ? labelTransform(seriesNameText, series.seriesIndex) : seriesNameText
  seriesName.classList.add('mr-1')
  const text = document.createElement('span')
  text.classList.add('ml-5', 'text-right', 'flex-grow', 'font-semibold')
  if (series.componentSubType === 'bar') {
    if (Array.isArray(series.value)) {
      const i = series.dimensionNames?.indexOf(series.seriesName!) || 0
      text.textContent = f((series.value as any[])[i])
    } else {
      text.textContent = f(series.value)
    }
  } else if (series.componentSubType === 'pie' && series.percent !== undefined) {
    text.textContent = f(series.value) + ` (${series.percent}%)`
  } else {
    text.textContent = f(series.value)
  }
  entry.appendChild(colorDot)
  entry.appendChild(seriesName)
  entry.appendChild(text)
  return entry
}

/**
 * Adapter for the ability to use MetricFormatter in ECharts tooltip formatters.
 * @param f formatter
 * @param labelTransform transform function for the label (for example to transform country code to country name)
 * @returns ECharts format string
 */
export function echartsTooltipFormatter (f: MetricFormatter, labelTransform?: (v: string, idx?: number) => string): TooltipFormatterCallback<TooltipComponentFormatterCallbackParams> {
  return (params: TooltipComponentFormatterCallbackParams) => {
    const elements: Record<string, HTMLElement> = {}
    if (Array.isArray(params)) {
      const eligibleSeries = params.filter(s => s.seriesName !== 'NONE')
      eligibleSeries.forEach(series => {
        const s = series as any
        if (elements[s.axisIndex] === undefined) {
          const container = document.createElement('div')
          if (s.seriesName !== 'Previous') {
            const header = document.createElement('div')
            header.classList.add('font-semibold')
            header.textContent = s.axisDim === 'y' ? s.axisValue : s.seriesName
            container.appendChild(header)
          }
          elements[s.axisIndex] = container
        }
        elements[s.axisIndex].appendChild(makeTooltipEntry(series, f, labelTransform))
      })
    } else {
      const container = document.createElement('div')
      const header = document.createElement('div')
      header.classList.add('font-semibold')
      header.textContent = params.seriesName || ''
      container.appendChild(header)
      container.appendChild(makeTooltipEntry(params, f, labelTransform))
      elements[params.seriesIndex!] = container
    }
    return Object.values(elements)
  }
}
