<template>
  <div :class="wrapperClass">
    <fieldset>
      <legend class="font-semibold text-text-primary">
        {{ t('labels.role', 2) }}
      </legend>
      <template v-if="fieldErrors?.$errors">
        <p
          v-for="(error, index) in fieldErrors.$errors"
          :key="`error-${index}`"
          class="mt-2 text-sm text-red-600"
        >
          {{ error }}
        </p>
      </template>
      <div class="mt-4">
        <FormMultiselect
          v-model="role"
          name="roles"
          mode="single"
          :options="roles"
          :can-clear="!edit"
          :can-deselect="!edit"
          :label="t('labels.role')"
        />
      </div>

      <div
        v-if="enableBilling"
        class="mt-4"
      >
        <FormMultiselect
          v-model="hasRoleBilling"
          name="isBilling"
          mode="single"
          :options="billingOptions"
          :can-clear="false"
          :can-deselect="false"
          :label="t('labels.billingEmailCommunication', 2)"
        />
      </div>
    </fieldset>
  </div>
</template>

<script lang="ts">
import { ErrorObject } from '@vuelidate/core'
import { PropType, computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

import { bidderRoles, billingOptions, publisherRoles } from '@/static/roles'

import { ContextType } from '@/plugins/context/context.enum'
import { useVuelidateError } from '@/plugins/form'

import { useContextStore } from '@/store/context.store'

import { Role } from '@/models/enum/roles'

import FormMultiselect from '@/components/Form/FormMultiselect.vue'

export default defineComponent({
  components: {
    FormMultiselect
  },
  props: {
    modelValue: {
      type: Array as PropType<Role[]>,
      required: false,
      default: () => []
    },
    errors: {
      type: [Array] as PropType<ErrorObject[]>,
      required: false,
      default: () => []
    },
    wrapperClass: {
      type: String,
      required: false,
      default: () => ''
    },
    edit: {
      type: Boolean,
      required: false,
      default: false
    },
    enableBilling: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const contextStore = useContextStore()

    const model = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit('update:modelValue', value)
      }
    })

    const isBidderContext = computed(() => contextStore.contextType === ContextType.BIDDERS)

    const roles = computed(() => {
      if (isBidderContext.value) {
        return bidderRoles
      } else {
        return publisherRoles
      }
    })

    const role = computed({
      get: () => {
        if (model.value.includes(Role.ADMIN)) {
          return Role.ADMIN
        } else if (model.value.includes(Role.ADOPS)) {
          return Role.ADOPS
        } else if (model.value.includes(Role.VIEWER)) {
          return Role.VIEWER
        } else if (model.value.includes(Role.ACCOUNT_MANAGER)) {
          return Role.ACCOUNT_MANAGER
        } else return null
      },
      set: (value) => {
        const billingEnabled = hasRoleBilling.value === 1
        if (!value) {
          emit('update:modelValue', [...billingEnabled ? [Role.BILLING] : []])
          return
        }
        emit('update:modelValue', [value, ...billingEnabled ? [Role.BILLING] : []])
      }
    })

    const hasRoleBilling = computed({
      get: () => model.value.includes(Role.BILLING) ? 1 : 0,
      set: (value) => {
        if (value) {
          if (!model.value.includes(Role.BILLING)) {
            emit('update:modelValue', model.value.concat([Role.BILLING]))
          }
        } else {
          const index = model.value.indexOf(Role.BILLING)
          if (index >= 0) {
            const m = model.value.slice()
            m.splice(index, 1)
            emit('update:modelValue', m)
          }
        }
      }
    })

    const fieldErrors = computed(() => useVuelidateError(props.errors))

    return {
      t,
      role,
      roles, // options,
      fieldErrors,
      hasRoleBilling,
      billingOptions
    }
  }
})
</script>
