import { defineStore } from 'pinia'
import { ref } from 'vue'

import { Notification } from '@/types/notifications'

export const useNotificationsStore = defineStore('notifications', () => {
  const notifications = ref<Notification[]>([])

  const add = (notification: Notification): void => {
    notifications.value.push(notification)
  }

  // throttledAdd is like add but the notification won't be added if there is already
  // an active identical notification.
  const throttledAdd = (notification: Notification): void => {
    if (notifications.value.some(n =>
      n.type === notification.type && n.message === notification.message && n.title === notification.title
    )) {
      return
    }
    notifications.value.push(notification)
  }

  const remove = (notification: Notification): void => {
    notifications.value.splice(notifications.value.indexOf(notification), 1)
  }

  return {
    notifications,
    add,
    throttledAdd,
    remove
  }
})
