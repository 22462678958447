import { AxiosResponse } from 'axios'

import { LoginForm, LoginResponse } from '@/models/auth'

import api from '@/api'

export function login (data: LoginForm): Promise<AxiosResponse<LoginResponse>> {
  return api.post<LoginResponse>('/auth/login', data, { withCredentials: true })
}

export function refreshToken (): Promise<AxiosResponse<LoginResponse>> {
  return api.get<LoginResponse>('/auth/refresh-token', { withCredentials: true })
}

export function logout (): Promise<AxiosResponse<void>> {
  return api.get('/auth/logout', { withCredentials: true })
}
