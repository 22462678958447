import { RouteRecordRaw } from 'vue-router'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

export function troubleshootRules (): RouteRecordRaw[] {
  const context = ContextType.GROUPS

  return [
    {
      path: 'troubleshoot-rules',
      name: `${context}.troubleshootRules.index`,
      component: () => import('./views/Index.vue'),
      meta: {
        title: i18n.global.t('labels.troubleshootRule', 2),
        feature: 'troubleshoot_rules',
        requireService: true
      },
      children: [
        {
          path: 'create',
          name: `${context}.troubleshootRules.create`,
          component: () => import('./views/Create.vue'),
          meta: {
            title: i18n.global.t('actions.create', [i18n.global.t('app.troubleshootRule')]),
            feature: 'troubleshoot_rules'
          }
        },
        {
          path: ':troubleshootRuleId/edit',
          name: `${context}.troubleshootRules.edit`,
          component: () => import('./views/Edit.vue'),
          meta: {
            title: i18n.global.t('actions.edit', [':troubleshootRuleName']),
            pattern: ':troubleshootRuleName',
            feature: 'troubleshoot_rules'
          },
          props: route => ({ troubleshootRuleId: route.params.troubleshootRuleId })
        }
      ]
    }
  ]
}
