<template>
  <Collapse
    v-if="mode === 'disclosure'"
    v-model:collapsed="collapsed"
    as="div"
    :open-by-default="isDefaultOpen"
    class="relative"
  >
    <template #header="{collapseButtonRef}">
      <Tooltip
        :disabled="(!collapsed && !forceShowErrors) || fieldErrors?.$errors === undefined"
        :closeable="true"
        :force-show="true"
      >
        <div
          class="flex flex-row items-center justify-between space-x-2"
          :class="{
            'after:pointer-events-none after:absolute after:outline after:outline-1 after:outline-red-400 after:top-[-10px] after:bottom-[-10px] after:right-[-10px] after:left-[-10px] after:rounded': (collapsed || forceShowErrors) && errors.length
          }"
        >
          <div class="flex flex-col text-left">
            <h2 class="font-semibold text-text-primary">
              {{ title }}
            </h2>
            <span
              v-if="subTitle"
              class="mt-1 text-sm font-normal leading-tight text-text-primary"
            >{{ subTitle }}</span>
          </div>

          <slot
            name="sectionHeader"
            :open="!collapsed"
            :collapse-button-ref="collapseButtonRef"
          />
        </div>

        <template
          v-if="fieldErrors?.$errors !== undefined"
          #title
        >
          <div class="flex flex-col gap-0.5">
            <p
              v-for="(err, iE) in fieldErrors?.$errors"
              :key="iE"
              class="inline-block text-xs font-normal"
            >
              {{ err }}
            </p>
          </div>
        </template>
      </Tooltip>
    </template>
    <div
      class="pt-2.5 pb-5 space-y-6.25 px-5"
    >
      <slot />
    </div>
  </Collapse>
  <div
    v-else
    class="space-y-5"
  >
    <div
      v-if="hasTitleBlock"
      class="flex items-center gap-4"
    >
      <div class="flex flex-col text-left">
        <h2
          class="font-semibold text-text-primary"
        >
          {{ title }}
        </h2>
        <span
          v-if="subTitle"
          class="mt-1 text-sm font-normal leading-tight text-text-primary"
        >{{ subTitle }}</span>
      </div>
      <slot
        name="sectionHeader"
        :open="undefined"
        :collapse-button-ref="undefined"
      />
    </div>
    <div class="space-y-2.5">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { ErrorObject } from '@vuelidate/core'
import { computed, defineComponent, PropType, ref, unref, watch, useSlots } from 'vue'

import { useVuelidateError } from '@/plugins/form'

import Collapse from '@/components/Collapse/Collapse.vue'
import Tooltip from '@/components/Tooltip/Tooltip.vue'

import { popperEmitter, POPPER_UPDATE_EVENT } from '../Tooltip/emitter'

export default defineComponent({
  components: {
    Collapse,
    Tooltip
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Section title'
    },
    subTitle: {
      type: String,
      required: false,
      default: ''
    },
    errors: {
      type: [Array] as PropType<ErrorObject[]>,
      required: false,
      default: () => []
    },
    mode: {
      type: String as PropType<'base' | 'disclosure'>,
      required: false,
      default: 'base'
    },
    isDefaultOpen: {
      type: Boolean,
      required: false,
      default: () => false
    },
    wrapperClass: {
      type: String,
      required: false,
      default: ''
    },
    forceShowErrors: { // Show validation errors even if the section is open
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const collapsed = ref(!unref(props.isDefaultOpen))
    const slots = useSlots()

    watch(
      () => props.errors.length,
      () => {
        if (collapsed.value) {
          collapsed.value = false
        }
      }
    )

    watch(
      () => collapsed.value,
      () => {
        popperEmitter.emit(POPPER_UPDATE_EVENT)
      }
    )

    const hasTitleBlock = computed(() => props.title || props.subTitle)
    const fieldErrors = computed(() => useVuelidateError(props.errors))

    return {
      collapsed,
      hasTitleBlock,
      fieldErrors,
      slots
    }
  }
})
</script>
