<template>
  <div
    class="flex flex-col justify-center h-full min-h-screen px-2 py-8 bg-white bg-cover lg:flex-row lg:p-0"
    :style="`background: linear-gradient(45deg, #FFF, #F3E7FF);`"
  >
    <div class="lg:w-1/2 lg:flex lg:flex-col lg:justify-center">
      <router-view />
    </div>
    <div class="hidden lg:block lg:flex-1 lg:relative lg:ml-auto">
      <span
        class="sticky block w-full h-full bg-left-bottom bg-no-repeat"
        :style="`background-image: url(${storageUrl}/img/adagio_connexion.png); background-size:200%;`"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    const storageUrl = import.meta.env.VITE_STORAGE_URL

    return {
      storageUrl
    }
  }
})
</script>
