import { defineStore } from 'pinia'
import { ref } from 'vue'

import { Invite } from '@/models/invite'

export const useInviteStore = defineStore('invite', () => {
  const currentInvite = ref<Invite | null>(null)

  const setCurrentInvite = (invite: Invite): void => {
    currentInvite.value = invite
  }

  const clearCurrentInvite = (): void => {
    currentInvite.value = null
  }

  return {
    currentInvite,
    setCurrentInvite,
    clearCurrentInvite
  }
})
