import { RouteRecordRaw } from 'vue-router'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

import { Role } from '@/models/enum/roles'

export function homeBidders (context: ContextType): RouteRecordRaw[] {
  return [
    {
      path: 'bidders',
      name: `${context}.index`,
      component: () => import('./views/Index.vue'),
      meta: {
        layout: 'heightFull',
        title: i18n.global.t('labels.bidder', 100),
        feature: 'bidders'
      },
      children: [
        {
          path: 'create',
          name: `${context}.create`,
          component: () => import('./views/Create.vue'),
          meta: {
            title: i18n.global.t('actions.create'),
            requireService: true,
            feature: 'bidders'
          }
        }
      ]
    }
  ]
}

export function bidders (context: ContextType): RouteRecordRaw[] {
  const r: RouteRecordRaw[] = [
    {
      path: '',
      name: `${context}.home`,
      component: () => import('./views/Home.vue'),
      meta: {
        title: i18n.global.t('labels.home'),
        hideTitle: true,
        feature: 'bidders'
      }
    },
    {
      path: 'settings',
      name: `${context}.settings`,
      component: () => import('./views/Settings.vue'),
      props: route => ({ bidderId: route.params.bidderId }),
      meta: {
        layout: 'base',
        title: i18n.global.t('labels.setting', 100),
        roles: [Role.ADMIN, Role.ACCOUNT_MANAGER, Role.PSM],
        feature: 'bidders'
      }
    }
  ]

  r[0].redirect = (to) => {
    return { name: `${context}.dashboards.show`, params: { bidderId: to.params.bidderId, dashboardId: '43' } }
  }

  return r
}
