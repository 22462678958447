// currencyFormat: Formats a number as a currency
export function currencyFormat (amount: number, currency: string, isCents: boolean): string {
  if (!amount) {
    amount = 0.0
  }
  if (isCents) {
    amount /= 100
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 2
  }).format(amount)
}

export function displayCurrencyConversion (inCurrency: string, outCurrency: string, amount: number): string {
  const etalon = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: inCurrency,
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 0,
    // Fix "maximumFractionDigits is out of range" error in old Safari browser
    // https://stackoverflow.com/questions/41045270/range-error-with-tolocalestring-with-maximumnumber-of-digits-0
    minimumFractionDigits: 0
  }).format(1)

  const converted = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: outCurrency,
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 10
  }).format(amount)

  return `${etalon} = ${converted}`
}
